import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
  Select,
  Snackbar,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';

import apiCall from '../apiHelper';
import { useStyles, theme } from '../stylesheets';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CooperativesEdit = ({
  id,
  serviceName,
  dateCreated,
  shortName,
  isActive,
  sharedServiceId,
  sharedService,
  handleDialogClose,
  ...props
}) => {
  const [cooperative_name, setCooperativeName] = useState(serviceName);
  const [short_name, setShortName] = useState(shortName);
  const [CoopServiceValues, setCoopServiceValues] = useState({
    cooperative: sharedServiceId,
  });
  const [result, setResult] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const handleFetchCoopservices = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperativeservices/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCoopservices(pageNumber, results);
          } else {
            setResult(results);
          }
        })
        .catch(error => error);

    apiCall('', sessionStorage.getItem('token'), 'get', 'cooperativeservices/')
      .then(response => {
        if (response.next !== null) {
          handleFetchCoopservices(2, response.results);
        }
      })
      .catch(error => {
        setResult(error);
      });
  }, []);

  const handleSubmit = evt => {
    evt.preventDefault();

    const data = { cooperative_name, short_name };

    apiCall(
      data,
      sessionStorage.getItem('token'),
      'patch',
      'cooperatives/' + id + '/'
    )
      .then(response => {
        if (response) {
          history.go(0);
          setOpen(true);
        }
      })
      .catch(error => {
        setResult(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function handleChange(event) {
    setCoopServiceValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.formStyle}>
        <ThemeProvider theme={theme}>
          <Typography variant="h6" className={classes.textField}>
            Update {short_name}
          </Typography>
        </ThemeProvider>
        <br />
        <TextField
          id="filled-name"
          label="Name"
          className={classes.textField}
          value={cooperative_name}
          onChange={e => setCooperativeName(e.target.value)}
          margin="normal"
          variant="filled"
        />
        <TextField
          id="filled-short_name"
          label=" Short Name"
          className={classes.textField}
          value={short_name}
          onChange={e => setShortName(e.target.value)}
          margin="normal"
          variant="filled"
        />
        <form className={classes.root} autoComplete="off">
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-filled-label">
              Shared Service
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={CoopServiceValues.cooperative}
              inputProps={{
                name: 'cooperative',
                id: 'age-native-simple',
              }}
              onChange={handleChange}
            >
              {result
                ? result.map(sharedService => (
                    <MenuItem key={sharedService.id} value={sharedService.id}>
                      {sharedService.coop_service_name}
                    </MenuItem>
                  ))
                : []}
            </Select>
          </FormControl>
        </form>
        <br />
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button onClick={handleDialogClose} variant="outlined">
          Cancel
        </Button>
      </form>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Service successfully created!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CooperativesEdit;
