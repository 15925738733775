import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRefresh,
  useNotify,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { ApproveButton } from '../../layout';
import { Transition, SectionTitle } from '../utils';

import { userEditStyles } from '../stylesheets';
import { apiFullCall } from '../apiHelper';

const UserDelete = props => {
  const classes = userEditStyles();

  const {
    isXSmall, 
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    onClose, 
    open, 
    record,
     ...rest 
  } = props;

  let fullName = record && record["middle_name"] ? (
    // include "middle name" if it exists
    `${record["first_name"]} ${record["middle_name"]} ${record["last_name"]}`
  ) : `${record["first_name"]} ${record["last_name"]}`

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="delete-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="delete-dialog-title"
      >
        <SectionTitle label="Please confirm..." />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx(
            'request',
            props.className
          )}
          submitOnEnter={false} 
          toolbar={
            <DeleteUserToolbar
              onClose={onClose}
              isXSmall={isXSmall}
              {...rest}
            />
          }
        >
          <TextInput
            disabled
            label="Name"
            source="name"
            defaultValue={fullName}
            className={classes.input}
          />
          <TextInput
            disabled
            label="Phone number"
            source="username"
            defaultValue={record["username"]}
            className={classes.input}
          />
          <TextInput
            disabled
            label="User id"
            source="id"
            defaultValue={record["id"]}
            className={clsx(classes.input, "hidden")}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

export default UserDelete;

// Handling "delete" mode
const DeleteUserToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { onClose, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="Cancel"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={onClose}
      />
      <DeleteUserButton {...rest} /> 
    </Toolbar>
  )  
};

const DeleteUserButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { onClose, ...rest } = props;

  // destructure "reason" to pass to API call
  const { id } = formState && formState.values;

  const token = sessionStorage.getItem('token');

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  const handleDeleteUser = React.useCallback(() => {

    // Ensure that we have a "user id"
    if (!!id) {

      apiFullCall(
        {}, 
        token, 
        'delete', 
        `users/${id}/`
      ).then(res => {

        if (res) {
          setLoading(!loading);
          const { status } = res;

          if (status === 200 || status === 204) {
            // HTTP Status 204: No content (for delete)

            // go back to user list
            onClose();
            refresh();

            // when successfully submitted
            notify('The user has been deleted', 'info');
            
          } else if (status === 400) {
            notify(`Please select user again!`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(!loading);
        };

      }).catch(
        error => console.error('Error while sending:', error)
      );

    } else {

      // inform user to enter a "reason"
      notify(`Please select a user again!`, 'warning');
    }

  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Delete"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleDeleteUser} 
    />
  );
};
