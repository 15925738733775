import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import pure from 'recompose/pure';

import { FieldProps, Service } from '../../types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

interface Props extends FieldProps<Service> {
  unit?: number;
  unit_of_measure?: string;
}

const UnitMeasureField: FC<Props> = ({ record, unit, unit_of_measure }) => {
  const classes = useStyles();

  return record ? (
    <div className={classes.root}>
      <i>per</i>&nbsp;{record.unit}
      {record.unit_of_measure}
    </div>
  ) : null;
};

const PureUnitMeasureField = pure(UnitMeasureField);

PureUnitMeasureField.defaultProps = {
  source: 'unit',
  label: 'Unit Measure',
};

export default PureUnitMeasureField;
