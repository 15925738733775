import React, { useEffect, useState } from 'react';
import { Loading, Error } from 'react-admin';
import apiCall from '../apiHelper';
import EnhancedTable from './UserRequests';

import { UserRequest } from '../../types';

export const UserRequestsView = ({ record, ...props }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserRequest>({ requests: {} });

  useEffect(() => {
    let url =
      record.is_farmer === 'True' ? 'farmerrequests' : 'providerrequests';

    apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      url + '/?userid=' + record.user_id
    )
      .then(response => {
        setLoading(false);
        setUser({ requests: response });
      })
      .catch(error => {
        setLoading(false);
        setError(error);
      });
  }, [record.is_farmer, record.user_id]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!user.requests) return null;

  return (
    <div>
      {record.is_farmer === 'True' ? (
        <EnhancedTable requests={user.requests} />
      ) : (
        <div>Provider Under implementation</div>
      )}
    </div>
  );
};
