import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import CancelNow from '@material-ui/icons/Clear';

export const CancelButton = ({ onCancel = f => f, ...rest }) => (
  <Button label="Cancel" onClick={onCancel}>
    <CancelNow />
  </Button>
);

CancelButton.propTypes = {
  onCancel: PropTypes.func,
};
