import React, { Fragment, useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceInput,
  ReferenceField,
  SearchInput,
  TextField,
  TextInput,
  BooleanField,
  DateField,
} from 'react-admin';
import {
  makeStyles,
  useMediaQuery,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';
import { FullNameField, GenderField, CustomBooleanField } from './CustomFields';
import UserAcionList from './CustomFields';
import SearchUserDialog from './createuser/SearchUser';

// import MobileGrid from './MobileGrid';

const UserFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="customer_id" reference="customers">
      <AutocompleteInput
        optionText={choice => `${choice.first_name} ${choice.last_name}`}
      />
    </ReferenceInput>
    <DateInput source="date_gte" />
    <DateInput source="date_lte" />
    <TextInput source="total_gte" />
    <NullableBooleanInput source="returned" />
  </Filter>
);

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
  heading: { fontWeight: 'bold' },
});

class TabbedDatagrid extends React.Component {
  /**
   * @Returns Users
   * All users, Service providers and farmers
   */
  tabs = [
    { id: 'users', name: 'all' },
    { id: 'is_provider', name: 'SERVICE PROVIDERS' },
    { id: 'is_farmer', name: 'FARMERS' },
    { id: 'un_approved', name: 'NEW USERS' },
  ];

  state = { users: [], is_provider: [], is_farmer: [], approved: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props; // eslint-disable-line
    value === 'is_farmer'
      ? setFilters({ is_farmer: true, status: value, approved: true })
      : value === 'is_provider'
      ? setFilters({ status: value, is_provider: true, approved: true })
      : value === 'un_approved'
      ? setFilters({ status: value, approved: false })
      : setFilters({ status: value });
  };

  render() {
    const { isXSmall, classes, filterValues, ...props } = this.props;

    return (
      <Fragment>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        {isXSmall ? (
          <div>hello</div>
        ) : (
          // <MobileGrid {...props} ids={this.state[filterValues.status]} />
          <div>
            {filterValues.status === 'users' && (
              <Datagrid
                {...props}
                ids={this.state.users}
                optimized
                // rowClick="show"
                classes={{ headerCell: classes.heading }}
              >
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Name"
                  link={false}
                >
                  <FullNameField source="first_name" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Phone Contact"
                  link={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <TextField source="role" label="Position" />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Gender"
                  link={false}
                >
                  <GenderField source="gender" />
                </ReferenceField>
                <DateField
                  source="date_added"
                  label="Date Registered"
                  locales="fr-FR"
                />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Farmer"
                  link={false}
                >
                  <CustomBooleanField source="is_farmer" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Provider"
                  link={false}
                >
                  <CustomBooleanField source="is_provider" />
                </ReferenceField>
                <BooleanField source="is_active" label="Active" />
                <UserAcionList source="id" label="Action" />
              </Datagrid>
            )}
            {filterValues.status === 'is_provider' && (
              <Datagrid
                {...props}
                ids={this.state.is_provider}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Name"
                  link={false}
                >
                  <FullNameField source="first_name" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Phone Contact"
                  link={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <TextField source="role" label="Position" />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Gender"
                  link={false}
                >
                  <GenderField source="gender" />
                </ReferenceField>
                <DateField
                  source="date_added"
                  label="Date Registered"
                  locales="fr-FR"
                />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Occupation"
                  link={false}
                >
                  <TextField source="occupation" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Provider"
                  link={false}
                >
                  <CustomBooleanField source="is_provider" />
                </ReferenceField>
                <BooleanField source="is_active" label="Active" />
                <UserAcionList source="id" label="Action" />
              </Datagrid>
            )}
            {filterValues.status === 'is_farmer' && (
              <Datagrid
                {...props}
                ids={this.state.is_farmer}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Name"
                  link={false}
                >
                  <FullNameField source="first_name" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Phone Contact"
                  link={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <TextField source="role" label="Position" />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Gender"
                  link={false}
                >
                  <GenderField source="gender" />
                </ReferenceField>
                <DateField
                  source="date_added"
                  label="Date Registered"
                  locales="fr-FR"
                />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Occupation"
                  link={false}
                >
                  <TextField source="occupation" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Farmer"
                  link={false}
                >
                  <CustomBooleanField source="is_farmer" />
                </ReferenceField>
                <BooleanField source="is_active" label="Active" />
                <UserAcionList source="id" label="Action" />
              </Datagrid>
            )}
            {filterValues.status === 'un_approved' && (
              <Datagrid
                {...props}
                ids={this.state.un_approved}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Name"
                  link={false}
                >
                  <FullNameField source="first_name" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Phone Contact"
                  link={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <TextField source="role" label="Position" />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Gender"
                  link={false}
                >
                  <GenderField source="gender" />
                </ReferenceField>
                <DateField
                  source="date_added"
                  label="Date Registered"
                  locales="fr-FR"
                />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Farmer"
                  link={false}
                >
                  <CustomBooleanField source="is_farmer" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Provider"
                  link={false}
                >
                  <CustomBooleanField source="is_provider" />
                </ReferenceField>
                <BooleanField source="is_active" label="Active" />
                <UserAcionList source="id" label="Action" />
              </Datagrid>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const StyledTabbedDatagrid = props => {
  const classes = useDatagridStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return <TabbedDatagrid classes={classes} isXSmall={isXSmall} {...props} />;
};

const CoopUserList = ({ classes, ...props }) => {
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/cooperativebranchusers');
  }, [history]);

  return (
    <>
      <List
        title="Sacco Members"
        {...props}
        filterDefaultValues={{ status: 'users' }}
        sort={{ field: 'first_name', order: 'DESC' }}
        perPage={10}
        filters={<UserFilter />}
      >
        <StyledTabbedDatagrid />
      </List>
      <Route path="/cooperativebranchusers/create">
        {({ match }) => (
          <>{match && <SearchUserDialog close={handleClose} {...props} />}</>
        )}
      </Route>
    </>
  );
};

export default CoopUserList;
