import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { ApproveButton } from '../../layout';
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import allSaccoMembersStyle from '../../stylesheets/jss/saccoDashboard';
import moment from 'moment';

interface Props {
  pendingRequestsCount: number;
  pendingRequests: any;
  handleApproval: any;
}

const PendingApproval: FC<Props> = ({
  handleApproval,
  pendingRequestsCount,
  pendingRequests,
}) => {
  const classes = allSaccoMembersStyle();
  const translate = useTranslate();

  return (
    <Card className={classes.root}>
      <Typography variant="h5" component="h2" className={classes.title}>
        {translate('pos.dashboard.pending_approval')} - {pendingRequestsCount}
      </Typography>
      <List dense={true}>
        {pendingRequests &&
          pendingRequests.map(record => (
            <ListItem key={record.id}>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText
                primary={translate('pos.dashboard.order.requestNo', {
                  request_number: record.request_number,
                  request_date: moment(record.request_date).format(
                    'DD-MM-YYYY'
                  ),
                  // request_date: new Date(record.request_date).toLocaleString('en-GB')
                })}
                secondary={translate('pos.dashboard.order.items', {
                  service_name:
                    record.farmer_requested_services[0]['service_name'],
                  nb_items:
                    record.farmer_requested_services[0]['number_of_items'],
                  customer_name: record.farmer['farmer_full_name'],
                })}
              />
              <ListItemSecondaryAction>
                <span className={classes.cost}>{record.total}/=</span>
                <ApproveButton
                  handleClick={() => handleApproval(`${record.id}`)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Card>
  );
};

export default PendingApproval;
