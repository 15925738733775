import ProviderServiceIcon from '@material-ui/icons/Collections';

import ProviderServiceList from './ProviderServiceList';

export default {
  list: ProviderServiceList,
  create: ProviderServiceList,
  edit: ProviderServiceList,
  icon: ProviderServiceIcon,
};
