import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Snackbar, TextField, Typography, Checkbox } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import apiCall from '../apiHelper';
import { useStyles } from '../stylesheets/sharedServiceStyle';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CooperativesCreate = ({ handleCreateSharedServiceClose }) => {
  const [checked, setChecked] = React.useState(false);
  const [coopserviceName, setCoopserviceName] = React.useState('');
  const [short_name, setShortName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const handleSubmit = evt => {
    evt.preventDefault();

    const data = {
      coop_service_name: coopserviceName,
      short_name,
      description,
      is_active: checked,
    };

    apiCall(
      data,
      sessionStorage.getItem('token'),
      'post',
      'cooperativeservices/'
    )
      .then(response => {
        history.go(0);
        setOpen(true);

      })
      .catch(error =>
        console.error('Error while creating a service:', error)
      );
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit} className={classes.formStyle}>
        <Typography>Create a Shared Service</Typography>
        <TextField
          id="filled-name"
          label="Name"
          className={classes.textField}
          value={coopserviceName}
          onChange={e => setCoopserviceName(e.target.value)}
          margin="normal"
          variant="filled"
        />
        <br />
        <TextField
          id="filled-short_name"
          label=" Short Name"
          className={classes.textField}
          value={short_name}
          onChange={e => setShortName(e.target.value)}
          margin="normal"
          variant="filled"
        />
        <br />
        <TextField
          id="filled-short_name"
          label="Description"
          className={classes.textField}
          value={description}
          onChange={e => setDescription(e.target.value)}
          margin="normal"
          variant="filled"
        />
        <br />
        Active
        <Checkbox
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
        />
        <br />
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          onClick={handleCreateSharedServiceClose}
          variant="outlined"
          className={classes.button}
        >
          Cancel
        </Button>
      </form>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Shared Service successfully created!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CooperativesCreate;
