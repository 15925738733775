import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  TextField,
} from 'react-admin';

import {
  Box,
  Button,
  Divider,
  Tabs, 
  Tab,
  Typography,
  useMediaQuery, 
} from '@material-ui/core';

import UserLinkField from './UserLinkField';
import UserProgramsField from './UserProgramsField';
import UserDelete from './UserDelete';

import { ActionButton, ListActions } from '../../layout';
import { userDatagridStyles } from '../stylesheets';

const UserFilter = props => {
  const FullNameField = ({ record }) => (
    <span>
      {record.first_name} {record.middle_name} {record.last_name}
    </span>
  );
  const matchSuggestion = (filterValue, choice) =>
    choice.first_name.match(filterValue) || choice.last_name.match(filterValue);
  const inputText = choice => `${choice.first_name} ${choice.last_name}`;

  return (
    <Filter {...props}>
      <ReferenceInput label="User" source="id" reference="users">
        <AutocompleteInput
          optionText={<FullNameField />}
          matchSuggestion={matchSuggestion}
          inputText={inputText}
          shouldRenderSuggestions={val => val.trim().length > 2}
        />
      </ReferenceInput>
      <DateInput label="Registration Date" source="date_added" />
    </Filter>
  );
};

class TabbedDatagrid extends React.Component {
  /**
   * @Returns Users / farmers
   */
  tabs = [{ id: 'is_farmer', name: 'ALL' }];

  state = { 
    users: [], 
    is_provider: [], 
    is_farmer: [], 
    new_users: [],
    active: [],
    inactive: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props; // eslint-disable-line
    value === 'is_farmer'
      ? setFilters({ is_farmer: true, status: value })
      : setFilters({ status: value });
  };

  render() {
    const { 
      isXSmall, 
      classes, 
      filterValues, 
      handleOpen,
      hasCreate,
      hasList, 
      hasEdit, 
      hasShow,
      total,
      // handle "delete" modal
      handleData,
      isAdmin,
      onClick,
      ...props
    } = this.props;

    // Utilize this within component to tap into "state" changes
    const ActionUserMenu = (basePath = '', record = { id: ''}) => {

      // for agent
      if (!isAdmin) {
        return ([
          {label: 'Update', path: `${basePath}/${record['id']}`},
        ]);
      };

      // for Admin
      return ([
        {label: 'Update', path: `${basePath}/${record['id']}`},
        { 
          label: 'Delete', 
          // receives single "record" to pass back to "UserDelete" component
          handleClickEvent: (x) => { 
            return (
              onClick(),
              handleData(x)
            ); 
          }
        },
      ]);
    };

    return (
      <>
        {hasCreate && 
          !!total && 
            total === 0 && 
              null
        }
        {!!total && 
          total !== 0 &&
            <>
              <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={this.handleChange}
              >
                {this.tabs.map(choice => (
                  <Tab key={choice.id} label={choice.name} value={choice.id} />
                ))}
              </Tabs>
              <Divider />
              <div>
                {filterValues.status === 'is_farmer' && (
                  <Datagrid
                  {...props}
                  ids={this.state.is_farmer}
                  optimized
                  classes={{ headerCell: classes.heading }}
                  >
                    {/* <NumberField /> */}
                    <UserLinkField />
                    <TextField
                      source="gender"
                      label="Gender"
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <TextField 
                      source="username" 
                      label="Contact" 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <TextField 
                      source="user_number" 
                      label="User ID" 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <DateField 
                      source="date_added" 
                      label="Registered" 
                      showTime 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <UserProgramsField />
                    {/* <VillageField
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens} 
                    />
                    <DistrictField /> */}
                    <ActionButton MenuOptions={ActionUserMenu} />
                  </Datagrid>
                )}
                {filterValues.status === 'users' && (
                  <Datagrid
                    {...props}
                    optimized
                    ids={this.state.users}
                    classes={{ headerCell: classes.heading }}
                  > 
                    <UserLinkField />
                    <TextField
                      source="gender"
                      label="Gender"
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <TextField 
                      source="username" 
                      label="Contact" 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <TextField 
                      source="user_number" 
                      label="User ID" 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <DateField 
                      source="date_added" 
                      label="Registered" 
                      showTime 
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <UserProgramsField />
                    {/* <VillageField
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens} 
                    />
                    <DistrictField /> */}
                    <ActionButton MenuOptions={ActionUserMenu} />
                  </Datagrid>
                )}
              </div>
            </>
        }
      </>
    );
  }
};

const StyledTabbedDatagrid = props => {
  const classes = userDatagridStyles();
  return <TabbedDatagrid classes={classes} {...props} />;
};

const Empty = ({ basePath, resource }) => ( // eslint-disable-line
  // TODO: will be used in rendering (If DEA has no registered users)
  <Box textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      No products available
    </Typography>
    <Typography variant="body1">
      Create one or import from a file
    </Typography>
    <CreateButton basePath={basePath} />
    <Button onClick={null}>Import</Button>
  </Box>
);

const UserList = ({ classes, permissions, ...props }) => {
  const location = useLocation();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  let superuser = permissions && permissions.includes('superuser');
  let agent = permissions && permissions.includes('agent');

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});

  // handles "delete" model open/close
  const handleOpenModal = () => setOpen(!open);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  return permissions !== undefined ? (
    (superuser || agent) ? (
      <>
        <List
          {...props}
          filterDefaultValues={{ is_farmer: true, status: "is_farmer" }}
          actions={<ListActions label='Register User' />}
          filters={<UserFilter />}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={15}
        >
          <StyledTabbedDatagrid
            handleData={handleData}
            isXSmall={isXSmall}
            isAdmin={superuser}
            onClick={handleOpenModal}
          />
        </List>
        <UserDelete        
          isXSmall={isXSmall}
          onClose={handleOpenModal}
          open={open}
          record={data} 
          {...props} 
        />
      </>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

export default UserList;
