import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

export const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 270,
    width: 270,
  },

  formStyle: {
    width: '100%',
    margin: '20px 12px 0px 8px',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 270,
    width: 270,
  },

  button: {
    margin: theme.spacing(1),
  },
}));
