import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import clsx from 'clsx';
import {
  Button,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

import { stringify } from 'query-string';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import CooperativesEdit from './CooperativesEdit';
import apiCall from '../apiHelper';

const useStyles = makeStyles(theme => ({
  list: {
    width: 330,
  },
  fullList: {
    width: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 270,
    width: 270,
  },

  formStyle: {
    width: '100%',
    margin: '20px 12px 0px 8px',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 270,
    width: 270,
  },

  button: {
    margin: theme.spacing(1),
  },
}));

export default function SwipeableTemporaryDrawer({
  id,
  serviceName,
  dateCreated,
  shortName,
  isActive,
  sharedServiceId,
  sharedService,
  record = {},
  source,
  basePath,
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activate, setActivate] = React.useState(false);
  const [terminate, setTerminate] = React.useState(false);
  const [cooperative, setCooperative] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleViewialogClose = () => setCooperative(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // handle activating accounts dialog
  const handleActivate = event => {
    handleClose(event);
    setActivate(true);
  };

  const handleDialogClose = () => setTerminate(false);

  const handleActivateDialogClose = () => setActivate(false);

  const handleActivateConfirm = () => {
    apiCall(
      { is_active: true },
      sessionStorage.getItem('token'),
      'patch',
      'cooperatives/' + id + '/'
    )
      .then(response => {
        if (response) {
          setActivate(false);
          window.location.reload(false);
        }
      })
      .catch(error => console.error('Error while activating:', error));
  };

  const handleDeactivate = event => {
    handleClose(event);
    setTerminate(true);
  };

  const handleViewCooperative = event => {
    handleClose(event);
    setCooperative(true);
  };
  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDeactivateConfirm = () => {
    apiCall(
      { is_active: false },
      sessionStorage.getItem('token'),
      'patch',
      'cooperatives/' + id + '/'
    )
      .then(response => {
        if (response) {
          setTerminate(false);
          window.location.reload(false);
        }
      })
      .catch(error => console.error('Error while deactivating:', error));
  };

  const editCooperative = (anchor, id, record, toggleDrawer) => {
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
      >
        <CooperativesEdit
          id={id}
          record={record}
          serviceName={serviceName}
          dateCreated={dateCreated}
          shortName={shortName}
          isActive={isActive}
          sharedServiceId={sharedServiceId}
          sharedService={sharedService}
          handleDialogClose={toggleDrawer}
        />
      </div>
    );
  };
  return (
    <div>
      {
        <React.Fragment>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Action <ArrowDropDownOutlinedIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.indexedz}
          >
            <MenuItem
              onClick={handleViewCooperative}
              className={classes.menuItem}
            >
              View Details
            </MenuItem>

            {isActive ? (
              <MenuItem className={classes.menuItem} onClick={handleDeactivate}>
                Deactivate
              </MenuItem>
            ) : !isActive ? (
              <MenuItem className={classes.menuItem} onClick={handleActivate}>
                activate
              </MenuItem>
            ) : (
              <>
                <MenuItem className={classes.menuItem} onClick={handleClick}>
                  Approve
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleClose}>
                  Reject
                </MenuItem>
              </>
            )}
            <MenuItem
              component={Link}
              to={{
                pathname: '/cooperativebranches',
                search: stringify({
                  page: 1,
                  perPage: 25,
                  sort: 'id',
                  order: 'DESC',
                  filter: JSON.stringify({ cooperative_id: id }),
                }),
                state: localStorage.setItem('service', serviceName),
              }}
              className={classes.menuItem}
            >
              View Branches
            </MenuItem>
            <MenuItem
              component={Link}
              to={{
                pathname: '/cooperativebranches/create',
                state: {
                  serviceId: localStorage.setItem('serviceId', id),
                  serviceName: localStorage.setItem('serviceName', serviceName),
                },
              }}
              className={classes.menuItem}
            >
              Add Branch
            </MenuItem>
          </Menu>
          <Drawer
            anchor="right"
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
            className={classes.drawerSize}
          >
            {editCooperative('right', id, record, toggleDrawer('right', false))}
          </Drawer>
        </React.Fragment>
      }
      {/* dialog deactivating*/}
      <Dialog
        open={terminate}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deactivate Acount?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure! You want to deactivate this related shared service?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeactivateConfirm}
            size="small"
            color="primary"
            variant="outlined"
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={handleDialogClose} size="small" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog activating*/}
      <Dialog
        open={activate}
        onClose={handleActivateDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Activate Acount?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure! You want to activate this related shared service?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleActivateConfirm}
            size="small"
            color="primary"
            variant="outlined"
            autoFocus
          >
            Yes
          </Button>
          <Button
            onClick={handleActivateDialogClose}
            size="small"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog viewing*/}
      <Dialog
        open={cooperative}
        onClose={handleViewialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{'Activate Acount?'}</DialogTitle> */}
        <DialogContent>
          <DialogContentText
            style={{ width: '100%', height: '350px' }}
            id="alert-dialog-description"
          >
            <h2> {sharedService}</h2>
            <h3 style={{ color: '#E02027', textDecoration: 'underline' }}>
              {serviceName}
            </h3>
            <table style={{ width: '100%', color: '#000000' }}>
              <tr>
                <td style={{ paddingRight: '60px' }}>Name: </td>
                <td>{serviceName}</td>
              </tr>
              <p></p>
              <tr>
                <td>Date: </td>
                <td>
                  <Moment format="DD-MM-YYYY">{dateCreated}</Moment>
                  <Moment
                    fromNow
                    style={{ marginLeft: '15px', fontStyle: 'italic' }}
                  >
                    {dateCreated}
                  </Moment>
                </td>
              </tr>
              <p></p>
              <tr>
                <td>Active: </td>
                <td>{isActive ? 'Yes' : 'No'}</td>
              </tr>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDrawer('right', true)}
            className={classes.menuItem}
            variant="contained"
            style={{ color: 'black' }}
            autoFocus
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
