import { makeStyles } from '@material-ui/core/styles';

const maxWidth = 599.98;   // sm (down)

export const requestStyles = makeStyles(theme => ({
  total: {
    fontWeight: 'bold',
  },
  heading: {
    fontWeight: 'bold',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'rows',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: theme.typography.body1,
  cardContentRow: {
    display: 'flex',
    flexDirection: 'rows',
    alignItems: 'center',
    margin: '0.5rem 0',
  },
  container: {
    minWidth: '35em',
    marginLeft: '1em',
  },
  rightAlignedCell: {
    textAlign: 'right',
  },
  boldCell: {
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  requestDialog: {
    minWidth: '600px',
    [`@media (max-width: ${maxWidth}px)`]: {
      minWidth: '250px',
    },
  },
  requestTotal: {
    fontSize: '1.02rem',
    fontWeight: '600',
  },
  requestDelivery: {
    fontSize: '0.95rem',
    fontWeight: '600',
    textAlign: 'right !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  requestItemType: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  approveButton: {
    float: 'right',
    padding: '10px 20px',
    margin: '0 10px',
    color: '#4caf50',
    borderColor: '#4caf50',
  },
  rejectButton: {
    float: 'right',
    padding: '10px 20px',
    margin: '0 10px',
    color: '#df1f26',
    borderColor: '#df1f26',
  },
  formRow: {
    width: '100%',
    display: 'flex',
  },
  formColumn: {
    width: '50%',
    padding: '5px 10px',
  },
  formEntry: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '18px',
  },
  captureDeliveryPopOver: {
    left: '28%',
    border: '1px solid #488a4b',
    bottom: '70px',
    padding: '10px',
    zIndex: '9999',
    position: 'absolute',
    width: '250px',
    background: '#ffffff',
    boxShadow: '0px 0px 10px 0px',
    borderRadius: '5px',
  },
  popOverNegative: {
    color: '#df1f26',
    margin: '0 10px',
    padding: '5px',
    borderColor: '#df1f26',
    borderRadius: '50px',
  },
  popOverPositive: {
    color: '#488a4b',
    margin: '0 10px',
    padding: '5px',
    float: 'right',
    borderColor: '#488a4b',
    borderRadius: '50px',
  },
  momentWarning: {
    color: '#ceaa22',
    fontStyle: 'italic',
  },
  momentSuccess: {
    color: '#488a4b',
    fontStyle: 'italic',
  },
  dividerSuccess: {
    marginTop: '10px',
    marginBottom: '10px',
    background: '#488a4b',
  },
  approverLabel: {
    fontStyle: 'italic',
    fontSize: '15px',
    fontWeight: '700',
  },
  approver: {
    fontSize: '20px',
  },
  approvalDate: {
    fontStyle: 'italic',
    fontSize: '15px',
  },
  deliveryLabel:{
    fontSize:'18px',
    fontWeight:'700',
  },
  deliveryDate:{
    fontSize:'18px',
    fontWeight:'700',
    fontStyle:'italic'
  },
  iconSuccess:{
    color:'#488a4b'
  },
  iconDanger:{
    color:'#df1f26'
  },
  userDialog:{
    minWidth:'1200px',
  },
  profilePhoto:{
    display:'none',
  },
  formThreeColumn: {
    width: '33%',
    padding: '5px 10px'
  },
  columnTwoThirds: {
    width: '66%',
    padding: '5px 10px'
  },
  formTwoColumn: {
    width: '50%',
    padding: '5px 10px'
  },
  formOneColumn:{
    width: '100%',
    padding: '5px 10px'
  },
  formInput:{
    width:'100%',
  },
  parent:{
    position:'relative',
    width:'100%'
  },
  searchResults:{
    position: 'absolute',
    zIndex: '9999',
    maxHeight: '300px',
    overflow: 'scroll',
    background:'#ffffff',
    width:'inherit',
    boxShadow: '2px 3px 6px 1px',
  },
  clearSuggestion:{
    textAlign:'center'
  },
  saccoServicesWrapper:{
    paddingTop:'25px',
  },
  saccoServiceTableCell:{
    fontSize: 14,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  saccoServiceTableRow:{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  saccoServiceTable:{
    width:'100%',
  },
  addServiceButton:{
    float:'right',
  },
  serviceColumn:{
    width:'33%',
  },
  serviceList:{
    width:'66%',
  },
  formColumFullWidth:{
    width:'100%',
  },
  addServiceDialog:{
    justifyContent:'space-between',
    display:'flex',
  },
  buttonAddService: {
    color: '#4caf50',
    borderColor: '#4caf50',
  },
  buttonSaveServices: {
    color: '#4caf50',
    borderColor: '#4caf50',
    marginTop:'10px',
    float:'right'
  },
  chipSuccess:{
    color: '#4caf50',
    borderColor: '#4caf50',
  },
  chipInfo:{
    color: '#648994',
    borderColor: '#648994',
  },
  addFunderButton:{
    float:'right',
    margin: '10px',
  },
  addFunderDialog:{
    maxWidth: '600px',
    margin: '0 auto'
  },
  buttonSaveFunder: {
    color: '#4caf50',
    borderColor: '#4caf50',
    float: 'right'
  },
  programDialog:{
    minWidth:'1100px',
  },
  addProgramFunderButton:{
    padding: '10px 20px',
    color: '#ffffff',
    background: '#4caf50',
    marginTop: '7.5px'
  },
  funderPanels:{
    width: '100%'
  },
  funderPanel:{
    margin: '10px'
  },
  funderPanelTitle:{
    background: '#555',
    cursor: 'pointer',
    padding: '5px 10px',
    fontWeight: '700',
    fontSize: '18px',
    color: '#ffffff',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
  },
  funderPanelFields:{
    background: '#f1f1f1',
    padding: '15px 15px 10px 15px',
    display: 'none'
  },
  funderPanelFieldsActive:{
    background: '#f1f1f1',
    padding: '15px 15px 10px 15px',
    display: 'block'
  },
  buttonEnrolUsers: {
    color: '#4caf50',
    borderColor: '#4caf50',
    marginLeft: '25px'
  },
  userEnrolment: {
    float: 'right',
    marginTop: '10px'
  },
  enrolUserDialog:{
    minHeight: '350px',
  },
  statisticsRow: {
    width: '100%',
    display: 'flex',
    background: '#ffffff',
    marginTop: '20px',
    borderBottom: '1px dashed #000000'
  },
  formTwoThirds: {
    width: '66%',
    padding: '5px 10px'
  },
}));
