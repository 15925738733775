import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Avatar, Box, useMediaQuery, Theme, Typography } from '@material-ui/core';
import {
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
  useTranslate,
} from 'react-admin';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GeneralUsers from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LooksIcon from '@material-ui/icons/Looks';
import AppsIcon from '@material-ui/icons/Apps';

import {
  requests,
  services,
  subcategories,
  categories,
  providers,
  providerrequests,
  users,
  coopservices,
} from '../../modules';
import { SubMenu } from './SubMenu';
import { AppState } from '../../types';

import INPUTMarkets from '../../assets/input-market.png';
import OUTPUTMarkets from '../../assets/output-market.png';
import { sideMenuStyles } from '../../stylesheets';

type MenuName = 'menuRequests' | 'menuService' | 'menuUsers' | 'menuReports' | 'menuPrograms';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const inputMarkets = `${INPUTMarkets}`;
const outputMarkets = `${OUTPUTMarkets}`;

export const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const classes = sideMenuStyles();

  const [state, setState] = useState({
    menuRequests: false,
    menuService: false,
    menuUsers: false,
    menuReports: false,
    menuPrograms: false
  });

  const { permissions } = usePermissions();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  let superuser = permissions && permissions.includes('superuser'),
    admin = permissions && permissions.includes('admin'),
    provider = permissions && permissions.includes('provider'),
    agent = permissions && permissions.includes('agent'),
    firstName = sessionStorage.getItem('firstName'),
    lastName = sessionStorage.getItem('lastName'),
    initials = firstName && lastName ? `${firstName[0]}${lastName[0]}` : null;

  let providerIcon = superuser ? <providers.icon /> : <BusinessCenterOutlinedIcon />;

  let providerId = sessionStorage.getItem('providerId')!;

  return permissions !== undefined ? (
    <div>
      {isSmall && (
        <>
          <Avatar className={classes.root}>{initials}</Avatar>
          <Typography component="div" gutterBottom>
            <Box fontSize="1.25rem" ml='22px'>
              {firstName}&nbsp;{lastName}
            </Box><hr />
          </Typography>
        </>
      )}
      {!isSmall && (
        <Box className={clsx('sidebar-head-title')}>
          <img src={inputMarkets} alt="input-markets" />
          <Typography component="div" gutterBottom>
				    <Box className={clsx('sidebar-head-content')}>
              Input&nbsp;Markets
            </Box>
          </Typography>         
        </Box>
      )}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {/* Requests */}
      {(admin || provider) &&
        <SubMenu
          handleToggle={() => handleToggle('menuRequests')}
          isOpen={state.menuRequests}
          sidebarIsOpen={open}
          name="pos.menu.requests.name"
          icon={<requests.icon />}
          dense={dense}
        >
          {admin &&
            <MenuItemLink
              to={`/saccorequests`}
              primaryText={translate(`pos.menu.requests.sacco`, {
                smart_count: 2,
              })}
              leftIcon={<services.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {provider &&
            <MenuItemLink
              to={`/providerrequests`}
              primaryText={translate(`pos.menu.requests.name`, {
                smart_count: 2,
              })}
              leftIcon={<providerrequests.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
        </SubMenu>
      }
      {/* Users */}
      {(admin || agent || superuser) &&
        <SubMenu
          handleToggle={() => handleToggle('menuUsers')}
          isOpen={state.menuUsers}
          sidebarIsOpen={open}
          name="pos.menu.users.name"
          icon={<GeneralUsers />}
          dense={dense}
        >
          {(agent || superuser) &&
            <MenuItemLink
              to={`/farmers`}
              primaryText={translate(`pos.menu.users.farmers`, {
                smart_count: 2,
              })}
              leftIcon={<users.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {(agent || superuser) &&
            <MenuItemLink
              to={`/provider`}
              primaryText={translate(`pos.menu.users.providers`, {
                smart_count: 2,
              })}
              leftIcon={<providers.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {(admin) &&
            <MenuItemLink
              to={`/groupusers`}
              primaryText={translate(`pos.menu.users.saccousers`, {
                smart_count: 2,
              })}
              leftIcon={<users.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
        </SubMenu>
      }
      {/* Groups */}
      {(superuser || agent) &&
        <MenuItemLink
          to={`/cooperativebranches`}
          primaryText={translate(`pos.menu.users.groups`, { 
            smart_count: 2,
          })}
          leftIcon={<GroupAddIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      }
      {/* Reports */}
      {superuser && 
        <SubMenu
          handleToggle={() => handleToggle('menuReports')}
          isOpen={state.menuReports}
          sidebarIsOpen={open}
          name="pos.menu.reports.name"
          icon={<AssignmentOutlinedIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/serviceproviders`}
            primaryText={translate(`pos.menu.reports.providers`, {
              smart_count: 2,
            })}
            leftIcon={<providers.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/farmersreport`}
            primaryText={translate(`pos.menu.reports.farmers`, {
              smart_count: 2,
            })}
            leftIcon={<users.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      }
      {/* Services - added "false" to hide */}
      {(superuser || admin || provider) && false &&
        <SubMenu
          handleToggle={() => handleToggle('menuService')}
          isOpen={state.menuService}
          sidebarIsOpen={open}
          name="pos.menu.services.name"
          icon={<services.icon />}
          dense={dense}
        >
          {superuser &&
            <MenuItemLink
              to={`/services`}
              primaryText={translate(`pos.menu.services.all`, {
                smart_count: 2,
              })}
              leftIcon={<GroupAddIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {superuser &&
            <MenuItemLink
              to={`/subcategories`}
              primaryText={translate(`pos.menu.services.subcategories`, {
                smart_count: 2,
              })}
              leftIcon={<subcategories.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {superuser &&
            <MenuItemLink
              to={`/categories`}
              primaryText={translate(`pos.menu.services.categories`, {
                smart_count: 2,
              })}
              leftIcon={<categories.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {admin &&
            <MenuItemLink
              to={`/saccoservices`}
              primaryText={translate(`pos.menu.services.saccoservices`, {
                smart_count: 2,
              })}
              leftIcon={<requests.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {superuser &&
            <MenuItemLink
              to={`/sharedservices`}
              primaryText={translate(`pos.menu.sharedservices`, {
                smart_count: 2,
              })}
              leftIcon={<coopservices.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
          {provider &&
            <MenuItemLink
              to={`/providerservice`}
              primaryText={'Provider services'}
              leftIcon={<QuestionAnswerIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          }
        </SubMenu>
      }
      {/* Programs - added "false" to hide */}
      {superuser && false &&
        <SubMenu
          handleToggle={() => handleToggle('menuPrograms')}
          isOpen={state.menuPrograms}
          sidebarIsOpen={open}
          name="pos.menu.prorams.name"
          icon={<LooksIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/funders`}
            primaryText={translate(`pos.menu.prorams.managefunders`, {
              smart_count: 2,
            })}
            leftIcon={<MonetizationOnIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/programs`}
            primaryText={translate(`pos.menu.prorams.manageprograms`, {
              smart_count: 2,
            })}
            leftIcon={<EventSeatIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>        
      }
      {provider &&
        <MenuItemLink
          to={
            `/provider/${providerId}`
          }
          primaryText={
            `My details`
          }
          leftIcon={providerIcon}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      }
      <hr className={clsx('AkListItem-line')} />
      <a
        href="https://outputs.akellobanker.com/"
        className={clsx(`MuiButtonBase-root MuiListItem-root AkListItem-image`)}
      >
        <div className={clsx('MuiListItemIcon-root')}>
          <img src={outputMarkets} alt="output-markets" className={clsx('MuiSvgIcon-root')} />
        </div>
        Output Markets
      </a>
      <a
        href="https://markets.akellobanker.com/"
        className={clsx(`MuiButtonBase-root MuiListItem-root AkListItem-image`)}
      >
        <div className={clsx('MuiListItemIcon-root')}>
          <AppsIcon />
        </div>
        Options
      </a>
      {isSmall && logout}
    </div>
  ) : null;
};
