import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { useFormState } from 'react-final-form';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { 
  Box,
  IconButton,
  Step,
  Stepper,
  StepLabel,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  Button as NavButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRedirect,
  useRefresh,
  useNotify,
} from 'react-admin';

import { Separator, filterObject, trim } from '../../utils';
import { CooperativesFormBasic } from './CooperativesFormBasic';
import { CooperativesFormPersonnel } from './CooperativesFormPersonnel';

import { apiFullCall } from '../../apiHelper';

const StepsToCreateCooperative = () => [
  'Add Group details', 
  'Add Group Manager'
];

const StepsToEditCooperative = () => [
  'Group details', 
  'Group Manager(s)'
];

const GetStepContent = (
  step: number, 
  props: object,
  isSmall?: boolean,
  // related to "basic form"
  rafields?: object, 
  handleChange?: object, 
  // related to "group manager" 
  admins?: object,
  members?: number,
  isRegistered?: boolean, 
  // related to village search
  searchable?: object,
  filterValue?: object,
  idDistrict?: string | null,
  inputOnChangeVillage?: object,
  downshiftOnChangeVillage?: object,
  // related to district search
  getDistrict?: object,
  filterDistrict?: object,
  inputOnChangeDistrict?: object,
  downshiftOnChangeDistrict?: object,
  // related to existing-user search
  filterExistingUser?: object,
  downshiftOnChangeUser?: object,
  handleRemoveUser?: object,
) => {
  switch (step) {
    case 0:
      return (
        <CooperativesFormBasic
          {...props}
          isSmall={isSmall}
          // related to "basic" form
          rafields={rafields}
          handleChange={handleChange}
          members={members}
          isRegistered={isRegistered}
          // related to village search 
          searchable={searchable}
          filterValue={filterValue}
          inputOnChangeVillage={inputOnChangeVillage}
          downshiftOnChangeVillage={downshiftOnChangeVillage}
          // related to district search
          getDistrict={getDistrict}
          filterDistrict={filterDistrict}
          inputOnChangeDistrict={inputOnChangeDistrict}
          downshiftOnChangeDistrict={downshiftOnChangeDistrict}
        />
      );
    case 1:
      return (
        <CooperativesFormPersonnel
          {...props}
          isSmall={isSmall}
          admins={admins}
          idDistrict={idDistrict}
          isRegistered={isRegistered}
          filterExistingUser={filterExistingUser}
          downshiftOnChangeUser={downshiftOnChangeUser}
          handleRemoveUser={handleRemoveUser}
        />
      );
    default:
      return 'Unknown step';
  }
};

export const CooperativesMultiForm = ({ onCancel, ...props }) => {

  const [state, setState] = useState({ activeStep: 0 });
  const [members, setMembers] = useState(0);
  const [admins, setAdmins] = useState({ total: 0, adminData: [{}], adminUsers: [{}] });
  
  const { basePath, record, ...rest } = props;

  let isLoaded = React.useRef(true);

  const abortController = new AbortController();
  const token = sessionStorage.getItem('token');

  const isSmall: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  // check if record has key 'id', use this check to render guide messages
  let isRegistered = Object.prototype.hasOwnProperty.call(record, 'id');
  
  const steps = isRegistered ? StepsToEditCooperative() : StepsToCreateCooperative();

  const handleNext = () => {
    const { activeStep } = state; // eslint-disable-line
    setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  const handleBack = () => {
    const { activeStep } = state; // eslint-disable-line
    setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };


  /* Related to persisting date within the "basic" form (edit mode) */


  // destructure "cooperative branches" data
  const { cooperative_id } = record && record;

  const [rafields, setRaFields] = React.useState<object | any>({
    cooperative_name: '', short_name: '', type: '',
  });

  // populate "cooperative" fields
  React.useEffect(() => {

    if (isLoaded && isRegistered) {

      apiFullCall(
        '', 
        token, 
        'get', 
        `cooperatives/${cooperative_id}` 
      ).then(res => {

        if (res) {

          const { status, body } = res;

          if (status === 200 || status === 201) {

            const { cooperative_name, short_name, coop_service_id } = body;

            setRaFields({ 
              ...rafields, 
              cooperative_name: cooperative_name, 
              short_name: short_name,
            });

            if (coop_service_id) {
              apiFullCall(
                '', 
                token, 
                'get', 
                `cooperativeservices/${coop_service_id}` 
              ).then(res => {
                if (res) {
                  const { status, body } = res;

                  if (status === 200 || status === 201) {
                    const { short_name: category } = body;
        
                    setRaFields(prevState => ({ ...prevState, type: category }));
                  }
                }      
              }).catch(
                error => console.error('Error while fetching:', error)
              );      
            };

          };

        };

      }).catch(
        error => console.error('Error while saving:', error)
      );

    }

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }
  }, [isLoaded, isRegistered]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    const { name, value } = e.target;

    // dynamically set the field values
    setRaFields({ [name]: value });
  };

  /* Related to updating state for "group manager(s)" (edit mode) */


  // define "cooperative branch id" (used in API call)
  let groupBranchId = record && record['id'];

  // fetch branch users data
  const getBranchUsers = async() => {

    let branchUsers, adminRelatedDetails, page = 1;

    // create empty array where we'll store user objects for each loop
    branchUsers = adminRelatedDetails = new Array<object>();

    // create a lastResult object to help check if there is a next page
    let lastResult = { next: null, results: [] };

    /*
     * do...while
     * Creates a loop that executes a specified statement until the test condition evaluates to false. 
     * The condition is evaluated after executing the statement, resulting in the specified statement 
     * executing at least once.
     */
    do {

      // try catch...
      try {
        // search for group users meta-data
        const res = await apiFullCall(
          '', 
          token, 
          'get', 
          `cooperativebranchusers/?cooperative_branch_id=${groupBranchId}&page=${page}` 
        );
        
        // manupilate the response
        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {

            const { count } = body;

            // update the member count
            setMembers(count);

            // assign lastResult to pick "next"
            lastResult = body;

            // concatenate new results
            branchUsers = [
              ...branchUsers,
              ...((lastResult && lastResult.results) || []),
            ];

            // increment the page with 1 on each loop
            page++;
          }
        };

      } catch (err) {       
        // to catch any errors in the async api call
        console.error(`Oops, something went wrong ${err}`);
      }
      // keep running until there's no next page
    } while (lastResult.next !== null);

    const groupAdmins = branchUsers.filter(
      ({ is_admin  }) =>
        typeof is_admin  === "string" && is_admin  === "True"
    );
    
    groupAdmins.map(
      ({ user_id }, i) =>
        // fetch the related user details
        apiFullCall(
          '', 
          token, 
          'get', 
          `users/${user_id}` 
        ).then(res => {
          if (res) {
            const { status, body } = res;

            if (status === 200 || status === 201) {
              
              // get user details
              adminRelatedDetails[i] = body;

            };

          }
          
        }).catch(
          error => console.error('Error while fetching data:', error)
        )   
    );

    // update the group admin details
    setAdmins(prevState => ({
      ...prevState, 
      total: groupAdmins.length,
      adminData: groupAdmins,
      adminUsers: adminRelatedDetails,
    }));
  };

  // fetch group admins (group-level)
  React.useEffect(() => {

    if (isLoaded && groupBranchId) {
      getBranchUsers();
    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };

  }, [isLoaded, groupBranchId]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Related to village/location search */ 


  const [searchable, setSearchable] = useState<object | any>({ address: [] });

  const [filterValue, setFilterValue] = useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtid: '', // required for user search
    districtname: '',
    regionname: '',
  });

  // onChange method for the input field
  const inputOnChangeVillage = useCallback((e, id?: string | number) => {
    if (!e.target.value) {
      return null;
    }

    searchCallVillage(e.target.value, id); // pass districtid
  }, []); // eslint-disable-line

  const searchCallVillage = (value, id?: string | number) => {
    let location = trim(value);

    if (location.length > 2) {

      if (!!id) {

        // search with districtid, if defined
        apiFullCall('', token, 'get', `locationsearch/?districtid=${id}&search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setSearchable(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      } else {

        // plain search, if no district is selected
        apiFullCall('', token, 'get', `locationsearch/?search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setSearchable(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      }
      
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeVillage = useCallback(value => {
    setFilterValue({ ...value });
  }, []); // eslint-disable-line  

  // define "village id" (used in API call)
  let id_village = record && record['village_id'];

  // fetch village meta-data
  React.useEffect(() => {
    if (isLoaded && id_village) {

      // search for this specific village inorder to pick other meta-data
      apiFullCall(
        '', 
        token, 
        'get', 
        `locationsearch/?id=${id_village}` 
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {

            // destructure the wanted meta-data
            const {
              id,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtid,
              districtname,
              regionname,
            } = body.results[0];

            const village_meta = {
              id,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtid,
              districtname,
              regionname,
            };
            
            // set-up the "filterValue" state
            setFilterValue({ ...filterValue, ...village_meta });
          }
        }      
      }).catch(
        error => console.error('Error while fetching data:', error)
      );

    }

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };
    
  }, [isLoaded, id_village]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Related to District/location search */ 


  const [getDistrict, setGetDistrict] = useState<object | any>({ districtList: [] });
  
  const [filterDistrict, setFilterDistrict] = useState<object | any>({
    districtid: '',
    districtname: '',
  });

  // onChange method for the input field
  const inputOnChangeDistrict = useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCallDistrict(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCallDistrict = value => {
    let district = trim(value);

    if (district.length > 2) {
      apiFullCall('', token, 'get', 'district/?search=' + district)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setGetDistrict(prevState => ({ ...prevState, districtList: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeDistrict = useCallback(value => {
    setFilterDistrict({ ...value });
    setFilterValue({
      id: '',
      villagename: '',
      parishname: '',
      subcountyname: '',
      countyname: '',
      districtname: '',
      regionname: '',
    });
  }, []); // eslint-disable-line  
  
  
  /* Related to existing-user search */

    
  const [filterExistingUser, setFilterExistingUser] = React.useState<object | any>({
    id: '',
    full_name: '',
    username: '',
    is_farmer: '',
    is_provider: '',
    villagename: '',
  });

  // input field for the <Downshift /> component (user-search)
  const downshiftOnChangeUser = React.useCallback(user => {
    setFilterExistingUser({ ...user });
  }, []); // eslint-disable-line

  // support capturing village/district/existing-user id(s)
  let idVillage = filterValue.id;
  let idDistrict = filterValue.districtid;
  let existingUser = filterExistingUser;                                                                               

  const handleRemoveUser = () => {
    // reset the user-id
    // user-id is used for checks to make API calls
    setFilterExistingUser({ id: '' });
  }

  const { activeStep } = state;

  return (
    <SimpleForm 
      {...props}
      className={clsx(
        'provider',
        'group',
        {
          'register-form': !isRegistered,
          'basic-form': isRegistered,
          // hide "back" button in create-mode on first step
          'first': !isRegistered && activeStep === 0,
          'second': activeStep === 1,
        },
        props.className
      )}
      submitOnEnter={false} 
      toolbar={
        isRegistered ? (
          // edit-mode
          <CooperativeUpdateToolbar
            {...rest}
            steps={steps}
            idVillage={idVillage}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : (
          // create-mode
          <CooperativeCreateToolbar
            {...rest}
            steps={steps}
            existingUser={existingUser}
            idVillage={idVillage}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )
      }
    >      
      <Box
        display={{ xs: 'inline-flex' }}
        width={{ xs: '100% !important' }}
      >        
        <Box flex={1}>
          <Stepper
            activeStep={activeStep}
            className={clsx('stepper-lg-header')}
          >
            {/* shown on larger screens */}
            {steps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {/* shown on mobile */}
          <div className={clsx('stepper-sm-header')}>
            {activeStep === 0 && <span>Group details</span>}
            {activeStep === 1 && <span>Group manager(s)</span>}
          </div>
        </Box>
        <Box>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>            
        </Box>
        <Separator />
      </Box>
      <Box 
        display={{ xs: 'flex' }} 
        flexDirection={{ xs: 'column' }}
        className={clsx(
          'stepper-content',
          { 'groups-form': isSmall },
          { 'group--managers': activeStep === 1 && isRegistered} // style edit-mode
        )}
      >
        {activeStep === steps.length 
          ? null 
          : GetStepContent(
            activeStep,
            props,
            isSmall,
            // related to "basic form"
            rafields,
            handleChange,
            // related to "group manager" 
            admins,
            members, 
            isRegistered, 
            // related to village search
            searchable,
            filterValue,
            idDistrict,
            inputOnChangeVillage,
            downshiftOnChangeVillage,
            // related to district search
            getDistrict,
            filterDistrict,
            inputOnChangeDistrict,
            downshiftOnChangeDistrict,
            // related to existing-user search
            filterExistingUser,
            downshiftOnChangeUser,
            handleRemoveUser,
          )
        }
      </Box>
    </SimpleForm>
  );
};

CooperativesMultiForm.propTypes = {
  classes: PropTypes.object,
};

// Handling "create" mode
const CooperativeCreateToolbar = props => {
  const {handleBack, ...rest } = props;
  const { activeStep, handleNext, idVillage, existingUser, save, ...other } = rest;

  return (
    <Toolbar {...other}>
      <BackButton handleBack={handleBack} />
      <NextOrSaveButton {...rest} /> 
    </Toolbar>
  )  
};

// handling "update/edit" mode
const CooperativeUpdateToolbar = props => {
  const { activeStep, handleBack, handleNext, idVillage, save, ...rest } = props;

  return (
    <Toolbar {...rest}>
      <NextOrUpdateButton {...props} /> 
    </Toolbar>
  )  
};

const BackButton = props => {
  const { handleBack } = props;

  return (
    <NavButton label="Back" onClick={handleBack} className={clsx('direction')}>
      <ArrowBackIcon />
    </NavButton>
  );
};

// handle "create"
const NextOrSaveButton = props => {
  const formState = useFormState();
  const redirectTo = useRedirect();
  const notify = useNotify();

  const [ loading, setLoading ] = useState<boolean>(false);

  const token = sessionStorage.getItem('token');

  const {
    // destructure "props" not to be pushed to button
    activeStep, 
    basePath, 
    handleNext,
    idVillage,
    existingUser,
    invalid, 
    save, 
    steps, 
    ...rest
  } = props;

  // used in adding existing-user to manage group
  const { id, full_name } = existingUser;

  const {
    // destructure "group" fields
    cooperative_name, 
    short_name, 
    coop_service_id,
    monthly_income, 
    primary_contact, 
    secondary_contact,
    email,
    box_number,
    physical_address,
    is_active,
    village_id,
    is_main_branch,
    ...other
  } = formState.values;

  // define "cooperatives" data 
  let cooperativeData = {
    cooperative_name: cooperative_name, 
    short_name: short_name, 
    coop_service_id: coop_service_id,
  }

  // define "cooperative branch" data  
  let branchData = {
    cooperative_branch_name: cooperative_name, 
    monthly_income: monthly_income, 
    primary_contact: primary_contact, 
    secondary_contact: secondary_contact,
    email: email,
    box_number: box_number,
    physical_address: physical_address,
    is_active: is_active,
    // pick from session, if this is undefined/empty
    village_id: !village_id ? idVillage : village_id,
    is_main_branch: is_main_branch,
  };

  // define "cooperative branch" data  
  let userData = {
    first_name: other['first_name'],
    middle_name: other['middle_name'],
    last_name: other['last_name'],
    dob: other['dob'],
    gender: other['gender'],
    email: email,
    username: other['username'], // primary contact
    secondary_phone_number: other['secondary_phone_number'],
    nin: other['nin'],
    is_admin: other['is_admin'],
    is_farmer: other['is_farmer'],
    is_provider: other['is_provider'],
  };

  const handleSaveBranch = useCallback(() => {

    // @param: user-id
    const createGroup = (param: string | any) => {

        // Trigger Cooperative save,
        // if we we have cooperative_name, coop_service_id and village_id
        apiFullCall(
          cooperativeData, 
          token, 
          'post', 
          `cooperatives/` 
        ).then(res => {

          if (res) {

            setLoading(true);
            const { status, body: savedCooperative } = res;

            if (status === 200 || status === 201) {
              
              // now we add the returned "id"
              let sanitizedBranchData = {
                ...branchData,
                cooperative_id: savedCooperative['id'],
              }

              // Trigger Cooperative branch save, 
              // when cooperative creation is a success
              apiFullCall(
                sanitizedBranchData, 
                token, 
                'post', 
                `cooperativebranches/` 
              ).then(res => {

                if (res) {
                  const { status, body: savedCoopBranch } = res;

                  if (status === 200 || status === 201) {
                    
                    let branchUserData: object;
                    let cooperative_branch_id: string = savedCoopBranch['id'];

                    // define "branch user" data
                    branchUserData = {
                      is_active: true,
                      approved: true,
                      role: other['role'],
                      // if we don't have a new-user-id, we take the existing-user-id
                      user_id: param,
                    };

                    // Trigger branch-user creation, when user creation is a success
                    // save the just created "user" as a branch-user
                    apiFullCall(
                      branchUserData, 
                      token, 
                      'post',
                      `cooperativebranchusers/?cooperative_branch_id=${cooperative_branch_id}`
                    ).then(res => {

                      if (res) {
                        
                        const { status } = res;

                        if (status === 200 || status === 201) {
                          // group is created
                          notify('A new group has been created', 'info');
                          
                          if ( param !== id ) {
                            // passed "id" is new; new-branch-user is added
                            notify(`And a new admin has been added.`, 'info');
                          } else {
                            // passed "id" is old; existing-user is made admin
                            notify(`And "${full_name}" is the group admin.`, 'info');
                          };

                          // redirect to provider list
                          redirectTo('list', basePath);

                        } else if (status === 400) {
                          notify(`This form is invalid. Please check again and resubmit.`, 'warning');
                        } else if (status >= 500) {
                          notify(`Server error, please try again later.`, 'warning');          
                        };
                        
                      };

                    }).catch(
                      error => console.error('Error while saving:', error)
                    );
          
                  } else if (status === 400) {
                    notify(`This form is invalid. Please check again and resubmit.`, 'warning');
                  } else if (status >= 500) {
                    notify(`Server error, please try again later.`, 'warning');          
                  };
                };

              }).catch(
                error => console.error('Error while saving:', error)
              );

            } else if (status >= 500) {
              notify(`Server error, please try again later.`, 'warning');          
            };

            setLoading(false);
          };

        }).catch(
          error => console.error('Error while saving:', error)
        );

    };

    /** 
     * The challenge is getting all 4 different endpoints to only be called if we have validated all data
     * 
     * Validation steps; on top of the form-validation (required fields)
     * 1. check if "cooperative_name" and "coop_service_id" are populated
     * 2. check that we have a village id; so user must "search by village"
     * 3. if the above are valid; then save a user (return error, if "username" is not unique)
     * 
     * API processes/endpoints:
     * 1. Create a cooperative -> /cooperatives
     * 2. Create a cooperative-branch (location dependant) -> /cooperativebranches
     * 3. Either create a user account (to be linked to the cooperative) -> /users
     *    Or add an existing user (search, within district) -> /usersview
     * 4. Create a cooperative-branch-user (to manage the cooperative-branch) -> /cooperativebranchusers
     */ 
    if (cooperativeData['cooperative_name'] && cooperativeData['coop_service_id']) {

      if (branchData['village_id']) {

        // check if user has filled "phone number"
        // we also verify that no existing-user is selected
        if ( 
          !!userData['username'] && // we have a "phone number"
            !!userData['first_name'] && // and "first name"
              Number(id) === 0 // and NO existing-user is selected
        ) {

          // Trigger user creation (If phone number is filled)
          // when cooperative "branch" is creation successfully
          apiFullCall(
            userData, 
            token, 
            'post', 
            `users/` 
          ).then(res => {

            if (res) {
              const { status, body: UserDetails } = res;

              if (status === 200 || status === 201) {

                // set new-user-id
                let newUserId = UserDetails['id'];

                // set user-location data
                // we assume is in same village as the "new" group
                const userLocationData = {
                  user_id: newUserId,
                  village_id: idVillage,
                  is_active: true,
                  box_number: branchData['box_number'], 
                  physical_address: branchData['physical_address'],
                };

                // create user-location
                // and if a success, create the group
                apiFullCall(
                  userLocationData, 
                  token, 
                  'post', 
                  `usersaddress/` 
                ).then(res => {
            
                  if (res) {
            
                    const { status } = res;
            
                    if (status === 200 || status === 201) {
            
                      // update "admin" state
                      if ( UserDetails['is_admin'] === true ) {

                        // create "group"
                        createGroup( newUserId );

                      };
            
                    } else if (status === 400) {
                      notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
                    } else if (status >= 500) {
                      notify(`Server error, please try again later.`, 'warning');          
                    };
            
                  };
            
                }).catch(
                  error => console.error('Error while saving:', error)
                );

              } else if (status === 400) {

                if (userData['username']) {

                  // trigger notification about possible duplication
                  notify(`A user with that phone number already exists.`, 'warning');
                  notify(`Please submit a user with different phone number`, 'info');

                } else {
                  notify(`This form is invalid. Please check again and resubmit.`, 'warning');
                };

              } else if (status >= 500) {
                notify(`Server error, please try again later.`, 'warning');          
              }
            };

          }).catch(
            error => console.error('Error while saving:', error)
          );  

        };        

        // check if one has searched exisitng-user 
        if (
          !userData['username'] && // NO "phone number"
            !userData['first_name'] && // NO "first name"
              Number(id) !== 0 // and existing-user IS selected
        ) {
          
          // Update existing-user to be an "admin"
          // since they've been selected to manage a group-account
          apiFullCall(
            { is_admin: true }, 
            token, 
            'patch',  
            // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
            `users/${id}/` 
          ).then(res => {

            if (res) {
              const { status, body } = res;

              if (status === 200 || status === 201) {

                // set existing-user-id
                let existingUserId = body['id'];

                // update "admin" state
                if ( body['is_admin'] === true ) {

                  // create "group"
                  createGroup( existingUserId );

                };

              } else {
                notify(`Server error, please try again later.`, 'warning');          
              }
            };

          }).catch(
            error => console.error('Error while saving:', error)
          );

        };
        
        // check we have both "new" and "existing" user
        if (
          !!userData['username'] && // we have a "phone number"
            !!userData['first_name'] && // and "first name"
              Number(id) !== 0 // and existing-user IS selected
        ) {

          // we realize that we have both a "phone number" and "first name" filled
          // and an exsiting-user was selected, we ask user to remove existing-user
          notify(`Saving error! Either remove the existing user or new user details.`, 'warning');

        };

      } else {
        // if "village" is not populated
        notify(`Please search and select a village`, 'warning');  
      };

    } else {
      // if "cooperative_name" or "coop_service_id" is not populated
      notify(`Please enter "Group name" and/or "Group category"`, 'warning');
    };

  }, [notify, cooperativeData, branchData, redirectTo, basePath]); // eslint-disable-line react-hooks/exhaustive-deps

  return activeStep === steps.length - 1 ? (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_exit"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveBranch}
    />
  ) : (    
    // only show "next" button on first step
    <NavButton label="Next" onClick={handleNext} className={clsx('direction')}>
      <ArrowForwardIcon />
    </NavButton>
  );
};

// handle "update"
const NextOrUpdateButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  const [ loading, setLoading ] = useState<boolean>(false);

  const token = sessionStorage.getItem('token');

  const {
    // destructure "props" not to be pushed to button
    activeStep, 
    basePath,
    handleBack,
    handleNext,
    idVillage,
    invalid, 
    save, 
    steps,
    ...rest
  } = props;

  const {
    // destructure "group" fields
    id,
    cooperative_name,
    cooperative_branch_name, 
    short_name,
    coop_service_id,
    cooperative_id,
    monthly_income, 
    primary_contact, 
    secondary_contact,
    email,
    box_number,
    physical_address,
    is_active,
    village_id,
    is_main_branch,
    ...other // eslint-disable-line
  } = formState && formState.values;

  // define "cooperatives" data 
  let cooperativeData = {
    cooperative_name, 
    short_name, 
    coop_service_id,
    is_active, // helps in sanitize process (since other fields are initially "undefined")
  };

  // declare a reference to the "providerData"
  const cooperativeRef = React.useRef<object | any>(cooperativeData);
  
  // declare a reference to the "branch data"
  const branchRef = React.useRef<object | any>({
    id,
    cooperative_branch_name, 
    monthly_income, 
    primary_contact, 
    secondary_contact,
    email,
    box_number,
    physical_address,
    is_active,
    // leave this village_id initially
    village_id,
    is_main_branch,
  });

  // define "cooperative branch" data  
  let branchData = {
    id,
    cooperative_branch_name, 
    monthly_income, 
    primary_contact, 
    secondary_contact,
    email,
    box_number,
    physical_address,
    is_active,
    // update "village_id" dynamically, if changed by user
    village_id: idVillage && village_id === Number(idVillage) ? village_id : Number(idVillage),
    is_main_branch,
  };
  
  // define provider "id"
  const branchID = branchData && branchData['id'];

  /* Avoid overwriting fields with  */

  // clean cooperatives data (contains some "undefined" fields)
  const sanitizedCooperativeData = cooperativeData && filterObject(cooperativeData, value => value !== null && value !== undefined ); 
  
  // clean branch data (contains some "null" fields)
  const sanitizedBranchData = branchData && filterObject(branchData, value => value !== null && value !== undefined); 

  const handleUpdateBranch = useCallback(() => {

    // only make API call to cooperatives/ if user changes specific fields
    // once any field changes; cooperativeRef.current !== cooperativeData
    if (branchID && !isEqual(cooperativeRef.current, cooperativeData)) { // !false

      // update "cooperative" details
      apiFullCall(
        sanitizedCooperativeData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `cooperatives/${cooperative_id}/` 
      ).then(res => {
        if (res) {
          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {
            notify(`Group details have been updated.`, 'info', {
              smart_count: 1,
            });    
          } else if (status === 400) {

            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          }
          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );

    };

    // only make API call to cooperativebranches/ if user changes branch-specific fields
    if (branchID && !isEqual(branchRef.current, branchData)) {
      
      // update "cooperative branch" details
      apiFullCall(
        sanitizedBranchData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `cooperativebranches/${branchID}/` 
      ).then(res => {
        if (res) {
          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {
            notify(`Group details have been updated.`, 'info', {
              smart_count: 1,
            });    
          } else if (status === 400) {
            
            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          }
          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );
    };

    // reload page asap
    refresh();

  }, [cooperativeData, notify, refresh, basePath]); // eslint-disable-line react-hooks/exhaustive-deps

  return activeStep === steps.length - 1 ? (
    <>
      <BackButton handleBack={handleBack} />
      <SaveButton
        {...rest}
        label={loading ? "Saving" : "post.action.save"}
        submitOnEnter={false}
        saving={loading}
        onSave={handleUpdateBranch} 
      />
    </>
  ) : (
    <>
      <NavButton label="Next" onClick={handleNext} className={clsx('direction')}>
        <ArrowForwardIcon />
      </NavButton>
      <SaveButton
        {...rest}
        label={loading ? "Saving" : "post.action.save"}
        submitOnEnter={false}
        saving={loading}
        onSave={handleUpdateBranch} 
      />
    </>
  );
};

