import React, { FC, useCallback, useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { stringify } from 'query-string';

import {
  useTranslate,
  useDataProvider,
  usePermissions,
  useVersion,
  Link,
} from 'react-admin';

import allSaccoMembersStyle from '../../stylesheets/jss/saccoDashboard';

import CardIcon from './CardIcon';

interface CountStats {
  saccousers?: number;
  newSaccoUsers: number;
  newSaccoUserDetails: any;
  newSaccoUserId: any;
  newSaccoUserName: any;
  users: [];
}

interface Props {
  value?: number;
}

const AllSaccoMembers: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = allSaccoMembersStyle();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const version = useVersion();

  const [state, setState] = useState<CountStats>({
    saccousers: 0,
    newSaccoUsers: 0,
    newSaccoUserId: '',
    newSaccoUserDetails: '',
    newSaccoUserName: '',
    users: [],
  });

  // Rely on the DOM API to clean side effects
  const abortController = new AbortController();
  const signal = abortController.signal;

  const getSaccoMembersStats = useCallback(async () => {
    const { data: members } = await dataProvider.getList(
      'cooperativebranchusers',
      {
        // Required params but no need to populate them,
        // since we'll just manupilate the user count from this call
        filter: {},
        sort: {},
        pagination: { page: 1, perPage: 50 },
      },
      { signal: signal }
    );

    const allSaccoMembers = members.filter(
      member => member.cooperative_branch_id === 'AkelloBanker'
    );
    const newSaccoMembers = members.filter(member => member.approved === false);

    newSaccoMembers &&
      newSaccoMembers.map(saccoMember => {
        dataProvider
          .getOne('users', { id: saccoMember.user_id })
          .then(response => {
            let users_list: any = state.users;
            users_list.push(response);

            setState(state => ({
              ...state,
              users: users_list,
            }));

            return response;
          })
          .catch(error => console.error('Error while fetching:', error));
        return null;
      });

    setState(state => ({
      ...state,
      saccousers: allSaccoMembers.length,
      newSaccoUsers: newSaccoMembers.length,
      newSaccoUserId: newSaccoMembers,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  useEffect(() => {
    function checkMyRights() {
      if (permissions !== undefined) {
        if (
          permissions.includes('superuser') ||
          permissions.includes('admin')
        ) {
          return getSaccoMembersStats();
        }
      }
    }

    checkMyRights();

    return function cleanup() {
      abortController.abort();
    };
  }, [version, permissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const { saccousers, newSaccoUsers, users } = state;

  return (
    <>
      <div className={classes.main}>
        <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
        <Card className={classes.card}>
          <Link
            to={{
              pathname: `/cooperativebranchusers`,
              search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ is_farmer: true }),
              }),
            }}
          >
            <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.new_sacco_member')}
            </Typography>
            <Typography variant="h5" component="h2" className={classes.value}>
              {newSaccoUsers}
            </Typography>
          </Link>
          <Divider />
          <List>
            {users
              ? users.map((record: any) => {
                  return (
                    <ListItem
                      button
                      to={`/users/${record.data['id']}`}
                      component={Link}
                      key={record.id}
                    >
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText primary={record.data['first_name']} />
                      <ListItemText primary={record.data['last_name']} />
                    </ListItem>
                  );
                })
              : null}
          </List>
        </Card>
      </div>
      <div className={classes.main}>
        <CardIcon Icon={CustomerIcon} bgColor="#31708f" />
        <Link to={`/cooperativebranchusers`}>
          <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
              {translate('pos.dashboard.all_sacco_members')}
            </Typography>
            <Typography variant="h5" component="h2">
              {saccousers}
            </Typography>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default AllSaccoMembers;
