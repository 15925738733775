import React from 'react';
import clsx from 'clsx';
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
} from 'react-admin';

import {
  Dialog,
  Divider, 
  Tab, 
  Tabs,
  useMediaQuery, 
} from '@material-ui/core';

import { ActionButton, ListActions } from '../../layout';

import MobileGrid from './MobileGrid';
import ProviderRequestEdit from './ProviderRequestEdit';
import { PureRequestDateField, PureDeliveryDateField, PureConfirmedDateField } from './RequestDates';

import { requestStyles } from '../stylesheets';

const ProviderRequestsFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput source="q" />
      <ReferenceInput source="customer_id" reference="customers">
        <AutocompleteInput
          optionText={choice => `${choice.first_name} ${choice.last_name}`}
        />
      </ReferenceInput>
      <DateInput source="date_gte" />
      <DateInput source="date_lte" />
      <TextInput source="total_gte" />
      <NullableBooleanInput source="returned" />
    </Filter>
  )
};

class TabbedDatagrid extends React.Component {
  /**
   * Requests
   * --------
   * pending, by default, these are on pending and not yet reviewed
   * approved, allowed by the SACCO Admin
   * rejected, disallowed by the SACCO Admin
   * ordered, accessible to this user
   */

  tabs = [
    { id: 'approved', name: 'New' },
    { id: 'accepted', name: 'Scheduled' },
    { id: 'delivered', name: 'Completed' },
  ];

  state = { approved: [], accepted: [], delivered: [], open: false };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => { // eslint-disable-line
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { isXSmall, classes, filterValues, handleData, onClick, ...props } = this.props;

    const ProviderRequestMenu = (basePath = '', record = { id: '' }) => [ // eslint-disable-line
      { 
        label: 'See details', 
        // receives single "record" to pass back to "Edit" component
        handleClickEvent: (x) => { 
          return (
            onClick(),
            handleData(x)
          ); 
        }
      },
    ];

    return (
      <>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        {isXSmall ? (
          <MobileGrid
            {...props}
            filterValues={filterValues}
            options={ProviderRequestMenu}
            ids={this.state[filterValues.status]}
          />
        ) : (
          <div>
            {filterValues.status === 'approved' && (
              <Datagrid
                {...props}
                ids={this.state.approved}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <TextField
                  label="Request &#x2116;"
                  source="request_number"
                />
                <PureRequestDateField />
                <TextField
                  label="Amount"
                  source="total"
                />
                <TextField
                  label="Farmer Name"
                  source="farmer.farmer_full_name"
                />
                <TextField
                  label="Village"
                  source="location.villagename"
                />
                <TextField
                  label="Parish"
                  source="location.parishname"
                />
                <TextField
                  label="District"
                  source="location.districtname"
                />
                <ActionButton MenuOptions={ProviderRequestMenu} />
              </Datagrid>
            )}
            {filterValues.status === 'accepted' && (
              <Datagrid
                {...props}
                ids={this.state.accepted}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <TextField
                  label="Request &#x2116;"
                  source="request_number"
                />
                <PureDeliveryDateField />
                <TextField
                  label="Amount"
                  source="total"
                />
                <TextField
                  label="Farmer Name"
                  source="farmer.farmer_full_name"
                />
                <TextField
                  label="Farmer Contact"
                  source="farmer.farmer_username"
                />
                <TextField
                  label="Village"
                  source="location.villagename"
                />
                <TextField
                  label="District"
                  source="location.districtname"
                />
                <ActionButton MenuOptions={ProviderRequestMenu} />
              </Datagrid>
            )}
            {filterValues.status === 'delivered' && (
              <Datagrid
                {...props}
                ids={this.state.delivered}
                classes={{ headerCell: classes.heading }}
              >
                <TextField
                  label="Request &#x2116;"
                  source="request_number"
                />
                <PureRequestDateField />
                <PureDeliveryDateField />
                <PureConfirmedDateField />
                <TextField
                  label="Amount"
                  source="total"
                />
                <TextField
                  label="Farmer Name"
                  source="farmer.farmer_full_name"
                />
                <TextField
                  label="Farmer Contact"
                  source="farmer.farmer_username"
                />
                <TextField
                  label="Village"
                  source="location.villagename"
                />
                <TextField
                  label="District"
                  source="location.districtname"
                />
              </Datagrid>
            )}
          </div>
        )}
      </>
    );
  }
}

const StyledTabbedDatagrid = props => {
  const classes = requestStyles();
  return <TabbedDatagrid classes={classes} {...props} />;
};

const ProviderRequestList = ({ classes, permissions, ...props }) => {

  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const [getDetails, setGetDetails] = React.useState(false);
  const [data, setData] = React.useState({});

  // handles modal open/close
  const handleOpenModal = () => setGetDetails(!getDetails);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  return (
    <>
      <List
        {...props}
        filterDefaultValues={{ status: 'approved' }}
        actions={<ListActions />}
        filters={<ProviderRequestsFilter />}
        sort={{ field: 'date', order: 'DESC' }}
      >
        <StyledTabbedDatagrid
          handleData={handleData}
          isXSmall={isXSmall}
          onClick={handleOpenModal} 
        />
      </List>      
      <Dialog
        maxWidth="lg"
        open={getDetails}
        onClose={handleOpenModal}
        className={clsx('provider-requests--modal')}
        aria-labelledby="requests-dialog-title"
      >
        <ProviderRequestEdit
          isXSmall={isXSmall}
          onClose={handleOpenModal} 
          record={data} 
          {...props} 
        />
      </Dialog>
    </>
  );
};

export default ProviderRequestList;
