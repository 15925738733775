import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { Button, MenuItem, Menu, Fade } from '@material-ui/core';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

export default function FunderActionButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editFunder, setEditFunder] = useState(false);
  const [viewPrograms, setViewPrograms] = useState(false);
  const [funder, setFunder] = useState(props['funder']);
  const open = Boolean(anchorEl);
  const classes = requestStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opeEditFunder = () => {
    setAnchorEl(null);
    setEditFunder(true);
  };

  const openViewPrograms = () => {
    setAnchorEl(null);
    setViewPrograms(true);
  };

  const closeEditFunderDialog = () => {
    setEditFunder(false);
  };

  const closeViewProgramsDialog = () => {
    setViewPrograms(false);
  };

  const handleTextInput = event => {
    event.preventDefault();
    const id = event.target.id;
    const value = event.target.value;
    setFunder({
      ...funder,
      [id]: value,
    });
  };

  const updateFunder = event => {
    event.preventDefault();
    var funder_name = document.getElementById('funder_name').value;
    var short_name = document.getElementById('short_name').value;
    if (funder_name.length > 0) {
      var data = {
        id: funder['id'],
        funder_name: funder_name,
        short_name: short_name,
      };
      apiCall(data, sessionStorage.getItem('token'), 'put', 'funder/')
        .then(response => {
          setEditFunder(false);
        })
        .catch(error => error);
    } else {
      document.getElementById('funder_name').focus();
    }
  };

  const getContactPerson = programFunders => {
    var contactPerson = {};
    for (var i = 0; i < programFunders.length; i++) {
      if (programFunders[i]['funder'] === funder['id']) {
        contactPerson = programFunders[i];
        break;
      }
    }

    return contactPerson;
  };

  return (
    <>
      <div>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          Action
          <ArrowDropDownOutlinedIcon />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={opeEditFunder}>Edit</MenuItem>
          <MenuItem onClick={openViewPrograms}>Programs</MenuItem>
        </Menu>
      </div>
      <Dialog
        open={editFunder}
        onClose={closeEditFunderDialog}
        className={classes.addFunderDialog}
        aria-labelledby="customized-dialog-title"
      >
        <MuiDialogTitle
          id="customized-dialog-title"
          onClose={closeEditFunderDialog}
          className={classes.requestDialog}
        >
          Edit Funder
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeEditFunderDialog}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <div className={classes.formColumFullWidth}>
            <TextField
              id="funder_name"
              label="Funder Names"
              variant="outlined"
              onChange={handleTextInput}
              value={funder.funder_name}
              className={classes.formInput}
            />
          </div>
          <br />
          <div className={classes.formColumFullWidth}>
            <TextField
              id="short_name"
              label="Short Name"
              variant="outlined"
              onChange={handleTextInput}
              value={funder.short_name}
              className={classes.formInput}
            />
          </div>
          <br />
          <Button
            className={classes.buttonSaveFunder}
            variant="outlined"
            color="primary"
            onClick={updateFunder}
          >
            <SaveIcon /> Update Details
          </Button>
        </MuiDialogContent>
      </Dialog>
      <Dialog
        open={viewPrograms}
        maxWidth="lg"
        onClose={closeViewProgramsDialog}
        aria-labelledby="customized-dialog-title"
      >
        <MuiDialogTitle
          id="customized-dialog-title"
          onClose={closeViewProgramsDialog}
          className={classes.programDialog}
        >
          <strong>{funder['funder_name']}</strong>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeViewProgramsDialog}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <h2>
            <strong>Active Programs</strong>
          </h2>
          <TableContainer component={Paper}>
            <Table
              className={classes.saccoServiceTable}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.saccoServiceTableCell}>
                    #
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Program
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Start Date
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    End Date
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Contact Person Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {funder['active_programs'].length > 0 ? (
                  funder['active_programs'].map((program, index) => (
                    <TableRow className={classes.saccoServiceTableRow}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <strong>{program['program_name']}</strong>
                        {program['short_name'].length > 0 && (
                          <>
                            <br />({program['short_name']})
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          <Moment format="DD-MM-YYYY">
                            {program['start_date']}
                          </Moment>
                        </strong>
                        <br />
                        <Moment className={classes.momentSuccess} fromNow>
                          {program['start_date']}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <strong>
                          <Moment format="DD-MM-YYYY">
                            {program['end_date']}
                          </Moment>
                        </strong>
                        <br />
                        <Moment className={classes.momentWarning} fromNow>
                          {program['end_date']}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {
                            getContactPerson(program['program_funders'])[
                              'contact_person_name'
                            ]
                          }
                        </strong>
                        <br />(
                        {
                          getContactPerson(program['program_funders'])[
                            'contact_person_desgination'
                          ]
                        }
                        )
                      </TableCell>
                      <TableCell>
                        <strong>
                          {
                            getContactPerson(program['program_funders'])[
                              'contact_person_email_address'
                            ]
                          }
                        </strong>
                        <br />(
                        {
                          getContactPerson(program['program_funders'])[
                            'contact_person_contact_1'
                          ]
                        }
                        )
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.saccoServiceTableRow}>
                    <TableCell align="center" colSpan="6">
                      No Active Programs Running
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <h2>
            <strong>Inactive Programs</strong>
          </h2>
          <TableContainer component={Paper}>
            <Table
              className={classes.saccoServiceTable}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.saccoServiceTableCell}>
                    #
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Program
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Start Date
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    End Date
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Contact Person
                  </TableCell>
                  <TableCell className={classes.saccoServiceTableCell}>
                    Contact Person Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {funder['inactive_programs'].length > 0 ? (
                  funder['inactive_programs'].map((program, index) => (
                    <TableRow className={classes.saccoServiceTableRow}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <strong>{program['program_name']}</strong>
                        {program['short_name'].length > 0 && (
                          <>
                            <br />({program['short_name']})
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          <Moment format="DD-MM-YYYY">
                            {program['start_date']}
                          </Moment>
                        </strong>
                        <br />
                        <Moment className={classes.momentSuccess} fromNow>
                          {program['start_date']}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <strong>
                          <Moment format="DD-MM-YYYY">
                            {program['end_date']}
                          </Moment>
                        </strong>
                        <br />
                        <Moment className={classes.momentWarning} fromNow>
                          {program['end_date']}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {
                            getContactPerson(program['program_funders'])[
                              'contact_person_name'
                            ]
                          }
                        </strong>
                        <br />(
                        {
                          getContactPerson(program['program_funders'])[
                            'contact_person_desgination'
                          ]
                        }
                        )
                      </TableCell>
                      <TableCell>
                        <strong>
                          {
                            getContactPerson(program['program_funders'])[
                              'contact_person_email_address'
                            ]
                          }
                        </strong>
                        <br />(
                        {
                          getContactPerson(program['program_funders'])[
                            'contact_person_contact_1'
                          ]
                        }
                        )
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.saccoServiceTableRow}>
                    <TableCell align="center" colSpan="6">
                      No inactive Programs found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </MuiDialogContent>
      </Dialog>
    </>
  );
}
