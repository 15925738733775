import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { HideOnScroll, LoadingIndicator } from 'react-admin';

import UserMenu from './UserMenu';

import MAAIFLogo from '../../assets/maaif.png';
import WBLogo from '../../assets/worldbank.png';
import ABLogo from '../../assets/ab-logo.png';

const maaifUrl = `${MAAIFLogo}`;
const wbUrl = `${WBLogo}`;
const abUrl = `${ABLogo}`;

const minLgWidth = 1200; // lg (min)

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      width: '100%',
      paddingRight: '15px',
      paddingLeft: '220px',
      marginRight: 'auto',
      marginLeft: 'auto',
      [`@media (min-width: ${minLgWidth}px)`]: {
        maxWidth: '1059px',
      },
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    logo: {
      height: '34.85px',
      paddingTop: '0.32rem',
      paddingBottom: '0.32rem',
      marginRight: '1rem',
      fontSize: '1.125rem',
    },
    logo1: {
      marginRight: '1.65rem',
    },
  }),
  { name: 'AkAppBar' }
);

/**
 * The AppBar component renders a custom MuiAppBar (Adopted from Ra)
 *
 * @param {ReactNode} children React node/s to be render as children of the AppBar
 * @param {Object} classes CSS class names
 * @param {string} className CSS class applied to the MuiAppBar component
 * @param {string} color The color of the AppBar
 * @param {Component} logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} open State of the <Admin/> Sidebar
 * @param {Element} userMenu A custom user menu component for the AppBar. <UserMenu/> component by default
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *        </AppBar>
 *    );
 *};
 */
const AkAppBar = props => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logo,
    logout,
    open,
    title,
    userMenu,
    ...rest
  } = props;

  const classes = useStyles(props);
  // const dispatch = useDispatch();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  // const translate = useTranslate();

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <img src={maaifUrl} alt="logo" className={clsx(classes.logo, classes.logo1)} />
          <img src={wbUrl} alt="logo" className={classes.logo} />
          <img src={abUrl} alt="logo" className={classes.logo} />
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
              children
            )}
          <LoadingIndicator />
          {cloneElement(userMenu, { icon: <MenuIcon/>, label: 'Menu', logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

AkAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

AkAppBar.defaultProps = {
  userMenu: <UserMenu />,
};

export default AkAppBar;
