import React from 'react';
import { Show } from 'react-admin';
import ScrollableTabsButtonAuto from './UserTabs';

const UserViewTitle = ({ record }) => {
	return <span>User {record ? record.role : 'User View'}</span>;
};

const SaccoUserShow = props => {
	return (
		<Show {...props} title={<UserViewTitle />}>
			<ScrollableTabsButtonAuto />
		</Show>
	);
};
export default SaccoUserShow;
