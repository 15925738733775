import React, { Fragment } from 'react';
import Moment from 'react-moment';
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, Divider, Tabs, Tab, Tooltip } from '@material-ui/core';

import RequestApprove from './RequestApprove';
import DialogDetails from './RequestDetails';
import MobileGrid from './MobileGrid';
import { requestStyles } from '../stylesheets';

const RequestFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="customer_id" reference="customers">
      <AutocompleteInput
        optionText={choice => `${choice.first_name} ${choice.last_name}`}
      />
    </ReferenceInput>
    <DateInput source="date_gte" />
    <DateInput source="date_lte" />
    <TextInput source="total_gte" />
    <NullableBooleanInput source="returned" />
  </Filter>
);

class TabbedDatagrid extends React.Component {
  /**
   * Requests
   * --------
   * pending, by default, these are on pending and not yet reviewed
   * approved, allowed by the SACCO Admin
   * rejected, disallowed by the SACCO Admin
   * ordered, accessible to this user
   */

  tabs = [
    { id: 'pending', name: 'pending' },
    { id: 'approved', name: 'approved' },
    { id: 'rejected', name: 'rejected' },
  ];

  state = { pending: [], approved: [], rejected: [], open: false };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  getMoment = record => {
    const classes = requestStyles();

    return (
      <>
        <strong>
          <Moment format="DD-MM-YYYY">{record.request_date}</Moment>
        </strong>
        <br />
        <Moment className={classes.momentWarning} fromNow>
          {record.request_date}
        </Moment>
      </>
    );
  };

  formateDate = record => {
    return <Moment format="DD-MM-YYYY">{record.request_date}</Moment>;
  };

  renderRequestField = record => {
    const classes = requestStyles();
    return (
      <>
        <strong>{record.request_number}</strong>
        <br />
        <span className={classes.momentSuccess}>{record.total}</span>
      </>
    );
  };

  render() {
    const { isXSmall, classes, filterValues, ...props } = this.props;

    return (
      <>
        <Fragment>
          <Tabs
            variant="fullWidth"
            centered
            value={filterValues.status}
            indicatorColor="primary"
            onChange={this.handleChange}
          >
            {this.tabs.map(choice => (
              <Tab key={choice.id} label={choice.name} value={choice.id} />
            ))}
          </Tabs>
          <Divider />
          {isXSmall ? (
            <MobileGrid {...props} ids={this.state[filterValues.status]} />
          ) : (
            <div>
              {filterValues.status === 'pending' && (
                <Datagrid
                  {...props}
                  ids={this.state.pending}
                  optimized
                  classes={{ headerCell: classes.heading }}
                >
                  <FunctionField
                    label="Request Number"
                    render={this.renderRequestField}
                  />
                  <FunctionField label="Request Date" render={this.getMoment} />
                  <TextField
                    label="Farmer Name"
                    source="farmer.farmer_full_name"
                  />
                  <TextField
                    label="Farmer Contact"
                    source="farmer.farmer_username"
                  />
                  <TextField
                    label="Provider Name"
                    source="provider.provider_full_name"
                  />
                  <Tooltip label="Details" arrow title="View Request">
                    <RequestApprove source="id" />
                  </Tooltip>
                </Datagrid>
              )}
              {filterValues.status === 'approved' && (
                <Datagrid
                  {...props}
                  ids={this.state.approved}
                  classes={{ headerCell: classes.heading }}
                >
                  <FunctionField
                    label="Request Number"
                    render={this.renderRequestField}
                  />
                  <FunctionField
                    label="Request Date"
                    render={this.formateDate}
                  />
                  <TextField
                    label="Farmer Name"
                    source="farmer.farmer_full_name"
                  />
                  <TextField
                    label="Farmer Contact"
                    source="farmer.farmer_username"
                  />
                  <TextField
                    label="Provider Name"
                    source="provider.provider_full_name"
                  />
                  <Tooltip label="Details" arrow title="View Request">
                    <DialogDetails source="id" />
                  </Tooltip>
                </Datagrid>
              )}
              {filterValues.status === 'rejected' && (
                <Datagrid
                  {...props}
                  ids={this.state.rejected}
                  classes={{ headerCell: classes.heading }}
                >
                  <FunctionField
                    label="Request Number"
                    render={this.renderRequestField}
                  />
                  <FunctionField
                    label="Request Date"
                    render={this.formateDate}
                  />
                  <TextField
                    label="Farmer Name"
                    source="farmer.farmer_full_name"
                  />
                  <TextField
                    label="Farmer Contact"
                    source="farmer.farmer_username"
                  />
                  <TextField
                    label="Provider Name"
                    source="provider.provider_full_name"
                  />
                  <Tooltip label="Details" arrow title="View Request">
                    <DialogDetails source="id" />
                  </Tooltip>
                </Datagrid>
              )}
            </div>
          )}
        </Fragment>
      </>
    );
  }
}

const StyledTabbedDatagrid = props => {
  const classes = requestStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return <TabbedDatagrid classes={classes} isXSmall={isXSmall} {...props} />;
};

const RequestList = ({ classes, permissions, ...props }) => {
  return (
    <List
      {...props}
      filterDefaultValues={{ status: 'pending', is_credit: 'true' }}
      sort={{ field: 'date', order: 'DESC' }}
      perPage={10}
      filters={<RequestFilter />}
    >
      <StyledTabbedDatagrid />
    </List>
  );
};

export default RequestList;
