import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField
} from 'react-admin';
import { FullNameField, GenderField, CustomBooleanField } from '../../../coopusers/CustomFields';
 
const FarmersList = ({ classes, ...props }) => {

  var farmerclass = props.location.state ? props.location.state.farmerclass : JSON.parse(sessionStorage.getItem('_farmers_classification'));
  var filters = props.location.state ? props.location.state.filters : JSON.parse(sessionStorage.getItem('_farmers_filter'))

  return (
    <List {...props} perPage={15} filterDefaultValues={{ filter_1: filters.filter_1, filter_2: filters.filter_2, filter_3: filters.filter_3, filter_4: filters.filter_4 ? filters.filter_4 : 'none', filter_5: filters.filter_5 ? filters.filter_5 : 'none', filter_6: farmerclass.id }}>
      <Datagrid>
        <FullNameField source="first_name" label="Name"/>
        <GenderField source="gender" label="Gender"/>
        <TextField source="username" label="Contact" />
        <TextField source="user_number" label="User Id" />
        <DateField source="date_added" label="Registered" showTime />
        <CustomBooleanField source="is_active" label="Active"/>
      </Datagrid>
    </List>
  )
}
export default FarmersList;