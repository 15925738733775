import React, { FC, useEffect, useState } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import { Customer, Order } from '../../types';
import allSaccoMembersStyle from '../../stylesheets/jss/saccoDashboard';
import apiCall from '../../modules/apiHelper';

interface Props {
  orders?: Order[];
  customers?: { [key: string]: Customer };
}

interface BranchService {
  id: string;
  added_by: string;
  date_added: string;
  cooperative_branch_id: string;
  is_active: true;
  service_id: number;
}

interface Service {
  id: string;
  date_added: string;
  service_type: string;
  service_addedby: string;
  mode_of_delivery: string;
  variation: string;
  service_name: string;
  unit: number;
  unit_of_measure: string;
  subcategoryid: number;
}

interface State {
  serviceId: number[];
  branchService: Array<BranchService>;
  service: Array<Service>;
}

const ServicesOffered: FC<Props> = ({ orders = [], customers = {} }) => {
  const classes = allSaccoMembersStyle();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [state, setState] = useState<State>({
    serviceId: [1],
    branchService: [
      {
        id: '7',
        added_by: 'Admin Akellobanker',
        date_added: '2020-05-28 01:45:45.833744+00:00',
        cooperative_branch_id: 'AkelloBanker',
        is_active: true,
        service_id: 1,
      },
    ],
    service: [],
  });

  const GetBranchServices = async () => {
    const handleFetchUsers = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperativebranchservices/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchUsers(pageNumber, results);
          } else {
            setState(state => ({
              ...state,
              branchService: results,
            }));
          }
        })
        .catch(error => console.error('Error while fetching:', error));

    await apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      'cooperativebranchservices/'
    )
      .then(response => {
        if (response.next !== null) {
          handleFetchUsers(2, response.results);
        } else {
          response.results.map((service: any) => {
            dataProvider
              .getOne('services', { id: service.service_id })
              .then(response => {
                let service_list: any = state.service;
                service_list.push(response);
                setState(state => ({
                  ...state,
                  service: service_list,
                }));
                return response;
              })
              .catch(error => console.error('Error while fetching:', error));
            return null;
          });
        }
      })
      .catch(error => console.error('Error while fetching:', error));
  };

  useEffect(() => {
    GetBranchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { service } = state;

  return (
    <Card className={classes.root}>
      <Typography variant="h5" component="h2" className={classes.title}>
        {translate('pos.dashboard.services_offered')} - {service.length}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell align="center">Service Type</TableCell>
              <TableCell align="center">Unit</TableCell>
              <TableCell align="center">Unit of Measure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {service.map((row: any) => (
              <TableRow key={row.data['id']}>
                <TableCell component="th" scope="row">
                  {row.data['service_name']}
                </TableCell>
                <TableCell align="center">{row.data['service_type']}</TableCell>
                <TableCell align="center">{row.data['unit']}</TableCell>
                <TableCell align="center">
                  {row.data['unit_of_measure']}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ServicesOffered;
