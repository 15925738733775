import SubCategoryIcon from '@material-ui/icons/TurnedInNot';

import SubCategoryList from './SubCategoryList';

export default {
  list: SubCategoryList,
  create: SubCategoryList,
  edit: SubCategoryList,
  icon: SubCategoryIcon,
};
