import React, { useState } from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInHours from 'date-fns/differenceInHours';
import isBefore from 'date-fns/isBefore'

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import { RequestAcceptForm } from './RequestAcceptForm';
import { RequestRejectForm } from './RequestRejectForm';
import { RequestDeliveryForm } from './RequestDeliveryForm';

import { ApproveButton } from '../../layout';
import { Separator, SectionTitle } from '../utils';

import { requestStyles } from '../stylesheets';

const useStyles = makeStyles({
	withInTime: {
		color: '#3f51b5',
	},
	outOfTime: {
		color: '#df1f26',
	},
});

// Not a typical react-admin component
const ProviderRequestEdit = props => {
  const classes = requestStyles();

  // destructure some props
  const { record, onClose } = props;
  
  const [acceptanceDialog, setAcceptanceDialog] = useState(false);
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [deliveryDialog, setDeliveryDialog] = useState(false);

  // support "accept" button actions
  const handleOpenAcceptDialog = e => {
    e.preventDefault();
    setAcceptanceDialog(!acceptanceDialog); 
    setRejectionDialog(acceptanceDialog);
  };

  // support "reject" button actions
  const handleOpenRejectDialog = e => {
    e.preventDefault();
    setAcceptanceDialog(rejectionDialog);
    setRejectionDialog(!rejectionDialog);
  };

  // support "delivery" button actions
  const handleDeliveryDialog = () => setDeliveryDialog(!deliveryDialog);

  // passed to the <RequestRequestForm>
  const handleCloseRejectDialog = () => setRejectionDialog(!rejectionDialog);

  // passed to the <RequestAcceptForm>
  const handleCloseAcceptDialog = () => setAcceptanceDialog(!acceptanceDialog);

  const RequestTitle = () => (
    // allow styling for modal-title
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Request no:&nbsp;</span>
      {record['request_number']}
    </p>
  );

  return (
    <>
      <DialogTitle
        id="requests-dialog-title"
        onClose={onClose}
        className={classes.requestDialog}
      >
        <Box
          display={{ xs: 'inline-flex' }} 
          width={{ xs: '100% !important' }}
          alignItems={{ xs: 'flex-end' }}
        >        
          <Box flex={1}>
            <SectionTitle label="" show={<RequestTitle/>} />            
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>            
          </Box>
          <Separator />
        </Box>
      </DialogTitle>
      <RequestDetailsContent 
        rejectionDialog={rejectionDialog}
        acceptanceDialog={acceptanceDialog}
        deliveryDialog={deliveryDialog}
        handleOpenAcceptDialog={handleOpenAcceptDialog}
        handleOpenRejectDialog={handleOpenRejectDialog}
        handleCloseAcceptDialog={handleCloseAcceptDialog}
        handleCloseRejectDialog={handleCloseRejectDialog}
        handleDeliveryDialog={handleDeliveryDialog}      
        {...props}    
      />
    </>
  );
};

const RequestDetailsContent = props => {
  const classes = requestStyles();
  const classesBase = useStyles();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    hasCreate, 
    hasEdit, 
    hasList, 
    hasShow,
    record,
    // from the direct parent component
    isXSmall,
    acceptanceDialog,
    rejectionDialog,
    deliveryDialog,
    handleOpenAcceptDialog,
    handleOpenRejectDialog,
    handleCloseAcceptDialog,
    handleCloseRejectDialog,
    handleDeliveryDialog,
    // passed to the <SimpleForm>
    ...rest
  } = props;
  
  // destructure "record"
  const { completion, farmer, location, request_status } = record;

  // returns "hours"
	let timeSinceRequest = record &&
  record.request_date &&
    differenceInHours(new Date(), new Date(record['request_date']));

  const RequestDateDetails = () => (
    // allow styling for modal-request-date    
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Date requested:&nbsp;</span>
      {format(new Date(record['request_date']), 'dd/MMM/yyyy')}<br/>
      <span className={clsx({
				[classesBase.withInTime]: timeSinceRequest < 24,
				[classesBase.outOfTime]: timeSinceRequest >= 24,
      })}>
        ({formatDistance(new Date(record['request_date']), new Date(), { addSuffix: true } )})
      </span>
    </p>
  );

  const RequestOwnerTitle = () => (
    // allow styling for modal-requested-by
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Requested by:</span>
    </p>
  );

  const RequestItemsTitle = () => (
    // allow styling for modal-requested-items
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Items requested:</span>
    </p>
  );

  const PlannedDeliveryTitle = () => (
    // allow styling for modal-planned-delivery
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Planned delivery:</span>
    </p>
  );

  // returns "boolean" to support color code
	let timeToDeliver = record &&
    record.completion &&
      record.completion.provider_visit_date &&
        isBefore(new Date(), new Date(record['completion']['provider_visit_date']));

  const PlannedDeliveryDate = () => (
    <span>
      {format(new Date(completion['provider_visit_date']), 'dd/MMM/yyyy')}&nbsp;
      <span className={clsx({
					[classesBase.withInTime]: timeToDeliver,
					[classesBase.outOfTime]: !timeToDeliver,
				})}>
        ({formatDistance(new Date(completion['provider_visit_date']), new Date(), { addSuffix: true } )})
      </span>
    </span>
  );

  return (
    <DialogContent dividers>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestDateDetails/>} />
      </Box>
      <Separator />
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestOwnerTitle/>} />
      </Box>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
      >
        <Card className={clsx('requested-by-card')}>
          {request_status && 
            (request_status === "accepted" || request_status === "delivered") &&
            // ONLY show "farmer contacts" when provider accepts the order
            <CardHeader
              className={clsx('requested-by-title')}
              title={
                <>
                  <span className={clsx('intro')}>Names:&nbsp;</span>
                  <span className={clsx('requester-names')}>
                    {farmer.farmer_full_name} ({farmer.farmer_username})
                  </span>
                </>
              }
              subheader={
                <>
                  <span className={clsx('intro')}>Location:&nbsp;</span>
                  <span className={clsx('requester-location')}>
                    {location.villagename}&nbsp;(Village)&#44; {location.districtname}&nbsp;(District)
                  </span>
                </>
              }
            />
          }
          {request_status && request_status === "approved" &&
            <CardContent className={clsx('provider-requests-notice')}>
              <p className={clsx('details')}>
                <span className={clsx('intro')}>Note:&nbsp;</span>
                Farmer contacts are ONLY shown when you accept this order.
              </p>
            </CardContent>
          }
        </Card>
      </Box>
      <Separator />
      <Box
        display={{ xs: 'flex' }} 
        width={{ xs: '100% !important' }}
      >
        <SectionTitle label="" show={<RequestItemsTitle/>} />
      </Box>
      <Box
        display={{ xs: 'block' }} 
        width={{ xs: '100% !important' }}
        mb={{ xs: '2rem' }}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Acreage</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Cost</TableCell>
                <TableCell>Total Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.services_requested_by_farmer 
                && record.services_requested_by_farmer.map((value, i) => {
                  return (
                    <React.Fragment key={i}>
                      <TableRow>
                        <TableCell>{i + 1}</TableCell>
                        {value.specifications 
                          && value.specifications.map((product, i) => (
                            <React.Fragment key={i}>
                              <TableCell>
                                {value['service_name']}&nbsp;({product['specification']})
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={product['acreage']}
                                  className={classes.requestItemType}
                                  variant="outlined"
                                />
                              </TableCell>                              
                              <TableCell>{product['qty']}</TableCell>
                              <TableCell>{product['service_cost']}</TableCell>
                              <TableCell>{record['total']}</TableCell>
                            </React.Fragment>
                          ))
                        }
                      </TableRow>
                      <TableRow>
                      {value.specifications 
                        && value.specifications.map((product, i) => (
                          <React.Fragment key={i}>
                            <TableCell></TableCell>
                            <TableCell className={classes.requestDelivery} colSpan="6">
                              *{(product['mode_of_delivery']).toLowerCase()}
                            </TableCell>
                          </React.Fragment>
                        )
                      )}
                      </TableRow>
                    </React.Fragment>
                  );
                })
              }
              <TableRow>
                <TableCell
                  className={classes.requestTotal}
                  colSpan="2"
                >
                  Total Cost
                </TableCell>
                <TableCell
                  className={classes.requestTotal}
                  colSpan="4"
                  align="right"
                >
                  {record['total']}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {request_status &&
        request_status === "accepted" && (
          <>
            <Box
              display={{ xs: 'block' }} 
              width={{ xs: '100% !important' }}
            >
              <SectionTitle label="" show={<PlannedDeliveryTitle/>} />
            </Box>
            <Box
              display={{ xs: 'block' }} 
              width={{ xs: '100% !important' }}
            >
              <Card className={clsx('requested-by-card')}>
                <CardContent className={clsx('provider-requests-notice')}>
                  <p className={clsx('details')}>
                    <PlannedDeliveryDate />
                  </p>
                </CardContent>
              </Card>
            </Box>
            <Separator />          
          </>
      )}
      <Box
        display={{ xs: 'flex' }}
        width={{ xs: '100% !important' }}
        className={clsx(
          {
            'two--btns': request_status && request_status === "approved",
            'one--btn': request_status && request_status === "accepted",
          },
        )}
      >
        {request_status && 
          request_status === "approved" &&
            <ApproveButton
              // reject
              label="Reject"
              isXSmall={isXSmall}
              handleClick={handleOpenRejectDialog}
              icon={<CancelOutlinedIcon />}
              // "small--btn" allows for mobile styling
              className={clsx('reject small--btn')}
              variant="contained"
              color={isXSmall ? "secondary" : "primary"}
              size="medium"
            />
        }
        {request_status && 
          request_status === "approved" &&
            <ApproveButton
              // accept
              label="Accept"
              isXSmall={isXSmall}
              handleClick={handleOpenAcceptDialog}
              icon={<CheckCircleOutlinedIcon />}
              // "small--btn" allows for mobile styling
              className={clsx('confirm small--btn')}
              variant="contained"
              color={isXSmall ? "secondary" : "primary"}
              size="medium"
            />
        }
        {request_status && 
          request_status === "accepted" &&
            <ApproveButton
              // delivery
              label="Confirm delivery"
              isXSmall={isXSmall}
              handleClick={handleDeliveryDialog}
              icon={<CheckCircleOutlinedIcon />}
              className={clsx('confirm small--btn')}
              variant="contained"
              color={isXSmall ? "secondary" : "primary"}
              size="medium"
            />
        }
        {rejectionDialog &&
          <RequestRejectForm
            isXSmall={isXSmall}
            record={record}
            rejectionDialog={rejectionDialog}
            handleCloseRejectDialog={handleCloseRejectDialog}
            {...rest} 
          />
        }          
        {acceptanceDialog &&
          <RequestAcceptForm
            isXSmall={isXSmall}
            record={record}
            acceptanceDialog={acceptanceDialog}
            handleCloseAcceptDialog={handleCloseAcceptDialog}
            {...rest}
          />
        }
        {deliveryDialog &&
          <RequestDeliveryForm
            isXSmall={isXSmall}
            record={record}
            deliveryDialog={deliveryDialog}
            handleDeliveryDialog={handleDeliveryDialog}
            {...rest}
          />
        }
      </Box>    
    </DialogContent>
  );
};

export default ProviderRequestEdit;
