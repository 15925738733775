import React, { FC } from 'react';
import { subDays, isAfter, parse } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import { useTranslate } from 'react-admin';
import { FieldProps, Service } from '../../types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const StatusField: FC<FieldProps<Service>> = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const today = new Date();
  const aWeekAgo = subDays(today, 7);

  return record ? (
    <span className={classes.main}>
      {record.approved ? (
        <Chip
          className={classes.chip}
          label={translate(`resources.providerservice.status.approved`)}
        />
      ) : record.added_added &&
        isAfter(
          aWeekAgo,
          parse(record.added_added, 'yy', new Date(2018, 0, 1))
        ) ? (
        <Chip
          className={classes.chip}
          label={translate(`resources.providerservice.status.denied`)}
        />
      ) : (
        <Chip
          className={classes.chip}
          label={translate(`resources.providerservice.status.pending`)}
        />
      )}
    </span>
  ) : null;
};

StatusField.defaultProps = {
  source: 'approved',
  label: 'resources.providerservice.status.name',
};

export default StatusField;
