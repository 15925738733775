import React, { useCallback } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';

import ServiceCreate from './ServiceCreate';
import ServiceEdit from './ServiceEdit';
import PureUnitMeasureField from './UnitMeasureField';
import { ActionButton, ListActions } from '../../layout';
import { drawerStyles } from '../stylesheets';

const ServiceFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" />
    <ReferenceInput
      label="Subcategory"
      source="subcategoryid"
      reference="subcategories"
      filter={{ subcategory: props.filterValues.subcategoryid }}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput optionText="sub_category_name" />
    </ReferenceInput>
  </Filter>
);

const ServiceList = ({ permissions, ...props }) => {
  const classes = drawerStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClose = useCallback(() => {
    history.push('/services');
  }, [history]);

  return permissions !== undefined ? (
    permissions.includes('superuser') ? (
      <>
        <List
          {...props}
          actions={<ListActions label='Add Service' />}
          filters={<ServiceFilter />}
          perPage={10}
          sort={{ field: 'service_name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField label="Service Name" source="service_name" />
            <PureUnitMeasureField />
            <DateField label="Added On" source="date_added" showTime />
            <ActionButton MenuOptions={ActionServiceMenu} {...props} />
          </Datagrid>
        </List>
        <Route path="/services/create">
          {({ match }) => (
            <>
              <Drawer open={!!match} anchor="right" onClose={handleClose}>
                <ServiceCreate
                  className={classes.drawerContent}
                  onCancel={handleClose}
                  {...props}
                />
              </Drawer>
            </>
          )}
        </Route>
        <Route path="/services/:id">
          {({ match }) => {
            let isMatch = match && match.params && match.params.id !== 'create';

            return (
              <>
                <Drawer open={!!isMatch} anchor="right" onClose={handleClose}>
                  {/* To avoid any errors if the route does not match,
                   * we don't render at all the component in this case
                   */}
                  {isMatch ? (
                    <ServiceEdit
                      className={classes.drawerContent}
                      id={isMatch && match !== null ? match.params.id : null}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : (
                    <div className={classes.drawerContent} />
                  )}
                </Drawer>
              </>
            );
          }}
        </Route>
      </>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

const ActionServiceMenu = (basePath = '', record = { id: ''}) => ([
  {label: 'Update', path: `${basePath}/${record.id}/edit`},
]);

export default ServiceList;
