import React, { useCallback } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { Datagrid, List, TextField } from 'react-admin';

import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';
import LinkToRelatedSubcategories from './LinkToRelatedSubcategories';
import { ActionButton, ListActions } from '../../layout';
import { drawerStyles } from '../stylesheets';

const CategoryList = ({ permissions, ...props }) => {
  const classes = drawerStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClose = useCallback(() => {
    history.push('/categories');
  }, [history]); 

  return permissions !== undefined ? (
    permissions.includes('superuser') ? (
      <>
        <List 
          actions={<ListActions label='Add Category' />}
          sort={{ field: 'category_name', order: 'ASC' }}
          {...props}
        >
          <Datagrid>
            <TextField label="Category Name" source="category_name" />
            <LinkToRelatedSubcategories />
            <ActionButton MenuOptions={ActionCategoryMenu} {...props} />
          </Datagrid>
        </List>
        <Route path="/categories/create">
          {({ match }) => (
            <>
              <Drawer open={!!match} anchor="right" onClose={handleClose}>
                <CategoryCreate
                  className={classes.drawerContent}
                  onCancel={handleClose}
                  {...props}
                />
              </Drawer>
            </>
          )}
        </Route>
        <Route path="/categories/:id">
          {({ match }) => {
            let isMatch = match && match.params && match.params.id !== 'create';

            return (
              <>
                <Drawer open={!!isMatch} anchor="right" onClose={handleClose}>
                  {/* To avoid any errors if the route does not match,
                   * we don't render at all the component in this case
                   */}
                  {isMatch ? (
                    <CategoryEdit
                      className={classes.drawerContent}
                      id={isMatch && match !== null ? match.params.id : null}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : (
                    <div className={classes.drawerContent} />
                  )}
                </Drawer>
              </>
            );
          }}
        </Route>
      </>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

const ActionCategoryMenu = (basePath = '', record = { id: ''}) => ([
  {label: 'Update', path: `${basePath}/${record.id}/edit`},
]);

export default CategoryList;
