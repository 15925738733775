import React, { useCallback, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Downshift from 'downshift';
import { SimpleForm, SaveButton, Toolbar, useRedirect, useNotify } from 'react-admin';
import { Box, MenuItem, Paper } from '@material-ui/core';

import {
  boxNumber, 
  Text,
} from '../../validation';

import { AkbInput, AkbTextField, SectionTitle, trim } from '../../utils';
import { userEditStyles } from '../../stylesheets';

import { apiFullCall } from '../../apiHelper';

interface Style {
  input?: string;
  container?: string;
  details?: string;
}

interface users {
	input?: string;
}

export const UserFormAddressWork = (props: any) => {
  const classes: Style = userEditStyles();
  const token = sessionStorage.getItem('token');

  const [state, setState] = useState<object | any>({ address: [] });
  const [rafields, setRaFields] = useState<object | any>({
    box_number: '', physical_address: '', villageid: '',
  });

  const [getDistrict, setGetDistrict] = useState<object | any>({ districtList: [] });
  
  const [filterDistrict, setFilterDistrict] = useState<object | any>({
    districtid: '',
    districtname: '',
  });

  const [filterValue, setFilterValue] = useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtname: '',
    regionname: '',
  });  

  let isLoaded = React.useRef(true);

  // onChange method for the input field
  const inputOnChangeDistrict = useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCallDistrict(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCallDistrict = value => {
    let district = trim(value);

    if (district.length > 2) {
      apiFullCall('', token, 'get', 'district/?search=' + district)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setGetDistrict(prevState => ({ ...prevState, districtList: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeDistrict = useCallback(value => {
    setFilterDistrict({ ...value });
    setFilterValue({
      id: '',
      villagename: '',
      parishname: '',
      subcountyname: '',
      countyname: '',
      districtname: '',
      regionname: '',
    });
  }, []); // eslint-disable-line  

  // onChange method for the input field
  const inputOnChangeVillage = useCallback((e, id?: string | number) => {
    if (!e.target.value) {
      return null;
    }

    searchCallVillage(e.target.value, id); // pass districtid
  }, []); // eslint-disable-line
  
  const searchCallVillage = (value, id?: string | number) => {
    let location = trim(value);

    if (location.length > 2) {

      if (!!id) {

        // search with districtid, if defined
        apiFullCall('', token, 'get', `locationsearch/?districtid=${id}&search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setState(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      } else {

        // plain search, if no district is selected
        apiFullCall('', token, 'get', `locationsearch/?search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setState(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      }
      
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeVillage = useCallback(value => {
    setFilterValue({ ...value });
  }, []); // eslint-disable-line

  // grab the village-id required for user
  if (typeof filterValue.id !== 'object') {
    sessionStorage.setItem('villageID', filterValue.id!);
    sessionStorage.setItem('villageName', filterValue.villagename!);
  }  

  React.useEffect(() => {
    if (isLoaded) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `usersview/${props['record']['id']}` 
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { 
              villageid,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtname,
              regionname,
              box_number,
              physical_address,
              ...rest // eslint-disable-line
            } = body;

            // pass this location object to form, onmount
            downshiftOnChangeVillage({ 
              id: villageid,
              villagename,
              parishname,
              subcountyname,
              countyname,
              districtname,
              regionname,
            });

            // set fields, to be used in API call (save buttons)
            // check if not "null" to avoid setting value=null in AkTextFields
            if (!box_number || !physical_address) {
              setRaFields({ ...rafields, villageid });
            } else {
              setRaFields({ ...rafields, box_number, physical_address, villageid });
            }
          }
        }      
      }).catch(
        error => console.error('Error while saving:', error)
      );      
    }
    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }
  }, [downshiftOnChangeVillage, props.record['id']]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    state.address && (
      <SimpleForm 
        {...props} 
        className={clsx('provider', 'register-form', props.className)}
        submitOnEnter={false}
        toolbar={<AddressToolbar addressInfo={rafields} {...props} />}
      >
        <Box display={{ md: 'flex' }} width={{ md: '100% !important' }} className={clsx('AkRegisterForm-body')}>
          <Box flex={2} mb={{ xs: '40px' }} className={clsx('address')}>
            <AkbTextField
              value={rafields.box_number}                  
              name="box_number"
              label="Box address"
              onChange={event => setRaFields({ ...rafields, box_number: event.target.value })}
              className={classes.input}
              validate={boxNumber}
            />
            <AkbTextField
              value={rafields.physical_address}                  
              name="physical_address"
              label="Physical address"
              onChange={event => setRaFields({ ...rafields, physical_address: event.target.value })}
              className={classes.input}
              validate={Text}
            />
          </Box>
        </Box>
        <Box
          display={{ md: 'flex' }} 
          mb={{ xs: '20px' }}
          width={{ md: '100% !important' }}
          className={clsx('address', 'AkRegisterForm-body')}
        >
          <Box flex={3}>
            <Downshift
              onChange={downshiftOnChangeDistrict}
              itemToString={item => (item ? item.districtname : '')}
            >
              {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                <div 
                  className={clsx(
                    classes.container,
                    { 'stretch--district': isOpen }
                  )}
                >
                  {AkbInput({
                    fullWidth: true,
                    classes,
                    name: 'search-district',
                    label: 'District',
                    InputProps: getInputProps({
                      onChange: inputOnChangeDistrict,
                    }),
                  })}
                  {isOpen && (
                    <Paper square className={clsx('select-location')}>
                      {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                        (item, index) => {
                          const { districtname } = item;

                          const isHighlighted = highlightedIndex === index;
                          const isSelected =
                            String(selectedItem || '').indexOf(districtname) >
                            -1;

                          return (
                            <MenuItem
                              {...getItemProps({ key: index, index, item })}
                              selected={isHighlighted}
                              component="div"
                              style={{
                                fontWeight: isSelected ? 500 : 400,
                              }}
                            >
                              {districtname}
                            </MenuItem>
                          );
                        }
                      )}
                    </Paper>
                  )}
                </div>
              )}
            </Downshift>           
            <Downshift
              onChange={downshiftOnChangeVillage}
              itemToString={item => (item ? item.villagename : '')}
            >
              {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                <div 
                  className={clsx(
                    classes.container,
                    { 'stretch': isOpen }
                  )}
                >
                  {AkbInput({
                    fullWidth: true,
                    classes,
                    name: 'search-village',
                    label: 'Search by Village',
                    InputProps: getInputProps({
                      onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                    }),
                  })}
                  {isOpen && (
                    <Paper square className={clsx('select-location')}>
                      {getVillageSuggestions(state.address, inputValue).map(
                        (item, index) => {
                          const {
                            villagename,
                            parishname,
                            subcountyname,
                            districtname,
                          } = item;
                          const isHighlighted = highlightedIndex === index;
                          const isSelected =
                            String(selectedItem || '').indexOf(villagename) >
                            -1;

                          return (
                            <MenuItem
                              {...getItemProps({ key: index, index, item })}
                              selected={isHighlighted}
                              component="div"
                              style={{
                                fontWeight: isSelected ? 500 : 400,
                              }}
                            >
                              {villagename} ({parishname})<br />
                              {subcountyname}, {districtname}
                            </MenuItem>
                          );
                        }
                      )}
                    </Paper>
                  )}
                </div>
              )}
            </Downshift>   
          </Box>
          <Box flex={3}></Box>
        </Box>
        <Box 
          display={{ md: 'flex' }}
          width={{ md: '100% !important' }}
          className={clsx('AkRegisterForm-body')}
        >
          <SectionTitle 
            label='*fields (below) will be updated when you "Search by Village"'
            className={clsx(
              { 'hidden': !filterValue.id },
              classes.details
            )} 
          />
        </Box>
        <Box 
          display={{ md: 'flex' }}
          width={{ md: '100% !important' }}
          className={clsx('address', 'AkRegisterForm-body')}
        >
          <Box flex={3}>
            <AkbTextField
              type="hidden"
              disabled={true}
              value={filterValue.id}                  
              name="village_id"
              label="id"
              className={clsx('unique-field', classes.input)}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.villagename}
              name="villagename"
              label="Village"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.parishname}
              name="parishname"
              label="Parish"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.subcountyname}
              name="subcountyname"
              label="Subcounty"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.countyname}
              name="countyname"
              label="County"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.districtname}
              name="districtname"
              label="District (selected)"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={filterValue.regionname}
              name="regionname"
              label="Region"
              className={clsx(
                { 'hidden': !filterValue.id },
                classes.input
              )}
            />           
          </Box>
          <Box flex={1}></Box>
        </Box>
      </SimpleForm>
    )
  );
};

const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const getVillageSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ villagename }) => {
    const keep =
      (!inputValue ||
        String(villagename)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const AddressToolbar = props => {
  const { addressInfo, ...rest } = props; // eslint-disable-line
  return (
    <Toolbar {...rest}>
      <SaveAndContinueButton {...props} />
      <SaveAndExitButton {...props} />
    </Toolbar>
  );
};

const SaveAndContinueButton = props => {
  const history = useHistory();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const formState = useFormState();

  const [ loading, setLoading ] = useState<boolean>(false);
  
  // avoid pushing "addressInfo" to DOM element (save button)
  const { addressInfo, ...rest} = props;

  const token = sessionStorage.getItem('token');
  const villageID = sessionStorage.getItem('villageID');

  const handleChangeTab = useCallback(
    id => {
      history.push(`${props.basePath}/${id}/1`);
    },
    [history, props.basePath]
  );

  const data = {
    user_id: formState['values']['id'],
    village_id: addressInfo.villageid || villageID,
    is_active: true,
    ...addressInfo,
  };

  const handleSaveAndContinue = useCallback(() => {
    // save user location/address
    apiFullCall(
      data, 
      token, 
      'post', 
      `usersaddress/` 
    ).then(res => {

      if (res) {

        const { status, body } = res;
        setLoading(true);

        if (status === 200 || status === 201) {

          redirectTo('edit', props.basePath, body.user_id, props.record);
          handleChangeTab(props.record.id);
          notify('post.notification.location_add', 'info', {
            smart_count: 1,
          });

        } else if (status === 400) {
          notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
        } else if (status >= 500) {
          notify(`Server error, please try again later.`, 'warning');          
        };

        setLoading(false);

      };

    }).catch(
      error => console.error('Error while saving:', error)
    );

  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_continue"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveAndContinue} 
    />
  );
};

const SaveAndExitButton = props => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const formState = useFormState();
  
  const [ loading, setLoading ] = useState<boolean>(false);
  
  // avoid pushing "addressInfo" to DOM element (save button)
  const { addressInfo, ...rest} = props;

  const token = sessionStorage.getItem('token');
  const villageID = sessionStorage.getItem('villageID');

  let data = {
    user_id: formState['values']['id'],
    village_id: addressInfo.villageid || villageID,
    is_active: true,
    ...addressInfo,
  };

  const handleSaveAndExit = useCallback(() => { 
    apiFullCall(
      data, 
      token, 
      'post', 
      `usersaddress/` 
    ).then(res => {

      if (res) {

        const { status, body } = res; // eslint-disable-line
        setLoading(true);

        if (status === 200 || status === 201) {

          redirectTo('list', props.basePath);
          notify('post.notification.location_add', 'info', {
            smart_count: 1,
          });

        } else if (status === 400) {
          notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
        } else if (status >= 500) {
          notify(`Server error, please try again later.`, 'warning');          
        };

        setLoading(false);

      };

    }).catch(
      error => console.error('Error while saving:', error)
    );
    
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_exit"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveAndExit} 
    />
  );
};
