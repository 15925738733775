import React, { FC } from 'react';
import pure from 'recompose/pure';

import { FieldProps, User } from '../../types';
import { fullNameStyles } from '../stylesheets/userStyle';

interface Props extends FieldProps<User> {
	size?: string;
}

const FullNameField: FC<Props> = ({ record, size }) => {
	const classes = fullNameStyles();

	return record ? (
		<span className={classes.root}>
			{record.first_name} {record.middle_name} {record.last_name}
		</span>
	) : null;
};

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
	source: 'last_name',
	label: 'pos.menu.providers.name'
};

export default PureFullNameField;
