import React from 'react';
import { useHistory } from 'react-router-dom'
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { TextField } from 'react-admin';
import PersonIcon from '@material-ui/icons/PeopleOutline';
import Avatar from '@material-ui/core/Avatar';
import { serviceProvidersStyles } from '../../stylesheets';


const FarmersCard = ({
	ids, 
	data, 
	basePath, // eslint-disable-line
	filters 
}) => {
	const history = useHistory();
	const classes = serviceProvidersStyles();

	const viewDetails = (classification) => { // eslint-disable-line
		sessionStorage.setItem('_providers_filter', JSON.stringify(filters))
		sessionStorage.setItem('_providers_classification', JSON.stringify(classification))
		history.push({ pathname: '/serviceprovidersview', state: { classification: classification, filters: filters } });
	};

	return <div className={classes.div1}>
		{ids.map(id =>
			<Card raised={false} key={id} className={classes.cardStyle} onClick={() => { }}>
				<CardHeader
					title={<TextField className={classes.cardHeaderTitle2} record={data[id]} source="name" />}
					subheader={<TextField className={classes.subHeaderTitle2} record={data[id]} source="id" />}
					avatar={<Avatar icon={<PersonIcon />} />}
				/>
				<CardContent className={classes.cardContent2}>
					<TextField className={classes.farmersCount} record={data[id]} source="count" />
				</CardContent>
			</Card>
		)}
	</div>
}
FarmersCard.defaultProps = {
	data: {},
	ids: [],
};

export default FarmersCard;