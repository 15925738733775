import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import ServiceReferenceField from '../services/ServiceReferenceField';
import UserReferenceField from '../users/UserReferenceField';
import StarRatingField from './StarRatingField';

import rowStyle from './rowStyle';

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const ReviewListDesktop = props => {
  const classes = useListStyles();
  return (
    <Datagrid
      rowClick="edit"
      rowStyle={rowStyle}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <DateField source="date" />
      <UserReferenceField link={false} />
      <ServiceReferenceField link={false} />
      <StarRatingField size="small" />
      <TextField source="comment" cellClassName={classes.comment} />
      <TextField source="status" />
    </Datagrid>
  );
};

export default ReviewListDesktop;
