import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  SelectInput,
  SimpleFormIterator, 
  TextInput,
} from 'react-admin';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem, 
  Paper,
  Select,
} from '@material-ui/core';
import {
  firstName,
  Text,
  lastName,
  Needed,
  NIN,
  Sex,
  Gender,
  phoneNumber,
} from '../../validation';

import { AkbInput, AkbTextField, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

interface Style {
  input?: string;
  container?: string;
  details?: string;
  photo?: string;
}

export const ProviderFormPersonWithAddress = (props: any) => {
  const classes: Style = userEditStyles();

  // related to input validation
  const AddUsername = [...phoneNumber, ...Needed];
  const mustAdd = [...Needed, ...Gender];

  const {
    record,
    // field checks if we're in create/edit mode
    isRegistered,
    // related to village search
    searchable,
    filterValue,
    inputOnChangeVillage,
    downshiftOnChangeVillage,
    // related to district search
    getDistrict,
    filterDistrict,
    inputOnChangeDistrict,
    downshiftOnChangeDistrict,
    // related to branch-user
    branchUser,
    setBranchUser
  } = props;

  const { provider_location } = record && record;

  // grab the village-id required for branch set-up
  if (filterValue && typeof filterValue.id !== 'object') {
    sessionStorage.setItem('villageID', filterValue.id!);
  };

  // grab the branch-user-id required for branch user update
  if (branchUser && typeof branchUser.id !== 'object') {
    sessionStorage.setItem('branchUserID', branchUser.id!);
  };

  return (
    searchable.address && (
      <>          
        <Box display={{ sm: 'block' }}>
          <SectionTitle label="Personnel" className={clsx('centered')} />
        </Box>
        <Box
          display={{ md: 'flex' }} 
          width={{ md: '100% !important' }}
          className={clsx('AkRegisterForm-body')}
        >
          <Box 
            flex={3} 
            className={clsx({ 
              'address': isRegistered, 
              'personel': isRegistered,
            })}
          >
            {/* TODO: Refactor these to use either material-ui or just ra-fields */}
            {isRegistered ? (
              // edit-mode
              <>
                <TextInput
                  // not updating at the moment, thus value = ""
                  defaultValue={branchUser.id}
                  label="Branch User ID"
                  source="users['id']"         
                  className={clsx(classes.input, 'hidden')}
                />
                {/* Render Material-ui fields (support easy input change from fetch) */}
                <AkbTextField
                  value={branchUser.first_name}               
                  name="first_name"
                  label="First name"
                  onChange={e => setBranchUser({ ...branchUser, first_name: e.target.value })}
                  className={classes.input}
                />
                <AkbTextField
                  value={
                    /**
                     * Resolve this Warning: `value` prop on `input` should not be null.
                     * Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                     * 
                     * Check that the we neither have a "null" or "undefined" value
                     */
                    branchUser && typeof(branchUser.middle_name) === "undefined" 
                      ? "" 
                      : typeof(branchUser.middle_name) === "object" 
                        ? "" 
                        : branchUser.middle_name
                  }                  
                  name="middle_name"
                  label="Middle name"
                  onChange={e => setBranchUser({ ...branchUser, middle_name: e.target.value })}
                  className={classes.input}
                />
                <AkbTextField
                  value={branchUser.last_name}                  
                  name="last_name"
                  label="Last name"
                  onChange={e => setBranchUser({ ...branchUser, last_name: e.target.value })}
                  className={classes.input}
                />
                <FormControl className={classes.input}>
                  <InputLabel htmlFor="gender">Gender</InputLabel>
                  <Select
                    name="gender"
                    id="gender"
                    value={branchUser.gender}
                    onChange={e => setBranchUser({ ...branchUser, gender: e.target.value })}
                  >
                    {Sex && Sex.map(({ id, name }, i) => 
                      <MenuItem key={i} value={id}>{name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <AkbTextField
                  value={branchUser.username}                  
                  name="username"
                  label="Phone no."
                  onChange={e => setBranchUser({ ...branchUser, username: e.target.value })}
                  className={classes.input}
                />
                <AkbTextField
                  value={
                    /**
                     * Resolve this Warning: `value` prop on `input` should not be null.
                     * Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                     * 
                     * Check that the we neither have a "null" or "undefined" value
                     */
                    branchUser && typeof(branchUser.nin) === "undefined" 
                      ? "" 
                      : typeof(branchUser.nin) === "object" 
                        ? "" 
                        : branchUser.nin
                  }                  
                  name="nin"
                  label="NIN"
                  onChange={e => setBranchUser({ ...branchUser, nin: e.target.value })}
                  className={classes.input}
                />           
              </>
            ) : (
              // create-mode
              <>
                {/* Render default ra-fields, support easy handling at create */}
                <TextInput
                  source="first_name"
                  className={classes.input}
                  validate={firstName}
                />
                <TextInput
                  source="middle_name"
                  className={classes.input}
                  validate={Text}
                />
                <TextInput
                  source="last_name"
                  className={classes.input}
                  validate={lastName}
                />
                <SelectInput
                  source="gender"
                  choices={Sex}
                  className={classes.input}
                  validate={mustAdd}
                />
                <DateInput 
                  label="Date of Birth" 
                  source="dob" 
                  className={classes.input}
                  validate={Needed}
                />
                <TextInput
                  label="Phone no."
                  source="username"
                  className={classes.input}
                  validate={AddUsername}
                />
                <TextInput
                  label="NIN"
                  source="nin"
                  className={classes.input}
                  validate={NIN}
                />              
              </>                
            )}
            <BooleanInput
              label="Active"
              source="is_active"
              defaultValue={true}
              className={clsx('hidden')}
            /> 
            <BooleanInput
              label="Approved"
              source="is_approved"
              defaultValue={true}
              className={clsx('hidden')}
            />          
          </Box>
          <Box flex={1}></Box>
        </Box>                  
        <Box
          display={{ sm: 'block' }} 
          mt={{ xs: '30px', md: '16px' }}
          className={clsx('form--subsection')}
        >
          {isRegistered ? (
            <SectionTitle label="Branch(es) / Address" className={clsx('centered')} />
            ):(
            <SectionTitle label="Location" className={clsx('centered')} />
          )}
        </Box>
        {isRegistered 
          ? provider_location 
            && provider_location.length === 0
            // edit-mode:
            ? (
              // render just intial record
              <>
                <Box
                  display={{ md: 'flex' }}
                  mb={{ xs: '1em' }}
                  width={{ md: '100% !important' }}
                  className={clsx('address', 'details', 'edit', 'personel', 'AkRegisterForm-body')}
                >
                  <Box flex={3}>
                    <TextInput
                      label="Branch name"
                      source="provider_location[0].branch_name"
                      className={clsx(classes.input, 'branch')}
                      validate={Text}
                    />
                    <SelectInput
                      label="Type of branch"
                      source="provider_location[0].location_type"
                      choices={[
                        { id: 'branch', name: 'Branch' },
                        { id: 'agent', name: 'Agent' }
                      ]}
                      className={clsx(classes.input, 'branch')}
                    />
                  </Box>
                  <Box flex={1}></Box>
                </Box>
                <Box
                  display={{ md: 'flex' }}
                  mb={{ xs: '1em' }}
                  width={{ md: '100% !important' }}
                  className={clsx('address', 'details', 'edit', 'personel', 'AkRegisterForm-body')}
                >
                  <Box flex={3}>
                    <Downshift
                      onChange={downshiftOnChangeDistrict}
                      itemToString={item => (item ? item.districtname : '')}
                    >
                      {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                        <div 
                          className={clsx(
                            classes.container,
                            { 'stretch--district': isOpen }
                          )}
                        >
                          {AkbInput({
                            fullWidth: true,
                            classes,
                            name: 'search-district',
                            label: 'District',
                            InputProps: getInputProps({
                              onChange: inputOnChangeDistrict,
                            }),
                          })}
                          {isOpen && (
                            <Paper square className={clsx('select-location')}>
                              {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                                (item, index) => {
                                  const { districtname } = item;

                                  const isHighlighted = highlightedIndex === index;
                                  const isSelected =
                                    String(selectedItem || '').indexOf(districtname) >
                                    -1;

                                  return (
                                    <MenuItem
                                      {...getItemProps({ key: index, index, item })}
                                      selected={isHighlighted}
                                      component="div"
                                      style={{
                                        fontWeight: isSelected ? 500 : 400,
                                      }}
                                    >
                                      {districtname}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Paper>
                          )}
                        </div>
                      )}
                    </Downshift>
                    <Downshift
                      onChange={downshiftOnChangeVillage}
                      itemToString={item => (item ? item.villagename : '')}
                    >
                      {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                        <div 
                          className={clsx(
                            classes.container,
                            { 'stretch': isOpen }
                          )}
                        >
                          {AkbInput({
                            fullWidth: true,
                            classes,
                            name: 'search-village',
                            label: 'Search by Village',
                            InputProps: getInputProps({
                              onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                            }),
                          })}
                          {isOpen && (
                            <Paper square className={clsx('select-location')}>
                              {getVillageSuggestions(searchable.address, inputValue).map(
                                (item, index) => {
                                  const {
                                    villagename,
                                    parishname,
                                    subcountyname,
                                    districtname,
                                  } = item;
                                  const isHighlighted = highlightedIndex === index;
                                  const isSelected =
                                    String(selectedItem || '').indexOf(villagename) >
                                    -1;

                                  return (
                                    <MenuItem
                                      {...getItemProps({ key: index, index, item })}
                                      selected={isHighlighted}
                                      component="div"
                                      style={{
                                        fontWeight: isSelected ? 500 : 400,
                                      }}
                                    >
                                      {villagename} ({parishname})<br />
                                      {subcountyname}, {districtname}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Paper>
                          )}
                        </div>
                      )}
                    </Downshift>
                  </Box>
                  <Box flex={1}></Box>
                </Box>
                <Box display={{ md: 'flex' }} width={{ md: '100% !important' }}>
                  <SectionTitle
                    label='*These fields are only filled after you "Search by Village"'
                    className={clsx(classes.details, 'personel')}
                  />
                </Box>
                <Box
                  display={{ md: 'flex' }}
                  width={{ md: '100% !important' }}
                  className={clsx('address', 'personel')}
                >
                  <Box flex={3}>
                    <TextInput
                      // not updating at the moment, thus value = ""
                      defaultValue={filterValue.id}
                      label="Village ID"
                      source="provider_location[0].village_id"         
                      className={clsx(classes.input, 'hidden')}
                    />
                    <BooleanInput
                      label="Main Branch"
                      source="provider_location[0].is_main_branch"
                      defaultValue={true}
                      className={clsx('hidden')}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.id}
                      name="my_village"
                      label="Village id"
                      className={clsx(classes.input, 'hidden')}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.villagename}
                      name="villagename"
                      label="Village"
                      className={classes.input}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.parishname}
                      name="parishname"
                      label="Parish"
                      className={classes.input}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.subcountyname}
                      name="subcountyname"
                      label="Subcounty"
                      className={classes.input}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.countyname}
                      name="countyname"
                      label="County"
                      className={classes.input}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.districtname}
                      name="districtname"
                      label="District"
                      className={classes.input}
                    />
                    <AkbTextField
                      disabled={true}
                      value={filterValue.regionname}
                      name="regionname"
                      label="Region"
                      className={classes.input}
                    />
                  </Box>
                  <Box flex={1}></Box>
                </Box>
              </>
            ) : (
              // render array of records
              <ArrayInput
                label=""
                source="provider_location"
                className={clsx('address', 'details', 'edit', 'AkRegisterForm-body')}
              >
                <SimpleFormIterator>
                  {/* For "source" to work, fields must be direct children of "iterator" */}
                  <TextInput
                    label="Branch name"
                    source="branch_name"
                    className={clsx(classes.input, 'branch')}
                    validate={Text}
                  />
                  <SelectInput
                    label="Type of branch"
                    source="location_type"
                    choices={[
                      { id: 'branch', name: 'Branch' },
                      { id: 'agent', name: 'Agent' }
                    ]}
                    className={clsx(classes.input, 'branch')}
                  />
                  <Box
                    display={{ md: 'flex' }}
                    width={{ md: '100% !important' }}
                  >
                    <Box flex={3}>
                      <Downshift
                        onChange={downshiftOnChangeDistrict}
                        itemToString={item => (item ? item.districtname : '')}
                      >
                        {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                          <div 
                            className={clsx(
                              classes.container,
                              { 'stretch--district': isOpen }
                            )}
                          >
                            {AkbInput({
                              fullWidth: true,
                              classes,
                              name: 'search-district',
                              label: 'District',
                              InputProps: getInputProps({
                                onChange: inputOnChangeDistrict,
                              }),
                            })}
                            {isOpen && (
                              <Paper square className={clsx('select-location')}>
                                {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                                  (item, index) => {
                                    const { districtname } = item;

                                    const isHighlighted = highlightedIndex === index;
                                    const isSelected =
                                      String(selectedItem || '').indexOf(districtname) >
                                      -1;

                                    return (
                                      <MenuItem
                                        {...getItemProps({ key: index, index, item })}
                                        selected={isHighlighted}
                                        component="div"
                                        style={{
                                          fontWeight: isSelected ? 500 : 400,
                                        }}
                                      >
                                        {districtname}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Paper>
                            )}
                          </div>
                        )}
                      </Downshift>
                      <Downshift
                        onChange={downshiftOnChangeVillage}
                        itemToString={item => (item ? item.villagename : '')}
                      >
                        {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                          <div 
                            className={clsx(
                              classes.container,
                              { 'stretch': isOpen }
                            )}
                          >
                            {AkbInput({
                              fullWidth: true,
                              classes,
                              name: 'search-village',
                              label: 'Search by Village',
                              InputProps: getInputProps({
                                onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                              }),
                            })}
                            {isOpen && (
                              <Paper square className={clsx('select-location')}>
                                {getVillageSuggestions(searchable.address, inputValue).map(
                                  (item, index) => {
                                    const {
                                      villagename,
                                      parishname,
                                      subcountyname,
                                      districtname,
                                    } = item;
                                    const isHighlighted = highlightedIndex === index;
                                    const isSelected =
                                      String(selectedItem || '').indexOf(villagename) >
                                      -1;

                                    return (
                                      <MenuItem
                                        {...getItemProps({ key: index, index, item })}
                                        selected={isHighlighted}
                                        component="div"
                                        style={{
                                          fontWeight: isSelected ? 500 : 400,
                                        }}
                                      >
                                        {villagename} ({parishname})<br />
                                        {subcountyname}, {districtname}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Paper>
                            )}
                          </div>
                        )}
                      </Downshift>                                        
                    </Box>
                    <Box flex={1}></Box>
                  </Box>
                  <Box display={{ md: 'flex' }} width={{ md: '100% !important' }}>
                    <SectionTitle
                      label='*These fields are only filled after you "Search by Village"'
                      className={classes.details}
                    />
                  </Box>
                  <Box
                    display={{ md: 'flex' }}
                    width={{ md: '100% !important' }}
                    className={clsx('address')}
                  >
                    <Box flex={3}>              
                      <TextInput
                        label="Village ID"
                        source="village_id"         
                        className={clsx(classes.input, 'hidden')}
                      />
                      <BooleanInput
                        label="Main Branch"
                        source="provider_location[0].is_main_branch"
                        defaultValue={true}
                        className={clsx('hidden')}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.id}
                        name="my_village"
                        label="Village id"
                        className={clsx(classes.input, 'hidden')}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.villagename}
                        name="villagename"
                        label="Village"
                        className={classes.input}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.parishname}
                        name="parishname"
                        label="Parish"
                        className={classes.input}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.subcountyname}
                        name="subcountyname"
                        label="Subcounty"
                        className={classes.input}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.countyname}
                        name="countyname"
                        label="County"
                        className={classes.input}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.districtname}
                        name="districtname"
                        label="District"
                        className={classes.input}
                      />
                      <AkbTextField
                        disabled={true}
                        value={filterValue.regionname}
                        name="regionname"
                        label="Region"
                        className={classes.input}
                      />
                    </Box>
                    <Box flex={1}></Box>
                  </Box>
                </SimpleFormIterator>
              </ArrayInput>
            ) 
          : (
            // create-mode: focus on creating one record
            <>
              <Box
                display={{ md: 'flex' }}
                mb={{ xs: '1em' }}
                width={{ md: '100% !important' }}
                className={clsx('address', 'details', 'AkRegisterForm-body')}
              >
                <Box flex={3}>
                  <TextInput
                    label="Branch name"
                    source="provider_location[0].branch_name"
                    className={clsx(classes.input, 'branch', 'hidden')}
                  />
                  <TextInput
                    defaultValue={'branch'}
                    label="Type of branch"
                    source="provider_location[0].location_type"
                    className={clsx(classes.input, 'branch', 'hidden')}
                  />
                  <TextInput
                    id="search-district"
                    value={filterDistrict.districtid}
                    name="search_district"
                    className={clsx(classes.input, 'hidden')}
                  />
                  <Downshift
                    onChange={downshiftOnChangeDistrict}
                    itemToString={item => (item ? item.districtname : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div 
                        className={clsx(
                          classes.container,
                          { 'stretch--district': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-district',
                          label: 'District',
                          InputProps: getInputProps({
                            onChange: inputOnChangeDistrict,
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-location')}>
                            {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                              (item, index) => {
                                const { districtname } = item;

                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(districtname) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {districtname}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>
                  <Downshift
                    onChange={downshiftOnChangeVillage}
                    itemToString={item => (item ? item.villagename : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div 
                        className={clsx(
                          classes.container,
                          { 'stretch': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-village',
                          label: 'Search by Village',
                          InputProps: getInputProps({
                            onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-location')}>
                            {getVillageSuggestions(searchable.address, inputValue).map(
                              (item, index) => {
                                const {
                                  villagename,
                                  parishname,
                                  subcountyname,
                                  districtname,
                                } = item;
                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(villagename) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {villagename} ({parishname})<br />
                                    {subcountyname}, {districtname}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>
                </Box>
                <Box flex={1}></Box>
              </Box>
              <Box display={{ md: 'flex' }} width={{ md: '100% !important' }}>
                <SectionTitle
                  label='*These fields are only filled after you "Search by Village"'
                  className={clsx(
                    { 'hidden': !filterValue.id },
                    classes.details
                  )}
                />
              </Box>
              <Box
                display={{ md: 'flex' }}
                width={{ md: '100% !important' }}
                className={clsx('address')}
              >
                <Box flex={3}>
                  <TextInput
                    // not updating at the moment, thus initial village_id doesn't change
                    // Changes are captured via sessionStorage (in multi form)
                    id="villageId"
                    defaultValue={filterValue.id}
                    name="provider_location[0].village_id"         
                    className={clsx(classes.input, 'hidden')}
                  />
                  <BooleanInput
                    label="Main Branch"
                    source="provider_location[0].is_main_branch"
                    defaultValue={true}
                    className={clsx('hidden')}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.villagename}
                    name="villagename"
                    label="Village"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.parishname}
                    name="parishname"
                    label="Parish"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.subcountyname}
                    name="subcountyname"
                    label="Subcounty"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.countyname}
                    name="countyname"
                    label="County"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.districtname}
                    name="districtname"
                    label="District (selected)"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.regionname}
                    name="regionname"
                    label="Region"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                </Box>
                <Box flex={1}></Box>
              </Box>
            </> 
        )}
      </>
    )
  );
};

const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const getVillageSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ villagename }) => {
    const keep =
      (!inputValue ||
        String(villagename)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};
