import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import {
  TextInput,
  useNotify,
} from 'react-admin';
import {
  Box,
  MenuItem, 
  Paper,
} from '@material-ui/core';

import CancelNow from '@material-ui/icons/Clear';
import { AkbInput, AkbTextField, SectionTitle, trim } from '../../utils';
import { DownloadButton as RemoveButton } from '../../../layout';
import { userEditStyles } from '../../stylesheets';

import { apiFullCall } from '../../apiHelper';

interface Style {
  input?: string;
  container?: string;
  details?: string;
  photo?: string;
}

export const SearchExistingUserForm = (props: any) => {
  const notify = useNotify();
  const classes: Style = userEditStyles();
  const token = sessionStorage.getItem('token');

  const [state, setState] = React.useState<object | any>({ existingUsers: [] });

  // field checks if we're in create/edit mode
  const {
    idDistrict,
    filterExistingUser,
    downshiftOnChangeUser,
    handleRemoveUser
  } = props;

  const {
    id,
    full_name,
    username,
    is_farmer,
    is_provider,
    villagename 
  } = filterExistingUser && filterExistingUser;

  // onChange method for the input field
  const inputOnChange = React.useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCall(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCall = value => {
    let name = trim(value);

    if (name.length > 2) {

      // check if "idDistrict" is not "null"
      if (!!idDistrict) {

        // pass "districtid" to do a targetted search
        apiFullCall('', token, 'get', `usersview/?districtid=${idDistrict}&search=${name}`)
          .then(res => {
            if (res) {
              // destructure response
              const { status, body } = res;
  
              if (status === 200 || status === 201) {
  
                setState(prevState => ({ ...prevState, existingUsers: body['results'] }))
  
              }
            }
          })
          .catch(error =>
            console.error('Error while searching for location:', error)
          );

      } else {
        // alert user to first enter village/location
        notify(`Please first complete "Group details"`, 'warning');
      }

    } else {
      return null
    }

  };

  // set "value" for "registered as" field
  const status = id ? (
    is_farmer && is_provider
      ? 'Farmer & Provider'
      : is_farmer && !is_provider
        ? 'Farmer'
        : !is_farmer && is_provider
          ? 'Provider'
          : ''
  ) : '';

  // grab the village-id required for branch set-up
  if (typeof id !== 'object') {
    sessionStorage.setItem('existingUserId', id!);
  };

  return (
    state.existingUsers && (
      <>
        <Box
          display={{ md: 'flex' }}
          mb={{ xs: '1em' }}
          width={{ md: '100% !important' }}
          className={clsx('address', 'details', 'AkRegisterForm-body')}
        >
          <Box flex={3}>
            <Downshift
              onChange={downshiftOnChangeUser}
              itemToString={item => (item ? item.full_name : '')}
            >
              {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                <div 
                  className={clsx(
                    classes.container,
                    { 'stretch--user': isOpen }
                  )}
                >
                  {AkbInput({
                    fullWidth: true,
                    classes,
                    name: 'search-registered-user',
                    label: 'Search by user\'s names',
                    InputProps: getInputProps({
                      onChange: inputOnChange,
                    }),
                  })}
                  {isOpen && (
                    <Paper square className={clsx('select-location')}>
                      {getSuggestions(state.existingUsers, inputValue).map(
                        (item, index) => {
                          const {
                            full_name,
                            username,
                            villagename,
                          } = item;
                          const isHighlighted = highlightedIndex === index;
                          const isSelected =
                            String(selectedItem || '').indexOf(full_name) >
                            -1;

                          return (
                            <MenuItem
                              {...getItemProps({ key: index, index, item })}
                              selected={isHighlighted}
                              component="div"
                              style={{
                                fontWeight: isSelected ? 500 : 400,
                              }}
                            >
                              {full_name}<br />
                              {username}&nbsp;({villagename})
                            </MenuItem>
                          );
                        }
                      )}
                    </Paper>
                  )}
                </div>
              )}
            </Downshift>
          </Box>
          <Box flex={1}></Box>
        </Box>
        <Box display={{ xs: 'none' }} width={{ md: '100% !important' }}>
          <SectionTitle
            label='*These fields are only filled after you "Search a registered user"'
            className={clsx(
              { 'hidden': !id },
              classes.details
            )}
          />
        </Box>
        <Box
          display={{ md: 'flex' }}
          width={{ md: '100% !important' }}
          className={clsx('address')}
        >
          <Box flex={3}>
            <TextInput
              id="userId"
              value={id}
              name="user_id"
              className={clsx(classes.input, 'hidden')}
            />
            <AkbTextField
              disabled={true}
              value={full_name}
              name="full_name"
              label="Names"
              className={clsx(
                { 'hidden': !id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={username}
              name="username"
              label="Phone contact (main)"
              className={clsx(
                { 'hidden': !id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={status}
              name="is_farmer"
              label="Registered as"
              className={clsx(
                { 'hidden': !id },
                classes.input
              )}
            />
            <AkbTextField
              disabled={true}
              value={villagename}
              name="villagename"
              label="Village"
              className={clsx(
                { 'hidden': !id },
                classes.input
              )}
            />
            {!!id &&
              <Box display={{ xs: 'block' }}>
                <RemoveButton
                  icon={<CancelNow />}
                  label="Remove user"
                  handleClick={handleRemoveUser}
                />
              </Box>
            }            
          </Box>
          <Box flex={1}></Box>
        </Box>
      </>
    )
  );
};

const getSuggestions = (existingUsers = [], inputValue) => {
  let count = 0;

  return existingUsers.filter(({ full_name }) => {
    const keep =
      (!inputValue ||
        String(full_name)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};
