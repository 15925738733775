import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Edit } from 'react-admin';

import { CooperativesMultiForm } from './form';
import { CooperativesTitle } from './CooperativesTitle';

const CooperativesBranchesEdit = ({ permissions, ...props }) => {
  const { 
    basePath, 
    hasCreate, 
    hasEdit, 
    hasList, 
    hasShow,
    match, 
    ...rest 
  } = props;

  let superuser = permissions && permissions.includes('superuser'),
      provider = permissions && permissions.includes('provider');

  const EditComponent = other => {
    const history = useHistory();
    const { basePath, hasCreate } = other;

    const handleClose = React.useCallback(() => {
      history.push(basePath);
    }, [history, basePath]);

    return (
      <Edit 
        {...other}
        title={<CooperativesTitle />}
      >
        <CooperativesMultiForm 
          onCancel={handleClose}
          redirect={hasCreate ? 'list' : basePath}
          permissions={permissions}
          {...rest} 
        />
      </Edit>
    );
  };

  return permissions !== undefined ? (
    (superuser || provider) ? <EditComponent {...props} /> : <Redirect to="/" />
  ) : null;
};

export default CooperativesBranchesEdit;
