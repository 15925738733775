import GroupWorkIcon from '@material-ui/icons/GroupWork';

import CooperativesBranchesList from './CooperativesBranchesList';
import CooperativesBranchesEdit from './CooperativesBranchesEdit';
import CooperativesBranchesCreate from './CooperativesBranchesCreate';

export default {
  create: CooperativesBranchesCreate,
  list: CooperativesBranchesList,
  edit: CooperativesBranchesEdit,
  icon: GroupWorkIcon,
};
