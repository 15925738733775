import UserIcon from '@material-ui/icons/People';

import UserList from './UserList';
import SaccoUserShow from './UserShow';

export default {
  list: UserList,
  create: UserList,
  show: SaccoUserShow,
  icon: UserIcon,
};