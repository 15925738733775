import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  SaveButton,
  SelectInput,
  ReferenceInput,
  Toolbar,
  required,
} from 'react-admin';

import { CancelButton } from '../../layout';
import { SectionTitle } from '../utils';
import {
  serviceType,
  serviceTypeChoices,
  Precise,
  weightUnits,
} from '../validation';
import { serviceCreateStyles } from '../stylesheets';

const ServiceCreateToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

const ServiceCreate = ({ onCancel, ...props }) => {
  const classes = serviceCreateStyles();

  return (
    <Create {...props} title=" " successMessage="Service has been created">
      <SimpleForm toolbar={<ServiceCreateToolbar onCancel={onCancel} />}>
        <TextInput
          label="Service Name"
          source="service_name"
          validate={required()}
        />
        <SelectInput
          label="Type of Service"
          source="service_type"
          choices={serviceTypeChoices}
          className={classes.input}
          validate={serviceType}
        />
        <ReferenceInput
          label="Subcategory"
          source="subcategoryid"
          reference="subcategories"
        >
          <SelectInput optionText="sub_category_name" />
        </ReferenceInput>
        <SectionTitle label="resources.services.fieldGroups.measure" />
        <TextInput
          label="Quantity"
          source="unit"
          className={classes.input}
          validate={Precise}
        />
        <SelectInput
          label="Unit"
          source="unit_of_measure"
          choices={weightUnits}
          className={classes.input}
        />
      </SimpleForm>
    </Create>
  );
};

export default ServiceCreate;
