import React from 'react';
import clsx from 'clsx';
import { SelectInput } from 'react-admin';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProviderServiceTemplateModal from './ProviderServiceTemplateModal';
import ProviderAddServiceModal from './ProviderAddServiceModal';
import { ProviderServicesList } from '../ProviderServicesList';

import { SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

import { apiFullCall } from '../../apiHelper';

const maxSmWidth = 599.98; // sm (down)

interface Style {
  details?: string;
  heading?: string;
  input?: string;
  photo?: string;
  root?: string;
  title?: string;
}

interface SelectObject {
  id?: string;
  name?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      flexBasis: '33.33%',
      fontWeight: 'normal',
      textAlign: 'left',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      [`@media (max-width: ${maxSmWidth}px)`]: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '100%',
        fontWeight: 500,
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);


export const ProviderFormServices = props => {
  const classes: Style = userEditStyles();
  const classesBase: Style = useStyles();

  const { record, isSmall } = props;

  const abortController = new AbortController();
  const token = sessionStorage.getItem('token');

  // Handle template modal state
  const [openTemplateModal, setOpenTemplateModal] = React.useState<boolean>(false);

  // handle template modal open
  const handleTempModalOpen = () => setOpenTemplateModal(!openTemplateModal);

  // handle template modal close
  const handleTempModalClose = () => setOpenTemplateModal(!openTemplateModal);

  // handle add-services modal state
  const [openAddServModal, setOpenAddServModal] = React.useState<boolean>(false);

  // handle add-service modal open
  const handleAddServiceModalOpen = () => setOpenAddServModal(!openAddServModal);

  // handle add-service modal close 
  const handleAddServiceModalClose = () => setOpenAddServModal(!openAddServModal);

  // Handle add-services button state
  const [showAddButton, setShowAddButton] = React.useState<boolean>(false);

  // handle hiding last filter subcategory
  const [showOtherSubcategory, setshowOtherSubcategory] = React.useState<boolean>(false);


  let isLoaded = React.useRef<boolean>(true);

  // related to selecting a "category"
  const [categoryList, setCategoryList] = React.useState<Array<SelectObject>>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<object>({ categoryid: '' });

  // related to selecting main "sub-category"
  const [mainSubCategoryList, setMainSubCategoryList] = React.useState<Array<SelectObject>>([]);
  const [selectedMainSubCategory, setSelectedMainSubCategory] = React.useState<object>({ subcategoryid_main: '' });

  // related to selecting other "sub-category"
  const [otherSubCategoryList, setOtherSubCategoryList] = React.useState<Array<SelectObject>>([]);
  const [selectedOtherSubCategory, setSelectedOtherSubCategory] = React.useState<object>({ subcategoryid_other: '' });

  const [filterSubCategory, setFilteredSubCategory] = React.useState<object>({ subcategoryid: '' });

  const [unassignedServices, setUnassignedServices] = React.useState<Array<SelectObject>>([]);

  // handle pagination bug
  React.useEffect(() => {

    /**
     * Pagination bug:
     * 
     * A click event on the list pagaination overrides entire form state (resets form)
     * This 'hasServices' key is set at login as 'false'
     * And is only set to 'true' when this component is mounted
     * 
     */
    if (isLoaded) {
      sessionStorage.removeItem('hasServices');
      sessionStorage.setItem('hasServices', 'true');
    }

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
      sessionStorage.removeItem('hasServices');
      sessionStorage.setItem('hasServices', 'false');
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };

  }, [isLoaded]); // eslint-disable-line

  // support filtering
  React.useEffect(() => {

    // fetch categories
    if (isLoaded && record['id']) {

      apiFullCall(
        '',
        token,
        'get',
        `categories/`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, category_name }) => ({ id: id, name: category_name })
              );

              // populate "selection" for category
              setCategoryList(data);
            }
          }
        }
      }).catch(
        error => console.error('Error while fetching "categories":', error)
      );

    };

    // fetch sub-categories (main)
    if (selectedCategory['categoryid']) {

      apiFullCall(
        '',
        token,
        'get',
        `subcategories/?categoryid=${selectedCategory['categoryid']}`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, sub_category_name }) => ({ id: id, name: sub_category_name })
              );

              // populate "selection" for main sub-category
              setMainSubCategoryList(data);
            }
          }
        }
      }).catch(
        error => console.error('Error while fetching "sub-categories":', error)
      );
    };

    // fetch sub-categories (other)
    if (selectedMainSubCategory['subcategoryid_main']) {

      apiFullCall(
        '',
        token,
        'get',
        `subcategories/?parent_id=${selectedMainSubCategory['subcategoryid_main']}`
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, sub_category_name }) => ({ id: id, name: sub_category_name })
              );

              // populate "selection" for other sub-category
              setOtherSubCategoryList(data);
              setshowOtherSubcategory(true);

            } else {
              setOtherSubCategoryList(mainSubCategoryList);
              setshowOtherSubcategory(false);
              setSelectedOtherSubCategory({ subcategoryid_other: selectedMainSubCategory['subcategoryid_main'] });
            }
          }
        }
      }).catch(
        error => console.error('Error while fetching "sub-categories":', error)
      );

    };

    // show the "add service" button
    if (selectedOtherSubCategory['subcategoryid_other']) {
      setShowAddButton(true);
      setFilteredSubCategory({ subcategoryid: selectedOtherSubCategory['subcategoryid_other'] })

      if (typeof (isSmall) === 'boolean' && isSmall) {
        handleOtherChange();
      };

    };

    // fetch services (unassigned)
    if (selectedOtherSubCategory['subcategoryid_other']) {

      let id = record
        && record['provider_location']
        && record['provider_location'][0]
        && record['provider_location'][0]['id'];

      if (id) {
        apiFullCall(
          '',
          token,
          'get',
          `providerservice/?providerlocationid=${id}&unasigned=yes&subcategoryid=${selectedOtherSubCategory['subcategoryid_other']}`
        ).then(res => {

          if (res) {
            const { status, body } = res;

            if (status === 200 || status === 201) {

              const { results } = body;

              if (results.length > 0) {

                let data = results.map(
                  ({ id, service_name, specifications }) => {

                    if (specifications.length > 0) {
                      return { id: id, name: service_name, spec: specifications }
                    };

                    return null;
                  }
                );

                // populate the services list (unassigned)
                setUnassignedServices(data);
              };

            };

          };

        }).catch(
          error => console.error('Error while fetching "services":', error)
        );
      }
    };

    // clean up API call, on unmount
    return function cleanup() {
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };

  }, [isLoaded, record, selectedCategory, selectedMainSubCategory, selectedOtherSubCategory]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleCategoryChange = e => {
    setSelectedCategory({ [e.target.name]: e.target.value });
  };

  const handleMainSubCategoryChange = e => {
    setSelectedMainSubCategory({ [e.target.name]: e.target.value });
  };

  const handleOtherSubCategoryChange = e => {
    setSelectedOtherSubCategory({ [e.target.name]: e.target.value });
  }

  /* related to accordions (mobile view) */

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) =>
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      handleOtherChange();
    };

  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleOtherChange = () => setCollapsed(!collapsed);

  return (
    <Box
      display={{ md: 'flex' }}
      flexDirection={{ xs: 'column' }} // create vs edit mode
      className={clsx(classesBase.root, 'AkRegisterForm-body')}
    >
      <ProviderAddServiceModal
        {...props}
        openDialog={openAddServModal}
        handleClose={handleAddServiceModalClose}
        listServices={unassignedServices}
        isSmall={isSmall}
        classes={classes}
      />
      <ProviderServiceTemplateModal
        {...props}
        openDialog={openTemplateModal}
        providerid={record['id']}
        handleClose={handleTempModalClose}
      />
      {typeof (isSmall) === 'boolean' && isSmall ? (
        // responsiveness
        <Box
          display={{ xs: 'flex' }}
          flexDirection={{ xs: 'column' }}
        >
          <Box className={clsx('add-service')}>
            <Box>
              <Typography gutterBottom className={classesBase.title}>
                Either
              </Typography>
            </Box>
            <ExpansionPanel
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1gm-content"
                id="panel1gm-header"
              >
                <Typography className={classesBase.heading}>
                  Add a service / Filter list
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box flex={1}>
                  <SelectInput
                    label="Category"
                    source="categoryid"
                    optionText="name"
                    choices={categoryList}
                    onChange={handleCategoryChange}
                    className={classes.input}
                  />
                  {!selectedCategory['categoryid'] ? (
                    <SelectInput
                      allowEmpty
                      label="Subcategory (main)"
                      source="subcategories"
                      optionText="name"
                      choices={mainSubCategoryList}
                      helperText="First, select a 'category'"
                    />
                  ) : (
                      <SelectInput
                        label="Subcategory (main)"
                        source="subcategoryid_main"
                        optionText="name"
                        choices={mainSubCategoryList}
                        onChange={handleMainSubCategoryChange}
                        className={classes.input}
                      />
                    )}
                  {showOtherSubcategory &&
                    (!selectedMainSubCategory['subcategoryid_main'] ? (
                      <SelectInput
                        allowEmpty
                        label="Subcategory (other)"
                        source="subcategories"
                        optionText="name"
                        choices={otherSubCategoryList}
                        helperText="First, select main 'subcategory'"
                      />
                    ) : (
                        <SelectInput
                          label="Subcategory (other)"
                          source="subcategoryid_other"
                          optionText="name"
                          onChange={handleOtherSubCategoryChange}
                          choices={otherSubCategoryList}
                          className={classes.input}
                        />
                      )
                    )
                  }
                  {showAddButton &&
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ marginRight: '20px' }}
                      onClick={handleAddServiceModalOpen}
                    >
                      Add Service
                    </Button>
                  }
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <Box mt={{ xs: '10px' }} mb={{ xs: '10px' }}>
              <Typography className={classesBase.title}>
                Or
              </Typography>
            </Box>
            <ExpansionPanel
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2gm-content"
                id="panel2gm-header"
              >
                <Typography className={classesBase.heading}>
                  Download services sheet
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box flex={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '20px' }}
                    onClick={handleTempModalOpen}
                  >
                    Services Template
                  </Button>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Box>
          <Box display={{ xs: 'flex' }} className={clsx('listed-services')}>
            <Box flex={1} width={{ xs: '100%' }}>
              <Box>
                <Typography gutterBottom className={classesBase.title}>
                  And
                </Typography>
              </Box>
              <ExpansionPanel
                expanded={collapsed === false}
                onChange={handleOtherChange}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3gm-content"
                  id="panel3gm-header"
                >
                  <Typography className={classesBase.heading}>
                    Listed services
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box
                    display={{ sm: 'block' }}
                    width={{ xs: '100% !important' }}
                    mt={{ md: '30px' }}
                    className={clsx('form--subsection')}
                  >
                    <ProviderServicesList
                      {...props}
                      subcategoryid={filterSubCategory["subcategoryid"]}
                    />
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
          </Box>
        </Box>
      ) : (
          <>
            <Box
              display={{ md: 'flex' }}
              width={{ md: '100% !important' }}
            >
              <Box flex={3} className={clsx('provider-services')}>
                <SectionTitle label="Listed services" />
              </Box>
              <Box flex={1}></Box>
            </Box>
            <Box
              display={{ md: 'flex' }}
              width={{ md: '100% !important' }}
            >
              <Box flex={4} className={clsx('provider-services')}>
                <ProviderServicesList
                  {...props}
                  subcategoryid={filterSubCategory["subcategoryid"]}
                />
              </Box>
              <Box flex={1}>
                <Box className={clsx('select-services')}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '20px' }}
                    onClick={handleTempModalOpen}
                  >
                    Services Template
                </Button>
                  <SelectInput
                    label="Category"
                    source="categoryid"
                    optionText="name"
                    choices={categoryList}
                    onChange={handleCategoryChange}
                    className={classes.input}
                  />
                  {!selectedCategory['categoryid'] ? (
                    <SelectInput
                      allowEmpty
                      label="Subcategory (main)"
                      source="subcategories"
                      optionText="name"
                      choices={mainSubCategoryList}
                      helperText="First, select a 'category'"
                    />
                  ) : (
                      <SelectInput
                        label="Subcategory (main)"
                        source="subcategoryid_main"
                        optionText="name"
                        choices={mainSubCategoryList}
                        onChange={handleMainSubCategoryChange}
                        className={classes.input}
                      />
                    )}
                  {showOtherSubcategory &&
                    (!selectedMainSubCategory['subcategoryid_main'] ? (
                      <SelectInput
                        allowEmpty
                        label="Subcategory (other)"
                        source="subcategories"
                        optionText="name"
                        choices={otherSubCategoryList}
                        helperText="First, select main 'subcategory'"
                      />
                    ) : (
                        <SelectInput
                          label="Subcategory (other)"
                          source="subcategoryid_other"
                          optionText="name"
                          onChange={handleOtherSubCategoryChange}
                          choices={otherSubCategoryList}
                          className={classes.input}
                        />
                      )
                    )
                  }
                  {showAddButton &&
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ marginRight: '20px' }}
                      onClick={handleAddServiceModalOpen}
                    >
                      Add Service
                  </Button>
                  }
                </Box>
              </Box>
            </Box>
          </>
        )}
    </Box>
  );
};
