import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

import { SectionTitle } from '../utils';
import { Precise } from '../validation';
import { serviceEditStyles } from '../stylesheets';

const ProviderServiceEdit = props => {
  const classes = serviceEditStyles();

  return (
    <Edit {...props} title=" " successMessage="Service has been updated">
      <SimpleForm>
        <ReferenceInput label="Service" source="serviceid" reference="services">
          <SelectInput optionText="service_name" />
        </ReferenceInput>
        <TextInput
          label="Cost"
          source="service_cost"
          className={classes.input}
          validate={Precise}
        />
        <SectionTitle label="resources.providerservice.fieldGroups.terms" />
        <TextInput
          label="Delivery time"
          source="turn_around_time"
          className={classes.input}
        />
        <TextInput
          label="Deposit (%)"
          source="down_payment_percent"
          className={classes.input}
          validate={Precise}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProviderServiceEdit;
