import React, { FC } from 'react';
import clsx from 'clsx';
import pure from 'recompose/pure';

import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInHours from 'date-fns/differenceInHours';
import isBefore from 'date-fns/isBefore'

import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FieldProps } from '../../types';

const useStyles = makeStyles({
	withInTime: {
		color: '#3f51b5',
	},
	outOfTime: {
		color: '#df1f26',
	},
});

// requested date
const RequestDateField: FC<FieldProps> = ({ record }) => {
	const classes = useStyles();
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	
	let dateOfRequested = record &&
		record.request_date &&
			format(new Date(record['request_date']), 'dd/MMM/yyyy p');			
	
	let daysFromRequestDate = record &&
		record.request_date &&
			formatDistance(new Date(record['request_date']), new Date(), { addSuffix: true } );

	// returns "hours"
	let timeSinceRequest = record &&
		record.request_date &&
			differenceInHours(new Date(), new Date(record['request_date']));

	if (!record) {

		return null;

	} else if (	
		record &&	record['request_status'] &&	record['request_status'] === "delivered"
	) {

		// if "delivered", show period between
		// "request date" and "planned deliver date"
		return (
			<span>
				{dateOfRequested}
			</span>
		);

	} else {

		return (
			<span>
				{isXSmall 
					? <>{dateOfRequested} &nbsp;</>
					: <>{dateOfRequested}<br/></>
				}
				<span className={clsx({
					[classes.withInTime]: timeSinceRequest < 24,
					[classes.outOfTime]: timeSinceRequest >= 24,
				})}>
					&#40;{daysFromRequestDate}&#41;
				</span>
			</span>
		);

	};
};

export const PureRequestDateField = pure(RequestDateField);

PureRequestDateField.defaultProps = {
	source: 'request_date',
	label: 'Requested On'
};

// scheduled date
const DeliveryDateField: FC<FieldProps> = ({ record }) => {
	const classes = useStyles();
	
	// return formated "planned delivery date"
	let dateOfDelivery = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				format(new Date(record['completion']['provider_visit_date']), 'dd/MMM/yyyy');			
	
	// return "period" between today and planned delivery date
	let daysToDeliveryDate = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				formatDistance(new Date(record['completion']['provider_visit_date']), new Date(), { addSuffix: true } );
	
	// returns "boolean" to support color code
	let timeToDeliver = record &&
		record.completion &&
			record.completion.provider_visit_date &&
				isBefore(new Date(), new Date(record['completion']['provider_visit_date']));

	if (!record) {

		return null;

	} else if (	
		record &&	record['request_status'] &&	record['request_status'] === "delivered"
	) {

		// if "delivered", show period between
		// "request date" and "planned deliver date"
		return (
			<span>
				{dateOfDelivery}
			</span>
		);

	} else {

		// Expected, if "accepted" to show period between
		// "today" and "planned delivery date"
		return (
			<span>
				{dateOfDelivery}<br/>
				<span className={clsx({
					[classes.withInTime]: timeToDeliver,
					[classes.outOfTime]: !timeToDeliver,
				})}>
					&#40;{daysToDeliveryDate}&#41;
				</span>
			</span>
		);

	};
};

export const PureDeliveryDateField = pure(DeliveryDateField);

PureDeliveryDateField.defaultProps = {
	source: 'completeion.provider_visit_date',
	label: 'Scheduled'
};

// confirmed date
const ConfirmedDateField: FC<FieldProps> = ({ record }) => {

	// return formated "confirmed delivery date"
	let confirmedDelivery = record &&
		record.completion &&
			record.completion.completion_date &&
				format(new Date(record['completion']['completion_date']), 'dd/MMM/yyyy p');

	if (!record) {

		return null;

	} else {

		// Expected, if "accepted" to show period between
		// "today" and "planned delivery date"
		return (
			<span>
				{confirmedDelivery}
			</span>
		);

	};
};

export const PureConfirmedDateField = pure(ConfirmedDateField);

PureConfirmedDateField.defaultProps = {
	source: 'completeion.completion_date',
	label: 'Delivered on'
};

