import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  DateInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  Email,
  Needed,
  TIN,
  Text,
  phoneNumber,
  providerType,
} from '../../validation';

import { AkbTextField, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

interface Style {
  details?: string;
  input?: string;
  check?: string;
  photo?: string;
  title?: string;
}

export const ProviderFormBasic = (props: any) => {
  const classes: Style = userEditStyles();

  const AddBusinessName = [ ...Text, ...Needed ];
  const AddPrimaryContact = [ ...phoneNumber, ...Needed ];

  const {
    isLoaded,
    isRegistered,
    permissions,
    record,
    spCategories,
    listClasses,
    listPrograms,
    cleanCategoryList,
  } = props;

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let superuser = permissions && permissions.includes('superuser'),
      provider = permissions && permissions.includes('provider');


  const CategoryTitle = () => {
    if (record && record.provider_class && record.provider_class.length > 1) {
      // allow styling based on no. of classes
      return (
        <p className={clsx('title')}>
          <span className={clsx('intro')}>Provider categories</span>
        </p>
      );

    } else {          
      // allow styling based on no. of classes
      return ( 
        <p className={clsx('title')}>
          <span className={clsx('intro')}>Provider category</span>
        </p>
      );
    }
  };


  return isSmall ? (
    <>
      <SectionTitle label="Basic" className={clsx('centered')} />
      <Box 
        display={{ md: 'flex' }} 
        width={{ md: '100% !important' }} 
        className={clsx('AkRegisterForm-body')}
      >
        <Box flex={2}>
          <TextInput
            source="business_name"
            className={classes.input}
            validate={AddBusinessName}
          />         
          <TextInput
            source="shortname"
            className={classes.input}
            validate={Text}
          />
          <TextInput
            label="Main phone no."
            source="primary_contact"
            className={classes.input}
            validate={AddPrimaryContact}
          />
          <TextInput
            label="Other phone no."
            source="secondary_contact"
            className={classes.input}
            validate={phoneNumber}
          />
          <TextInput
            label="Main email"
            type="email"
            source="primary_email"
            className={classes.input}
            validate={Email}
          />
          <TextInput
            label="Other email"
            type="email"
            source="secondary_email"
            className={classes.input}
            validate={Email}
          />
          {!isRegistered && 
            <SelectArrayInput
              label="Service Provider category"
              source="classid" 
              optionText="name"
              choices={listClasses}
              className={clsx('multiple-select-small')}
              validate={Needed}
            />
          }
          {isRegistered ? (
            cleanCategoryList.length > 0 ? (
              <SelectArrayInput
                label="Add provider category"
                source="add_category" // giving this the same "source" as above causes a polygot (translation error)
                optionText="name"
                choices={cleanCategoryList}
                className={clsx('multiple-select-small')}
              />
            ) : (
              <AkbTextField
                disabled={true}
                value={isLoaded && isLoaded.current ? "fetching..." : "No other categories"}
                label="Add to Category"
                className={clsx('multiple-select-small select-disabled')}
              />
            )
          ) : null}
        </Box> 
        <Box flex={1} 
          display={{ md: 'flex' }}
          flexDirection={{ xs: 'column' }}
          mb={{ xs: '30px' }}
          className={clsx('AkRegisterForm-body')}
        >
          {isRegistered &&
            <Box>
              <SectionTitle label="" show={<CategoryTitle />} /><hr/>
              <Typography variant="body1" component="h3" gutterBottom>
                {record && 
                  record.provider_class && 
                    record.provider_class.length > 0
                      ? (
                        <div>
                          {/* only map if list is not "empty", else return "none" */}
                          {record.provider_class.map(
                            ({class_id}, i) => <p key={i}>{spCategories[class_id - 1]}</p>                        
                          )}<hr/><br/>
                        </div>
                      ) : "None"
                }
              </Typography>
              <SectionTitle label="Program(s)"/><hr/>
              <Typography variant="body1" component="h3" gutterBottom>
                {record && 
                  record.provider_program && 
                    record.provider_program.length > 0
                      // only map if list is not "empty", else return "none"
                      ? record.provider_program.map(
                        ({ program_name, short_name}, i) => 
                          <div key={i}>
                            <span>{program_name} ({short_name})</span><hr/>
                          </div>
                        ) 
                      : "None"
                }
              </Typography>
              {superuser &&
                // only accessible if "superuser", 
                // thus user can't add themselves to a program
                <SelectInput
                  label="Add to program"
                  source="programid" 
                  optionText="name"
                  choices={listPrograms}
                  className={clsx('add-to-program')}
                />
              }
            </Box>                 
          }
        </Box>     
      </Box>        
      <SectionTitle label="Operations" classes={classes.title} className={clsx('centered')} />                 
      <Box 
        display={{ xs: 'block' }} 
        flex={3} 
        mr={{ md: 0, lg: '1em' }}
        mb={{ xs: '30px' }}
        className={clsx('AkRegisterForm-body')}
      > 
        <RadioButtonGroupInput
          label="Registration type"
          source="provider_type"
          choices={providerType}
          initialValue="individual"
          helperText='Please note that registration requirements differ for both.'
        />
      </Box>
      <Box 
        display={{ xs: 'flex' }} 
        width={{ xs: '102% !important' }}
        className={clsx('AkRegisterForm-body')}
      >
        <Box
          flex={2} 
          mr={{ md: 0, lg: '1em' }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.provider_type ? (
                formData.provider_type === 'company' ? (
                  <>
                    <SectionTitle label="Company details" className={classes.details} />
                    <TextInput
                      label="Registration Number"
                      source="reg_number"
                      className={classes.input}
                    />
                    <DateInput 
                      label="Registered Date" 
                      source="reg_date" 
                      className={classes.input} 
                    />
                    <TextInput
                      label="TIN"
                      source="tin"
                      className={classes.input}
                      helperText="Tax Identification Number (URA)"
                      validate={TIN}
                    />
                    <TextInput
                      label="Trading License"
                      source="license_number"
                      className={classes.input}
                    />
                  </>
                ) : formData.provider_type === 'individual' ? (
                  <>
                    <SectionTitle label="Individual details" className={classes.details} />
                    <TextInput
                      label="Trading License"
                      source="license_number"
                      className={classes.input}
                    />
                    <TextInput
                      label="Individual TIN"
                      source="tin"
                      className={classes.input}
                      helperText="Tax Identification Number (URA)"
                      validate={TIN}
                    />
                  </>
                ) : null
              ) : null
            }
          </FormDataConsumer>          
        </Box>        
        <Box flex={1} display={{ xs: 'none' }}></Box>
      </Box>
    </>
  ) : (
    <Box display={{ md: 'flex' }}>
      <Box 
        flex={2}
        width={{ md: '100% !important' }} 
        className={clsx('AkRegisterForm-body')}
      >
        <SectionTitle label="Basic" />
        <Box flex={1}>
          <TextInput
            source="business_name"
            className={classes.input}
            validate={AddBusinessName}
          />         
          <TextInput
            source="shortname"
            className={classes.input}
            validate={Text}
          />
          <TextInput
            label="Main phone no."
            source="primary_contact"
            className={classes.input}
            validate={AddPrimaryContact}
          />
          <TextInput
            label="Other phone no."
            source="secondary_contact"
            className={classes.input}
            validate={phoneNumber}
          />
          <TextInput
            label="Main email"
            type="email"
            source="primary_email"
            className={classes.input}
            validate={Email}
          />
          <TextInput
            label="Other email"
            type="email"
            source="secondary_email"
            className={classes.input}
            validate={Email}
          />
          {!isRegistered &&
            <SelectArrayInput
              label="Service provider category"
              source="provider_class" 
              optionText="name"
              choices={listClasses}
              className={clsx('multiple-select-large')}
              validate={Needed}
            />
          }
          {isRegistered ? (
            cleanCategoryList.length > 0 ? (
              <SelectArrayInput
                label="Add provider category"
                source="add_category" // giving this the same "source" as above causes a polygot (translation error)
                optionText="name"
                choices={cleanCategoryList}
                className={clsx('multiple-select-large')}
              />
            ) : (
              <AkbTextField
                disabled={true}
                value={isLoaded && isLoaded.current ? "fetching..." : "No other categories"}
                label="Add to Category"
                className={clsx('multiple-select-large select-disabled')}
              />
            )
          ) : null}
        </Box> 
        <SectionTitle label="Operations" classes={clsx(classes.title, 'edit')} />                 
        <Box 
          display={{ xs: 'block' }} 
          flex={1} 
          mr={{ md: 0, lg: '1em' }}
          className={clsx('AkRegisterForm-body')}
        > 
          <RadioButtonGroupInput
            label="Registration type"
            source="provider_type"
            choices={providerType}
            initialValue="individual"
            helperText='Please note that registration requirements differ for both.'
          />
        </Box>
        <Box 
          display={{ xs: 'flex' }} 
          width={{ xs: '102% !important' }}
          className={clsx('AkRegisterForm-body')}
        >
          <Box
            flex={2} 
            mr={{ md: 0, lg: '1em' }}
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.provider_type ? (
                  formData.provider_type === 'company' ? (
                    <>
                      <SectionTitle label="Company details" className={classes.details} />
                      <TextInput
                        label="Registration Number"
                        source="reg_number"
                        className={classes.input}
                      />
                      <DateInput 
                        label="Registered Date" 
                        source="reg_date" 
                        className={classes.input} 
                      />
                      <TextInput
                        label="TIN"
                        source="tin"
                        className={classes.input}
                        helperText="Tax Identification Number (URA)"
                        validate={TIN}
                      />
                      <TextInput
                        label="Trading License"
                        source="license_number"
                        className={classes.input}
                      />
                    </>
                  ) : formData.provider_type === 'individual' ? (
                    <>
                      <SectionTitle label="Individual details" className={classes.details} />
                      <TextInput
                        label="Trading License"
                        source="license_number"
                        className={classes.input}
                      />
                      <TextInput
                        label="Individual TIN"
                        source="tin"
                        className={classes.input}
                        helperText="Tax Identification Number (URA)"
                        validate={TIN}
                      />
                    </>
                  ) : null
                ) : null
              }
            </FormDataConsumer>          
          </Box>        
          <Box flex={1} display={{ xs: 'none' }}></Box>
        </Box>
      </Box>
      <Box flex={1} 
        display={{ md: 'flex' }}
        flexDirection={{ xs: 'column' }}
        className={clsx('AkRegisterForm-body')}
      >
        {isRegistered &&
          <Box>
            <SectionTitle label="" show={<CategoryTitle />} /><hr/>
            <Typography variant="body1" component="h3" gutterBottom>
              {record && 
                record.provider_class && 
                  record.provider_class.length > 0
                    ? (
                      <ul>
                        {/* only map if list is not "empty", else return "none" */}
                        {record.provider_class.map(
                          ({class_id}, i) => <li key={i}>{spCategories[class_id - 1]}</li>                        
                        )}
                      </ul>
                    ) : "None"
              }
            </Typography>
            <SectionTitle label="Program(s)"/><hr/>
            <Typography variant="body1" component="h3" gutterBottom>
              {record && 
                record.provider_program && 
                  record.provider_program.length > 0                    
                    ? (
                      <ul>
                        {/* only map if list is not "empty", else return "none" */}
                        {record.provider_program.map(
                          ({short_name}, i) => <li key={i}>{short_name}</li>                        
                        )}
                      </ul>
                    ) : "None"
              }
            </Typography>
            {(superuser || provider) &&
              // only accessible if "superuser", 
              // thus user can't add themselves to a program
              <SelectInput
                label="Add to program"
                source="programid" 
                optionText="name"
                choices={listPrograms}
                className={clsx('add-to-program')}
              />
            }
          </Box>                 
        }
      </Box>             
    </Box>    
  );
};
