import React from 'react';
import { Link } from 'react-router-dom';
import { useRefresh } from 'react-admin';
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

import apiCall from '../apiHelper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    fontWeight: 398,
    minHeight: '34px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  indexedz: {
    zIndex: 99999999,
    paddingRight: '60px',
  },
}));

export const FullNameField = ({ record = {}, source }) => (
  <div>
    {record['first_name']} {record['last_name']} {record['middle_name']}
  </div>
);

export const CustomBooleanField = ({ record = {}, source }) => (
  <div>{record[source] ? 'Yes' : 'No'}</div>
);

export const GenderField = ({ record = {}, source }) => (
  <div>
    {record[source] === 'M'
      ? 'Male'
      : record[source] === 'F'
      ? 'Female'
      : 'Other'}
  </div>
);

export default function UserAcionList({ record = {}, source, basePath }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [terminate, setTerminate] = React.useState(false);
  const [activate, setActivate] = React.useState(false);
  const anchorRef = React.useRef(null);
  const refresh = useRefresh();

  // handle activating accounts dialog
  const handleActivate = event => {
    handleClose(event);
    setActivate(true);
  };
  const handleActivateDialogClose = () => setActivate(false);
  const handleActivateConfirm = () => {
    apiCall(
      { is_active: true },
      sessionStorage.getItem('token'),
      'patch',
      'cooperativebranchusers/' + record['id'] + '/'
    )
      .then(response => {
        if (response) {
          setActivate(false);
          refresh();
        }
      })
      .catch(error => {
        console.error('Error while confirming:', error);
      });
  };

  // handle terminating accounts
  const handleClick = event => {
    handleClose(event);
    setTerminate(true);
  };
  const handleDialogClose = () => setTerminate(false);
  const handleConfirm = () => {
    apiCall(
      { is_active: false },
      sessionStorage.getItem('token'),
      'patch',
      'cooperativebranchusers/' + record['id'] + '/'
    )
      .then(response => {
        if (response) {
          setTerminate(false);
          refresh();
        }
      })
      .catch(error => {
        console.error('Error while confirming:', error);
      });
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <>
        <div>
          <Button
            size="small"
            ref={anchorRef}
            color="primary"
            variant="outlined"
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            Action <ArrowDropDownOutlinedIcon />
          </Button>
          <Popper
            className={classes.indexedz}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        component={Link}
                        to={{
                          pathname: basePath + '/' + record['id'] + '/show',
                        }}
                        className={classes.menuItem}
                      >
                        view Detail
                      </MenuItem>
                      {record['is_active'] && record['approved'] ? (
                        <>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClick}
                          >
                            terminate
                          </MenuItem>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClose}
                          >
                            assign branch
                          </MenuItem>
                        </>
                      ) : !record['is_active'] && record['approved'] ? (
                        <MenuItem
                          className={classes.menuItem}
                          onClick={handleActivate}
                        >
                          activate
                        </MenuItem>
                      ) : (
                        <>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClick}
                          >
                            Approve
                          </MenuItem>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClose}
                          >
                            Reject
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {/* dialog terminating*/}
        <Dialog
          open={terminate}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Terminate Acount?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure! You want to terminate this User's account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirm}
              size="small"
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
            <Button onClick={handleDialogClose} size="small" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog activating*/}
        <Dialog
          open={activate}
          onClose={handleActivateDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Activate Acount?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure! You want to activate this User's account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleActivateConfirm}
              size="small"
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={handleActivateDialogClose}
              size="small"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
}
