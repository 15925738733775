import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import {
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Typography,
  Select,
  Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import apiCall from '../apiHelper';
import { useStyles } from '../stylesheets/sharedServiceStyle';

const CooperativesCreate = ({ sharedServiceId }) => {
  const history = useHistory();  
  const dataProvider = useDataProvider();
  const [checked, setChecked] = useState(false);
  const [cooperativeName, setCooperativeName] = useState('');
  const [short_name, setShortName] = useState('');
  const [CoopServiceValues, setCoopServiceValues] = useState({
    cooperative: sharedServiceId,
  });
  const [sharedServices, setSharedServices] = useState([
    {
      id: 0,
      coop_service_name: '',
    },
  ]);

  useEffect(() => {
    const handleFetchCoopservices = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperativeservices/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCoopservices(pageNumber, results);
          } else {
            setSharedServices(results);
          }
        })
        .catch(error => console.error('Error while fetching:', error));

    apiCall('', sessionStorage.getItem('token'), 'get', 'cooperativeservices/')
      .then(response => {
        if (response.next !== null) {
          handleFetchCoopservices(2, response.results);
        }
      })
      .catch(error => 
        console.error('Error while fetching:', error)
      );
  }, [dataProvider]);

  const classes = useStyles();
  const handleSubmit = evt => {
    evt.preventDefault();

    const data = {
      cooperative_name: cooperativeName,
      short_name,
      coop_service_id: CoopServiceValues.cooperative,
      is_active: checked,
    };

    apiCall(data, sessionStorage.getItem('token'), 'post', 'cooperatives/')
      .then(response => {
        if (
          response &&
          response.cooperative_name[0] !== 'This field may not be blank.' &&
          response.short_name[0] !== 'This field may not be blank.'
        ) {
          history.push({
            pathname: '/cooperativebranches/create',
            state: {
              serviceId: localStorage.setItem('serviceId', response.id),
              serviceName: localStorage.setItem(
                'serviceName',
                response.cooperative_name
              ),
            },
          });
        }
      })
      .catch(error => 
        console.error('Error while saving:', error)
      );
  };

  const handleChange = event => {
    setCoopServiceValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className={classes.formStyle}>
      <Typography>Create a service</Typography>
      <TextField
        id="filled-name"
        label="Name"
        className={classes.textField}
        value={cooperativeName}
        onChange={e => setCooperativeName(e.target.value)}
        margin="normal"
        variant="filled"
      />
      <br />
      <TextField
        id="filled-short_name"
        label=" Short Name"
        className={classes.textField}
        value={short_name}
        onChange={e => setShortName(e.target.value)}
        margin="normal"
        variant="filled"
      />
      <br />
      <br />
      <form autoComplete="off">
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="demo-simple-select-filled-label">
            Shared Service
          </InputLabel>
          <Select
            disabled
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={CoopServiceValues.cooperative}
            inputProps={{
              name: 'cooperative',
              id: 'age-native-simple',
            }}
            onChange={handleChange}
          >
            {sharedServices &&
              sharedServices.map(sharedService => (
                <MenuItem key={sharedService.id} value={sharedService.id}>
                  {sharedService.coop_service_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </form>
      <br />
      <br />
      Active
      <Checkbox
        checked={checked}
        onChange={e => setChecked(e.target.checked)}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        color="primary"
      />
      <br />
      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>
    </form>
  );
};

export default CooperativesCreate;
