import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Datagrid, DateField, List, TextField } from 'react-admin';

import { useMediaQuery, Divider, Tabs, Tab } from '@material-ui/core';

import ProviderLinkField from './ProviderLinkField';
import NbBranchesField from './NbBranchesField';
import ProviderProgramsField from './ProviderProgramsField';
import { VillageField, DistrictField } from './ProviderLocationFields';
import ProviderDelete from './ProviderDelete';

import { ActionButton, ListActions } from '../../layout';
import { userDatagridStyles } from '../stylesheets';

class TabbedDatagrid extends React.Component {
  /**
   * @Returns Providers
   */
  tabs = [{ id: 'providers', name: 'ALL' }];

  state = {
    providers: [],
    active: [],
    inactive: [],
    new_providers: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props; // eslint-disable-line
    value === 'new'
      ? setFilters({ new_providers: true, status: value })
      : setFilters({ status: value });
  };

  render() {
    const { 
      isXSmall, 
      classes, 
      exporter, 
      hasCreate, 
      filterValues, 
      total, 
      totalPages,
      // handle "delete" modal
      handleData,
      isAdmin,
      onClick,
      ...props 
    } = this.props;

    // Utilize this within component to tap into "state" changes
    const ActionProviderMenu = (basePath = '', record = { id: ''}) => {

      // for agent
      if (!isAdmin) {
        return ([
          {label: 'Update', path: `${basePath}/${record['id']}`},
        ]);
      };

      // for Admin
      return ([
        {label: 'Update', path: `${basePath}/${record['id']}`},
        { 
          label: 'Delete', 
          // receives single "record" to pass back to "ProviderDelete" component
          handleClickEvent: (x) => { 
            return (
              onClick(),
              handleData(x)
            ); 
          }
        },
      ]);
    };

    return (
      <>
        {hasCreate && 
          !!total && 
            total === 0 && 
              null
        }
        {!!total && 
          total !== 0 &&
            <>
              <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={this.handleChange}
              >
                {this.tabs.map(choice => (
                  <Tab key={choice.id} label={choice.name} value={choice.id} />
                ))}
              </Tabs>
              <Divider />
              <div>
                {filterValues.status === 'providers' && (
                  <Datagrid
                    {...props}
                    optimized
                    ids={this.state.providers}
                    classes={{ headerCell: classes.heading }}
                  >
                    {/* <NumberField /> */}
                    <ProviderLinkField />
                    <TextField 
                      label="Contact"
                      source="primary_contact"
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens} 
                    />
                    <NbBranchesField />
                    <DateField 
                      source="date_added" 
                      label="Registered" 
                      showTime               
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens}
                    />
                    <ProviderProgramsField />
                    <VillageField
                      cellClassName={classes.hiddenOnSmallScreens}
                      headerClassName={classes.hiddenOnSmallScreens} 
                    />
                    <DistrictField />
                    <ActionButton MenuOptions={ActionProviderMenu} {...props} />
                  </Datagrid>
                )}
              </div>
            </>
        }
      </>
    );
  }
}

const StyledTabbedDatagrid = props => {
  const classes = userDatagridStyles();
  return <TabbedDatagrid classes={classes} {...props} />;
};

const ProviderList = ({ classes, permissions, ...props }) => {
  const location = useLocation();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  let superuser = permissions && permissions.includes('superuser');
  let agent = permissions && permissions.includes('agent');

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});

  // handles "delete" model open/close
  const handleOpenModal = () => setOpen(!open);

  // handles fetch of single record
  const handleData = singleRecord => setData({ ...data, ...singleRecord });

  return permissions !== undefined ? (
    (superuser || agent) ? (
      <>
        <List
          {...props}
          actions={<ListActions label="Register Provider" />}
          filterDefaultValues={{ status: 'providers' }}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={20}
        >
          <StyledTabbedDatagrid
            handleData={handleData}
            isXSmall={isXSmall}
            isAdmin={superuser}
            onClick={handleOpenModal}
          />
        </List>
        <ProviderDelete        
          isXSmall={isXSmall}
          onClose={handleOpenModal}
          open={open}
          record={data} 
          {...props} 
        />
      </>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

export default ProviderList;
