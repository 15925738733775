import React from 'react';
import { Route } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { Drawer, withStyles } from '@material-ui/core';
import {
  Datagrid,
  Filter,
  List,
  DateField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';

import CostPerServiceField from './CostPerServiceField';
import ProviderServiceCreate from './ProviderServiceCreate';
import ProviderServiceEdit from './ProviderServiceEdit';
import SystemProviderServicesList from './SystemProviderServicesList';
import StatusField from './StatusField';

import { ActionButton, ListActions } from '../../layout';

const styles = {
  drawerContent: {
    width: 300,
  },
};

const ProviderServiceList = ({ classes, permissions, ...props }) => {
  const location = useLocation();

  const handleClose = () => {
    props.history.push('/providerservice');
  };

  return permissions !== undefined ? (
    permissions.includes('provider') ? (
      <>
        <List
          {...props}
          actions={<ListActions label='Register service' />}
          filters={<ProviderViewProviderServiceFilter />}
          perPage={10}
          sort={{ field: 'servicename', order: 'ASC' }}
        >
          <Datagrid optimized>
            <TextField label="Service Name" source="servicename" />
            <CostPerServiceField />
            <DateField label="Added On" source="added_added" showTime />
            <StatusField />
            <ActionButton MenuOptions={ActionProviderServiceMenu} {...props} />
          </Datagrid>
        </List>
        <Route path="/providerservice/create">
          {({ match }) => (
            <>
              <Drawer open={!!match} anchor="right" onClose={handleClose}>
                <ProviderServiceCreate
                  className={classes.drawerContent}
                  onCancel={handleClose}
                  {...props}
                />
              </Drawer>
            </>
          )}
        </Route>
        <Route path="/providerservice/:id">
          {({ match }) => {
            let isMatch = match && match.params && match.params.id !== 'create';

            return (
              <>
                <Drawer open={!!isMatch} anchor="right" onClose={handleClose}>
                  {/* To avoid any errors if the route does not match,
                   * we don't render at all the component in this case
                   */}
                  {isMatch ? (
                    <ProviderServiceEdit
                      className={classes.drawerContent}
                      id={isMatch && match !== null ? match.params.id : null}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : (
                    <div className={classes.drawerContent} />
                  )}
                </Drawer>
              </>
            );
          }}
        </Route>
      </>
    ) : permissions.includes('superuser') ? (
      <SystemProviderServicesList {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

const ActionProviderServiceMenu = (basePath = '', record = { id: '' }) => [
  { label: 'Update', path: `${basePath}/${record.id}/edit` },
];

const ProviderViewProviderServiceFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      label="Subcategory"
      source="sub_category_id"
      reference="subcategories"
      filter={{ subcategory: props.filterValues.subcategoryid }}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput optionText="sub_category_name" />
    </ReferenceInput>
  </Filter>
);

export default withStyles(styles)(ProviderServiceList);
