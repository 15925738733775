import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AkAppBar from './appbar/index';
import { Menu } from './menus';

import { AkTheme, layoutStyles, sidebarStyles } from '../stylesheets';

export const AbLayout = (props: any) => {
  const classes = layoutStyles();

  return (
    <Layout
      {...props}
      appBar={AkAppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={AkTheme}
      className={classes.root}
    />
  );
};

const CustomSidebar = (props: any) => {
  const classes = sidebarStyles();

  return <Sidebar {...props} size={200} className={classes.root} />;
};
