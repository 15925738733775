import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import clsx from 'clsx';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Theme,
	Typography,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import RequestIcon from '@material-ui/icons/AttachMoney';
import ServiceIcon from '@material-ui/icons/Collections';
import ProImage from '../../assets/akb_pro.jpg';
import { SmallProDashboard, MediumProDashboard } from './responsive';

import { apiFullCall } from '../../modules/apiHelper';

const useStyles = makeStyles(
	theme => ({
		media: {
			height: '32em',
		},
		provider: {
			margin: '1em auto',
			[theme.breakpoints.down('xs')]: {
				margin: '1em',
			},
    },
    button: {
      fontSize: '14px',
      color: '#FFFFFF',
      textAlign: 'center',
      borderRadius: '4px',
      background: 'linear-gradient(#069751, #066a3a)',
      padding: '0.7rem 6rem',
      marginTop: '0.5rem',
      '&:hover, &:focus, &:active': {
        color: '#DDDDDD',
      },
    },
    farmerButton: {
      marginRight: '12px',
    },
	})
);

const mediaUrl: string = `${ProImage}`;

export default (props: any) => {
	const translate = useTranslate();
	const classes = useStyles();

	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let firstName = sessionStorage.getItem('firstName'),
       lastName = sessionStorage.getItem('lastName'),
        userId = sessionStorage.getItem('id'),
        token = sessionStorage.getItem('token');
  
  const [providerId, setProviderId] = React.useState<any>(null); // eslint-disable-line

  let isLoaded = React.useRef<boolean>(true);

  // fetch provider-id via calls
  React.useEffect(() => {
    // "userId" should be defined before making expensive API calls
    if (isLoaded && userId) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `providerbranchuser/?user_id=${userId}` 
      ).then(res => {
        if (res) {

          const { status, body } = res;

          if (status === 200 || status === 201) {
            if (body.results && body.results.length > 0) {
              // extract provider_location_id from query
              const { provider_location_id } = body.results && body.results[0];            
              
              if (provider_location_id) {
                // pass provider_location_id, if it's not "undefined", to fetch branch-details
                apiFullCall(
                  '', 
                  token, 
                  'get', 
                  `providerbranch/${provider_location_id}/` 
                ).then(res => {
                  if (res) {

                    const { status, body } = res;

                    if (status === 200 || status === 201) {
                      const {
                        provider_id,
                        ...rest // eslint-disable-line
                      } = body;
                      
                      setProviderId(provider_id);
                      // save the provider-id in session
                      sessionStorage.setItem('providerId', provider_id);
                    }
                  }      
                }).catch(
                  error => console.error('Error while fetching data:', error)
                );
              }
            }
          }
        }      
      }).catch(
        error => console.error('Error while fetching data:', error)
      );      
    }
    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }
  }, [isLoaded, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return isXSmall ? (
    <SmallProDashboard
      RequestIcon={RequestIcon}
      ServiceIcon={ServiceIcon}
      firstName={firstName}
      lastName={lastName}
      translate={translate}
      Link={Link}
      classes={classes}
    />
  ) : isSmall ? (
    <MediumProDashboard
      RequestIcon={RequestIcon}
      ServiceIcon={ServiceIcon}
      firstName={firstName}
      lastName={lastName}
      translate={translate}
      Link={Link}
      classes={classes}
    /> 
  ) : (
    <Card className={classes.provider}>
      <CardMedia image={mediaUrl} className={classes.media} />
      <CardContent>
        <Typography variant="h5" component="h2">
          {translate('pos.dashboard.provider.title')} {firstName}&nbsp;{lastName}
        </Typography>
        <Typography component="p">
          {translate('pos.dashboard.provider.subtitle')}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        {/* <Button component={Link} to="/providerrequests">
          <RequestIcon style={{ paddingRight: '0.5em' }} />
          {translate('pos.dashboard.provider.aor_button')}
        </Button>
        <Button component={Link} to="/providerservice">
          <ServiceIcon style={{ paddingRight: '0.5em' }} />
          {translate('pos.dashboard.provider.demo_button')}
        </Button> */}
        <Button
          // farmer/user registration
          size="small" 
          component={Link} 
          to="/farmers/create"
          className={clsx(classes.button, classes.farmerButton)}
        >
          {translate('pos.dashboard.agent.farmer_button')}
        </Button>
        <Button 
          // provider registration
          size="small" 
          component={Link} 
          to="/provider/create"
          className={clsx(classes.button)}
        >
          {translate('pos.dashboard.agent.provider_button')}
        </Button>
      </CardActions>
    </Card>
  );
};
