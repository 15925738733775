import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import { stringify } from 'query-string';

import { FieldProps, Category } from '../../types';
import subcategories from '../subcategories';

import { LinkToRelatedSubcategoryStyles } from '../stylesheets';

const LinkToRelatedSubcategories: FC<FieldProps<Category>> = ({ record }) => {
  const translate = useTranslate();
  const classes = LinkToRelatedSubcategoryStyles();

  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/subcategories',
        search: stringify({
          page: 1,
          perPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({ categoryid: record.id }),
        }),
      }}
      className={classes.link}
    >
      <subcategories.icon className={classes.icon} />
      {translate('resources.categories.fields.subcategories')}
    </Button>
  ) : null;
};

export default LinkToRelatedSubcategories;
