import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import {
  Button,
  Chip,
  Dialog,
  Divider,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';

import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

const RequestApprove = ({ record }, source) => {
  const [open, setOpen] = useState(false);
  const [captureReason, setCaptureReason] = useState(false);
  let requestDate = new Date(record['request_date']);
  let day =
    requestDate.getDate() < 10
      ? '0' + requestDate.getDate()
      : requestDate.getDate();
  let month =
    requestDate.getMonth() < 9
      ? '-0' + (requestDate.getMonth() + 1)
      : requestDate.getMonth() + 1;
  let formatedDate = day + month + '-' + requestDate.getFullYear();

  const classes = requestStyles();
  const refresh = useRefresh();

  const viewRequestDetials = () => {
    setOpen(true);
  };

  const closeRequestDetails = () => {
    setOpen(false);
  };

  const approveFarmerRequest = value => {
    apiCall(
      { status: 'approved', request_id: value, reason: '' },
      sessionStorage.getItem('token'),
      'post',
      'adminresponceonrequest/'
    )
      .then(response => {
        if (response) {
          setOpen(false);
          refresh();
        }
      })
      .catch(error => console.error('Error during approval:', error));
  };

  const rejectFarmerRequest = value => {
    let reason = document.getElementById('reject_reason').value;
    if (reason !== '') {
      apiCall(
        { status: 'rejected', request_id: value, reason: reason },
        sessionStorage.getItem('token'),
        'post',
        'adminresponceonrequest/'
      )
        .then(response => {
          if (response) {
            setCaptureReason(false);
            setOpen(false);
            refresh();
          }
        })
        .catch(error => console.error('Error during approval:', error));
    }
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={viewRequestDetials}>
        <CalendarViewDayIcon />
      </Button>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={closeRequestDetails}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeRequestDetails}
          className={classes.requestDialog}
        >
          Process Request
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeRequestDetails}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <h3>Request Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Request No."
                  className={classes.formEntry}
                  defaultValue={record['request_number']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Request Date"
                  className={classes.formEntry}
                  defaultValue={formatedDate}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <h3>Farmer Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Farmer"
                  className={classes.formEntry}
                  defaultValue={record['farmer']['farmer_full_name']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Email Address"
                  className={classes.formEntry}
                  defaultValue={record['farmer']['farmer_user_email']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <Divider />
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <RoomIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={record['location']['vallagename']}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {record['location']['parishname'] +
                        '(' +
                        record['location']['districtname'] +
                        ')'}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
            <h3>Provider Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Provider"
                  className={classes.formEntry}
                  defaultValue={record['provider']['business_name']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Contact"
                  className={classes.formEntry}
                  defaultValue={record['provider']['primary_contact']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <h3>Requested Items</h3>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="right">Unit Cost</TableCell>
                    <TableCell align="right">Total Cost</TableCell>
                    <TableCell align="right">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record['farmer_requested_services'].map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">
                          {value['service_name']}
                        </TableCell>
                        <TableCell align="left">
                          {value['number_of_items']}
                          {value['unit_of_measure']}
                        </TableCell>
                        <TableCell align="right">
                          {value['service_cost']}
                        </TableCell>
                        <TableCell align="right">
                          {value['total_cost']}
                        </TableCell>
                        <TableCell align="right">
                          <Chip
                            label={value['service_type']}
                            className={classes.requestItemType}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow key="total">
                    <TableCell className={classes.requestTotal} colSpan="2">
                      Total Cost
                    </TableCell>
                    <TableCell
                      className={classes.requestTotal}
                      colSpan="4"
                      align="right"
                    >
                      {record['total']}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider className={classes.divider} />
            <Button
              variant="outlined"
              className={classes.approveButton}
              onClick={() => approveFarmerRequest(record['id'])}
            >
              <CheckCircleOutlineIcon /> Approve Request
            </Button>
            <Button
              variant="outlined"
              className={classes.rejectButton}
              onClick={() => setCaptureReason(true)}
            >
              <CancelOutlinedIcon /> Reject Request
            </Button>
            {captureReason && (
              <div className={classes.captureReasonPopOver}>
                <h3>Reject Request</h3>
                <TextField
                  id="reject_reason"
                  label="Enter Reason"
                  multiline
                  rows={6}
                  className={classes.formEntry}
                  InputProps={{
                    readOnly: false,
                  }}
                  variant="outlined"
                />
                <Divider className={classes.divider} />
                <Button
                  variant="outlined"
                  className={classes.popOverNegative}
                  onClick={() => setCaptureReason(false)}
                >
                  <CancelOutlinedIcon />
                </Button>
                <Button
                  variant="outlined"
                  className={classes.popOverPositive}
                  onClick={() => rejectFarmerRequest(record['id'])}
                >
                  <CheckCircleOutlineIcon />
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestApprove;
