import React, { useState } from 'react';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';

import RoomIcon from '@material-ui/icons/Room';
import apiCall from '../../apiHelper';
import { requestStyles } from '../../stylesheets';

export default function AddNewUserAddress() {
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  let userAddressDetails = JSON.parse(sessionStorage.getItem("user_address"));
  if (userAddressDetails === null) {
    userAddressDetails = {};
  }
  const [userAddress, setUserAddress] = useState(userAddressDetails);
  const classes = requestStyles();

  const trim = (x) => {
    return x.replace(/^\s+|\s+$/gm, '');
  }

  const searchLocation = event => {
    event.preventDefault();
    let search = event.target.value
    setUserAddress({
      villageid: '',
      village: search,
      parish: '',
      subcounty:'',
      county:'',
      district:'',
      region:''
    });
    let location = trim(search);
    if (location.length > 0) {
      apiCall('', sessionStorage.getItem("token"), 'get', 'locationviewsearch/?search=' + location).then((response) => {
        setSearchResults(response.results);
        setShowSearchResults(true);
      }).catch(error => error)
    } else {
      setSearchResults([]);
    }
  }

  const displayResults = () => {
    setShowSearchResults(true);
  };

  const hideResults = () => {
    setShowSearchResults(false);
  };

  const selectVillage = (location) => {
    setUserAddress(location);
    hideResults();
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <input type="hidden" value={userAddress.id} id="villageid"/>
          <TextField
            className={classes.formInput}
            id="village"
            label="Village"
            color="primary"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.villagename}
            onFocus={displayResults}
            onFocusOut={hideResults}
            onChange={searchLocation} />
          {searchResults.length > 0 && showSearchResults &&
            <div className={classes.parent}>
              <div className={classes.searchResults}>
                {
                  <List >
                    {searchResults.map((location, index) =>
                      <>
                        <Divider />
                        <ListItem button onClick={() => selectVillage(location)}>
                          <ListItemAvatar>
                            <Avatar>
                              <RoomIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={location.villagename + " (" + location.subcountyname + ")"}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {location.countyname + "-" + location.districtname}
                                </Typography>
                              </React.Fragment>
                            } />
                        </ListItem>
                        <Divider />
                      </>
                    )}
                  </List>
                }
              </div>
            </div>
          }
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="parish"
            label="Parish"
            variant="outlined"
            color="primary"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.parishname} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="subcounty"
            label="Sub County"
            variant="outlined"
            color="primary"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.subcountyname} />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="county"
            label="County"
            color="primary"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.countyname} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="district"
            label="District"
            variant="outlined"
            color="primary"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.districtname} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            id="region"
            className={classes.formInput}
            label="Region"
            variant="outlined"
            color="primary"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.regionname} />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="nationality"
            label="Nationality"
            variant="outlined"
            color="primary"
            InputLabelProps={{
              shrink: true,
            }}
            value={userAddress.nationality} />
        </div>
        <div className={classes.formThreeColumn}></div>
        <div className={classes.formThreeColumn}></div>
      </div>
    </form>
  );
}
