import GroupWorkIcon from '@material-ui/icons/GroupWork';

import CooperativesEdit from './CooperativesEdit';
import CooperativesCreate from './CooperativesCreate';

export default {
  create: CooperativesCreate,
  edit: CooperativesEdit,
  icon: GroupWorkIcon,
};
