import ServiceIcon from '@material-ui/icons/Collections';

import ServiceList from './ServiceList';

export default {
  list: ServiceList,
  create: ServiceList,
  edit: ServiceList,
  icon: ServiceIcon,
};
