import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Edit } from 'react-admin';
import {
  AbTabbedForm,
  AbFormTab,
  UserFormBasic,
  UserFormAddressWork,
} from './form';
import FullNameField from './FullNameField';
import { User, FieldProps } from '../../types';

import { trim } from '../utils';
import { apiFullCall } from '../apiHelper';


const UserEdit = ({ permissions, ...props }) => {

  let superuser = permissions && permissions.includes('superuser');
  let agent = permissions && permissions.includes('agent');
  let userId = sessionStorage.getItem('id');

  let allowedUser = permissions && (superuser || agent);
 
  /* related to adding users to a group */ 

  const token = sessionStorage.getItem('token');
  const [state, setState] = React.useState<object | any>({ groupList: [] });
  const [userRole, setUserRole] = React.useState<object | any>({ role: '' });

  const [branchDetails, setBranchDetails] = React.useState<object | any>({
    id: '', cooperative_branch_name: '', village_id: '',
  });  

  const [filterValue, setFilterValue] = React.useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtname: '',
    regionname: '',
  }); 

  // onChange method for the input field
  const inputOnChangeCooperative = React.useCallback((e, id?: string | number) => {
    if (!e.target.value) {
      return null;
    }

    searchCooperative(e.target.value); // pass districtid
  }, []); // eslint-disable-line
  
  const searchCooperative = (value) => {
    let cooperative = trim(value);

    if (cooperative.length > 2) {

      apiFullCall('', token, 'get', `cooperativebranches/?search=${cooperative}`)
      .then(res => {
        if (res) {
          // destructure response
          const { status, body } = res;

          if (status === 200 || status === 201) {

            setState(prevState => ({ ...prevState, groupList: body['results'] }))

          }
        }
      })
      .catch(error =>
        console.error('Error while searching for location:', error)
      );

    };

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeCooperative = React.useCallback(value => {
    // update branch details
    setBranchDetails({ ...value });
    // trigger fetch of location
    fetchCooperativeLocation(value['village_id']);
  }, []); // eslint-disable-line


  const fetchCooperativeLocation = villageId => {

    apiFullCall('', token, 'get', `locationsearch/?id=${villageId}`)
    .then(res => {
      if (res) {
        // destructure response
        const { status, body } = res;

        if (status === 200 || status === 201) {

          // destructure the wanted meta-data
          const {
            id,
            villagename,
            parishname,
            subcountyname,
            countyname,
            districtid,
            districtname,
            regionname,
          } = body.results[0];

          const village_meta = {
            id,
            villagename,
            parishname,
            subcountyname,
            countyname,
            districtid,
            districtname,
            regionname,
          };

          setFilterValue(prevState => ({ ...prevState, ...village_meta }))

        }
      }
    })
    .catch(error =>
      console.error('Error while searching for group location:', error)
    );

  };

  // used by superuser or agent
  let groupManagement = {
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative 
  };

  return permissions !== undefined ? (
    allowedUser ? (
      // pass dynamic props (system-admin or agent)
      <EditComponent 
        {...props}
        agent={agent}
        superuser={superuser}
        {...groupManagement}
      />
    ) : userId ? (
      // pass updated props (if any other user)
      <EditComponent
        {...props}
        id={userId}
        agent={agent}
        superuser={superuser}
        hasCreate={false}
        hasList={false}
        basePath="/"
      />
    ) : (
      <Redirect to="/" />
    )
  ) : null;
};


// Handling the user-tabbed-form
const EditComponent = ({ agent, superuser, ...props }) => {
  const history = useHistory();

  const {
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
    ...other
  } = props;

  const {
    classes,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
  } = other;

  let groupRelated = {
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
  };

  const { basePath } = props;

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return (
    <Edit
      {...other}
      title={<UserTitle />}
    >
      <AbTabbedForm redirect={hasCreate ? 'list' : basePath}>
        <AbFormTab label="Basic Info">
          <UserFormBasic 
            {...rest}
            {...groupRelated}
            onCancel={handleClose}
            superuser={superuser}
            agent={agent}
          />
        </AbFormTab>
        <AbFormTab label="Other Info">
          <UserFormAddressWork {...rest} />
        </AbFormTab>
      </AbTabbedForm>
    </Edit>
  );
};

const UserTitle: React.FC<FieldProps<User>> = ({ record }) =>
  record ? <FullNameField record={record} size="32" /> : null;


export default UserEdit;
