import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { 
  useTranslate, 
  useLogin, 
  useNotify, 
  useSafeSetState
} from 'react-admin';
import {
  Button,
  CardActions,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';

import CallIcon from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(
  theme => ({
    form: {
      padding: '0 1em 1em 1em'
    },
    input: {
      marginTop: '1.65em'
    },
    button: {
      width: '100%'
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField 
    error={!!(touched && error)} 
    helperText={touched && error} 
    {...inputProps} 
    {...props} 
    fullWidth 
  />
);

export const LoginForm = ({ redirectTo }) => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles({});

  const [state, setState] = React.useState({ showPassword: false });

  // const handleChange = prop => (event) => {
  //   setState({ ...values, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setState({ showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validate = values => {
    // once backend is updated, we'll refactor to use "phone"
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={clsx('login-form')}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                id="phone"
                name="username"
                size="small"
                component={Input}
                label={translate('login.auth.phone')}
                disabled={loading}
                variant="outlined" 
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CallIcon />
                    </InputAdornment>
                  ),
                }}
                />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                size="small"
                component={Input}
                label={translate('login.auth.password')}
                // type="password"
                type={state.showPassword ? 'text' : 'password'}
                disabled={loading}
                autoComplete="current-password"
                variant="outlined" 
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <CardActions>
            <Button 
              variant="contained" 
              type="submit" 
              color="primary" 
              disabled={loading} 
              className={classes.button}
            >
              {loading && 
                <CircularProgress className={classes.icon} size={18} thickness={2} />}
              login
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string
};
