import React, { Children, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import { useLocation } from 'react-router-dom';

export const getTabFullPath = (tab, index, baseUrl) =>
  `${baseUrl}${
    tab.props.path ? `/${tab.props.path}` : index > 0 ? `/${index}` : ''
  }`;

export const AbTabbedFormTabs = ({
  children,
  classes,
  record,
  url,
  tabsWithErrors,
  ...rest
}) => {
  const location = useLocation();

  const validTabPaths = Children.map(children, (tab, index) => {
    if (!isValidElement(tab)) return undefined;
    return getTabFullPath(tab, index, url);
  });

  // This ensure we don't get warnings from material-ui Tabs component when
  // the current location pathname targets a dynamically added Tab
  // In the case the targeted Tab is not present at first render (when
  // using permissions for example) we temporarily switch to the first
  // available tab. The current location will be applied again on the
  // first render containing the targeted tab. This is almost transparent
  // for the user who may just see an short tab selection animation
  const tabValue = validTabPaths.includes(location.pathname)
    ? location.pathname
    : validTabPaths[0];

  return (
    <Tabs value={tabValue} indicatorColor="primary" {...rest}>
      {Children.map(children, (tab, index) => {
        if (!isValidElement(tab)) return null;

        // Builds the full tab tab which is the concatenation of the last matched route in the
        // TabbedShowLayout hierarchy (ex: '/posts/create', '/posts/12', , '/posts/12/show')
        // and the tab path.
        // This will be used as the Tab's value
        const tabPath = getTabFullPath(tab, index, url);

        return cloneElement(tab, {
          intent: 'header',
          value: tabPath,
          record,
          className:
            tabsWithErrors.includes(tab.props.label) &&
            location.pathname !== tabPath
              ? classes.errorTabButton
              : null,
        });
      })}
    </Tabs>
  );
};

AbTabbedFormTabs.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  url: PropTypes.string,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
};
