import React from 'react';
import PropTypes from 'prop-types';
import { 
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';

import { UserDetailView } from './UserBioData'
import { UserRequestsView } from './UserRequest'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Bio Data" {...a11yProps(0)} />
          <Tab label="Requests" {...a11yProps(1)} />
          {/* <Tab label="Loans" {...a11yProps(2)} />
          {props.record.is_provider==='True' &&
          (<Tab label="Services" {...a11yProps(3)} />)
          }
          {props.record.is_farmer==='True' &&
          (<Tab label="Farmer" {...a11yProps(4)} />)
          } */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UserDetailView {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserRequestsView {...props} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        User loans under implementation
      </TabPanel>
      <TabPanel value={value} index={3}>
        User Services under implementation
      </TabPanel>
      <TabPanel value={value} index={4}>
        Farmer crops and animals under implementation
      </TabPanel>
    </div>
  );
}
