import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { useFormState } from 'react-final-form';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { 
  Box,
  IconButton,
  Step,
  Stepper,
  StepLabel,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  Button as NavButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRedirect,
  useRefresh,
  useNotify,
} from 'react-admin';

import { Separator, filterObject, trim } from '../../utils';
import { ProviderFormBasic } from './ProviderFormBasic';
import { ProviderFormServices } from './ProviderFormServices';
import { ProviderFormPersonWithAddress } from './ProviderFormPersonWithAddress';

import { apiFullCall } from '../../apiHelper';

const StepsToCreateProvider = () => [
  'Add business info', 
  'Add personnel & address'
];

const StepsToEditProvider = () => [
  'Business details', 
  'Branch details', 
  'Services'
];

// handle rendering of stepper forms
const GetStepContent = (
  step: number,
  props: object,
  isSmall?: boolean,
  isLoaded?: React.MutableRefObject<boolean>,
  isRegistered?: boolean,
  // related to provider-class
  cleanCategoryList?: Array<object>,
  // related to classes/programs
  spCategories?: Array<string>,
  listClasses?: Array<object>,
  listPrograms?: Array<object>,
  // related to village search
  searchable?: object,
  filterValue?: object,
  inputOnChangeVillage?: object,
  downshiftOnChangeVillage?: object,
  // related to district search
  getDistrict?: object,
  filterDistrict?: object,
  inputOnChangeDistrict?: object,
  downshiftOnChangeDistrict?: object,
  // related to branch-user
  branchUser?: object,
  setBranchUser?: object,
) => {
  switch (step) {
    case 0:
      return (
        <ProviderFormBasic
          {...props}
          isLoaded={isLoaded}
          listClasses={listClasses}
          isRegistered={isRegistered}
          spCategories={spCategories}
          listPrograms={listPrograms}
          cleanCategoryList={cleanCategoryList}
        />
      );
    case 1:
      return (
        <ProviderFormPersonWithAddress
          {...props} 
          isRegistered={isRegistered}
          // related to village search 
          searchable={searchable}
          filterValue={filterValue}
          inputOnChangeVillage={inputOnChangeVillage}
          downshiftOnChangeVillage={downshiftOnChangeVillage}
          // related to district search
          getDistrict={getDistrict}
          filterDistrict={filterDistrict}
          inputOnChangeDistrict={inputOnChangeDistrict}
          downshiftOnChangeDistrict={downshiftOnChangeDistrict}
          // related to branch-user
          branchUser={branchUser}
          setBranchUser={setBranchUser}
        />
      );
    case 2:
      return (
        <ProviderFormServices
          {...props}
          isSmall={isSmall}
        />
      );
    default:
      return 'Unknown step';
  }
};

export const ProviderMultiForm = ({ onCancel, ...props }) => {
  const notify = useNotify();
  const [state, setState] = useState({ activeStep: 0 });

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // supports the pagination implementation (see: useEffect)
  let check = sessionStorage.getItem('hasServices');

  const handleNext = () => {
    setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  const handleBack = () => {
    setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };

  const { activeStep } = state;
  const { basePath, record, ...rest } = props;
  const { provider_class, provider_location } = record && record;

  // check if record has key 'id', use this check to render guide messages
  let isRegistered = Object.prototype.hasOwnProperty.call(record, 'id');

  const steps = isRegistered ? StepsToEditProvider() : StepsToCreateProvider();

  React.useEffect(() => {

    /**
     * Pagination bug:
     * 
     * If a pagination button is clicked;
     * push the form back to step-2 (services section)
     * 
     */
    if (activeStep === 0 && check === "true") {
      setState({ activeStep: 2 });
    }

  }, [activeStep, check]); // eslint-disable-line

  let isLoaded = React.useRef(true);
  const token = sessionStorage.getItem('token');

  const [ listPrograms, setListPrograms ] = React.useState([]);
  const [ spCategories, setSpCategories ] = React.useState<Array<string>>([]);
  const [ listClasses, setListClasses ] = React.useState<Array<{ id: string, name: string}>>([]);
  const [ cleanCategoryList, setCleanCategoryList ] = React.useState<Array<object>>([]);

  // fetch provider programs & classes
  React.useEffect(() => {

    // capture provider-location-id to be used in the service templates
    sessionStorage.setItem(
      'providerlocationid', 
      props.record &&
        props.record.provider_location &&
          props.record.provider_location[0]['id']
    );

    // populate service-provider classes
    if (isLoaded) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `classification` 
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, name }) => ({ id: id, name: name })
              );
              setListClasses(data);

              if (isRegistered) {
                let categoryList = results.map(
                  ({ name }) => name
                );
                setSpCategories(categoryList);
              };

            };

          }
        }      
      }).catch(
        error => console.error('Error while saving:', error)
      );
    };

    // populate programs
    if (isLoaded && record['id']) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `programs/?provider_id=${props['record']['id']}` 
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, short_name }) => ({ id: id, name: short_name })
              );
              setListPrograms(data);
            }
          }
        }      
      }).catch(
        error => console.error('Error while saving:', error)
      );      
    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };
    
  }, [ isLoaded, record['id'], isRegistered ]); // eslint-disable-line react-hooks/exhaustive-deps


  // manupilate provide-classes
  React.useEffect(() => {

    // check if we're in edit-mode
    if (isLoaded && isRegistered && provider_class ) {
      
      // check if provider belongs to any class already
      // and check if we have listed classes (from endpoint)
      if ( spCategories.length > 0 && listClasses.length > 0 ) {

        // let alreadyExistingClasses: Array<{ id: string, name: string}> = [];

        let alreadyExistingClasses = provider_class && provider_class.length > 0 && provider_class.map(
          ({ class_id }) => spCategories[class_id - 1]
        );

        let manupilatedArray = listClasses && listClasses.filter(
          category => alreadyExistingClasses && alreadyExistingClasses.includes(category['name'])
        ).map(item => ({ ...item, 'disabled': true }));

        let map = new Map();
        
        // obtain an updated list to disable certain fields
        listClasses.forEach(item => map.set(item.id, item));
        manupilatedArray.forEach(item => map.set(item.id, { ...map.get(item.id), ...item }));
        
        // return list with all categories (with disabled)
        let sanitizedCategoryList = listClasses && Array.from(map.values());

        // return list WITHOUT disabled categories (where user already belongs)
        let cleanCategoryList = listClasses && sanitizedCategoryList.filter(
          // return only categories (without "disabled" key)
          category => !Object.prototype.hasOwnProperty.call(category, 'disabled')
        );
        
        // update the category list
        setCleanCategoryList(cleanCategoryList);

      };

    };

    // clean up effect, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };

  }, [ isRegistered, provider_class, spCategories, listClasses ]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Related to village/location search */ 


  const abortController = new AbortController();

  const [searchable, setSearchable] = useState<object | any>({ address: [] });

  const [filterValue, setFilterValue] = useState<object | any>({
    id: '',
    villagename: '',
    parishname: '',
    subcountyname: '',
    countyname: '',
    districtid: '', // required for user search
    districtname: '',
    regionname: '',
  });

  // support capturing village id(s)
  let idVillage = filterValue.id;

  // onChange method for the input field
  const inputOnChangeVillage = useCallback((e, id?: string | number) => {
    if (!e.target.value) {
      return null;
    }

    searchCallVillage(e.target.value, id); // pass districtid
  }, []); // eslint-disable-line

  const searchCallVillage = (value, id?: string | number) => {
    let location = trim(value);

    if (location.length > 2) {

      if (!!id) {

        // search with districtid, if defined
        apiFullCall('', token, 'get', `locationsearch/?districtid=${id}&search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setSearchable(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      } else {

        // plain search, if no district is selected
        apiFullCall('', token, 'get', `locationsearch/?search=${location}`)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setSearchable(prevState => ({ ...prevState, address: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );

      }
      
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeVillage = useCallback(value => {
    setFilterValue({ ...value });
  }, []); // eslint-disable-line  


  // fetch village meta-data
  React.useEffect(() => {
    
    // define "village id" (used in API call)
    let villageId = record && provider_location && 
      provider_location.length > 0 && provider_location[0]['village_id'];
    
    // only run in edit-mode (when we have a "record")
    if (record['id']) {

      if (isLoaded && !villageId) {

        // Only make API call if "village id" is undefined and we have a "record"
        // we pick an existing "village_id" from here
        apiFullCall(
          '', 
          token, 
          'get', 
          `provider/${record['id']}` 
        ).then(res => {
          if (res) {
            const { status, body } = res;
            if (status === 200 || status === 201) {

              if (body.provider_location && body.provider_location.length > 0) {
                // destructure the "village_id" for use in the next API call
                const { village_id } = body.provider_location && body.provider_location[0];

                if (village_id) {
                  // search for this specific village inorder to pick other meta-data
                  apiFullCall(
                    '', 
                    token, 
                    'get', 
                    `locationsearch/?id=${village_id}` 
                  ).then(res => {
                    if (res) {
                      const { status, body } = res;
                      if (status === 200 || status === 201) {
                        
                        // destructure the wanted meta-data
                        const {
                          id,
                          villagename,
                          parishname,
                          subcountyname,
                          countyname,
                          districtname,
                          regionname,
                        } = body.results[0];
      
                        const village_meta = {
                          id,
                          villagename,
                          parishname,
                          subcountyname,
                          countyname,
                          districtname,
                          regionname,
                        };
                        
                        // set-up the "filterValue" state
                        setFilterValue({ ...filterValue, ...village_meta });
                      }
                    }      
                  }).catch(
                    error => console.error('Error while fetching data:', error)
                  );
                }

              }
            }
          }      
        }).catch(
          error => console.error('Error while fetching data:', error)
        );

      } else {

        // if "record[provider_location][0]['village_id']" isn't "",
        // then we just make that call
        apiFullCall(
          '', 
          token, 
          'get', 
          `locationsearch/?id=${villageId}` 
        ).then(res => {
          if (res) {
            const { status, body } = res;
            if (status === 200 || status === 201) {

              // destructure the wanted meta-data
              const {
                id,
                villagename,
                parishname,
                subcountyname,
                countyname,
                districtname,
                regionname,
              } = body.results[0];

              const village_meta = {
                id,
                villagename,
                parishname,
                subcountyname,
                countyname,
                districtname,
                regionname,
              };
              
              // set-up the "filterValue" state
              setFilterValue({ ...filterValue, ...village_meta });
            }
          }      
        }).catch(
          error => console.error('Error while fetching data:', error)
        );
      };

    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };
    
  }, [isLoaded, record['id']]); // eslint-disable-line react-hooks/exhaustive-deps


  /* Related to District/location search */ 


  const [getDistrict, setGetDistrict] = useState<object | any>({ districtList: [] });
  
  const [filterDistrict, setFilterDistrict] = useState<object | any>({
    districtid: '',
    districtname: '',
  });

  // onChange method for the input field
  const inputOnChangeDistrict = useCallback(e => {
    if (!e.target.value) {
      return null;
    }
    searchCallDistrict(e.target.value);
  }, []); // eslint-disable-line
  
  const searchCallDistrict = value => {
    let district = trim(value);

    if (district.length > 2) {
      apiFullCall('', token, 'get', 'district/?search=' + district)
        .then(res => {
          if (res) {
            // destructure response
            const { status, body } = res;

            if (status === 200 || status === 201) {

              setGetDistrict(prevState => ({ ...prevState, districtList: body['results'] }))

            }
          }
        })
        .catch(error =>
          console.error('Error while searching for location:', error)
        );
    } else {
      return null
    }

  };

  // input field for the <Downshift /> component
  const downshiftOnChangeDistrict = useCallback(value => {
    setFilterDistrict({ ...value });
    setFilterValue({
      id: '',
      villagename: '',
      parishname: '',
      subcountyname: '',
      countyname: '',
      districtname: '',
      regionname: '',
    });
  }, []); // eslint-disable-line


  /* related to branch-user */


  const [branchUser, setBranchUser] = useState<object | any>({
    id: '',
    first_name: '', 
    middle_name: '', 
    last_name: '',
    username: '',
    gender: '',
    nin: '',
  });

  // define "branch-id"
  let branchId = record && provider_location && provider_location.length > 0 && provider_location[0]['id'];

  // fetch branch-user details
  React.useEffect(() => {

    // "branch-id" should be defined before making expensive API calls
    if (isLoaded && branchId) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `providerbranchuser/?provider_location_id=${branchId}` 
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {

            if (body.results && body.results.length > 0) {
              // extract user_id from query
              const { user_id } = body.results && body.results[0];            
              
              if (user_id) {
                // pass user_id, if it's not "undefined", to fetch user-details
                apiFullCall(
                  '', 
                  token, 
                  'get', 
                  `users/${user_id}` 
                ).then(res => {
                  if (res) {
                    const { status, body } = res;
                    if (status === 200 || status === 201) {
                      const {
                        id,
                        first_name,
                        middle_name,
                        last_name,
                        username,
                        gender,
                        nin,
                        ...rest // eslint-disable-line
                      } = body;
                      
                      const user = { id, first_name, middle_name, last_name, username, gender, nin };
                      // update the material-ui fields
                      setBranchUser({ ...branchUser, ...user });
                    }
                  }      
                }).catch(
                  error => console.error('Error while fetching data:', error)
                );
              }

            } else {
              notify(`No branch user`, 'warning');
              notify(`Please add user to manage this provider account`, 'info');
            }
          }
        }      
      }).catch(
        error => console.error('Error while fetching data:', error)
      );      
    }

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };

  }, [isLoaded, branchId]); // eslint-disable-line react-hooks/exhaustive-deps


  /* trigger "system role" fetch */


  // get the "system role"
  const [systemRole, setSystemRole] = React.useState<string | number | undefined>('');

  React.useEffect(() => {

    // check if we're in "create mode" (no user-id yet)
    if ( isLoaded && !record['id'] ) {

      // query for provider (role) system-id
      apiFullCall(
        '', 
        token, 
        'get',
        `systemroles/?key=provider` 
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {

            // get the system "id" for provider (role)
            let role = body && body.results && body.results[0] && body.results[0]['id'];
            setSystemRole(role);
          };

        };

      }).catch(
        error => console.error('Error while saving:', error)
      );
    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded, record['id']]) // eslint-disable-line react-hooks/exhaustive-deps

  
  return (
    <SimpleForm 
      {...props}
      className={clsx(
        'provider',
        {
          'register-form': !isRegistered,
          'basic-form': isRegistered,
          // hide "back" button in create-mode on first step
          'first': !isRegistered && activeStep === 0,
          // manupilate service sheet
          'only-services': activeStep === 2,
        },
        props.className
      )}
      submitOnEnter={false} 
      toolbar={
        isRegistered ? (
          // edit-mode
          <ProviderUpdateToolbar
            {...rest}
            steps={steps}
            activeStep={activeStep}
            idVillage={idVillage}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : (
          // create-mode
          <ProviderCreateToolbar
            {...rest}
            steps={steps}
            activeStep={activeStep}
            idVillage={idVillage}
            handleNext={handleNext}
            handleBack={handleBack}
            newUserRole={systemRole}
          />
        )
      }
    >      
      <Box
        display={{ xs: 'inline-flex' }}
        width={{ xs: '100% !important' }}
      >        
        <Box flex={1}>
          <Stepper
            activeStep={activeStep}
            className={clsx('stepper-lg-header')}
          >
            {/* shown on larger screens */}
            {steps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {/* shown on mobile */}
          <div className={clsx('stepper-sm-header')}>
            {activeStep === 0 && <span>Business details</span>}
            {activeStep === 1 && <span>Branch details</span>}
            {activeStep === 2 && <span>Services</span>}
          </div>
        </Box>
        <Box>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>            
        </Box>
        <Separator />
      </Box>
      <Box 
        display={{ xs: 'flex' }} 
        flexDirection={{ xs: 'column' }}
        className={clsx(
          'stepper-content',
          { 'services': isSmall },
        )}
      >
        {activeStep === steps.length 
          ? null 
          : GetStepContent(
              activeStep, 
              props,
              isSmall,
              isLoaded,
              isRegistered,
              // related to provider-classes
              cleanCategoryList,
              // related to classes/programs
              spCategories,
              listClasses,
              listPrograms,
              // related to village search
              searchable,
              filterValue,
              inputOnChangeVillage,
              downshiftOnChangeVillage,
              // related to district search
              getDistrict,
              filterDistrict,
              inputOnChangeDistrict,
              downshiftOnChangeDistrict,
              // related to branch-user
              branchUser,
              setBranchUser
            )
        }
      </Box>
    </SimpleForm>
  );
};

ProviderMultiForm.propTypes = {
  classes: PropTypes.object,
};

// Handling "create" mode
const ProviderCreateToolbar = props => {
  const {handleBack, ...rest } = props;
  const { activeStep, handleNext, idVillage, newUserRole, save, ...other } = rest;

  return (
    <Toolbar {...other}>
      <BackButton handleBack={handleBack} />
      <NextOrSaveButton {...rest} /> 
    </Toolbar>
  )  
};

// handling "update/edit" mode
const ProviderUpdateToolbar = props => {
  const { activeStep, handleBack, handleNext, idVillage, save, ...rest } = props;

  return (
    <Toolbar {...rest}>
      <NextOrUpdateButton {...props} /> 
    </Toolbar>
  )  
};

const BackButton = props => {
  const { handleBack } = props;

  return (
    <NavButton label="Back" onClick={handleBack} className={clsx('direction')}>
      <ArrowBackIcon />
    </NavButton>
  );
};

// handle "create"
const NextOrSaveButton = props => {
  const formState = useFormState();
  const redirectTo = useRedirect();
  const notify = useNotify();

  const [ loading, setLoading ] = useState<boolean>(false);

  const token = sessionStorage.getItem('token');
  var spClass = []; // to capature provider_class 

  const {
    // destructure "props" not to be pushed to button
    activeStep, 
    basePath, 
    handleNext,
    idVillage,
    invalid,
    newUserRole,
    save, 
    steps, 
    ...rest
  } = props;

  const {
    // destructure "user" fields
    first_name, 
    middle_name, 
    last_name, 
    nin, 
    username, 
    gender, 
    is_provider,
    ...other
  } = formState.values;

  // define "user" data
  let userData = {
    first_name: first_name, 
    middle_name: middle_name, 
    last_name: last_name, 
    nin: nin, 
    username: username, 
    gender: gender,
  };

  if (other && other.provider_class && other.provider_class.length > 0) {
    // extract each selected "service provider category"
    // and map it to a "class_id" field
    spClass = other.provider_class.map(
      selected_id_by_user => ({ class_id: selected_id_by_user})
    );
  };

  // define "provider" data  
  let providerData = {
    business_name: other['business_name'], 
    certificate: other['certificate'],
    is_active: other['is_active'],
    is_approved: other['is_approved'],
    license_number: other['license_number'],
    primary_contact: other['primary_contact'],
    primary_email: other['primary_email'],
    provider_class: spClass, 
    provider_cert_agency: [],
    provider_location: other['provider_location'],
    provider_type: other['provider_type'],
    reg_date: other['reg_date'],
    reg_number: other['reg_number'],
    secondary_contact: other['secondary_contact'],
    secondary_email: other['secondary_email'],
    shortname: other['shortname'],
    tin: other['tin'],
  };

  // Please note; this is vital to creating a provider.
  if (providerData['provider_location']) {
    /**
     * See .form/ProviderFormPersonWithAddress, this value = ""
     * thus we manupilate it here to take on a valid id
     */
    providerData['provider_location'][0]['village_id'] = idVillage;
    providerData['provider_location'][0]['branch_name'] = !providerData['business_name'] ? '' : providerData['business_name'];
  };
  
  if (userData) {
    // send default branch "primary_contact" as "username" for access (helps prefill fields)
    userData['username'] = !userData['username'] ? providerData['primary_contact'] : userData['username'];
  };

  let sanitizedUserData = {
    ...userData,
    user_system_role: [{ system_role: newUserRole }],
  };

  const handleSaveProvider = useCallback(() => {
    /** 
     * The challenge is getting all 4 different endpoints to only be called if we have validated other data
     * 
     * Validation steps; on top of the form-validation (required fields)
     * 1. check if "business_name" and "primary_contact" are populated
     * 2. check that we have a village id; so user must "search by village"
     * 3. check if we have a "service provider category" selected
     * 4. check if we don't have the "phone number" already;
     *    If yes, then ask user to save with different contact otherwise save a user
     */ 
    if (providerData['business_name'] && providerData['primary_contact']) {

      if (providerData['provider_location'] && providerData['provider_location'][0]['village_id']) {
        
        if (providerData['provider_class'] && providerData.provider_class.length > 0) {

          if (sanitizedUserData['username']) {

            // Check added "phone number" exists, or not
            apiFullCall(
              '', 
              token, 
              'get', 
              `users/?username=${sanitizedUserData['username']}`
            ).then(res => {

              if (res) {

                const { status, body: checkedUser } = res;

                if (status === 200 || status === 201) {

                  let userExists = checkedUser && 
                    checkedUser.results && checkedUser.results.length;

                  if (userExists > 0) {

                    // if we have a result, it means that "phone number" already exists
                    notify(`A user with that phone number already exists.`, 'warning');
                    notify(`Please use a different phone number`, 'info');

                  } else {
        
                    // Else we don't have that "phone number" yet
                    // Therefore trigger PROVIDER save, first
                    apiFullCall(
                      providerData, 
                      token, 
                      'post', 
                      `provider/`
                    ).then(res => {
          
                      if (res) {
                        
                        const { status, body: provider } = res;
          
                        if (status === 200 || status === 201) {
          
                          // Trigger USER save, after provider is created
                          apiFullCall(
                            sanitizedUserData, 
                            token, 
                            'post', 
                            `users/` 
                          ).then(res => {
          
                            if (res) {

                              setLoading(true);

                              const { status, body: branchUser } = res;

                              if (status === 200 || status === 201) {
                                
                                // define "branch user" data
                                let branchUserData = {
                                  is_active: true,
                                  provider_location_id: provider['provider_location'][0]['id'],
                                  user_id: branchUser['id'],
                                  // roles: [], // requires a pk-value, not a string (TODO: consult BE-team)
                                };
                      
                                // save the above linked "user" as a branch-user
                                apiFullCall(
                                  branchUserData, 
                                  token, 
                                  'post', 
                                  `providerbranchuser/`
                                ).then(res => {
          
                                  if (res) {
          
                                    const { status } = res;

                                    if (status === 200 || status === 201) {
                                      // display notice that provider and branch-user have been created
                                      notify('post.notification.provider_is_created', 'info');
                                      notify(`Branch user has been added.`, 'info'); 
          
                                      // redirect to provider list
                                      redirectTo('list', basePath);
          
                                    } else if (status === 400) {
                                      notify(`This form is invalid. Please check again and resubmit.`, 'warning');
                                    } else if (status >= 500) {
                                      notify(`Server error, please try again later.`, 'warning');          
                                    }
                                  };
          
                                }).catch(
                                  error => console.error('Error while saving:', error)
                                ); 
                                
                              } else if (status === 400) {
          
                                  // trigger notification about possible duplication
                                  notify(`This form is invalid. Please check again and resubmit.`, 'warning');
          
                              } else if (status >= 500) {
                                notify(`Server error, please try again later.`, 'warning');          
                              };
          
                              setLoading(false);
                            };
          
                          }).catch(
                            error => console.error('Error while saving:', error)
                          );                   
                
                        } else if (status === 400) {
                          notify(`This form is invalid. Please check again and resubmit.`, 'warning');
                        } else if (status >= 500) {
                          notify(`Server error, please try again later.`, 'warning');          
                        };
                      };
          
                    }).catch(
                      error => console.error('Error while saving:', error)
                    );

                  };

                } else {

                  // since this is a "GET", we only anticipate a server error (if any)
                  notify(`Server error, please try again later.`, 'warning'); 
                };

              };
            
            }).catch(
              error => console.error('Error while saving:', error)
            );

          } else {

            // return this, if there's no "phone contact" (username) entered yet
            notify(`Please add a "Phone number".`, 'warning'); 
          };

        } else {

          // return this, if there's no "service provider category" selected
          notify(`Please select/add a "Service provider category"`, 'warning'); 
        };

      } else {
        // return this if "village" is not populated
        notify(`Please search and select a village`, 'warning');  
      };

    } else {
      // return this if "business_name" or "primary_contact" are not populated
      notify(`Please enter "business name" and/or "primary contact"`, 'warning');
    };

  }, [providerData, sanitizedUserData, notify, redirectTo, basePath]); // eslint-disable-line react-hooks/exhaustive-deps

  return activeStep === steps.length - 1 ? (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_exit"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveProvider}
    />
  ) : (
    // only show "next" button on first step
    <NavButton label="Next" onClick={handleNext} className={clsx('direction')}>
      <ArrowForwardIcon />
    </NavButton>
  );
};

// handle "update"
const NextOrUpdateButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  const [ loading, setLoading ] = useState<boolean>(false);

  const token = sessionStorage.getItem('token');
  let villageID = sessionStorage.getItem('villageID');
  let branchUserID = sessionStorage.getItem('branchUserID');

  const {
    // destructure "props" not to be pushed to button
    activeStep, 
    basePath,
    handleBack,
    handleNext,
    idVillage,
    invalid, 
    save, 
    steps, 
    ...rest
  } = props;

  const {
    // destructure data to be saved to "users" endpoint
    first_name, 
    middle_name, 
    last_name, 
    gender,
    is_active,
    is_approved,
    is_provider,
    nin,
    username,
    programid,
    /**
     * In edit-mode, we exclude the "provider_location" field from "providerData"
     * 
     * 1. It causes an "IntegrityError" to the back end (since we'll be trying to update "village_id")
     * 2. "provider_location" is better edited from another endpoint "/providerbranch"
     * 3. We therefore avoid including "provider_location" within the initial isEqual comparison
     * 4. We also exclude "provider_class" from "providerData" because we can't edit/patch it directly;
     *    We're using "add_category" to instead add the provider to other categories.
     * 
     */
    provider_location,
    provider_class,
    add_category,
    // data to be pushed to "/provider" endpoint
    ...other
  } = formState && formState.values;

  // define "provider" data  
  // avoid sending; provider_class: [] and provider_cert_agency: [] directly
  let providerData = { ...other };

  // define "user" data
  let userData = { first_name, middle_name, last_name, gender, is_active, is_approved, is_provider, nin, username };

  // need to update within entire scope
  var spAddedClass = []; // to capature more provider "classes"

  // Please note; this is vital to updating a provider.
  if (provider_location && provider_location.length > 0) {
    /**
     * See .form/ProviderFormPersonWithAddress, this value = ""
     * thus we manupilate it here to take on a valid id
     */
    provider_location[0]['village_id'] = !provider_location[0]['village_id'] ? villageID : provider_location[0]['village_id'];
    // send default as "branch" if left empty (helps prefill fields)
    provider_location[0]['location_type'] = !provider_location[0]['location_type'] ? "branch" : provider_location[0]['location_type'];
    // send default as "business_name" if left empty
    provider_location[0]['branch_name'] = !provider_location[0]['branch_name'] ? providerData['business_name'] : provider_location[0]['branch_name'];
  };

  if (add_category && add_category.length > 0) {
    // extract each selected "service provider category"
    // and map it to a "class_id" field
    spAddedClass = add_category.map(
      added_by_user => ({ class_id: added_by_user})
    );
  };

  // declare a reference to the "providerData"
  const providerRef = React.useRef<object | any>(providerData);

  const providerLocationRef = React.useRef<object | any>(provider_location);

  // define a reference to the "userData"
  const userRef = React.useRef<object | any>(userData)
  
  // define provider "id"
  const providerID = providerData && providerData['id'];

  // We sanitize the userData to remove any "undefined" values
  const sanitizedUserData = userData && filterObject(userData, value => value !== undefined);

  const handleUpdateProvider = useCallback(() => {

    // only make API call to provider if user changes any fields
    // once any field changes; providerRef.current !== providerData
    if (providerID && !isEqual(providerRef.current, providerData)) { // !false

      // update "provider" details
      apiFullCall(
        providerData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `provider/${providerID}/` 
      ).then(res => {

        if (res) {
          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {
            notify('post.notification.provider_is_updated', 'info', {
              smart_count: 1,
            });    
          } else if (status === 400) {
            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          }
          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );
    };

    // updating relevant branches
    if (providerLocationRef.current.length > 0) {

      // update "provider" details (and provider_location)
      provider_location.map((location, i: number) => {

        // NOTE: 
        // providerLocationRef.current[i] will differ (false) from "location"
        // if user changes the "branch name" or "type of branch (aka location_type in API fields)"
        
        // check for changes "branch name" or "type of branch" 
        // to map through "provider_location"
        if (!isEqual(providerLocationRef.current[i], location)) {  // !false

          // then only make API calls, per branch that has been changed
          return apiFullCall(
            { 
              branch_name: location['branch_name'], 
              location_type: location['location_type']
            }, 
            token, 
            'patch',  
            // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
            `providerbranch/${location['id']}/` 
          ).then(res => {

            if (res) {
              const { status } = res;

              setLoading(true);

              if (status === 200 || status === 201) {

                notify('post.notification.branch_is_updated', 'info', {
                  smart_count: 1,
                });      
              } else if (status === 400) {

                notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
              } else if (status >= 500) {

                notify(`Server error, please try again later.`, 'warning');          
              };

              setLoading(false);
            }
          }).catch(
            error => console.error('Error while updating:', error)
          );

        };

        // we check for a change in "village id"
        // meaning the user has changed the location
        if (providerLocationRef.current[i]['village_id'] !== idVillage) {

          apiFullCall(
            { village_id: idVillage }, 
            token, 
            'patch',  
            // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
            `providerbranch/${location['id']}/` 
          ).then(res => {

            if (res) {
              const { status } = res;

              setLoading(true);

              if (status === 200 || status === 201) {

                notify('post.notification.provider_location_updated', 'info', {
                  smart_count: 1,
                });      
              } else if (status === 400) {

                notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
              } else if (status >= 500) {

                notify(`Server error, please try again later.`, 'warning');          
              };
              
              setLoading(false);
            }
          }).catch(
            error => console.error('Error while updating:', error)
          );

        };

        return null;
      });
    };

    // only make API call to "/users" if user changes any fields
    // once any field changes; userRef.current !== userData
    if (branchUserID && !isEqual(userRef.current, userData)) { // !false 

      // update "user" details with "defined" data
      apiFullCall(
        sanitizedUserData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `users/${branchUserID}/` 
      ).then(res => {
        if (res) {
          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {

            notify('post.notification.branch_user_is_updated', 'info', {
              smart_count: 1,
            });    
          } else if (status === 400) {

            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );
    };

    // only make API call to "/program-service-providers" if user selects a new program
    // once a program is selected; programid !== undefined
    if (providerID && programid) {

      // create/add a new "program" to provider
      apiFullCall(
        { 'program': programid, 'provider': providerID}, 
        token, 
        'post',
        `program-service-providers/` 
      ).then(res => {
        if (res) {
          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {

            notify('Provider has been added to program', 'info');    
          } else if (status === 400) {

            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );
    };

    // only make API call to "/provider/:id" to update provider_class
    // if the user has been added to another category
    if (providerID && spAddedClass.length > 0) {

      // update "provider_class" details
      apiFullCall(
        { provider_class: spAddedClass }, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `provider/${providerID}/` 
      ).then(res => {

        if (res) {

          const { status } = res;
  
          setLoading(true);

          if (status === 200 || status === 201) {

            notify(`Provider has been addedd to a new category`, 'info', {
              smart_count: 1,
            });
             
          } else if (status === 400) {

            notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );

    };

    // reload page asap
    refresh();

  }, [providerData, userData, notify, refresh, basePath]); // eslint-disable-line react-hooks/exhaustive-deps

  return activeStep === steps.length - 1 ? (
    <>
      <BackButton handleBack={handleBack} />
      <SaveButton
        {...rest}
        label={loading ? "Saving" : "post.action.save"}
        submitOnEnter={false}
        saving={loading}
        onSave={handleUpdateProvider} 
      />
    </>
  ) : activeStep === steps.length - 2 ? (
    <>
      <div className={clsx('btn-group')}>
        <BackButton handleBack={handleBack} />
        <NavButton label="Next" onClick={handleNext} className={clsx('direction')}>
          <ArrowForwardIcon />
        </NavButton>
      </div>
      <SaveButton
        {...rest}
        label={loading ? "Saving" : "post.action.save"}
        submitOnEnter={false}
        saving={loading}
        onSave={handleUpdateProvider} 
      />
    </>
  ) : (
    <>
      <NavButton label="Next" onClick={handleNext} className={clsx('direction')}>
        <ArrowForwardIcon />
      </NavButton>
      <SaveButton
        {...rest}
        label={loading ? "Saving" : "post.action.save"}
        submitOnEnter={false}
        saving={loading}
        onSave={handleUpdateProvider} 
      />
    </>
  );
};
