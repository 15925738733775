import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  DateInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  Email,
  Needed,
  Text,
  phoneNumber,
} from '../../validation';

import { CooperativesFormAddress } from './CooperativesFormAddress';
import { AkbTextField, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

import { apiFullCall } from '../../apiHelper';

interface Style {
  details?: string;
  input?: string;
  check?: string;
  photo?: string;
  title?: string;
}

export const CooperativesFormBasic = (props: any) => {
  const classes: Style = userEditStyles();
  const token = sessionStorage.getItem('token');
  
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); //eslint-disable-line
  
  let isLoaded = React.useRef(true);
  const abortController = new AbortController();

  const AddGroupName = [...Text, ...Needed];
  const AddPrimaryContact = [...phoneNumber, ...Needed];
  
  const { handleChange, isRegistered, members, rafields } = props;
  
  const [ listServices, setListServices ] = React.useState([]);
 
  // populate the "group category" dropdown
  React.useEffect(() => {
    if (isLoaded) {
      apiFullCall(
        '', 
        token, 
        'get', 
        `cooperativeservices/` 
      ).then(res => {
        if (res) {
          const { status, body } = res;
          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, coop_service_name, short_name }) => {
                  const details = `${coop_service_name} (${short_name})`;

                  return ({ id: id, name: details })
                }
              );
              setListServices(data);
            }
          }
        }      
      }).catch(
        error => console.error('Error while fetching:', error)
      );      
    }

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };

  }, [isLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  let word = members && members > 1 ? 's' : '';

  const { type } = rafields;

  const ShowMemberCount = () => (
    // allow styling for modal-title
    <p className={clsx('details')}>
      <span className={clsx('intro')}>Basic &nbsp;</span>
      {!!type && <>(Type: {type}) </>}
      {!!type && members > 0 && <>({members} Member{word})</>}
    </p>
  );

  return (
    <Box display={{ md: 'flex' }}>
      <Box 
        flex={5}
        width={{ md: '100% !important' }} 
        className={clsx('AkRegisterForm-body')}
      >
        {!isRegistered && <SectionTitle label="Basic" />}
        {isRegistered && <SectionTitle label="" show={<ShowMemberCount />} />}
        <Box>
          {isRegistered && (
            <Box flex={2} mb={{ xs: '8.75px' }} className={clsx('address other')}>
              <AkbTextField
                value={rafields.cooperative_name}                  
                name="cooperative_name"
                label="Group Name"
                onChange={handleChange}
                className={classes.input}
              />
              <AkbTextField
                value={rafields.short_name}                  
                name="short_name"
                label="Short Name"
                onChange={handleChange}
                className={classes.input}
                validate={Text}
              />
              <SelectInput
                label="Change Group category"
                source="coop_service_id" 
                choices={listServices} 
                optionText="name"
                className={classes.input}
              />        
            </Box>
          )}
          {!isRegistered && (
            <>
              <TextInput
                label="Group Name"
                source="cooperative_name"
                className={classes.input}
                validate={AddGroupName}
              />         
              <TextInput
                source="short_name"
                className={classes.input}
                validate={Text}
              />            
              <SelectInput
                label="Select Group category"
                source="coop_service_id" 
                choices={listServices} 
                optionText="name"
                className={classes.input}
              />        
            </>
          )}
          {isRegistered &&        
            <TextInput
              label="Branch Name"
              source="cooperative_branch_name"
              className={classes.input}
            />
          }
          <TextInput
            source="monthly_income"
            className={classes.input}
          />
          <TextInput
            label="Group phone no."
            source="primary_contact"
            className={classes.input}
            validate={AddPrimaryContact}
          />
          <TextInput
            label="Other phone no."
            source="secondary_contact"
            className={classes.input}
            validate={phoneNumber}
          />
          <TextInput
            type="email"
            source="email"
            className={classes.input}
            validate={Email}
          />
          {isRegistered &&
            <>
              <DateInput
                disabled
                label="Date Added"
                source="date_added"
                className={classes.input}
              />
              <TextInput
                disabled
                source="reference_number"
                className={classes.input}
              /> 
            </>
          }
        </Box>
        <CooperativesFormAddress {...props} />
      </Box>
      <Box flex={1}></Box>             
    </Box>    
  );
};
