import React from 'react';
import {
  List,
  CardActions
} from 'react-admin';
import CommentGrid from './HomeCards';
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { apiFullCall } from '../../../modules/apiHelper';
import { useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';

import { serviceProvidersStyles } from '../../stylesheets';


const ServiceProvidersFilters = ({ programid, endDate, startDate, setDates, setSelectProgram, programs, agents, agent, setSelectAgent, regions, districts, setfuncDistrictValue, setDistricts, setSelectDistrictValue }) => {
  const classes = serviceProvidersStyles();

  return <form noValidate autoComplete="off" style={{ width: '100%', marginTop: '30px', marginBottom: '-30px' }}>
    <Autocomplete
      id="country-select-demo"
      style={{ width: 224, float: 'left', marginLeft: '10px' }}
      options={districts}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.districtname}
      onChange={(event, newValue) => {
        event.preventDefault();
        setDistricts(newValue ? [newValue, ...districts] : districts);
        setSelectDistrictValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setfuncDistrictValue(newInputValue);
      }}
      renderOption={(option) => (
        <React.Fragment>
          {option.districtname} ({option.region})
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search District"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
    <TextField id="outlined-basic" label="Start date"
      value={startDate}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        max: moment().format('YYYY-MM-DD')
      }}
      type="date" onChange={setDates} name="startDate" variant="outlined" style={{ float: 'left', marginLeft: '10px' }} />
    <TextField id="outlined-basic" label="End date"
      value={endDate}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        max: moment().format('YYYY-MM-DD')
      }}
      type="date" onChange={setDates} name="endDate" variant="outlined" style={{ float: 'left', marginLeft: '10px' }} />
    <FormControl variant="outlined" style={{ float: 'left', minWidth: 160, marginLeft: '10px' }}>
      <InputLabel id="demo-simple-select-outlined-label">Program</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={programid}
        onChange={setSelectProgram}
        label="Program"
      >
        <MenuItem value="none">
          <em>Select</em>
        </MenuItem>
        {programs.map((program, index) => <MenuItem key={index} value={program.id}>{program.short_name}</MenuItem>
        )}
      </Select>
    </FormControl>
    <FormControl variant="outlined" style={{ float: 'left', minWidth: 220, marginLeft: '10px' }}>
      <InputLabel id="demo-simple-select-outlined-label">Agent</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={agent}
        onChange={setSelectAgent}
        label="Agent"
      >
        <MenuItem value="none">
          <em>Select</em>
        </MenuItem>
        {agents.map((agent, index) => <MenuItem key={index} value={agent.id}>{agent.fullname}</MenuItem>
        )}
      </Select>
    </FormControl>
  </form>
}
const NoneActions = props => (
  <CardActions />
);
const ServiceProvidersList = ({ classes, ...props }) => {
  const [districts, setDistricts] = React.useState([]);
  const [districtid, setDistrict] = React.useState('none');
  const [agentid, setAgent] = React.useState('none');
  const [programid, setProgram] = React.useState('none');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [regions, setRegions] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [agents, setAgents] = React.useState([]);
  const notify = useNotify();

  const setSelectDistrictValue = (district) => {
    setDistrict(district ? district.districtid : 'none')
    if (district) {
      get_agent(`is_agent=${true}&districtid=${district.districtid}`)
    } else {
      get_agent(`is_agent=${true}`)
    }
  }

  const setSelectProgram = e => {
    setProgram(e.target.value ? e.target.value : 'none')
  }

  const setDates = e => {
    if (e.target.value) {
      if (e.target.name === "startDate") {
        setStartDate(e.target.value)
      }
      else {
        setEndDate(e.target.value)
      }
    }
  }
  const setSelectAgent = e => {
    setAgent(e.target.value ? e.target.value : 'none')
  }

  const setfuncDistrictValue = (newInputValue) => {

    if (newInputValue.length >= 3) {
      apiFullCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        `district/?search=${newInputValue}`
      ).then(res => {
        const { status, body } = res;

        if (status === 200) {
          setDistricts(body.results)
        } else {
          notify(`Experienced an error, please try again later.`, 'warning')
        };

      }).catch(
        error => console.error('Error while getting districts:', error)
      );
    }
  }

  const get_agent = (parameters) => {
    apiFullCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      `providerclassifications/?${parameters}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setAgents(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );
  }

  React.useEffect(() => {
    apiFullCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      `region/`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setRegions(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting regions:', error)
    );

    apiFullCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      `programs/`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setPrograms(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting programs:', error)
    );
    
    apiFullCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      `providerclassifications/?is_agent=${true}`
    ).then(res => {
      const { status, body } = res;

      if (status === 200) {
        setAgents(body.results)
      } else {
        notify(`Experienced an error, please try again later.`, 'warning')
      };

    }).catch(
      error => console.error('Error while getting agents:', error)
    );
  }, [notify]);

  return (
    <>
      <ServiceProvidersFilters endDate={endDate} startDate={startDate} setDates={setDates} programid={programid} setSelectProgram={setSelectProgram} agents={agents} agent={agentid} setSelectAgent={setSelectAgent} programs={programs} regions={regions} districts={districts} setfuncDistrictValue={setfuncDistrictValue} setDistricts={setDistricts} setSelectDistrictValue={setSelectDistrictValue} />
      <List title="Service Providers Classification" filterDefaultValues={{ filter_1: districtid, filter_2: programid, filter_3: agentid, filter_4: startDate ? startDate : 'none', filter_5: endDate ? endDate : 'none' }} {...props} pagination={false} actions={<NoneActions />}>
        <CommentGrid {...props} filters={{filter_1: districtid, filter_2: programid, filter_3: agentid, filter_4: startDate ? startDate : 'none', filter_5: endDate ? endDate : 'none' }}/>
      </List>
      {(districtid !== 'none' || programid !== 'none' || agentid !== 'none' || startDate !== '' || endDate !== '') &&
        <Paper>
          <div style={{ textAlign: 'center' }}>
            <p><i>Applied filters: <span><b>{`${districtid !== 'none' ? `District ,` : ``} ${programid !== 'none' ? `Program ,` : ``} ${agentid !== 'none' ? `Agent ,` : ``} ${startDate !== '' ? `Start date ,` : ``} ${endDate !== '' ? `End date` : ``}`}</b></span></i></p>
          </div>
        </Paper>
      }
    </>
  );
};

export default ServiceProvidersList;