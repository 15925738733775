import React from 'react';
import clsx from 'clsx';
import {
	Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
	CardMedia,
	makeStyles,
  Typography,
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import ProImage from '../../../assets/akb_pro.jpg';

const mediaUrl = `${ProImage}`;
const maxSmWidth = 599.98; // sm (down)

const useStyles = makeStyles(theme => ({
  media: {
		height: '60vh',
		backgroundPosition: 'inherit',
  },
  provider: {
    margin: '1em auto',
    width: '94%',
    height: '90vh',
  },
  button: {
    marginTop: '12px',
    fontSize: '0.95rem',
    color: '#3f51b5',
    backgroundColor: '#fff',
    padding: '6px 12px',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset',
    '&:hover, &:focus, &:active': {
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#df1f26',
      boxShadow: 'none',
    },
  },
  farmerButton: {
    marginRight: '12px',
  },
  cta: {
    display: 'block',
    marginTop: '-280px',
		color: '#fff',
		fontWeight: '900',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  CardActions: {
    [`@media (max-width: ${maxSmWidth}px)`]: {
      display: 'block',
      textAlign: 'center',
    },
  },
}));

export const SmallProDashboard = props => {
  const { 
  	firstName, 
  	lastName, 
  	translate, 
  	Link
	} = props;

	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

	return (
		<>
			<Card className={classes.provider}>
				<CardMedia image={mediaUrl} className={classes.media} />
				<CardContent>
					<Typography variant="h6" component="h6" gutterBottom>
						{translate('pos.dashboard.provider.title')} {firstName}&nbsp;{lastName}
					</Typography>
					<Typography component="p" variant="body2">
						{translate('pos.dashboard.provider.subtitle')}
					</Typography>
				</CardContent>
				<CardActions 
						style={{ justifyContent: 'space-evenly' }}
						className={classes.CardActions}
					>
						<Typography variant="h6" component="h6" className={classes.cta}>
							{translate('pos.dashboard.agent.cta')}
						</Typography>
						<Button
							// farmer/user registration
							size="small" 
							component={Link} 
							to="/farmers/create"
							className={clsx(classes.button, classes.farmerButton)}
							onClick={handleToggle}
						>
							{translate('pos.dashboard.agent.farmer_button')}
						</Button>
						<Button 
							// provider registration
							size="small" 
							component={Link} 
							to="/provider/create"
							className={clsx(classes.button)}
							onClick={handleToggle}
						>
							{translate('pos.dashboard.agent.provider_button')}
						</Button>
					</CardActions>
			</Card>
			<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
