import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  Filter,
  SearchInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceField,
} from 'react-admin';

import {
  Divider,
  useMediaQuery, 
  Tabs, 
  Tab,
  Theme,
} from '@material-ui/core';

import CooperativesLinkField from './CooperativesLinkField';
import { ActionButton, ListActions } from '../../layout'; // eslint-disable-line
import { userDatagridStyles } from '../stylesheets';

interface CooperativeProps {
  isXSmall?: boolean;
  classes: { heading: any, hiddenOnSmallScreens: any };
  exporter?: any; 
  filterValues: { status: string };
  handleOpen?: boolean;
  hasCreate?: boolean;
  hasList?: boolean; 
  hasEdit?: boolean;
  hasShow?: boolean;
  total?: number;
  totalPages?: number;
  setFilters: (data: { new?: boolean, status?: string }) => void;
};

// eslint-disable-next-line
const CooperativeFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <TextInput
        label="Coop Name"
        source="cooperative_name"
        defaultValue="DFCU"
      />
      <ReferenceInput
        label="CoopService"
        source="coop_service_id"
        reference="cooperativeservices"
        sort={{ field: 'id', order: 'ASC' }}
      >
        <SelectInput optionText="coop_service_name" />
      </ReferenceInput>
    </Filter>
  );
};

class TabbedDatagrid extends React.Component<CooperativeProps> {
  /**
   * @Returns Users
   * All users, Service providers and farmers
   */
  tabs = [
    // { id: 'is_farmer', name: 'FARMERS' },
    { id: 'cooperative_branch_name', name: 'ALL' },
  ];

  state = { 
    cooperative_branch_name: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props; // eslint-disable-line
    value === 'new'
      ? setFilters({ new: true, status: value })
      : setFilters({ status: value });
  };

  render() {
    // eslint-disable-next-line
    const {
      isXSmall, 
      classes,
      exporter,
      filterValues, 
      handleOpen,
      hasCreate,
      hasList, 
      hasEdit, 
      hasShow,
      total,
      totalPages,
      ...props
    } = this.props;

    // Utilize this within component to tap into "state" changes
    // eslint-disable-next-line
    const ActionGroupMenu = (basePath = '', record = { id: ''}) => ([
      {label: 'Update details', path: `${basePath}/${record['id']}`},
      // {label: 'Add to program', path: `${basePath}/${record['id']}`},
    ]);

    return (
      <>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === 'cooperative_branch_name' && (
            <Datagrid
              {...props}
              optimized
              ids={this.state.cooperative_branch_name}
              classes={{ headerCell: classes.heading }}
            >
              <CooperativesLinkField />
              <TextField
                label="Ref. &#x2116;" 
                source="reference_number"
                cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}
              />
              <DateField 
                showTime 
                label="Registered" 
                source="date_added" 
                cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}
              />
              <TextField
                label="Primary Contact" 
                source="primary_contact"
                cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}
              />
              <ReferenceField
                link={false}
                label="Village"
                source="village_id"
                reference="locationviewsearch"
                cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}
              >
                <TextField source="villagename" />
              </ReferenceField>
              <ReferenceField
                link={false}
                label="Parish"
                source="village_id"
                reference="locationviewsearch"
                cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}
              >
                <TextField source="parishname" />
              </ReferenceField>
              <ReferenceField
                link={false}
                label="District"
                source="village_id"
                reference="locationviewsearch"
              >
                <TextField source="districtname" />
              </ReferenceField>
              <ActionButton MenuOptions={ActionGroupMenu} {...props} />
            </Datagrid>
          )}
        </div>
      </>
    );
  }
};

const StyledTabbedDatagrid = props => {
  const classes = userDatagridStyles();
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return <TabbedDatagrid classes={classes} isXSmall={isXSmall} {...props} />;
};

const CooperativesBranchesList = ({ classes, permissions, ...props }) => {
  const location = useLocation();

  return permissions !== undefined ? (
    permissions.includes('superuser') || permissions.includes('provider') ? (
      <List
        {...props}
        filterDefaultValues={{ status: "cooperative_branch_name" }}
        actions={<ListActions label='Register a Group' />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={10}
        title="Groups"
      >
        <StyledTabbedDatagrid />
      </List>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

export default CooperativesBranchesList;
