import { makeStyles } from '@material-ui/core/styles';

export const serviceProvidersStyles = makeStyles(theme => ({
    cardStyle: {
        width: 300,
        minHeight: 200,
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top',
        cursor: 'pointer'
    },
    div1: {
        margin: '1em',
        textAlign: 'center'
    },
    cardHeaderTitle: {
        fontSize: '14px'
    },
    cardContent: {
        textAlign: 'center',
        marginTop: '-24px'
    },
    providersCount: {
        fontSize: '24px',
        fontWeight: 'normal',
        marginLeft: '-22px'
    },
    div2: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-14px'
    },
    div3: {
        float: 'left'
    },
    maleCount: {
        fontSize: '20px',
        fontWeight: 'normal'
    },
    hr: {
        float: 'left', width: '1px', marginInlineStart: '5px', marginInlineEnd: '5px', height: '66px', backgroundColor: 'red', marginTop: '20px'
    },
    div4: {
        float: 'left'
    },
    femaleCount: {
        fontSize: '20px', fontWeight: 'normal'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    cardHeaderTitle2: {
        fontSize: '22px'
    },
    farmersCount: {
        fontSize: '40px',
        fontWeight: 'normal',
        marginLeft: '-22px'
    },
    cardContent2: {
        textAlign: 'center',
        marginTop: '-8px'
    },
    subHeaderTitle2:{
        fontSize: '1.3rem'
    }
}));