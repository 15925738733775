import React, { useCallback } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  SearchInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import SubCategoryCreate from './SubCategoryCreate';
import SubCategoryEdit from './SubCategoryEdit';
import { ActionButton, ListActions } from '../../layout';
import LinkToRelatedServices from './LinkToRelatedServices';
import { drawerStyles } from '../stylesheets';

const SubCategoryFilter = props => (
  <Filter {...props}>
    <SearchInput source="category_name" />
    <ReferenceInput
      label="Category"
      source="categoryid"
      reference="categories"
      filter={{ category: props.filterValues.categoryid }}
      sort={{ field: 'sub_category_name', order: 'ASC' }}
    >
      <SelectInput optionText="category_name" />
    </ReferenceInput>
  </Filter>
);

const SubCategoryList = ({ permissions, ...props }) => {
  const classes = drawerStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClose = useCallback(() => {
    history.push('/subcategories');
  }, [history]);

  return permissions !== undefined ? (
    permissions.includes('superuser') ? (
      <>
        <List
          {...props}
          actions={<ListActions label='Add Subcategory' />}
          filters={<SubCategoryFilter />}
          perPage={10}
          sort={{ field: 'sub_category_name', order: 'ASC' }}
        >
          <Datagrid>
            <TextField label="Category Name" source="sub_category_name" />
            <LinkToRelatedServices />
            <ActionButton MenuOptions={ActionSubcategoryMenu} {...props} />
          </Datagrid>
        </List>
        <Route path="/subcategories/create">
          {({ match }) => (
            <>
              <Drawer open={!!match} anchor="right" onClose={handleClose}>
                <SubCategoryCreate
                  className={classes.drawerContent}
                  onCancel={handleClose}
                  {...props}
                />
              </Drawer>
            </>
          )}
        </Route>
        <Route path="/subcategories/:id">
          {({ match }) => {
            let isMatch = match && match.params && match.params.id !== 'create';

            return (
              <>
                <Drawer open={!!isMatch} anchor="right" onClose={handleClose}>
                  {/* To avoid any errors if the route does not match,
                   * we don't render at all the component in this case
                   */}
                  {isMatch ? (
                    <SubCategoryEdit
                      className={classes.drawerContent}
                      id={isMatch && match !== null ? match.params.id : null}
                      onCancel={handleClose}
                      {...props}
                    />
                  ) : (
                    <div className={classes.drawerContent} />
                  )}
                </Drawer>
              </>
            );
          }}
        </Route>
      </>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    )
  ) : null;
};

const ActionSubcategoryMenu = (basePath = '', record = { id: ''}) => ([
  {label: 'Update', path: `${basePath}/${record.id}/edit`},
]);

export default SubCategoryList;
