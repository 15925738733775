import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import clsx from 'clsx';
import { Menu, MenuItem, makeStyles, useMediaQuery } from '@material-ui/core';
import { ArrowDropDownOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    fontWeight: 398,
    minHeight: '34px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

export const ActionButton = props => {  
  const { 
    basePath = '',
    label = 'Action',
    icon = defaultIcon,
    MenuOptions = [],
    source,
    record,
    children,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    // <List> props
    currentSort,
    defaultTitle,
    displayedFilters,
    hasBulkActions,
    hideFilter,
    loaded,
    loading,
    onToggleItem,
    onUnselectItems,
    perPage,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    ...rest
  } = props;

  let List = MenuOptions(basePath, record);

  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  const handleClickButton = useCallback(
    event => {      
      event.preventDefault(); // This prevents ghost click.
      setOpen(true);
      anchorEl.current = event.currentTarget;
    },
    [anchorEl, setOpen]
  );

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return record ? (
    <div className={classes.root}>
      <Button
        onClick={handleClickButton}
        label={label}
        {...rest}
      >
        {icon}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleRequestClose}
        className={clsx(
          'AkAction--popup',
          {
            'action--menu': isXSmall,
          }
        )}
      >
        {List.map((item, i) =>
          <MenuItem
            key={i}
            component={Link}
            to={{ pathname: item.path, search: item.search }}
            onClick={() => {
              if (typeof(item.handleClickEvent) === 'function') {
                // pass "record" as parameter
                // received in ProviderRequestList to pass back unique record
                item.handleClickEvent(record);
              };
              return setOpen(false);
            }}
            className={classes.menuItem}
          >
            {item.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  ) : null;
};

const defaultIcon = <ArrowDropDownOutlined />;

ActionButton.defaultProps = {
  label: 'Action',
};