import ProviderIcon from '@material-ui/icons/PeopleOutline';

import ProviderList from './ProviderList';
import ProviderCreate from './ProviderCreate';
import ProviderEdit from './ProviderEdit';

export default {
  list: ProviderList,
  create: ProviderCreate,
  edit: ProviderEdit,
  icon: ProviderIcon,
};
