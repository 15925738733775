import CategoryIcon from '@material-ui/icons/Bookmark';

import CategoryList from './CategoryList';

export default {
  list: CategoryList,
  create: CategoryList,
  edit: CategoryList,
  icon: CategoryIcon,
};
