import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  login: {
    auth: {
      phone: 'Phone number',
      password: 'Password',
    },
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      main_title: 'Site Overview',
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      all_requests: 'All Requests',
      pending_approval: 'Requests Pending Approval',
      services_offered: 'Services Offered',
      pending_orders: 'Pending Orders',
      new_sacco_member: 'New Sacco Members',
      all_sacco_members: 'All Sacco Members',
      order: {
        items: '%{service_name}, qty - %{nb_items} by %{customer_name}',
        requestNo: 'No. %{request_number} on %{request_date}',
      },
      pending_provider_requests: {
        notice:
          '%{smart_count} provider service, needs approval |||| %{smart_count} provider services, need approval',
      },
      newSaccoUsers: {
        notice:
          'you have %{smart_count}  new sacco users |||| you have %{smart_count} new sacco users',
      },
      provider: {
        title: 'Hi,',
        subtitle:
          // 'This is your admin center as a Service Provider. Feel free to add or update the services that you offer, and check on any requests that need your response.',
          "We are working together to help increase farm production and productivity, as you boost your income too! Click a button to sign-up (register) a farmer or service provider.",
        aor_button: 'Requests',
        demo_button: 'My Services',
      },
      agent: {
        title: 'Hello,',
        cta: "Sign-up or Register",
        subtitle:
          "We're supporting local farmers to increase their farm production and productivity. Click a button to sign-up (register) a farmer or service provider.",
        farmer_button: 'farmer',
        provider_button: 'Provider',
      },
      welcome: {
        title: 'Welcome to AkelloBanker',
        subtitle:
          'This is your admin center as a Service Provider. Feel free to add or update the services that you offer, and check on any requests that need your response.',
        aor_button: 'Requests',
        demo_button: 'My Services',
      },
      farmers: {
        count: 'FARMER |||| FARMERS',
      },
      providers: {
        count: 'SERVICE PROVIDER |||| SERVICE PROVIDERS',
      },
      users: {
        count: 'APP USER |||| APP USERS',
      },
    },
    menu: {
      systemfarmerrequest: 'Farmer Request |||| Farmer Requests',
      providerrequest: 'Provider Request |||| Provider Requests',
      saccorequest: 'Credit Request |||| Credit Requests',
      providerservices: 'My Service |||| My Services',
      requests: {
        name: 'Requests',
        sacco: 'Credit',
        provider: 'Services',
      },
      services: {
        name: 'Services',
        all: 'All Services',
        subcategories: 'Subcategories',
        categories: 'Categories',
        groupservices: "Member |||| Members'",
        systemproviderservices: "Provider |||| Providers'",
        saccoservices: 'Sacco Services',
      },
      providers: 'Service Provider |||| Service Providers',
      users: {
        name: 'Users',
        farmers: 'Farmer |||| Farmers',
        providers: 'Service Provider |||| Service Providers',
        saccousers: 'Sacco User |||| Sacco Users',
        groups: 'Group |||| Groups',
      },
      reports: {
        name: 'Reports',
        providers: 'Service Provider |||| Service Providers',
        farmers: 'Farmers |||| Farmers',
      },
      page: {
        delete: 'Delete Customer',
      },
      sharedservices: 'Shared Services |||| Shared Services',
      cooperatives: 'Cooperatives',
      cooperativebranches: 'Cooperativebranches',
      prorams: {
        name: 'Support Programs',
        managefunders: 'Manage Funders',
        manageprograms: 'Manage Programs',
      },
      dashboard: {
        name: 'Dashboard Settings'
      },
    },
  },
  resources: {
    users: {
      name: 'Farmers',
    },
    provider: {
      name: 'Service Provider |||| Service Providers',
      fields: {
        create_title: 'Service Provider Registration',
        edit_title: 'Service Provider Update',
      },
      fieldGroups: {
        create: 'Please complete the form',
        edit: 'Please update the relevant fields',
      },
    },
    services: {
      name: 'Service |||| Services',
      fields: {
        sub_category_id: 'Subcategory',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      fieldGroups: {
        measure: 'Measure',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        subcategories: 'Subcategories',
      },
    },
    subcategories: {
      name: 'Subcategory |||| Subcategories',
      fields: {
        services: 'Services',
      },
    },
    cooperatives: {
      name: 'Cooperative |||| Sub Clusters',
      identityCreated: 'Create',
      identityEdit: 'Update',
      fields: {
        id: 'Cooperative',
        cooperatives: 'View List',
        added_by: 'Created By',
        date_added: 'Date Created',
        cooperative_name: 'Cooperative name',
        is_active: 'true',
        short_name: 'Short Name',
        coop_service_id: 'Cooperative Service',
        create_title: 'Group Registration',
        edit_title: 'Group Update',
      },
    },
    cooperativebranches: {
      name: 'cooperativebranches |||| Branches',
      identity: 'Create Branch',
      fields: {
        id: '1',
        date_added: 'date added',
        branch_added_by: 'Branch Added By',
        cooperative_branch_name: 'All Women Development Group',
        is_active: 'is_active',
        is_main_branch: 'main branch',
        reference_number: 'refence number',
        monthly_income: 'monthly income',
        physical_address: 'physical address',
        photo: 'photo',
        signiture: 'signature',
        email: 'email',
        primary_contact: 'primary contact',
        box_number: 'box number',
        secondary_contact: 'secondary contact',
        additional_info: 'additional Info',
        cooperative_id: 'cooperative',
        village_id: 'village',
      },
    },
    cooperativebranchusers: {
      name: 'User |||| Sacco Users',
    },
    providerrequests: {
      name: 'Requests',
    },
    systemfarmerrequest: {
      name: 'Request |||| Requests',
      amount: '1 order |||| %{smart_count} orders',
      title: 'Request %{reference}',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          total: 'Total (UGX)',
          unit_price: 'Unit Price (UGX)',
        },
        user_id: 'User',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
      },
    },
    sharedservices: {
      name: 'Shared Services',
      identityCreated: 'Create Shared Service',
      identityEdit: 'Update Shared Service',
      fields: {
        id: 'CoopService',
        added_by: 'Created by',
        date_added: 'date of CoopServices',
        coop_service_name: 'CoopService Name',
        short_name: 'Short Name',
        description: 'CoopService Description',
        is_active: 'true',
      },
    },
    providerservice: {
      name: 'Service |||| Services',
      fields: {
        unit_cost: 'Unit Cost (UGX)',
      },
      fieldGroups: {
        terms: 'Terms of Trade',
      },
      status: {
        name: 'Status',
        approved: 'Approved',
        pending: 'Not yet approved',
        denied: 'Denied',
      },
    },
    customers: {
      name: 'customer |||| customers',
      fields: {
        requests: 'Requests',
        first_seen: 'First seen',
        groups: 'Segments',
        last_seen: 'Last seen',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent',
        password: 'Password',
        confirm_password: 'Confirm password',
      },
      fieldGroups: {
        identity: 'Basic Info',
        home_address: 'Home',
        work_address: 'Work',
        other_info: 'Other',
        relations: 'Relations',
        crops: 'Crops',
        animals: 'Animals',
        services: 'Offered Services',
        question: 'I operate as',
        qualified: 'Certification',
        more: 'Other Requirements',
      },
      page: {
        delete: 'Delete Customer',
      },
      errors: {
        username: 'Please add a Valid Phone number',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'User',
        request_id: 'Request',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'User',
        request_id: 'Request',
        service_id: 'Service',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
    segments: {
      name: 'Segments',
      fields: {
        customers: 'Users',
        name: 'Name',
      },
      data: {
        farmer: 'Farmer',
        provider: 'S/Provider',
        admin: 'Admin',
        regular: 'Regular',
      },
    },
  },
  post: {
    action: {
      save: 'save',
      save_and_continue: 'Save and Continue',
      save_and_exit: 'Save and Exit',
      submit: 'submit',
    },
    notification: {
      created_user: 'User has been created',
      updated_user: 'User details have been updated',
      include_address: 'Please enter an address',
      location_add: "User's location has been added",
      provider_is_approved: 'Submitted for approval. Thank you.',
      provider_is_created: 'Service Provider has been registered.',
      provider_is_updated: 'Service Provider details have been updated.',
      provider_also_updated: 'And Service Provider details have been updated.',
      branch_user_is_updated: 'Branch user details have been updated.',
      branch_is_updated: 'Branch details have been updated.',
      provider_location_updated: 'Provider location have been updated.',
    },
  },
};
