import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import format from 'date-fns/format'

import {
  DateInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRefresh,
  useNotify,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { ApproveButton } from '../../layout';
import { Transition, SectionTitle } from '../utils';

import { userEditStyles } from '../stylesheets';
import { apiFullCall } from '../apiHelper';

export const RequestAcceptForm = props => {
  const classes = userEditStyles();

  const { isXSmall, acceptanceDialog, handleCloseAcceptDialog, ...rest } = props;
  
  return (
    <Dialog
      maxWidth="xs"
      open={acceptanceDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="accept-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="accept-dialog-title"
      >
        <SectionTitle label="Accept this request" />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx(
            'request',
            props.className
          )}
          submitOnEnter={false} 
          toolbar={
            <AcceptRequestToolbar
              isXSmall={isXSmall}
              handleCloseAcceptDialog={handleCloseAcceptDialog}
              {...rest}
            />
          }
        >
          <DateInput
            label="Date to deliver"
            source="provider_visit_date"
            className={classes.input}
          />
          <TextInput
            label="Add a comment"
            source="reason"
            className={classes.input}
            validate={[required('Please add a comment')]}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

// Handling "create" mode
const AcceptRequestToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleCloseAcceptDialog, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleCloseAcceptDialog}
      />
      <AcceptButton {...rest} /> 
    </Toolbar>
  )  
};

const AcceptButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();
  
  // destructure to pass "rest" to <SaveButton>
  const { handleCloseAcceptDialog, onClose, record, ...rest } = props;

  // destructure "date" and "reason" to pass to API call
  const { provider_visit_date, reason } = formState && formState.values;

  const token = sessionStorage.getItem('token');
  
  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  const acceptData = {
    request_id: record && record['id'],
    provider_visit_date: provider_visit_date && format(new Date(provider_visit_date), 'dd-MM-yyyy'),
    reason: reason,
    status: 'accepted',
  };

  const handleAcceptance = React.useCallback(() => {

    // Double-check to ensure that "date", "reason" and "id" are defined or not empty
    if (!!provider_visit_date && !!reason && !!record['id']) {

      apiFullCall(
        acceptData, 
        token, 
        'post', 
        `providerrequests/` 
      ).then(res => {
        if (res) {
          setLoading(!loading);
          const { status } = res;

          if (status === 200 || status === 201) {

            // when successfully submitted
            notify('The request has been scheduled for delivery', 'info');

            // go back to requests list
            handleCloseAcceptDialog();
            onClose();
            refresh();
            
          } else if (status === 400) {
            notify(`Please check again and resend.`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };
        };

      }).catch(
        error => console.error('Error while sending:', error)
      );

    } else {

      // inform user to enter a "date" and/or "comment"
      notify(`Please select a delivery date and/or add a comment`, 'warning');
    }

  }, [acceptData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleAcceptance} 
    />
  );
};
