import React from 'react';
import { Route } from 'react-router-dom';
import {
  CreateOrEditSaccoUser,
  Funders,
  ManageSaccoServices,
  // Programs,
  SharedServices,
  providerslistdetails,
  DashboardSettings
} from './modules';

export default [
  <Route exact path="/cooperativebranchusers/edit" component={CreateOrEditSaccoUser}/>,
  <Route exact path="/saccoservices" component={ManageSaccoServices} />,
  <Route exact path="/funders" component={Funders} />,
  <Route exact path="/dashboardsettings" component={DashboardSettings} />,
  // <Route exact path="/programs" component={Programs} />,
  <Route path="/sharedservices" component={SharedServices} />,
  <Route exact path="/serviceprovidersview" component={providerslistdetails} />,
];

/**
 * Notes on programs
 * 
 * To use the "programs" endpoint within <Admin> API like <ReferenceInput>, 
 * we need to register it as a <Resource name="programs">
 * 
 * This was clushing with the <Route path="programs"> witin here.
 * Because, from within user list "Add to program", once clicked,
 * it would display the programs list (as implemented by Micheal)
 * 
 * To hit the market, we intended to route that click event to a modal
 * with a simple select to "Add a user to a program".
 * 
 * We'll to refactor so that the click event leads to Micheal's "programs" module
 * And all operations are then carried out there.
 */
