import React, { useState } from 'react';
import { 
  Dialog, 
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { CreateOrEditSaccoUser } from './CreateOrEditUser';
import { requestStyles } from '../../stylesheets';

export default function SearchUserDialog({ close, ...props }) {
  const [open, setOpen] = useState(true);

  const classes = requestStyles();

  const closeAddNewUser = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={closeAddNewUser}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeAddNewUser}
          className={classes.userDialog}
        >
          Add SACCO User
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeAddNewUser}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <CreateOrEditSaccoUser/>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
