import React from 'react';
import {
  DateField,
  EditButton,
  NumberField,
  TextField,
  BooleanField,
  useTranslate,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import UserReferenceField from '../users/UserReferenceField';

import { requestStyles } from '../stylesheets';

const MobileGrid = ({ ids, data, basePath }) => {
  const translate = useTranslate();
  const classes = requestStyles();

  return (
    <div style={{ margin: '1em' }}>
      {ids.map(id => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <span>
                  {translate('resources.systemfarmerrequest.name', 1)}
                  :&nbsp;
                  <TextField record={data[id]} source="reference" />
                </span>
                <EditButton
                  resource="systemfarmerrequest"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <span className={classes.cardContentRow}>
              {translate('resources.customers.name', 1)}:&nbsp;
              <UserReferenceField record={data[id]} basePath={basePath} />
            </span>
            <span className={classes.cardContentRow}>
              {translate('resources.reviews.fields.date')}:&nbsp;
              <DateField record={data[id]} source="date" showTime />
            </span>
            <span className={classes.cardContentRow}>
              {translate('resources.systemfarmerrequest.fields.basket.total')}
              :&nbsp;
              <NumberField
                record={data[id]}
                source="total"
                options={{ style: 'currency', currency: 'UGX' }}
                className={classes.total}
              />
            </span>
            <span className={classes.cardContentRow}>
              {translate('resources.systemfarmerrequest.fields.status')}
              :&nbsp;
              <TextField source="status" record={data[id]} />
            </span>
            <span className={classes.cardContentRow}>
              {translate('resources.systemfarmerrequest.fields.returned')}
              :&nbsp;
              <BooleanField record={data[id]} source="returned" />
            </span>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
