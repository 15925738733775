import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Create } from 'react-admin';

import { UserFormBasic } from './form';

const UserCreate = (props: any) => {
  const history = useHistory();

  const { basePath, hasCreate, hasEdit, hasList, hasShow, permissions, ...rest } = props;
  
  let superuser = permissions && permissions.includes('superuser'),
       provider = permissions && permissions.includes('provider'),
          agent = permissions && permissions.includes('agent');

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return permissions !== undefined ? (
    (superuser || provider || agent) ? (
      <Create title="Farmer Registration" {...props}>
        <UserFormBasic onCancel={handleClose} {...rest} />
      </Create>
    ) : (
      <Redirect to="/" />
    )
  ) : null;
};

export default UserCreate;
