import React from 'react';
import { useHistory } from 'react-router-dom'
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { TextField } from 'react-admin';
import PersonIcon from '@material-ui/icons/PeopleOutline';
import Avatar from '@material-ui/core/Avatar';
import { serviceProvidersStyles } from '../../stylesheets';


const CommentGrid = ({ ids, data, filters }) => {
	const history = useHistory();
	const classes = serviceProvidersStyles();
	const viewDetails = (classification) => {

		sessionStorage.setItem('_providers_filter', JSON.stringify(filters))
		sessionStorage.setItem('_providers_classification', JSON.stringify(classification))
		history.push({ pathname: '/serviceprovidersview', state: { classification: classification, filters: filters } });
	};

	return <div className={classes.div1}>
		{ids.map(id =>
			<Card raised={false} key={id} className={classes.cardStyle} onClick={() => { viewDetails(data[id]) }}>
				<CardHeader
					title={<TextField className={classes.cardHeaderTitle} record={data[id]} source="name" />}
					subheader={<TextField record={data[id]} source="short_name" />}
					avatar={<Avatar icon={<PersonIcon />} />}
				/>
				<CardContent className={classes.cardContent}>
					<TextField className={classes.providersCount} record={data[id]} source="providers_count" />
					<div className={classes.div2}>
						<div className={classes.div3}>
							<h3>Male</h3>
							<TextField className={classes.maleCount} record={data[id]} source="male" />
						</div>
						<hr className={classes.hr}></hr>
						<div className={classes.div4}>
							<h3>Female</h3>
							<TextField className={classes.femaleCount} record={data[id]} source="female" />
						</div>
					</div>
				</CardContent>
			</Card>
		)}
	</div>
}
CommentGrid.defaultProps = {
	data: {},
	ids: [],
};

export default CommentGrid;