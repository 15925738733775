import React, { useState, useEffect, useCallback } from 'react';
import {
  useDataProvider,
  usePermissions,
  useTranslate,
  useVersion,
} from 'react-admin';

import { Theme, useMediaQuery } from '@material-ui/core';

import { SuperUserDashboard } from '../modules';
import AgentDashboard from './agent';
import ProviderDashboard from './provider';
import SaccoAdminDashboard from './admin';
import { UpdatesBar } from '../layout';

interface CountStats {
  users?: number;
  farmers?: number;
  providers?: number;
  sumfarmerRequests?: number;
  unapprovedProviderRequests?: number;
  newSaccoUsers?: number;
  anyUpdates?: boolean;
}

export const Dashboard = (props: any) => {
  /**
   * @returns: Stats of app members and requests
   */
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const version = useVersion();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Rely on the DOM API to clean side effects
  const abortController = new AbortController();
  const signal = abortController.signal;

  let superuser = permissions && permissions.includes('superuser'),
       provider = permissions && permissions.includes('provider'),
          agent = permissions && permissions.includes('agent'),
          admin = permissions && permissions.includes('admin');

  const [state, setState] = useState<CountStats>({
    users: 0,
    farmers: 0,
    providers: 0,
    sumfarmerRequests: 0,
    unapprovedProviderRequests: 0,
    newSaccoUsers: 0,
    anyUpdates: false,
  });

  const getUserStats = useCallback(async () => {
    const { data: members, total: all } = await dataProvider.getList(
      'users',
      {
        // Required params but no need to populate them,
        // since we'll just manupilate the user count from this call
        filter: {},
        sort: {},
        pagination: { page: 1, perPage: 50 },
      },
      { signal: signal }
    );

    const allFarmers = members.filter(member => member.is_farmer === true);
    const allProviders = members.filter(member => member.is_provider === true);

    setState(state => ({
      ...state,
      users: all,
      farmers: allFarmers && allFarmers.length,
      providers: allProviders && allProviders.length,
    }));
  }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPendingProviderRequests = useCallback(async () => { // eslint-disable-line
    const {
      data: allUnapproved, // eslint-disable-line
      total: countPendingProviderRequests,
    } = await dataProvider.getList(
      'providerservice', // formerly /systemproviderservices
      {
        filter: { approved: false },
        sort: {},
        pagination: { page: 1, perPage: 50 },
      },
      { signal: signal }
    );

    let display =
      countPendingProviderRequests && countPendingProviderRequests !== 0
        ? true
        : false;

    setState(state => ({
      ...state,
      unapprovedProviderRequests: countPendingProviderRequests,
      anyUpdates: display,
    }));
  }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNewSaccoUsers = useCallback(async () => {
    const {
      data: allUnapproved, // eslint-disable-line
      total: countNewSaccoUsers,
    } = await dataProvider.getList(
      'cooperativebranchusers',
      {
        filter: { approved: false },
        sort: {},
        pagination: { page: 1, perPage: 50 },
      },
      { signal: signal }
    );

    let display = countNewSaccoUsers && countNewSaccoUsers !== 0 ? true : false;

    setState(state => ({
      ...state,
      newSaccoUsers: countNewSaccoUsers,
      anyUpdates: display,
    }));
  }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function checkMyRights() {
      if (permissions !== undefined) {
        if (superuser) {
          getUserStats();
        }
        if (admin) {
          getNewSaccoUsers();
        }
      }
    }

    checkMyRights();

    return function cleanup() {
      abortController.abort();
    };
  }, [version, permissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    users,
    farmers,
    providers,
    unapprovedProviderRequests,
    newSaccoUsers,
    anyUpdates,
  } = state;

  return permissions !== undefined ? (
    superuser ? (
      isSmall ? (
        <AgentDashboard {...props} />
      ) : (
        <>
          <UpdatesBar
            link="/providerservice" // formerly /systemproviderservices
            show={anyUpdates}
            cardMessage={translate(
              'pos.dashboard.pending_provider_requests.notice',
              {
                smart_count: unapprovedProviderRequests,
              }
            )}
          />
          <SuperUserDashboard
            {...props}
            users={users}
            farmers={farmers}
            providers={providers}
          />
        </>
      )     
    ) : (provider || agent) ? (
      <ProviderDashboard {...props} />
    ) : admin ? (
      <>
        <UpdatesBar
          link="/cooperativebranchusers/?approved=false"
          show={anyUpdates}
          cardMessage={translate('pos.dashboard.newSaccoUsers.notice', {
            smart_count: newSaccoUsers,
          })}
        />
        <SaccoAdminDashboard {...props} />
      </>
    ) : null
  ) : null;
};
