import { makeStyles } from '@material-ui/core/styles';

const allSaccoMembersStyle = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },

  mainallrequests: {
    flex: '1',
    marginLeft: '1em',
    marginTop: 20,
  },

  flex: { display: 'flex' },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  leftCol: { flex: 1, marginRight: '1em' },

  rightCol: { flex: 1, marginLeft: '1em' },

  rightCol2: { flex: 1, marginLeft: '1em' },

  singleCol: {
    marginTop: '2em',
    marginBottom: '2em',
  },

  media: {
    height: '18em',
  },

  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },

  graphContainer: {
    padding: '15px',
  },

  value: {
    padding: '0 16px',
    minHeight: 48,
  },

  root: {
    margin: 0,
  },

  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },

  title: {
    padding: '16px 16px',
  },

  table: {
    minWidth: 650,
  },
}));

export default allSaccoMembersStyle;
