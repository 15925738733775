import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import apiCall from '../../apiHelper';

import Check from '@material-ui/icons/Check';
import RoomIcon from '@material-ui/icons/Room';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';

import AddNewUserBasic from './newUserBasic'
import AddNewUserAddress from './newUserAddress'

const token = sessionStorage.getItem('token');

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  // Whether this step is active.
  active: PropTypes.bool,
  // Mark the step as completed. Is passed to child components.
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <CalendarViewDayIcon />,
    2: <RoomIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  // Whether this step is active.
  active: PropTypes.bool,
  // Mark the step as completed. Is passed to child components.
  completed: PropTypes.bool,
  // The label displayed in the step icon.
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: "#fff",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completion: {
    margin: '0 auto',
  }
}));

function getSteps() {
  return ['Basic Info', 'Address Details'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddNewUserBasic />
    case 1:
      return <AddNewUserAddress />
    default:
      return 'Unknown step';
  }
}

export function CreateOrEditSaccoUser(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if(getFormData(0)){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getUserRegKeys = () => {
    let keys = {
      0: [
        'user_id',
        'first_name',
        'middle_name',
        'last_name',
        'username',
        'secondary_phone_number',
        'nin',
        'email',
        'secondary_email',
        'dob',
        'gender',
        'user_type',
        'income_status',
        'marital_status',
        'occupation',
        'profile_photo'
      ],
      1: [
        'villageid',
        'village',
        'parish',
        'subcounty',
        'county',
        'district',
        'region',
        'nationality'
      ]
    };
    return keys;
  };

  const getFormData = (index) => {
    let details = {};
    let keys = getUserRegKeys()[index];
    for (let i = 0; i < keys.length; i++) {
      details[keys[i]] = document.getElementById(keys[i]).value;
    }
    if (index === 0) {
      if(details['first_name'].length < 1){
        document.getElementById('first_name').focus();
        return false;
      }
      if(details['last_name'].length < 1){
        document.getElementById('last_name').focus();
        return false;
      }
      if(details['username'].length < 1){
        document.getElementById('username').focus();
        return false;
      }
      sessionStorage.setItem("user_details", JSON.stringify(details));
    } else if (index === 1) {
      if(details['villageid'].length < 1){
        document.getElementById('village').focus();
        return false;
      }
      sessionStorage.setItem("user_address", JSON.stringify(details));
    }

    return true;
  };

  const saveUser = () => {
    if( getFormData(1) ){
      let userBasicDetails = JSON.parse(sessionStorage.getItem("user_details"));
      if( userBasicDetails['user_id'].length > 0 ){
        saveUserSacco(userBasicDetails['user_id']);
        sessionStorage.removeItem('user_details');
        sessionStorage.removeItem('user_address');
        handleReset();
      }else{
        let userAddressDetails = JSON.parse(sessionStorage.getItem("user_address"));
        let data = userBasicDetails;
        data['nationality'] = userAddressDetails['nationality'];
        data['nationality'] = userAddressDetails['nationality'];
        data['is_farmer'] = data['user_type'] === 'farmer';
        data['is_provider'] = data['user_type'] === 'provider';
        apiCall(data, token, 'post', 'users/').then((response) => {
          saveUserAdress( response.id, userAddressDetails['villageid']);
          saveUserSacco(response.id);
          sessionStorage.removeItem('user_details');
          sessionStorage.removeItem('user_address');
          handleReset();
        }).catch(error => error)
      }
    }
  };

  const saveUserAdress = (userid, villageid) =>{
    let data = {
      is_active: true,
      village_id: villageid,
      user_id: userid
    };
    apiCall(data, token, 'post', 'usersaddress/').then((response) => {
      // Process response
    }).catch(error => error)
  };

  const saveUserSacco = (userid) =>{
    let data = {
      is_active: true,
      approve:true,
      role: 'member',
      user_id: userid
    };
    apiCall(data, token, 'post', 'cooperativebranchusers/').then((response) => {
      // Process response
    }).catch(error => error)
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ paddingLeft: "4%", paddingBottom: "10%" }}>
        <fieldSet style={{ marginRight: '1.5em', marginBottom: '.5em', marginTop: '.5em', border: '1px solid red' }}>
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div style={{ float: "right" }}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
                  </Button>
              {activeStep === steps.length - 1 ?
                (<Button
                  variant="contained"
                  color="primary"
                  onClick={saveUser}
                  className={classes.button}
                >
                  Save User
                </Button>) :
                (<Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Next
                </Button>)
              }
            </div>
          </div>
        </fieldSet>
      </div>
    </div>
  );
}
