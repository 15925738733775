import React, { FC } from 'react';
import clsx from 'clsx';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
}  from '@material-ui/core';

import { TextField } from 'react-admin';

import EditIcon from '@material-ui/icons/Edit';
import { ActionButton } from '../../layout';
import { PureRequestDateField, PureDeliveryDateField, PureConfirmedDateField } from './RequestDates';

import { Identifier } from 'ra-core';
import { Customer } from '../../types';

const useStyles = makeStyles(theme => ({
  root: { margin: '1em' },
  card: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  ids?: Identifier[];
  data?: { [key: string]: Customer };
  filterValues?: { [key: string]: string };
  options?: object[];
}

const MobileGrid: FC<Props> = ({ ids, data, filterValues, options }) => {
  const classes = useStyles();

  if (!ids || !data || !filterValues) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id, index) => (
        <Card key={id} className={clsx(classes.card, 'farmer-request--card')}>
          <CardHeader
            className={clsx('request-list--card')}
            title={
              <Box className={classes.cardTitleContent}>
                <div>
                  <span><b>{index+1}. Request No&#58;&nbsp;</b></span>
                  <TextField
                    record={data[id]} 
                    source="request_number"
                  />
                </div>
                <ActionButton
                  icon={<EditIcon />}
                  record={data[id]} 
                  MenuOptions={options}
                />
              </Box>
            }
          />
          <CardContent className={classes.cardContent}>
            {filterValues.status === 'approved' && (
              <Box>
                <Typography variant="h5" component="div" >
                  <span>Requested On&#58;&nbsp;</span>
                  <PureRequestDateField record={data[id]} />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Amount (USh)&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="total"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Farmer Name&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="farmer.farmer_full_name"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Village&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.villagename"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Parish&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.parishname"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>District&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.districtname"
                  />
                </Typography>
              </Box>
            )}
            {filterValues.status === 'accepted' && (
              <Box>
                <Typography variant="h5" component="div" >
                  <span>Planned delivery&#58;&nbsp;</span>
                  <PureDeliveryDateField record={data[id]} />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Amount (USh)&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="total"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Farmer Name&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="farmer.farmer_full_name"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Farmer Contact&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="farmer.farmer_username"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Village&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.villagename"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Parish&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.parishname"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>District&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.districtname"
                  />
                </Typography>
              </Box>
            )}
            {filterValues.status === 'delivered' && (
              <Box>
                <Typography variant="h5" component="div" >
                  <span>Requested On&#58;&nbsp;</span>
                  <PureRequestDateField record={data[id]} />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Planned delivery&#58;&nbsp;</span>
                  <PureDeliveryDateField record={data[id]} />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Delivered On&#58;&nbsp;</span>
                  <PureConfirmedDateField record={data[id]} />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Amount (USh)&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="total"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Farmer Name&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="farmer.farmer_full_name"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Farmer Contact&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="farmer.farmer_username"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Village&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.villagename"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>Parish&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.parishname"
                  />
                </Typography>
                <Typography variant="h5" component="div" >
                  <span>District&#58;&nbsp;</span>
                  <TextField
                    record={data[id]} 
                    source="location.districtname"
                  />
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  filterValues: {},
  ids: [],
  options: [],
};

export default MobileGrid;
