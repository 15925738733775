import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import ProviderAcionList from './CustomField';
import { apiFullCall } from '../../../modules/apiHelper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useNotify } from 'react-admin';

const useStyles = makeStyles({
	root: {
		width: '100%',
		marginTop: '4px'
	},
	container: {
		maxHeight: 540,
	},
});

const ProvidersListDetails = props => {

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [classification, setClassification] = React.useState({}); // eslint-disable-line
	const [filters, setfilters] = React.useState({}); // eslint-disable-line
	const [providers, setProviders] = React.useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [loading, setLoading] = React.useState(true);
	const [load, setLoader] = React.useState(true);
	const notify = useNotify();

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const updateState = () => {
		setLoading(false)
	};

	useEffect(() => {
		var class_f = {};
		var filter = {};

		if (props.location.state) {
			class_f = props.location.state.classification
			filter = props.location.state.filters

			setClassification(props.location.state.classification)
			setfilters(props.location.state.filters)
		}	else {
			class_f = JSON.parse(sessionStorage.getItem('_providers_classification'))
			filter = JSON.parse(sessionStorage.getItem('_providers_filter'))

			setProviders(JSON.parse(sessionStorage.getItem('_providers_classification')))
			setfilters(JSON.parse(sessionStorage.getItem('_providers_filter')))
		};

		apiFullCall(
			'',
			sessionStorage.getItem('token'),
			'get',
			`classification-providers/?filter_1=${filter.filter_1}&filter_2=${filter.filter_2}&filter_3=${filter.filter_3}&filter_4=${filter.filter_4}&filter_5=${filter.filter_5}&class_id=${class_f.id}`
		).then(res => {
			const { status, body } = res;

			if (status === 200) {
				setLoader(false)
				setProviders(body.results)

				const timer = setTimeout(() => {
					updateState()
				}, 5000);
				return () => clearTimeout(timer);

			} else {
				notify(`Experienced an error, please try again later.`, 'warning')
			};

		}).catch(
			error => console.error('Error while getting programs:', error)
		);

	}, [notify, props.location.state]);

	return (
		<Paper className={classes.root}>
			<div style={{ textAlign: 'center' }}><h3>{props.location.state ? props.location.state.classification.name : JSON.parse(sessionStorage.getItem('_providers_classification'))["name"]}</h3></div>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell
								align={'left'}
								style={{ minWidth: '10' }}
							>
								check all
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '100' }}
							>
								No
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								Business Name
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '80' }}
							>
								Short Name
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								Primary Contact
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '50' }}
							>
								Approved
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '50' }}
							>
								Active
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								Village
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								Subcounty
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								District
                            </TableCell>
							<TableCell
								align={'left'}
								style={{ minWidth: '170' }}
							>
								Manage
                            </TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{providers.length > 0 && providers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((provider, index) => {
							return (
								<TableRow hover role="checkbox" tabIndex={-1} key={index}>
									<TableCell align={'left'}>
										{index + 1}
									</TableCell>
									<TableCell align={'left'}>
										{index + 1}
									</TableCell>
									<TableCell align={'left'}>
										{provider.business_name}
									</TableCell>
									<TableCell align={'left'}>
										{provider.shortname}
									</TableCell>
									<TableCell align={'left'}>
										{provider.primary_contact}
									</TableCell>
									<TableCell align={'left'}>
										{provider.is_approved === true ? `Yes` : `No`}
									</TableCell>
									<TableCell align={'left'}>
										{provider.is_active === true ? `Yes` : `No`}
									</TableCell>
									<TableCell align={'left'}>
										{loading ? <LinearProgress color="primary" /> : provider.location.villagename}
									</TableCell>
									<TableCell align={'left'}>
										{loading ? <LinearProgress color="primary" /> : provider.location.subcountyname}
									</TableCell>
									<TableCell align={'left'} >
										{loading ? <LinearProgress color="primary" /> : provider.location.districtname}
									</TableCell>
									<TableCell align={'center'} >
										<ProviderAcionList {...provider} />
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{!load &&
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={providers.length > 0 ? providers.length : 0}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			}
			{load &&
				<div style={{ textAlign: 'center', padding: '20px' }}><CircularProgress color="primary" /></div>
			}
		</Paper>
	);
}

export default ProvidersListDetails;