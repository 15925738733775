import {
  required,
  minLength,
  maxLength,
  regex,
  email,
  choices,
} from 'react-admin';

/* Generic validation */

const Needed = [required()];

const Text = [
  // ^               // start of line
  // [a-zA-Z]{2,}    // will expect a name with at least two characters
  // \s              // will look for white space between name and surname
  // [a-zA-Z]{1,}    // needs at least 1 Character
  // \'?-?           // possibility of **'** or **-** for double barreled and hyphenated surnames
  // [a-zA-Z]{2,}    // will expect a name with at least two characters
  // \s?             // possibility of another whitespace
  // ([a-zA-Z]{1,})? // possibility of a second surname
  regex(
    /^[a-zA-Z]{2,}\s?([a-zA-z]{1,})?'?-?([a-zA-Z]{2,})?\s?([a-zA-Z]{1,})?/,
    'Please enter "letters" only'
  ),
];

const boxNumber = [
  regex(
    /^[0-9]{1,},?\s?,?-?\s?[a-zA-z]{2,}/,
    'Please enter correct box address'
  ),  
];

const Precise = [
  regex(/^\d*\.?\d*$/, 'Please enter numbers only'), 
  ...Needed
];

const Truthy = [
  { id: 'Y', name: 'Yes' },
  { id: 'N', name: 'No' },
];

/* Linked to User Create/Registration */

const firstName = [...Text, ...Needed];

const lastName = [...Text, ...Needed];

const phoneNumber = [minLength(10, 'Enter 10 digits')];

const Email = [
  email('Please enter a valid email'),
];

const Gender = [
  choices(['M', 'F', 'O'], 'Must be one of the choices'),
  ...Needed,
];

const Sex = [
  { id: 'M', name: 'Male' },
  { id: 'F', name: 'Female' },
  { id: 'O', name: 'Other' },
];

const Passwords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ['resources.customers.errors.password_mismatch'];
  }
  return errors;
};

const grantAccess = ({ username, is_admin, is_farmer, is_provider }) => {
  const errors = {};
  // const reg = /^[0-9]*$/g;

  if (
    (is_admin === true && username === '') ||
    (is_admin === true && username === undefined)
  ) {
    errors.username = ['resources.customers.errors.username'];
  }

  if (
    (is_farmer === true && username === '') ||
    (is_farmer === true && username === undefined)
  ) {
    errors.username = ['resources.customers.errors.username'];
  }

  if (
    (is_provider === true && username === '') ||
    (is_provider === true && username === undefined)
  ) {
    errors.username = ['resources.customers.errors.username'];
  }

  return errors;
};

const NIN = [
  maxLength(14, 'NIN is incorrect'),
  regex(/^[A-Z0-9]+$/, 'Please enter a valid NIN'),
];

const Passport = [
  maxLength(8, 'Passport number is incorrect'),
  regex(/^[A-Z]{1,}[0-9]{7,}$/, 'Please enter a valid Passport number.'),
];

/* Used within Provider Services */

const serviceType = [
  choices(['Buy', 'Hire'], 'Must be one of the choices'),
  ...Needed,
];

const serviceTypeChoices = [
  { id: 'Buy', name: 'For Sale' },
  { id: 'Hire', name: 'For Hire' },
];

const weightUnits = [
  { id: 'mg', name: 'milligram(s)' },
  { id: 'g', name: 'gram(s)' },
  { id: 'kg', name: 'kilogram(s)' },
  { id: 'tonne', name: 'tonne(s)' },
  { id: 'person', name: 'person' },
  { id: 'tractor', name: 'tractor' },
];

/* Used within Provider Approval Processs */

const idType = [
  { id: 'n', name: 'National ID' },
  { id: 'p', name: 'Passport' },
];

const providerType = [
  { id: 'individual', name: 'Individual' },
  { id: 'company', name: 'Company' },
];

const TIN = [
  maxLength(10, 'TIN is incorrect'),
  regex(/^[0-9]+$/, 'Please enter a valid TIN'),
];

const certAgency = [
  { id: 'agrinvest', name: 'AGRINVEST' },
  { id: '6', name: 'Other' },
];

const providerCategory = [
  { id: 'ip', name: 'Input Producer' },
  { id: 'i', name: 'Importer' },
  { id: 's', name: 'Supplier' },
  { id: 'd', name: 'Distributor' },
];

/* Other validation, to be refactored */

const nationality = [
  { id: 'Uganda', name: 'Ugandan' },
  { id: 'Other', name: 'Non-Ugandan' },
];

const users = [
  { id: '1', name: 'Farmer' },
  { id: '2', name: 'Service Provider' },
  { id: '3', name: 'SACCO Admin' },
  { id: '4', name: 'Other' },
];

const workType = [
  { id: '1', name: 'Contract' },
  { id: '2', name: 'Full-time' },
  { id: '3', name: 'Part-time' },
  { id: '4', name: 'Other' },
];

const incomeStatus = [
  { id: '1', name: 'Below 499,000' },
  { id: '2', name: '500,000-1,499,000' },
  { id: '3', name: '1,500,000-2,499,000' },
  { id: '4', name: 'Above 2,500,000' },
];

const maritalStatus = [
  { id: '1', name: 'Married' },
  { id: '2', name: 'Single' },
  { id: '3', name: 'Divorced' },
  { id: '4', name: 'Widow/Widower' },
  { id: '5', name: 'Other' },
];

const landNature = [
  { id: 1, name: 'Hired' },
  { id: 2, name: 'Private' },
  { id: 3, name: 'Community' },
  { id: 4, name: 'Family' },
  { id: 5, name: 'Other' },
];

const cropsPlanted = [
  { id: 1, name: 'Cassava' },
  { id: 2, name: 'Coffeee' },
  { id: 3, name: 'Maize' },
  { id: 4, name: 'Rice' },
  { id: 5, name: 'Other' },
];

const keptAnimals = [
  { id: 1, name: 'Chicken' },
  { id: 2, name: 'Cows' },
  { id: 3, name: 'Goats' },
  { id: 4, name: 'Pigs' },
  { id: 5, name: 'Sheep' },
  { id: 7, name: 'Other' },
];

export {
  /* Mainly for User Create/Edit */
  Text,
  boxNumber,
  Needed,
  Precise,
  Truthy,
  firstName,
  lastName,
  phoneNumber,
  Email,
  Gender,
  Sex,
  NIN,
  Passport,
  grantAccess,
  /* Mainly for Provider Services */
  serviceType,
  serviceTypeChoices,
  weightUnits, 
  /* Mainly for Provider Approval */
  idType, 
  providerType,     
  TIN,
  certAgency,
  providerCategory,
  /* TODO: Refactor */
  nationality,
  users,
  Passwords,
  workType,
  incomeStatus,
  maritalStatus,
  landNature,
  cropsPlanted,
  keptAnimals,
};
