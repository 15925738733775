import React from 'react';
import clsx from 'clsx';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import {
  BooleanInput,
  DateInput,
  Link,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  firstName,
  Text,
  lastName,
  Needed,
  NIN,
  Sex,
  Gender,
  Email,
  phoneNumber,
} from '../../validation';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SearchExistingUserForm } from './SearchExistingUser';
import { SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

const maxSmWidth = 599.98; // sm (down)

interface Style {
  heading?: string;
  input?: string;
  check?: string;
  photo?: string;
  root?: string;
  secondaryHeading?: string;
  title?: string;
  groupSection?: string;
}

interface User {
  first_name?: string;
  last_name?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      flexBasis: '33.33%',
      fontWeight: 'normal',
      textAlign: 'left',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      [`@media (max-width: ${maxSmWidth}px)`]: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '100%',
        fontWeight: 500,
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

export const CooperativesFormPersonnel = props => {
  const classes: Style = userEditStyles();
  const classesBase: Style = useStyles();

  const [state, setState] = React.useState<User[]>();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => 
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      handleOtherChange();
  };

  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleOtherChange = () => setCollapsed(!collapsed);
  
  // destructure "basePath", remove from DOM element (div, line 80)
  const { admins, isRegistered, isSmall, filterExistingUser, record } = props;

  const { total, adminData, adminUsers } = admins && admins;

  // used to "de-require" fields, if we search existing-user
  const { id } = filterExistingUser && filterExistingUser;

  let isLoaded = React.useRef(true);
  const abortController = new AbortController();
  
  const bool = clsx(classes.input, classes.check);
  const mainContact = [...Needed, ...phoneNumber];
  const role = [...Needed, ...Text];

  React.useEffect(() => {
    if (isLoaded || state) {
      setState(adminUsers);
    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false;
      // Rely on the DOM API to clean side effects
      abortController.abort();
    };
  }, [isLoaded, state, adminUsers, abortController]);

  let word = total && total > 1 ? 's' : '';

  const ShowAdminCount = () => (
    // allow styling for modal-title
    <p className={clsx('details')}>
      <span className={clsx('intro')}>
        {record && record.cooperative_branch_name}{word} &nbsp;
      </span>
      {total > 0 && <>(has {total} admin{word})</>}
    </p>
  );

  return (
    <Box
      display={{ md: 'flex' }}
      flexDirection={{ xs: 'column' }} // create vs edit mode
      className={clsx( classesBase.root, 'AkRegisterForm-body')}
    >
      {isRegistered && (
        <Box>
          {/* edit-mode */}
          <Box display={{ sm: 'block' }}>
            {!isRegistered && <SectionTitle label="Personnel" />}
            {isRegistered && <SectionTitle label="" show={<ShowAdminCount />} />}
          </Box>
          {state && adminData && 
            Array.isArray(adminData) && // check if "details" is an array before map()
              adminData.map((user, i) => {
                const { role, user_id } = user;
                let panel: string = `panel${i+1}`;
    
                  return (
                    <ExpansionPanel
                      key={i}
                      expanded={expanded === panel} 
                      onChange={handleChange(panel)}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classesBase.heading}>
                          {role && <>{i+1}. ({role})</>}
                        </Typography>
                        <Typography className={classesBase.secondaryHeading}>
                          {state[i] && state[i]["first_name"]} {state[i] && state[i]["last_name"]}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Contact:&nbsp;{state[i] && state[i]["username"]},&nbsp;<br/>
                          Registered as:&nbsp;
                          <span className={clsx('user-type')}>
                            {state[i] && state[i]["is_farmer"] && state[i]["is_farmer"] === true && "farmer"}
                            {state[i] && state[i]["is_provider"] && state[i]["is_provider"] !== false && "Provider"},&nbsp;
                          </span><br/>
                          (<Link className={clsx('user--link')} to={`/farmers/${user_id}`}>Edit user details</Link>)
                        </Typography>                   
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )
              })
          }
        </Box>
      )}
      {!isRegistered && (
        // responsiveness
        typeof(isSmall) === 'boolean' && isSmall ? (
          <Box
            display={{ xs: 'flex' }}
            flexDirection={{ xs: 'column' }}
          >
            <Box className={clsx('add-managers')}>
              <Box>
                <Typography gutterBottom className={classesBase.title}>
                  Either
                </Typography>
              </Box>           
              <ExpansionPanel
                expanded={expanded === 'panel1'} 
                onChange={handleChange('panel1')}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1gm-content"
                  id="panel1gm-header"
                >
                  <Typography className={classesBase.heading}>
                    Add new user
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {/* create-mode */}
                  <Box flex={1}>
                    <TextInput
                      resettable
                      source="first_name"
                      className={classes.input}
                      validate={!!id ? null : firstName}
                    />
                    <TextInput
                      source="middle_name"
                      className={classes.input}
                      validate={Text}
                    />
                    <TextInput
                      source="last_name"
                      className={classes.input}
                      validate={!!id ? null : lastName}
                      />
                    <DateInput
                      label="Date of Birth" 
                      source="dob" 
                      className={classes.input} 
                      validate={!!id ? null : Needed}
                    />
                    <SelectInput
                      source="gender"
                      choices={Sex}
                      className={classes.input}
                      validate={!!id ? null : Gender}
                    />
                    <TextInput
                      label="Email"
                      type="email"
                      source="email"
                      className={classes.input}
                      validate={Email}
                    />
                    <TextInput
                      resettable
                      label="Phone contact (Main)"
                      source="username"
                      className={classes.input}
                      validate={!!id ? null : mainContact}
                    />
                    <TextInput
                      label="Phone contact (Other)"
                      source="secondary_phone_number"
                      className={classes.input}
                      validate={phoneNumber}
                    />
                    <TextInput
                      label="NIN"
                      source="nin"
                      className={classes.input}
                      validate={NIN}
                    />
                    {!isRegistered &&
                      <Box className={clsx('AkRegisterForm-role')}>
                        <SectionTitle label="Register as:" />            
                      </Box>
                    }
                    {isRegistered &&
                      <Box className={clsx('AkRegisterForm-role')}>
                        <SectionTitle label="Registered as:" />            
                      </Box>
                    }
                    {!isRegistered && <BooleanInput 
                      label="Farmer"
                      defaultValue={false}
                      source="is_farmer" 
                      className={clsx(bool, 'check')} 
                    />}        
                    {!isRegistered && <BooleanInput 
                      label="Service Provider"
                      defaultValue={false}
                      source="is_provider" 
                      className={clsx(bool, 'check')}  
                    />} 
                  </Box>              
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Box mt={{ xs: '10px' }} mb={{ xs: '10px' }}>
                <Typography className={classesBase.title}>
                  Or
                </Typography>
              </Box>
              <ExpansionPanel
                expanded={expanded === 'panel2'} 
                onChange={handleChange('panel2')}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2gm-content"
                  id="panel2gm-header"
                >
                  <Typography className={classesBase.heading}>
                    Add a registered user
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box flex={1}>
                    {/* gets these props...
                      * - idDistrict
                      * - filterExistingUser
                      * - downshiftOnChangeUser;
                      * to enable existing-user search
                      */}
                    <SearchExistingUserForm {...props} />
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
            <Box display={{ xs: 'flex' }} className={clsx('add-role')}>
              <Box flex={1}>
                <Box>
                  <Typography gutterBottom className={classesBase.title}>
                    And
                  </Typography>
                </Box>  
                <ExpansionPanel
                  expanded={collapsed === false} 
                  onChange={handleOtherChange}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3gm-content"
                    id="panel3gm-header"
                  >
                    <Typography className={classesBase.heading}>
                      Add user's role
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <TextInput
                      label="Group role"
                      source="role"
                      className={classes.input}
                      validate={role}
                    />
                    <BooleanInput 
                      label="Group Admin" 
                      source="is_admin"
                      defaultValue={true}
                      className={clsx('hidden')} 
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box display={{ xs: 'flex' }}>
              {/* create-mode */}
              <Box flex={2}>
                <Box display={{ sm: 'block' }}>
                  <SectionTitle label="Add new user" />
                </Box>
                <TextInput
                  resettable
                  source="first_name"
                  className={classes.input}
                  validate={!!id ? null : firstName}
                />
                <TextInput
                  source="middle_name"
                  className={classes.input}
                  validate={Text}
                />
                <TextInput
                  source="last_name"
                  className={classes.input}
                  validate={!!id ? null : lastName}
                  />
                <DateInput
                  label="Date of Birth" 
                  source="dob" 
                  className={classes.input} 
                  validate={!!id ? null : Needed}
                />
                <SelectInput
                  source="gender"
                  choices={Sex}
                  className={classes.input}
                  validate={!!id ? null : Gender}
                />
                <TextInput
                  label="Email"
                  type="email"
                  source="email"
                  className={classes.input}
                  validate={Email}
                />
                <TextInput
                  resettable
                  label="Phone contact (Main)"
                  source="username"
                  className={classes.input}
                  validate={!!id ? null : mainContact}
                />
                <TextInput
                  label="Phone contact (Other)"
                  source="secondary_phone_number"
                  className={classes.input}
                  validate={phoneNumber}
                />
                <TextInput
                  label="NIN"
                  source="nin"
                  className={classes.input}
                  validate={NIN}
                />
                <Box display={{ xs: 'block' }}>
                  {!isRegistered &&
                    <Box className={clsx('AkRegisterForm-role')}>
                      <SectionTitle label="Register as:" />            
                    </Box>
                  }
                  {isRegistered &&
                    <Box className={clsx('AkRegisterForm-role')}>
                      <SectionTitle label="Registered as:" />            
                    </Box>
                  }
                  {!isRegistered && <BooleanInput 
                    label="Farmer"
                    defaultValue={false}
                    source="is_farmer" 
                    className={clsx(bool, 'check')} 
                  />}        
                  {!isRegistered && <BooleanInput 
                    label="Service Provider"
                    defaultValue={false}
                    source="is_provider" 
                    className={clsx(bool, 'check')}  
                  />}
                </Box>
              </Box>
              <Box flex={1}>
                {/* gets these props...
                  * - idDistrict
                  * - filterExistingUser
                  * - downshiftOnChangeUser;
                  * to enable existing-user search
                  */}
                <Box display={{ sm: 'block' }}>
                  {isRegistered ? (
                    <SectionTitle label="Add more registered users" />
                    ):(
                    <SectionTitle label="Add a registered user" />
                  )}
                </Box>
                <SearchExistingUserForm {...props} />
              </Box>
            </Box>
            <Box display={{ xs: 'flex' }}>
              <Box flex={6}>
                <Box 
                  display={{ sm: 'block' }}
                  mt={{ md: '30px' }}
                  className={clsx('form--subsection')}
                >
                  <SectionTitle label="Add user's role" />
                </Box>
                <TextInput
                  label="Group role"
                  source="role"
                  className={classes.input}
                  validate={role}
                />
                <BooleanInput 
                  label="Group Admin" 
                  source="is_admin"
                  defaultValue={true}
                  className={clsx('hidden')} 
                />
              </Box>
              <Box flex={1}></Box>
            </Box>
          </>          
        )
      )}
    </Box>
  );
};
