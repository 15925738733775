import React, { FC } from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import FullNameField from './FullNameField';
import { FieldProps, User } from '../../types';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

const UserLinkField: FC<FieldProps<User>> = props => {
  const classes = useStyles();

  return props.record ? (
    <Link to={`/farmers/${props.record.id}`} className={classes.root}>
      <FullNameField {...props} />
    </Link>
  ) : null;
};

UserLinkField.defaultProps = {
  source: 'id',
  label: 'resources.customers.fields.name',
};

export default UserLinkField;
