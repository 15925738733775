import React, { FC } from 'react';
import { ReferenceField, TextField } from 'react-admin';

import { FieldProps } from '../../types';

const ProviderReferenceField: FC<FieldProps> = (props) => (
	<ReferenceField source="id" reference="provider" {...props}>
		<TextField source="business_name" />
	</ReferenceField>
);

ProviderReferenceField.defaultProps = {
	source: 'providerid',
	addLabel: true,
	label: 'Service Provider'
};

export default ProviderReferenceField;
