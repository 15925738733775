import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import { isEqual } from 'lodash-es';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, MenuItem, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  DateInput,
  ImageInput,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin';
import {
  firstName,
  grantAccess,
  Text,
  lastName,
  Needed,
  NIN,
  Sex,
  Gender,
  Email,
  phoneNumber,
} from '../../validation';
import { AkbInput, AkbTextField, filterObject, Separator, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

import { apiFullCall } from '../../apiHelper';

interface Style {
  input?: string;
  check?: string;
  container?: string;
  details?: string;
  photo?: string;
}

export const UserFormBasic = ({ onCancel, ...props }) => {
  const classes: Style = userEditStyles();
  const token = sessionStorage.getItem('token');

  // get the "system role"
  const [systemRole, setSystemRole] = React.useState<string | number | undefined>('');

  // destructure "basePath", remove from DOM element (div, line 80)
  const {
    agent,
    superuser,
    // related to group management (edit-mode)
    state,
    userRole,
    filterValue,
    setUserRole,
    branchDetails,
    inputOnChangeCooperative, 
    downshiftOnChangeCooperative,
    ...rest
  } = props;

  const { basePath, className, match, record } = props;
  
  let isRegistered: boolean = !(match && match.path === `${basePath}/create`);

  let isSmall: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const mainContact = [ ...Needed, ...phoneNumber ];


  /* related to program list */


  let isLoaded = React.useRef(true);
  const [listPrograms, setListPrograms] = React.useState([]);

  React.useEffect(() => {

    if (isLoaded && record['id']) {

      apiFullCall(
        '',
        token,
        'get',
        `programs/?user_id=${record['id']}`
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {
            const { results } = body;

            if (results.length > 0) {
              let data = results.map(
                ({ id, short_name }) => ({ id: id, name: short_name })
              );

              setListPrograms(data);
            };

          };

        };

      }).catch(
        error => console.error('Error while saving:', error)
      );
    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded, record['id']]) // eslint-disable-line react-hooks/exhaustive-deps


  /* trigger "system role" fetch */


  React.useEffect(() => {

    // check if we're in "create mode" (no user-id yet)
    if ( isLoaded && !record['id'] ) {

      // query for farmer (role) system-id
      apiFullCall(
        '', 
        token, 
        'get',
        `systemroles/?key=farmer` 
      ).then(res => {

        if (res) {
          const { status, body } = res;

          if (status === 200 || status === 201) {

            // get the system "id" for farmer (role)
            let role = body && body.results && body.results[0] && body.results[0]['id'];
            setSystemRole(role);
          };

        };

      }).catch(
        error => console.error('Error while saving:', error)
      );
    };

    // clean up API call, on unmount
    return function cleanup() { isLoaded.current = false; }

  }, [isLoaded, record['id']]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SimpleForm
      {...rest}
      className={clsx(
        className,
        'user-registration',
        {
          'register-form': !isRegistered,
          'basic-form': isRegistered,
        }        
      )}
      submitOnEnter={false}
      validate={grantAccess}
      toolbar={
        isRegistered ? (
          <UserEditToolbar 
            {...rest} 
            coopBranchId={branchDetails && branchDetails['id']}
            coopBranchName={branchDetails && branchDetails['cooperative_branch_name']}
          /> // edit-mode
        ) : (
          <UserCreateToolbar
            {...rest}
            newUserRole={systemRole}
          /> // create-mode
        )
      }
    >
      {!isRegistered &&
        <Box display={{ xs: 'inline-flex' }} width={{ xs: '100% !important' }} mb={{ xs: '10px' }}>
          <Box flex={1}>
            <SectionTitle label="resources.customers.fieldGroups.identity" className={clsx('AkRegisterForm-title')} />
          </Box>
          <Box>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Separator />
        </Box>
      }
      <Box
        display={{ md: 'flex' }}
        className={clsx('AkRegisterForm-body')}
      >
        <Box flex={2}>
          <Box>
            <TextInput
              autoFocus={!isRegistered ? true : false}
              source="first_name"
              className={classes.input}
              validate={firstName}
            />
            <TextInput
              source="middle_name"
              className={classes.input}
              validate={Text}
            />
            <TextInput
              source="last_name"
              className={classes.input}
              validate={lastName}
            />
            <DateInput
              label="Date of Birth"
              source="dob"
              className={classes.input}
              validate={Needed}
            />
            <SelectInput
              source="gender"
              choices={Sex}
              className={classes.input}
              validate={Gender}
            />
            <TextInput
              label="Email"
              type="email"
              source="email"
              className={classes.input}
              validate={Email}
            />
            <TextInput
              label="Phone contact (Main)"
              source="username"
              className={classes.input}
              validate={mainContact}
            />
            <TextInput
              label="Phone contact (Other)"
              source="secondary_phone_number"
              className={classes.input}
              validate={phoneNumber}
            />
            <TextInput
              label="NIN"
              source="nin"
              className={classes.input}
              validate={NIN}
            />
          </Box>
          <Box mb={{ xs: '18px' }}>
            {record && record.user_system_role &&
              <TextInput
                label="Registered as"
                source="user_system_role[0][key]"
                className={clsx(classes.input, 'capitalize')}
              />
            }
          </Box>
          {(superuser || agent) &&
            <>
              <SectionTitle label="Add to Group" />
              <Box
                display={{ md: 'flex' }}
                mb={{ xs: '20px' }}
                width={{ md: '100% !important' }}
                className={clsx('add-to-group', 'AkRegisterForm-body')}
              >
                <Box flex={3}>              
                  <Downshift
                    onChange={downshiftOnChangeCooperative}
                    itemToString={item => (item ? item.cooperative_branch_name : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div
                        className={clsx(
                          classes.container,
                          { 'stretch': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-cooperative',
                          label: 'Search by Group',
                          InputProps: getInputProps({
                            onChange: inputOnChangeCooperative,
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-group')}>
                            {getVillageSuggestions(state.groupList, inputValue).map(
                              (item, index) => {
                                const { cooperative_branch_name } = item;

                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(cooperative_branch_name) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {cooperative_branch_name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>
                  <AkbTextField
                    disabled={true}
                    value={branchDetails.cooperative_branch_name}
                    name="cooperative_branch_id"
                    label="Selected Group"
                    className={clsx(
                      { 'hidden': !branchDetails.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    value={userRole.role}
                    name="role"
                    label="Group role"
                    onChange={event => setUserRole({ role: event.target.value })}
                    className={classes.input}
                    validate={Text}
                  />
                </Box>
              </Box>
              <Box 
                display={{ md: 'flex' }}
                width={{ md: '100% !important' }}
                className={clsx('AkRegisterForm-body')}
              >
                <SectionTitle 
                  label='*fields (below) will be updated when you "Search by Group"'
                  className={clsx(
                    { 'hidden': !filterValue.id },
                    classes.details
                  )} 
                />
              </Box>
              <Box
                display={{ md: 'flex' }}
                width={{ md: '100% !important' }}
                className={clsx('add-to-group', 'AkRegisterForm-body')}
              >
                <Box flex={3}>
                  <AkbTextField
                    type="hidden"
                    disabled={true}
                    value={filterValue.id}                  
                    name="village_id"
                    label="id"
                    className={clsx('unique-field', classes.input)}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.villagename}
                    name="villagename"
                    label="Village"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.parishname}
                    name="parishname"
                    label="Parish"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.subcountyname}
                    name="subcountyname"
                    label="Subcounty"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.countyname}
                    name="countyname"
                    label="County"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.districtname}
                    name="districtname"
                    label="District (selected)"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.regionname}
                    name="regionname"
                    label="Region"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />           
                </Box>
              </Box>
            </>
          }
        </Box>
        <Box flex={1}>
          {!isRegistered &&
            <ImageInput
              source="users"
              label=""
              accept="image/*"
              placeholder={
                <Box
                  mt={{ xs: '1em', md: '3.75em' }}
                  mb={{ xs: '1em', md: '3.75em' }}
                >
                  {!isRegistered
                    ? <span>Click here, to add a profile photo</span>
                    : <span>Click here, to add&nbsp;/&nbsp;update a profile photo</span>
                  }
                </Box>
              }
              className={clsx({
                'create': !isRegistered,
                'edit': isRegistered,
                'hidden': isSmall,
              },
                classes.photo
              )}
            >
              <ImageField source="picture_url" title="title" />
            </ImageInput>
          }
          {isRegistered 
            && record.user_system_role 
              && record.user_system_role.filter(
                value => value['key'] === 'farmer').length > 0 && // check if object contains "farmer" key
            <>
              <SectionTitle label="Program(s)" /><hr />
              <Typography variant="body1" component="h3" gutterBottom>
                {record &&
                  record.user_program &&
                    record.user_program.length > 0
                  // only map if list is not "empty", else return "none"
                  ? record.user_program.map(
                    ({ program_name, short_name }, i) =>
                      <div key={i}>
                        <span>{program_name} ({short_name})</span><hr />
                      </div>
                  )
                  : "None"
                }
              </Typography>
              {(superuser || agent) &&
                // only accessible if "superuser", 
                // thus user can't add themselves to a program
                <SelectInput
                  label="Add to program"
                  source="programid"
                  optionText="name"
                  choices={listPrograms}
                  className={clsx('add-to-program')}
                />
              }
            </>
          }
        </Box>
      </Box>
    </SimpleForm>
  );
};


const getVillageSuggestions = (groupList = [], inputValue) => {
  let count = 0;

  return groupList.filter(({ cooperative_branch_name }) => {
    const keep =
      (!inputValue ||
        String(cooperative_branch_name)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const UserCreateToolbar = props => {
  const { save, ...rest } = props;

  const { newUserRole, ...other } = rest;

  return (
    <Toolbar {...other}>
      {/* hide (css) save-and-exit-button to make adding location mandatory */}
      <SaveAndExitButton {...rest} />
      <SaveAndContinueButton {...rest} />
    </Toolbar>
  );
};

const SaveAndContinueButton = props => {
  const [create, { loading }] = useCreate('farmers');

  const notify = useNotify();
  const history = useHistory();
  const redirectTo = useRedirect();

  const { newUserRole, ...rest } = props;

  const { basePath, redirect } = rest; //eslint-disable-line

  const token = sessionStorage.getItem('token');
  const loggedInUserId = sessionStorage.getItem('id');

  const handleChangeTab = React.useCallback(
    id => {
      history.push(`${basePath}/${id}/1`);
    },
    [history, basePath]
  );

  const handleSaveAndContinue = React.useCallback(
    (values) => {

      let userData = {
        user_system_role: [{ system_role: newUserRole }],
        ...values
      };

      create(
        {
          payload: { data: { ...userData } },
        },
        {
          onSuccess: ({ data: newRecord }) => {

            notify('post.notification.created_user', 'info', {
              smart_count: 1,
            });

            // redirect to "edit"
            redirectTo(redirect, basePath, newRecord.id, newRecord);
            handleChangeTab(newRecord.id);

            notify('post.notification.include_address', 'info', {
              smart_count: 1,
            });

          },
          onFailure: error => {

            const { status, body } = error;

            if (status === 400) {

              if (!!body['username']) {
                
                /* 
                 * This endpoint functionality is as follows:
                 * - If user was registered by superuser [registered_by: 1], it returns a single-user record 
                 * - Otherwise, if user was registered by anyone else e.g DEA, then it returns an empty list
                 */
                apiFullCall(
                  '', 
                  token,
                  'get', 
                  `users/?username=${values['username']}`
                ).then(res => {
    
                  if (res) {
    
                    const { status, body: checkedUser } = res;
    
                    if (status === 200 || status === 201) {

                      let userExists = checkedUser && 
                      checkedUser.results && checkedUser.results.length;
                      
                      // Check if user was registered by superuser - we have a record
                      if (userExists > 0) {

                        const { username, ...rest } = values;

                        let sanitizedUserData = { 
                          registered_by: Number(loggedInUserId),
                          ...rest,
                        };

                        let userId = checkedUser.results[0]['id'];

                        // Check if user was registered by superuser
                        apiFullCall(
                          sanitizedUserData, 
                          token,
                          'patch', 
                          `users/${userId}/`
                        ).then(res => {
            
                          if (res) {
            
                            const { status, body: updatedUserRecord } = res;
            
                            if (status === 200 || status === 201) {

                              notify(`User has been updated`, 'info');
                  
                              // redirect to "edit"
                              redirectTo(redirect, basePath, updatedUserRecord.id, updatedUserRecord);
                              handleChangeTab(updatedUserRecord.id);                                

                            } else { // 0789491833, 0756023111
            
                              // since this is a "GET", we only anticipate a server error (if any)
                              notify(`Server error, please try again later.`, 'warning'); 
                            };
            
                          };
                        
                        }).catch(
                          error => console.error('Error while saving:', error)
                        );
                
                      } else {

                        // we suspect that a user with same number already exists
                        notify(`User with that phone contact already exists`, 'warning');
                      };
    
                    } else { // 0789491833
    
                      // since this is a "GET", we only anticipate a server error (if any)
                      notify(`Server error, please try again later.`, 'warning'); 
                    };
    
                  };
                
                }).catch(
                  error => console.error('Error while saving:', error)
                );

              } else {
  
                // else it's probably linked to other invalid fields
                notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
              };

            } else if (status >= 500) {

              notify(`Server error, please try again later.`, 'warning');          
            };

          },
        }
      );

    }, [create, newUserRole, notify, redirectTo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_continue"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveAndContinue}
    />
  );
};

// hidden for now
const SaveAndExitButton = props => {
  const [create, { loading }] = useCreate('farmers');

  const notify = useNotify();
  const redirectTo = useRedirect();

  const { newUserRole, ...rest } = props;

  const { basePath, redirect } = rest; //eslint-disable-line

  const handleSaveAndExit = React.useCallback(
    (values) => {

      let userData = {
        user_system_role: [{ system_role: newUserRole }],
        ...values
      };

      create(
        {
          payload: { data: { ...userData } },
        },
        {
          onSuccess: response => { // eslint-disable-line

            notify('post.notification.created_user', 'info', {
              smart_count: 1,
            });

            // redirect to "list"
            redirectTo(redirect, basePath);

            notify('post.notification.include_address', 'info', {
              smart_count: 1,
            });

          },
          onFailure: error => {

            const { status, body } = error;

            if (status === 400) {

              if (!!body['username']) {

                // we suspect that a user with same number already exists
                notify(`User with that phone contact already exists`, 'warning');            
              } else {
  
                // else it's probably linked to other invalid fields
                notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
              };

            } else if (status >= 500) {

              notify(`Server error, please try again later.`, 'warning');          
            };

          },
        }
      );

    }, [create, newUserRole, notify, redirectTo]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <SaveButton
      {...rest}
      className={clsx('save-and-exit-button')}
      label={loading ? "Saving" : "post.action.save_and_exit"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSaveAndExit}
    />
  );
};

const UserEditToolbar = props => {

  const { coopBranchId, coopBranchName, ...rest } = props;

  return (
    <Toolbar {...rest}>
      <EditAndContinueButton {...props} />
      <EditAndExitButton {...props} />
    </Toolbar>
  );
};

const EditAndContinueButton = props => {

  const notify = useNotify();
  const history = useHistory();
  const redirectTo = useRedirect();
  const formState = useFormState();

  const token = sessionStorage.getItem('token');
  
  const [ loading, setLoading ] = React.useState<boolean>(false);
  const { coopBranchId, coopBranchName, ...rest } = props;
  const { basePath, record } = rest;

  const {
    role,
    programid,
    ...other
  } = formState && formState.values;

  let userDetails = { ...other };

  // We sanitize the userData to remove any "undefined" values
  const sanitizedUserData = userDetails && filterObject(userDetails, value => value !== undefined);

  // define "branch user" data
  let branchUserData = {
    is_active: true,
    approved: true,
    role: role && role,
    // we get the existing-user-id
    user_id: other['id'],
  };

  // declare a reference to the "userData"
  const userDetailsRef = React.useRef<object | any>(userDetails);

  // declare a reference to the "branchUserData"
  const branchUserDataRef = React.useRef<object | any>(branchUserData);

  const handleChangeTab = React.useCallback(
    id => {
      history.push(`${basePath}/${id}`);
    },
    [history, basePath]
  );

  const handleEditAndContinue = React.useCallback(() => {

    let userId = record && record['id'];

    // if user-details have changed
    if (userId && !isEqual(userDetailsRef.current, userDetails)) {

      // update "user" details with "defined" data
      apiFullCall(
        sanitizedUserData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `users/${userId}/` 
      ).then(res => {

        if (res) {
          const { status, body: newlyCreatedUser } = res;
  
          setLoading(!loading);

          if (status === 200 || status === 201) {

            notify('post.notification.updated_user', 'info', { smart_count: 1 });

            // remain within the edit-form
            redirectTo('edit', basePath, newlyCreatedUser.id, newlyCreatedUser);
            handleChangeTab(newlyCreatedUser.id);

            // support immediate update of users' names at dashboard
            // clear these variables
            sessionStorage.removeItem('firstName');
            sessionStorage.removeItem('lastName');
            // re-create them with new values
            sessionStorage.setItem('firstName', newlyCreatedUser['first_name']);
            sessionStorage.setItem('lastName', newlyCreatedUser['last_name']);

          } else if (status === 400) {

            if (userDetailsRef.current.username !== userDetails.username) {

              // since the phone-number has been changed
              // we suspect that a user with same number already exists
              notify(`User with that main phone contact already exists`, 'warning');            
            } else {

              // else it's probably linked to other invalid fields
              notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
            };

          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(false);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );

    };

    // if trying to add user to "program"
    if (!!programid) {

      // save a new program as it added to this "user"
      apiFullCall(
        { program: programid, user: userId }, 
        token, 
        'post',
        `user-program/`
      ).then(res => {
        
        if (res) {
          const { status } = res;

          if (status === 200 || status === 201) {

            // display notice that user has been added to a program
            notify('User has been added to program', 'info');

            // remain within edit-form
            redirectTo('edit', basePath, userId, record);
            handleChangeTab(userId);

          } else if (status === 400) {

            notify(`Something isn't right, please check again and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          }
        };

      }).catch(
        error => console.error('Error while saving:', error)
      );

    };

    // if trying to add user to a "group"
    if (!!coopBranchId) {

      if (!!role && !isEqual(branchUserDataRef.current, branchUserData)) {

        // Trigger branch-user creation when added to a "group"
        apiFullCall(
          branchUserData, 
          token, 
          'post',
          `cooperativebranchusers/?cooperative_branch_id=${coopBranchId}`
        ).then(res => {
  
          if (res) {
            
            const { status } = res;
  
            if (status === 200 || status === 201) {

              // group is created
              notify(`${record['first_name']} has been added to "${coopBranchName}"`, 'info');
                
              // remain within edit-form
              redirectTo('edit', basePath, userId, record);
              handleChangeTab(userId);              
  
            } else if (status === 400) {

              notify(`Something went wrong while adding ${record['first_name']}, please check again and resubmit.`, 'warning');
            } else if (status >= 500) {

              notify(`Server error, please try again later.`, 'warning');          
            };
            
          };
  
        }).catch(
          error => console.error('Error while saving:', error)
        );

      } else {

        // notify user that "group role" is required
        notify(`Please enter "Group role" for this user`, 'warning');
      };

    };

  }, [ coopBranchId, programid, record, branchUserData, userDetails ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_continue"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleEditAndContinue}
    />
  );
};

const EditAndExitButton = props => {

  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();

  const token = sessionStorage.getItem('token');
  
  const [ loading, setLoading ] = React.useState<boolean>(false);
  const { coopBranchId, coopBranchName, ...rest } = props;
  const { basePath, record } = rest;

  const {
    role,
    programid,
    ...other
  } = formState && formState.values;

  let userDetails = { ...other };

  // We sanitize the userData to remove any "undefined" values
  const sanitizedUserData = userDetails && filterObject(userDetails, value => value !== undefined);

  // define "branch user" data
  let branchUserData = {
    is_active: true,
    approved: true,
    role: role && role,
    // we get the existing-user-id
    user_id: other['id'],
  };

  // declare a reference to the "userData"
  const userDetailsRef = React.useRef<object | any>(userDetails);

  // declare a reference to the "branchUserData"
  const branchUserDataRef = React.useRef<object | any>(branchUserData);

  const handleEditAndExit = React.useCallback(() => {

    // if user-details have changed
    if (record['id'] && !isEqual(userDetailsRef.current, userDetails)) {

      // update "user" details with "defined" data
      apiFullCall(
        sanitizedUserData, 
        token, 
        'patch',  
        // To PATCH, the URL must end with a slash (APPEND_SLASH was set in Django).
        `users/${record['id']}/` 
      ).then(res => {

        if (res) {
          const { status, body: newlyCreatedUser } = res;
  
          setLoading(!loading);

          if (status === 200 || status === 201) {

            notify('post.notification.updated_user', 'info', { smart_count: 1 });

            redirectTo('list', basePath);

            // support immediate update of users' names at dashboard
            // clear these variables
            sessionStorage.removeItem('firstName');
            sessionStorage.removeItem('lastName');
            // re-create them with new values
            sessionStorage.setItem('firstName', newlyCreatedUser['first_name']);
            sessionStorage.setItem('lastName', newlyCreatedUser['last_name']);

          } else if (status === 400) {

            if (userDetailsRef.current.username !== userDetails.username) {

              // since the phone-number has been changed
              // we suspect that a user with same number already exists
              notify(`User with that main phone contact already exists`, 'warning');            
            } else {

              // else it's probably linked to other invalid fields
              notify(`This form is invalid. Please double-check and resubmit.`, 'warning');
            };

          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(!loading);
        }
      }).catch(
        error => console.error('Error while updating:', error)
      );

    };

    // if trying to add user to "program"
    if (!!programid) {

      // save a new program as it added to this "user"
      apiFullCall(
        { program: programid, user: record['id'] }, 
        token, 
        'post',
        `user-program/`
      ).then(res => {
        
        if (res) {
          const { status } = res;

          if (status === 200 || status === 201) {

            // display notice that user has been added to a program
            notify('User has been added to program', 'info');

            // redirect to user list
            redirectTo('list', basePath);

          } else if (status === 400) {

            notify(`Something isn't right, please check again and resubmit.`, 'warning');
          } else if (status >= 500) {

            notify(`Server error, please try again later.`, 'warning');          
          }
        };

      }).catch(
        error => console.error('Error while saving:', error)
      );

    };

    // if trying to add user to a "group"
    if (!!coopBranchId) {

      if (!!role && !isEqual(branchUserDataRef.current, branchUserData)) {

        // Trigger branch-user creation when added to a "group"
        apiFullCall(
          branchUserData, 
          token, 
          'post',
          `cooperativebranchusers/?cooperative_branch_id=${coopBranchId}`
        ).then(res => {
  
          if (res) {
            
            const { status } = res;
  
            if (status === 200 || status === 201) {

              // group is created
              notify(`${record['first_name']} has been added to "${coopBranchName}"`, 'info');
                
              // redirect to provider list
              redirectTo('list', basePath);
  
            } else if (status === 400) {

              notify(`Something went wrong while adding ${record['first_name']}, please check again and resubmit.`, 'warning');
            } else if (status >= 500) {

              notify(`Server error, please try again later.`, 'warning');          
            };
            
          };
  
        }).catch(
          error => console.error('Error while saving:', error)
        );

      } else {

        // notify user that "group role" is required
        notify(`Please enter "Group role" for this user`, 'warning');
      };

    };

  }, [ coopBranchId, programid, record, branchUserData, userDetails ]); // eslint-disable-line react-hooks/exhaustive-deps
  

  return (
    <SaveButton
      {...rest}
      label={loading ? "Saving" : "post.action.save_and_exit"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleEditAndExit}
    />
  );
};
