import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Theme,
	Typography,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import RequestIcon from '@material-ui/icons/AttachMoney';
import ServiceIcon from '@material-ui/icons/Collections';
import { SmallAgentDashboard, MediumAgentDashboard } from './responsive';

const useStyles = makeStyles(
	theme => ({
		media: {
			height: '18em',
		},
		provider: {
			margin: '1em auto',
			[theme.breakpoints.down('xs')]: {
				margin: '1em',
			},
		},
	})
);

export default (props: any) => {
	const translate = useTranslate();
	const classes = useStyles();

	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let firstName = sessionStorage.getItem('firstName'),
       lastName = sessionStorage.getItem('lastName');

  let [state, setState] = React.useState(true);
  let mainPort = document.getElementsByClassName('layout')[0].classList

  React.useEffect(() => {
    if (state) {
      // support expansion to fill mobile view-port
      mainPort.add('agent--view');
    }
    // remove "class", on unmount
    return function cleanup() { 
      setState(false); 
      mainPort.remove('agent--view'); 
    }
  }, [state]) // eslint-disable-line

  return isXSmall ? (
    <SmallAgentDashboard
      RequestIcon={RequestIcon}
      ServiceIcon={ServiceIcon}
      firstName={firstName}
      lastName={lastName}
      translate={translate}
      Link={Link}
      classes={classes}
    />
  ) : isSmall ? (
    <MediumAgentDashboard
      RequestIcon={RequestIcon}
      ServiceIcon={ServiceIcon}
      firstName={firstName}
      lastName={lastName}
      translate={translate}
      Link={Link}
      classes={classes}
    /> 
  ) : (
    <Card className={classes.provider}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {translate('pos.dashboard.provider.title')} {firstName}&nbsp;{lastName}
        </Typography>
        <Typography component="p">
          {translate('pos.dashboard.provider.subtitle')}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button component={Link} to="/providerrequests">
          <RequestIcon style={{ paddingRight: '0.5em' }} />
          {translate('pos.dashboard.provider.aor_button')}
        </Button>
        <Button component={Link} to="/providerservice">
          <ServiceIcon style={{ paddingRight: '0.5em' }} />
          {translate('pos.dashboard.provider.demo_button')}
        </Button>
      </CardActions>
    </Card>
  );
};
