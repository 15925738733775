import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const ServiceReferenceField = props => (
  <ReferenceField
    label="Service"
    source="service_id"
    reference="services"
    {...props}
  >
    <TextField source="reference" />
  </ReferenceField>
);

ServiceReferenceField.defaultProps = {
  source: 'service_id',
  addLabel: true,
};

export default ServiceReferenceField;
