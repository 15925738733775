import { makeStyles } from '@material-ui/core/styles';

export const useCoopBranchStyle = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '25ch',
    },
  },
  gridContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    padding: '5px',
  },
  gridItem: {
    fontSize: '30px',
    textAlign: 'left',
  },
  textField: {
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    marginBottom: 20,
  },
  input: {
    color: 'white',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 270,
    width: 270,
  },
  typoStyle: {
    marginBottom: 20,
    color: '#DF2026',
    textTransform: 'uppercase',
  },
}));
