import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import { Dialog, DialogContent } from '@material-ui/core';

import {
	FormDataConsumer,
	maxLength,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
  useRefresh,
	useNotify
} from 'react-admin';

import { DialogTitle as AddServiceDialogTitle } from './DialogTitle';
import { ApproveButton } from '../../../layout';
import { Transition } from '../../utils';

import { apiFullCall } from '../../apiHelper';

const ProviderAddServiceModal = ({
	openDialog, 
	handleClose,
	listServices,
	isSmall,
	classes,
	...props
}) => {

	const Price = [maxLength(7, 'Maximum of 7 digits allowed'), required()];

	return (
		<Dialog
			maxWidth='xs'
			open={openDialog}
			onClose={handleClose}
      TransitionComponent={Transition}
      className={clsx('add-service--modal')}
			aria-labelledby="customized-dialog-title" 
		>
			<AddServiceDialogTitle
				id="customized-dialog-title" 
				onClose={handleClose}
			>
				Add Services
			</AddServiceDialogTitle>
			<DialogContent dividers >
				<SimpleForm 
          {...props}
          className={clsx(
            'add-service',
            props.className
          )}
          submitOnEnter={false} 
          toolbar={
            <ProviderServiceToolbar
              isSmall={isSmall}
              handleClose={handleClose}
              {...props}
            />
          }
        >
					<SelectInput
						label="Select service"
						source="service" 
						optionText="name"
						choices={listServices}
						className={clsx('add-to-program')}
						validate={[required()]}
					/>
					<FormDataConsumer>
						{({ formData }) => {

							if (formData.service) {

								// to be populated with "specifications" after filtering
								var listSpecifications = [];

								let selectedService = listServices && listServices.filter(
									({ id }) =>	id === formData.service
								);

								const { spec } = selectedService && selectedService[0];

								// only map if we have a spec, to fill select
								if ( spec && spec.length > 0	) {

									listSpecifications = spec.map(
										({ id, unit, unit_of_measure }) => ({ id: id, name: unit+' '+unit_of_measure })
									);
								};

								return (
									<SelectInput
										label="Select specification"
										source="selectedSpec"
										optionText="name"
										choices={listSpecifications}
										className={classes.input}
										validate={[required()]}
									/>
								)
							} else {

								return (
									<SelectInput
										allowEmpty
										label="Select specification"
										source="selectedSpec"
										optionText="name"
										choices={[]}
										helperText="First, select a 'service'"
										validate={[required()]}
									/>
								)
							};

						}}
					</FormDataConsumer>
          <TextInput
            label="Enter Price/cost"
            source="cost"
            className={classes.input}
            validate={Price}
          />
				</SimpleForm>
			</DialogContent>
		</Dialog>
	);
};

// Handle "creating a service"
const ProviderServiceToolbar = props => {

  // destructure to pass directly to <ApproveButton>
	const { isSmall, handleClose, save, ...rest } = props;

  return (
    <Toolbar {...rest}>
      <ApproveButton
        label="Close"
        icon={null}
        isXSmall={isSmall}
        className={clsx('small--btn')}
        onClick={handleClose}
      />
      <DeliveryButton {...rest} /> 
    </Toolbar>
  );
};

const DeliveryButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
	const notify = useNotify();

	const [ loading, setLoading ] = React.useState(false);

	const {	service, selectedSpec, cost } = formState && formState.values;

	const token = sessionStorage.getItem('token');
	const providerlocationid = sessionStorage.getItem('providerlocationid');

	const handleSave = React.useCallback(() => { 

		if (!!service) {

			if (!!selectedSpec) {

				if (!!cost) {

					apiFullCall(
						[{ id: selectedSpec, service_cost: cost }],
						token,
						'post',
						`providerservice/?providerlocationid=${providerlocationid}`
					).then(res => {
						
						if (res) {
							
							setLoading(!loading);
							const { status } = res;

							if (status === 200 || status === 201) {

								refresh();
								notify(`Service has been added successfully.`, 'success')
							};

							setLoading(!!loading);
		
						};
		
					}).catch(
						error => console.error('Error while fetching "categories":', error)
					);

				} else {

					// inform user to select a "specification" (in case, html validation is overriden)
					notify(`Please enter a "cost"`, 'warning');
				};

			} else {

				// inform user to select a "specification" (in case, html validation is overriden)
				notify(`Please select a "specification"`, 'warning');
			};

		} else {

			// inform user to select a "service" (in case, html validation is overriden)
      notify(`Please select a "service"`, 'warning');
		};

	}, [service, selectedSpec, cost]); // eslint-disable-line react-hooks/exhaustive-deps
	
	// define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
	const NoIcon = () => null;
	
  return (
    <SaveButton
      {...props}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleSave} 
    />
  );
};

export default ProviderAddServiceModal;
