import React, { FC, useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import apiCall from '../../modules/apiHelper';

import CardIcon from './CardIcon';

interface Props {
  value?: number;
}

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  title: {},
});

interface CountStats {
  users?: number;
  farmers?: number;
  providers?: number;
  sumfarmerRequests?: number;
  unapprovedProviderRequests?: number;
  anyUpdates?: boolean;
}

const MonthlyRevenue: FC<Props> = ({ value }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const saccoName = sessionStorage.getItem('saccoName');
  const [coopBranches, setCoopBranches] = useState([
    {
      id: 0,
      cooperative_branch_name: '',
      monthly_income: '',
    },
  ]);

  useEffect(() => {
    const handleFetchCoopBranches = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperativebranches/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCoopBranches(pageNumber, results);
          } else {
            setCoopBranches(results);
          }
        })
        .catch(error => console.error('Error while fetching:', error));

    apiCall('', sessionStorage.getItem('token'), 'get', 'cooperativebranches/')
      .then(response => {
        if (response.next !== null) {
          handleFetchCoopBranches(2, response.results);
        }
      })
      .catch(error => console.error('Error while fetching:', error));
  }, []);

  return (
    <div className={classes.main}>
      <CardIcon Icon={DollarIcon} bgColor="#31708f" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.monthly_revenue')}
        </Typography>
        <Typography variant="h5" component="h2">
          {coopBranches && coopBranches.map(branch =>
            branch.cooperative_branch_name === saccoName ? branch.monthly_income : null
          )}
        </Typography>
      </Card>
    </div>
  );
};

export default MonthlyRevenue;
