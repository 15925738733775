import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { stringify } from 'query-string';
import Button from '@material-ui/core/Button';

import { FieldProps, Category } from '../../types';
import services from '../services';

import { LinkToRelatedServiceStyles } from '../stylesheets';

const LinkToRelatedServices: FC<FieldProps<Category>> = ({ record }) => {
  const translate = useTranslate();
  const classes = LinkToRelatedServiceStyles();

  return record ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/services',
        search: stringify({
          page: 1,
          perPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({ subcategoryid: record.id }),
        }),
      }}
      className={classes.link}
    >
      <services.icon className={classes.icon} />
      {translate('resources.subcategories.fields.services')}
    </Button>
  ) : null;
};

export default LinkToRelatedServices;
