import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts/highcharts.js'
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const SuperUserDashboard = props => {
    const classes = requestStyles();
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState({
        districtid: 0,
        districtname: '',
        region: '',
        hckey: null 
    });

    const drawGauge = (chartData, anchor, label) => { // eslint-disable-line
        Highcharts.chart(anchor, {
            chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            title: {
                text: label
            },
            pane: {
                startAngle: -150,
                endAngle: 150,
            },

            // the value axis
            yAxis: {
                min: 0,
                max: chartData['max'],
                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 10,
                minorTickPosition: 'inside',
                minorTickColor: '#666',

                tickPixelInterval: 30,
                tickWidth: 2,
                tickPosition: 'inside',
                tickLength: 10,
                tickColor: '#666',
                labels: {
                    step: 2,
                    rotation: 'auto'
                },
                plotBands: [
                    {
                        from: 0,
                        to: Math.round(chartData['max'] * 0.75),
                        color: '#DF5353'
                    }, {
                        from: Math.round(chartData['max'] * 0.75),
                        to: Math.round(chartData['max'] * 0.90),
                        color: '#DDDF0D'
                    }, {
                        from: Math.round(chartData['max'] * 0.90),
                        to: chartData['max'],
                        color: '#55BF3B'
                    }
                ]
            },

            series: [
                {
                    name: label,
                    data: [chartData['value']],
                    tooltip: {
                        valueSuffix: ' of ' + chartData['max']
                    }
                }
            ]
        });
    };

    const drawVUMeter = (chartData, anchor, label) => {
        Highcharts.chart(anchor, {
            chart: {
                type: 'gauge',
                plotBackgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#fffbed'],
                        [0.9, '#FFFFFF'],
                        [1, '#ffffff']
                    ]
                },
                plotBackgroundImage: null,
            },
            title: {
                text: label
            },
            pane: [
                {
                    startAngle: -85,
                    endAngle: 85,
                    background: null
                }
            ],
            yAxis: [
                {
                    min: 0,
                    max: chartData['max'],
                    minorTickPosition: 'outside',
                    tickPosition: 'outside',
                    labels: {
                        rotation: 'auto',
                        distance: 20
                    },
                    plotBands: [
                        {
                            from: 0,
                            to: Math.round(chartData['max'] * 0.75),
                            color: '#DF5353'
                        }, {
                            from: Math.round(chartData['max'] * 0.75),
                            to: Math.round(chartData['max'] * 0.90),
                            color: '#DDDF0D'
                        }, {
                            from: Math.round(chartData['max'] * 0.90),
                            to: chartData['max'],
                            color: '#55BF3B'
                        }
                    ],
                    title: {
                        text: '<span style="font-size:10px">' + chartData['value'] + '/' + chartData['max'] + '</span>'
                    }
                }
            ],

            series: [
                {
                    name: label,
                    data: [chartData['value']],
                    yAxis: 0
                }
            ]

        });
    };

    const plotUserGrowth = (plotData) => {
        Highcharts.chart('usergrowth', {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Farmer Growth for the past 12 Months'
            },
            subtitle: {
                text: plotData['subtitle']
            },
            yAxis: {
                title: {
                    text: 'Cumulative Farmers Enrolled'
                }
            },
            xAxis: {
                categories: plotData['labels']
            },
            series: plotData['series']
        });
    };

    const plotUserTrends = (plotData) => {
        var series = [];
        for (var i = 0; i < plotData['series'].length; i++) {
            var episode = {
                name: plotData['series'][i]['name']
            };
            episode['data'] = [plotData['series'][i]['data'][0]];
            for (var j = 1; j < plotData['series'][i]['data'].length; j++) {
                episode['data'].push(plotData['series'][i]['data'][j] - plotData['series'][i]['data'][j - 1]);
            }

            series.push(episode);
        }

        Highcharts.chart('usertrends', {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Farmer Enrolment Trends for the past 12 Months'
            },
            subtitle: {
                text: plotData['subtitle']
            },
            yAxis: {
                title: {
                    text: 'Farmers Enrolled'
                }
            },
            xAxis: {
                categories: plotData['labels']
            },
            series: series
        });
    };

    const plotUserGrouping = (groupingData) => {
        Highcharts.chart('total', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Farmer Distribution Over Groups'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [
                {
                    name: 'Farmers',
                    colorByPoint: true,
                    data: groupingData['series']
                }
            ]
        });
    };

    const plotProviderGrowth = (providerGrowth) => {
        Highcharts.chart('service_providers', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Service Providers Enrolled'
            },
            subtitle: {
                text: 'Last 6 Months'
            },
            xAxis: {
                categories: providerGrowth['labels'],
            },
            legend: {
              enabled: false
            },
            yAxis: {
                title: {
                    text: 'Service Providers Enrolled'
                }
            },
            series: providerGrowth['series']
        });
    };

    const plotRequests = (requestsData) => {
        Highcharts.chart('requests', {

            title: {
                text: 'Request Processing Trends'
            },
            subtitle:{
                text: requestsData['subtitle']
            },
            yAxis: {
                title: {
                    text: 'Number of Requests'
                }
            },

            xAxis: {
                categories: requestsData['labels']
            },
            series: requestsData['series']
        });
    };

    const serviceProviderGrowthCumulative = (plotData) =>{
        var sum = 0;
        var series = [];
        for (var i = 0; i < plotData['series'][0]['data'].length; i++) {
            sum = sum + plotData['series'][0]['data'][i];
            series.push(sum);
        }

        Highcharts.chart('provider_growth_cumulative', {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Service Provider Cumulative Enrolments.'
            },
            subtitle: {
                text: 'Past 6 Months'
            },
            yAxis: {
                title: {
                    text: 'Cumulative Enrolment'
                }
            },
            xAxis: {
                categories: plotData['labels']
            },
            series: [
                {
                    name: 'Service Providers',
                    data: series
                }
            ]
        });
    };

    const plotProviderGrouping = (groupingData) => {
        var series = [];
        for( var x = 0; x < groupingData['results'].length; x++){
            series.push(
                {
                    name: groupingData['results'][x]['name'],
                    y: parseInt(groupingData['results'][x]['male']) + parseInt(groupingData['results'][x]['female'])
                }
            );
        }

        Highcharts.chart('provider_distribution', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Service Provider Classes'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [
                {
                    name: 'Service Providers',
                    colorByPoint: true,
                    data: series
                }
            ]
        });
    };

    const plotCharts = () => {
        //Fetch Districts
        apiCall('', sessionStorage.getItem('token'), 'get', 'district/').then(districtData => {
            setDistricts(districtData['results']);
        }).catch(error => console.error('Error during fetch:', error));

        //Plot farmer targets
        apiCall('', sessionStorage.getItem('token'), 'get', 'appsettings/?setting_key=dashboard_targets').then(appSettings => {
            if (appSettings['setting_value'] !== 'Not Found') {
                apiCall('', sessionStorage.getItem('token'), 'get', 'farmertargets/').then(targetValues => {
                    var targets = JSON.parse(appSettings['setting_value']);
                    var totalFarmers = parseInt(targets['target_male']) + parseInt(targets['target_female']);
                    // drawGauge({ value: targetValues['male'], max: targets['target_male'] }, 'male', 'Male Farmers');
                    // drawGauge({ value: targetValues['female'], max: targets['target_female'] }, 'female', 'Female Farmers');
                    drawVUMeter({ value: targetValues['total'], max: totalFarmers }, 'farmer_targets', 'Farmers Targets');
                }).catch(error => console.error('Error during fetch:', error));
            }
        }).catch(error => console.error('Error during fetch:', error));

        //Plot farmer growth
        apiCall('', sessionStorage.getItem('token'), 'get', 'usergrowth/?district=' + selectedDistrict['districtid']).then(farmerGrowth => {
            plotUserGrowth(farmerGrowth);
            plotUserTrends(farmerGrowth);
        }).catch(error => console.error('Error during fetch:', error));

        //Plot service provider growth
        apiCall('', sessionStorage.getItem('token'), 'get', 'providergrowth/?district=' + selectedDistrict['districtid']).then(providerGrowth => {
            plotProviderGrowth(providerGrowth);
        }).catch(error => console.error('Error during fetch:', error));

        //Plot farmer groupings
        apiCall('', sessionStorage.getItem('token'), 'get', 'farmergroupings/?district=' + selectedDistrict['districtid']).then(groupingData => {
            plotUserGrouping(groupingData);
        }).catch(error => console.error('Error during fetch:', error));

        //Plot requests processing trends
        apiCall('', sessionStorage.getItem('token'), 'get', 'requestprocessing/?district=' + selectedDistrict['districtid']).then(requestsData => {
            plotRequests(requestsData);
        }).catch(error => console.error('Error during fetch:', error));

        //Provider grouping
        var filter = selectedDistrict['districtid'] === 0 ? '' : '?filter_1=' + selectedDistrict['districtid'];
        apiCall('', sessionStorage.getItem('token'), 'get', 'providerclassifications/' + filter).then(providerData => {
            plotProviderGrouping(providerData);

            // Plot service provider growth
            apiCall('', sessionStorage.getItem('token'), 'get', 'providergrowth/?district=' + selectedDistrict['districtid']).then(providerGrowth => {
                serviceProviderGrowthCumulative(providerGrowth);
            }).catch(error => console.error('Error during fetch:', error));
        }).catch(error => console.error('Error during fetch:', error));
    };
    useEffect(plotCharts, [selectedDistrict]);

    return (
        <>
            <Autocomplete
                style={{ width: 400, float: 'left', marginLeft: '10px' }}
                options={districts}
                autoHighlight
                getOptionLabel={(option) => option.districtname}
                onChange={(event, newValue) => {
                    event.preventDefault();
                    if(newValue === null){
                        setSelectedDistrict({
                            districtid: 0,
                            districtname: '',
                            region: '',
                            hckey: null 
                        });
                    }else{
                        setSelectedDistrict(newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    apiCall('', sessionStorage.getItem('token'), 'get', 'district/?search=' + newInputValue).then(districtData => {
                        setDistricts(districtData['results']);
                    }).catch(error => console.error('Error during fetch:', error));
                }}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.districtname} ({option.region})
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Search District"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off'
                    }}
                    />
                )}
            />
            <div className={classes.statisticsRow}>
                <div className={classes.columnTwoThirds}>
                    <div id="service_providers"></div>
                </div>
                <div className={classes.formThreeColumn}>
                    <div id="farmer_targets"></div>
                </div>
            </div>
            <div className={classes.statisticsRow}>
                <div className={classes.formThreeColumn}>
                    <div id="provider_distribution"></div>
                </div>
                <div className={classes.columnTwoThirds}>
                    <div id="provider_growth_cumulative"></div>
                </div>
            </div>
            <div className={classes.statisticsRow}>
                <div className={classes.columnTwoThirds}>
                    <div id="requests"></div>
                </div>
                <div className={classes.formThreeColumn}>
                    <div id="total"></div>
                </div>
            </div>
            <div className={classes.statisticsRow}>
                <div className={classes.formOneColumn}>
                    <div id="usergrowth"></div>
                </div>
            </div>
            <div className={classes.statisticsRow}>
                <div className={classes.formOneColumn}>
                    <div id="usertrends"></div>
                </div>
            </div>
        </>
    );
}
