const urls = {
    serviceproviders:'providerclassifications',
    serviceprovidersview:'providerclassifications',
    farmersreport:'farmerclassifications',
    farmers:'users',
    groupusers:'cooperativebranchusers'
}

const  get_url = (param) => {
    
    return urls.hasOwnProperty(param) ? urls[param] : '';
}

export default get_url;
