import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRefresh,
  useNotify,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { ApproveButton } from '../../layout';
import { Transition, SectionTitle } from '../utils';

import { userEditStyles } from '../stylesheets';
import { apiFullCall } from '../apiHelper';

export const RequestDeliveryForm = props => {
  const classes = userEditStyles();

  const { isXSmall, deliveryDialog, handleDeliveryDialog, ...rest } = props;

  const [errorStatus, setErrorStatus] = React.useState(false);

  const checkCode = value => {

    if (!value) return null;

    if (!!value && errorStatus) {
      return 'Code is invalid!';
    }

    return [];
  };

  let isValid = [required('Please enter a delivery code'), checkCode];

  return (
    <Dialog
      maxWidth="xs"
      open={deliveryDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="delivery-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="delivery-dialog-title"
      >
        <SectionTitle label="Confirm delivery" />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx(
            'request',
            props.className
          )}
          submitOnEnter={false} 
          toolbar={
            <DeliveryToolbar
              isXSmall={isXSmall}
              setErrorStatus={setErrorStatus}
              handleDeliveryDialog={handleDeliveryDialog}
              {...rest}
            />
          }
        >
          <TextInput
            id="completion_code"
            label="Enter delivery code"
            source="completion_code"
            className={classes.input}
            validate={isValid}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

// Handling "create" mode
const DeliveryToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleDeliveryDialog, setErrorStatus, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleDeliveryDialog}
      />
      <DeliveryButton {...rest} /> 
    </Toolbar>
  )  
};

const DeliveryButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { handleDeliveryDialog, setErrorStatus, onClose, record, ...rest } = props;

  // destructure "date" and "reason" to pass to API call
  const { completion_code } = formState && formState.values;

  const token = sessionStorage.getItem('token');
  
  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line
  
  const validateCode = () => {
    const code = document.getElementById('completion_code-helper-text');
    code.focus();
    triggerEvent(code, 'keyup');
  };

  const triggerEvent = (el, type) => {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    } else {
      // IE 8
      let e = document.createEventObject();
      e.eventType = type;
      el.fireEvent('on'+e.eventType, e);
    }
  }

  const deliveryData = {
    request_id: record && record['id'],
    completion_code: completion_code,
  };

  const handleDelivery = React.useCallback(() => {

    // Double-check to ensure that "date", "reason" and "id" are defined or not empty
    if (!!completion_code && !!record['id']) {

      apiFullCall(
        deliveryData, 
        token, 
        'post', 
        `completerequest/` 
      ).then(res => {
        if (res) {
          setLoading(!loading);
          const { status } = res;

          if (status === 200 || status === 201) {

            // when successfully submitted
            notify('Delivery has been confirmed', 'info');

            // go back to requests list
            handleDeliveryDialog();
            onClose();
            refresh();
            
          } else if (status === 406) {
            // notify(body['message'], 'warning');
            validateCode();
            setErrorStatus(true);
            setLoading(!!loading);

          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };
        };

      }).catch(
        error => console.error('Error while sending:', error)
      );

    } else {

      // inform user to enter a "date" and/or "comment"
      notify(`Please add a delivery code`, 'warning');
    }

  }, [deliveryData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleDelivery} 
    />
  );
};
