import React, { FC } from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from './FullNameField';
import { FieldProps } from '../../types';

const UserReferenceField: FC<FieldProps> = props => (
  <ReferenceField source="userid" reference="users" {...props}>
    <FullNameField />
  </ReferenceField>
);

UserReferenceField.defaultProps = {
  source: 'userid',
  label: 'User',
  addLabel: true,
};

export default UserReferenceField;
