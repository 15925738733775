import { createMuiTheme } from '@material-ui/core/styles';

export const AkTheme = createMuiTheme({
  palette: {
    primary: {
      // dark-green
      main: '#497c55',
    },
    secondary: {
      main: '#fff',
    },
  },
});
