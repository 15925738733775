import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Chip, Grid, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import { UserData } from '../../types';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  pad: {
    marginBottom: '.4em',
  },
  field: {
    marginLeft: '.1em',
    marginBottom: '.5em',
    marginTop: '.5em',
    border: '1px solid red',
  },
  label: {
    font: '15px Arial, sans-serif',
  },
}));

const handleDelete = () => {
  alert('You are editing this...');
};

export const UserDetailView = ({ record, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [user, setUser] = useState<UserData>({ user: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getOne('usersview', { id: record.user_id })
      .then(({ data }) => {
        setUser({ user: data });
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!user.user) return null;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs>
          <fieldset className={classes.field}>
            <legend className={classes.pad}>
              <strong>Basic Details</strong>
            </legend>
            <div className={classes.pad}>
              <span className={classes.label}>Name:</span>&nbsp;
              <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={user.user ? user.user['full_name'] : 'Name'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Gender:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user
                    ? user.user['gender'] === 'M'
                      ? 'Male'
                      : user.user['gender'] === 'F'
                      ? 'Female'
                      : 'Other'
                    : 'Gender'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Date of birth:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['dob'] : 'DOB'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>NIN:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['nin'] : 'NIN'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Marital Status:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user ? user.user['marital_status'] : 'Marital status'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Education:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['education'] : 'Education'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Occupation:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['occupation'] : 'Occupation'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Income Status:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['income_status'] : 'Income status'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Active:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user
                    ? user.user['is_active'] === true
                      ? 'Yes'
                      : 'No'
                    : 'Is Active'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
          </fieldset>
        </Grid>
        <Grid item xs>
          <fieldset
            style={{
              marginRight: '.1em',
              marginBottom: '.5em',
              marginTop: '.5em',
              border: '1px solid red',
            }}
          >
            <legend className={classes.pad}>
              <strong>
                Location Details{' '}
                <Chip
                  size="small"
                  color="primary"
                  variant="outlined"
                  label="2"
                  deleteIcon={<DoneIcon />}
                  onDelete={handleDelete}
                />
              </strong>
            </legend>
            <div className={classes.pad}>
              <span className={classes.label}>Address:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user ? user.user['physical_address'] : 'Physical Address'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Village:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['villagename'] : 'village name'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>

            <div className={classes.pad}>
              <span className={classes.label}>Parish:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                label={user.user ? user.user['parishname'] : 'Parish name'}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Subcounty:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                label={
                  user.user ? user.user['subcountyname'] : 'Subcounty name'
                }
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>County:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                label={user.user ? user.user['countyname'] : 'County name'}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>District:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                label={user.user ? user.user['districtname'] : 'District name'}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Region:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                label={user.user ? user.user['regionname'] : 'Region name'}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Nationality:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['nationality'] : 'Nationality'}
              />
            </div>
          </fieldset>
        </Grid>
        <Grid item xs>
          <fieldset
            style={{
              marginBottom: '.5em',
              marginTop: '.5em',
              border: '1px solid red',
            }}
          >
            <legend className={classes.pad}>
              <strong>Contact Details</strong>
            </legend>
            <div className={classes.pad}>
              <span className={classes.label}>Phone Contact:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['username'] : 'Contact'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Secondary Contact:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user ? user.user['secondary_phone_number'] : 'Contact'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Primary Email:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={user.user ? user.user['email'] : 'Primary Email'}
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Secondary Email:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label={
                  user.user ? user.user['secondary_email'] : 'Primary Email'
                }
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
          </fieldset>
          <fieldset
            style={{
              marginBottom: '.5em',
              marginTop: '.5em',
              border: '1px solid red',
            }}
          >
            <legend className={classes.pad}>
              <strong>Other Details</strong>
            </legend>
            <div className={classes.pad}>
              <span className={classes.label}>Next of Kin:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label="+256 703196362"
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
            <div className={classes.pad}>
              <span className={classes.label}>Number of dependants:</span>&nbsp;
              <Chip
                size="small"
                variant="outlined"
                color="primary"
                label="5"
                deleteIcon={<DoneIcon />}
                onDelete={handleDelete}
              />
            </div>
          </fieldset>
        </Grid>
      </Grid>
    </div>
  );
};
