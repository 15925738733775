import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Edit } from 'react-admin';

import { ProviderMultiForm } from './form';
import { ProviderTitle } from './ProviderTitle';

const ProviderEdit = props => {

  const { permissions } = props;

  let superuser = permissions && permissions.includes('superuser'),
      provider = permissions && permissions.includes('provider'),
      agent = permissions && permissions.includes('agent');

  let allowedUser = permissions && (superuser || provider || agent);

  return permissions !== undefined ? (
    allowedUser ? <EditComponent {...props} /> : <Redirect to="/" />
  ) : null;
};

const EditComponent = props => {
  const history = useHistory();

  const { classes, hasCreate, hasEdit, hasList, hasShow, ...rest } = props;

  const { basePath } = rest;

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return (
    <Edit 
      {...rest}
      title={<ProviderTitle />}
    >
      <ProviderMultiForm 
        {...rest} 
        onCancel={handleClose}
        redirect={hasCreate ? 'list' : basePath}
      />
    </Edit>
  );
};

export default ProviderEdit;
