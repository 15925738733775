import { makeStyles } from '@material-ui/core/styles';

const appBarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.palette.white,
  },
  spacer: {
    flex: 1
  },
  appBar: {
    background: '#df1f26',
    color: '#fff'
  },
}));

const sideMenuStyles = makeStyles(theme => ({
  root: {
    margin: '20px auto 20px 22px',
    height: 100,
    width: 100,
    fontSize: '3.95rem',
    backgroundColor: '#df1f26',
  }
}));

const subMenuStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
}));

export {
  appBarStyles,
  sideMenuStyles,
  subMenuStyles
}