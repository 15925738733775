import React from 'react';
import clsx from 'clsx';
import { useNotify, useRefresh } from 'react-admin';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	CircularProgress,
} from '@material-ui/core';


import { DialogTitle as ServTempDialogTitle } from './DialogTitle';
import { Transition } from '../../utils';

import { apiFileUploadCall, apiFullCall } from '../../apiHelper';

const ProviderServiceTemplateModal = ({
	handleClose,
	openDialog,
	providerid,
	...props
}) => {

	const notify = useNotify();
	const refresh = useRefresh();
	
	const { isSmall } = props;
	console.log(isSmall);

	const [ state, setState ] = React.useState({ fileName: '', file: '' });
	const [ loading, setLoading ] = React.useState(false);
	const [ uploading, setUploading ] = React.useState(false);

	const token = sessionStorage.getItem('token');
	const providerlocationid = sessionStorage.getItem('providerlocationid');

	const handleDownloadTemplate = async () => {

		if (providerid && providerlocationid) {
			apiFullCall(
				'',
				token,
				'get',
				`serviceprovidertemplate/?providerid=${providerid}&providerlocationid=${providerlocationid}`,
				''
			).then(res => {
				
				setLoading(true);

				const { status, body } = res;

				if (status === 200) {

					window.location.href = body.url;
					notify(`Template downloaded successfully.`, 'info');
					refresh();
				} else {

					notify(`Experienced an error, please try again later.`, 'warning');
				};

			}).catch(
				error => console.error('Error while getting template:', error)
			);
		};

	};

	const fileUpload = () => {
		setState({ ...state, fileName: ''	});
		document.getElementById("service_template").click();
	};

	const fileUploadChange = e => {
		setState({ ...state, fileName: e.target.files[0].name, file: e.target.files[0]	});
		setLoading(false);
	};

	const handleUploadTemplate = () => {

		let form_data = new FormData();

		form_data.append('file', state.file, state.file.name);

		apiFileUploadCall(
			form_data,
			token,
			'post',
			`importproviderservices/?providerlocationid=${providerlocationid}`
		).then(res => {
			setUploading(true);
			
			const { status } = res;

			if (status === 201) {

				setUploading(!uploading);
				notify(`Services Uploaded successfully.`, 'info');
				setState({	...state,	fileName: '',	file: ''	});
				refresh();
			} else {

				setUploading(!uploading);
				notify(`Experienced an error, please try again later.`, 'warning');
				setState({	...state,	fileName: '',	file: '' });
				refresh();
			};

		}).catch(
			error => console.error('Error while uploading template:', error)
		);

		setLoading(false);

	};

	return (
		<Dialog 
			maxWidth='xs'
			open={openDialog} 
			onClose={handleClose}
      TransitionComponent={Transition}
      className={clsx('service-template--modal')}
			aria-labelledby="customized-dialog-title" 
		>
			<ServTempDialogTitle
				id="customized-dialog-title"
				onClose={handleClose}
			>
				Provider Services Template
			</ServTempDialogTitle>
			<DialogContent dividers>
				<Box
					display={{ xs: 'none' }}
				>
					<input
						id="service_template"
						type="file"
						accept=".xls"
						onChange={fileUploadChange}
					/>
				</Box>
				<Box
					display={{ xs: 'flex' }}
					width={{ md: '100% !important' }}
					className={clsx('service-buttons')}
				>
					<Button
						color="primary"
						variant="outlined"
						onClick={fileUpload}
					>
						{uploading ? <span>Uploading...</span> : <span>Upload</span>}
					</Button>
					<Button
						color="primary"
						variant="outlined"
						onClick={handleDownloadTemplate}
					>
						{loading ? <span>Downloading...</span> : <span>Download</span>}
					</Button>
				</Box>
				<Box
					className={clsx('selected-file')}
				>
					{state.fileName &&
						<p>
							<span className={clsx('selected-file-title')}>Selected file:</span>{state.fileName}
						</p>
					}
				</Box>
			</DialogContent>
			{state.fileName &&
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						disabled={loading}
						onClick={handleUploadTemplate}
					>
						{uploading ?
							<span><CircularProgress size={16} color="primary" /><br/></span>
							: <span>Submit</span>
						}
					</Button>
				</DialogActions>
			}
		</Dialog>
	);
};

export default ProviderServiceTemplateModal;
