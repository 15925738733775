import React from 'react';
import clsx from 'clsx';
import {
  BulkActionsToolbar,
  LoadingIndicator,
  NumberField,
  TextField,
  ListToolbar,
  Pagination,
  useListController,
  useRefresh
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Theme,
  useMediaQuery,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import { ProviderEditServiceModal } from './form';
import { ListActions } from '../../layout';


const ServicesGrid = ({ ids, data, ...props }) => {
  
  const refresh = useRefresh();
  const { page, perPage } = props;

  const [specification, setSpecification] = React.useState<object>({});
  const [service_name, setServiceName] = React.useState<String>('');
  
  // handle edit-service modal state
  const [openEditServModal, setOpenEditServModal] = React.useState<boolean>(false);

  // handle edit-service modal open
  const handleEditServModalOpen = () => setOpenEditServModal(!openEditServModal);

  // handle edit-service modal close
  const handleProviderEditServiceClose = () => {
    setOpenEditServModal(!openEditServModal);
    refresh();
  };

  // handle editing a service
  const handleServiceEdit = (specification, service_name) => {
    setSpecification(specification)
    setServiceName(service_name);
    handleEditServModalOpen();
  };

  // let prices = document.getElementById('services-list')?.getElementsByClassName('service-price')!;
  let pg = page - 1;

  if (ids && ids.length > 0) {
    sessionStorage.setItem('hasServices', 'true');
  };

  return (
    <>
      <ProviderEditServiceModal
        openDialog={openEditServModal} 
        handleClose={handleProviderEditServiceClose} 
        specification={specification} 
        service_name={service_name}
      />
      <Box className={clsx('services-list')} id="services-list">
        {ids && ids.length > 0 && (
          (perPage > 0 ? ids.slice(pg * perPage, pg * perPage + perPage) : ids).map(id =>
            <Card key={id} className={clsx('services-card')}>
              <CardHeader
                className={clsx('services-title')}
                title={
                  <TextField
                    record={data[id]}
                    source="service_name"
                  />
                }
                subheader={
                  <>
                    <span className={clsx('services-measure')}>(&nbsp;</span>
                    <TextField className={clsx('services-measure')} record={data[id]} source="unit_of_measure" />
                    <span className={clsx('services-measure')}>&nbsp;)</span>
                  </>
                }
              />
              <CardContent className={clsx('services-details')}>
                {data[id]
                  && data[id]['specifications']
                  && data[id]['specifications'].map(({ unit, unit_of_measure }, i) => (
                    <div key={i}>
                      <span>
                        {unit}&nbsp;{unit_of_measure}&nbsp;
                      </span>
                      <Chip
                        className={clsx('service-price')}
                        variant="outlined"
                        label={
                          <NumberField
                            record={data[id]['specifications'][i]}
                            source="service_cost"
                            options={{ style: 'currency', currency: 'UGX' }}
                          />
                        }
                        onDelete={() => { handleServiceEdit(data[id]['specifications'][i], data[id]['service_name']) }}
                        deleteIcon={<EditIcon />}
                      />
                    </div>
                  ))}
              </CardContent>
            </Card>
          ))}
      </Box>
    </>
  );
};

ServicesGrid.defaultProps = {
  data: {},
  ids: [],
};

const CustomList = props => {
  // TODO: When upgrading react-admin version; 
  // replace with useListContext() (ra-core/src/controller)
  const controllerProps = useListController(props);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    // fetched data
    data, // an id-based dictionary of the list data, e.g. { 123: { id: 123, title: 'hello world' }, 456: { ... } }
    ids, // an array listing the ids of the records in the list, e.g [123, 456, ...]
    total, // the total number of results for the current filters, excluding pagination. Useful to build the pagination controls. e.g. 23 
    loaded, // boolean that is false until the data is available
    loading, // boolean that is true on mount, and false once the data was fetched
    // pagination
    page, // the current page. Starts at 1
    perPage, // the number of results per page. Defaults to 25
    setPage, // a callback to change the page, e.g. setPage(3)
    setPerPage, // a callback to change the number of results per page, e.g. setPerPage(25)
    // sorting
    currentSort, // a sort object { field, order }, e.g. { field: 'date', order: 'DESC' } 
    setSort, // a callback to change the sort, e.g. setSort('name', 'ASC')
    // filtering
    displayedFilters, // a dictionary of the displayed filters, e.g. { title: true, nationality: true }
    filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
    setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
    showFilter, // a callback to show one of the filters, e.g. showFilter('title', defaultValue)
    hideFilter, // a callback to hide one of the filters, e.g. hidefilter('title')
    // row selection
    selectedIds, // an array listing the ids of the selcted rows, e.g. [123, 456]
    onSelect, // callback to change the list of selected rows, e.g onSelect([456, 789])
    onToggleItem, // callback to toggle the selection of a given record based on its id, e.g. onToggleItem(456)
    onUnselectItems, // callback to clear the selection, e.g. onUnselectItems();
    // misc
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Posts'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = controllerProps;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  let pagination = <Pagination />;

  if (loaded === false) {
    return (
      <LoadingIndicator />
    );
  }

  return (
    <div>
      <ListToolbar
        filters={props.filters}
        {...controllerProps}
        actions={props.actions}
        permanentFilter={props.filter}
      />
      <BulkActionsToolbar {...controllerProps}>
        {props.bulkActionButtons}
      </BulkActionsToolbar>
      {React.cloneElement(props.children, {
        ...controllerProps,
        hasBulkActions: props.bulkActionButtons !== false,
      })}
      {pagination && React.cloneElement(pagination, controllerProps)}
    </div>
  );
};

export const ProviderServicesList = props => {
  const { basePath, resource, subcategoryid, ...rest } = props;

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let customPerPage = isSmall ? 10 : 5;

  let newBasePath: string, newResource: string;
  // changes these props for list to fetch the services list
  newBasePath = newResource = "providerservice";

  let id = props.record
    && props.record['provider_location']
    && props.record['provider_location'][0]
    && props.record['provider_location'][0]['id'];

  // helps us to filter; /providerservice/?providerlocationid=id
  sessionStorage.setItem('providerlocationid', id);
  let queryService: any = id ? { providerlocationid: id } : '';
  if (id && subcategoryid) {
    queryService = { providerlocationid: id, subcategoryid: subcategoryid }
  }

  return (
    <CustomList
      {...rest}
      basePath={newBasePath}
      resource={newResource}
      hasCreate={false}
      actions={<ListActions label="Add new service" />}
      filterDefaultValues={queryService}
      sort={{ field: 'service_name', order: 'ASC' }}
      perPage={customPerPage}
    >
      <ServicesGrid {...rest} />
    </CustomList>
  );
};
