import React, { Children, isValidElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import { Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { escapePath, FormWithRedirect } from 'ra-core';
import { Box, Divider, IconButton, makeStyles }  from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AbTabbedFormTabs, getTabFullPath } from './AbTabbedFormTabs';

export const AbTabbedForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => <TabbedFormView {...formProps} />}
  />
);

AbTabbedForm.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
};

const useStyles = makeStyles(
  theme => ({
    errorTabButton: { color: theme.palette.error.main },
    content: {
      paddingTop: theme.spacing(1),
    },
  }),
  { name: 'AbTabbedForm' }
);

const TabbedFormView = props => {
  const {
    basePath,
    children,
    className,
    classes: classesOverride,
    form,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect: defaultRedirect,
    resource,
    saving,
    setRedirect,
    submitOnEnter,
    tabs,
    toolbar,
    translate,
    undoable,
    value,
    variant,
    margin,
    ...rest
  } = props;
  
  const tabsWithErrors = findTabsWithErrors(children, form.getState().errors);
  const classes = useStyles({ classes: classesOverride });
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  const url = match ? match.url : location.pathname;
  return (
    <div
      className={clsx('tabbed-form', className)}
      {...tabsanitizeRestProps(rest)}
    >
      <Box display={{ xs: 'inline-flex' }} width={{ xs: '100%' }}>
        <Box flex={2}>
          {React.cloneElement(
            tabs,
            {
              classes,
              record,
              url,
              tabsWithErrors,
            },
            children
          )}
        </Box>
        <Box mr={{ md: '0.75em' }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <div className={classes.content}>
        {/* All tabs are rendered (not only the one in focus), 
          to allow validation on tabs not in focus.
          The tabs receive a `hidden` property, which they'll use to
          hide the tab using CSS if it's not the one in focus.
          See https://github.com/marmelab/react-admin/issues/1866 */}
        {Children.map(
          children,
          (tab, index) =>
            tab && (
              <Route exact path={escapePath(getTabFullPath(tab, index, url))}>
                {routeProps =>
                  isValidElement(tab)
                    ? React.cloneElement(tab, {
                        intent: 'content',
                        resource,
                        record,
                        basePath,
                        hidden: !routeProps.match,
                        variant: tab.props.variant || variant,
                        margin: tab.props.margin || margin,
                      })
                    : null
                }
              </Route>
            )
        )}
      </div>
    </div>
  );
};

TabbedFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  tabs: PropTypes.element.isRequired,
  tabsWithErrors: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
};

TabbedFormView.defaultProps = {
  submitOnEnter: true,
  tabs: <AbTabbedFormTabs />,
  toolbar: false,
};

const tabsanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  staticContext,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  _reduxForm,
  ...props
}) => props;

const findTabsWithErrors = (children, errors) => {
  return Children.toArray(children).reduce((acc, child) => {
    if (!isValidElement(child)) {
      return acc;
    }

    const inputs = Children.toArray(child.props.children);

    if (
      inputs.some(
        input => isValidElement(input) && get(errors, input.props.source)
      )
    ) {
      return [...acc, child.props.label];
    }

    return acc;
  }, []);
};
