import React, {
  HtmlHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Notification, useCheckAuth } from 'react-admin';
import clsx from 'clsx';
import {
  Box,
  Card,
  // Avatar,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
// import LockIcon from '@material-ui/icons/Lock';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { LoginForm } from './LoginForm';
import { AkAppBar } from '../layout';

import INPUTMarkets from '../assets/icon.input.png';

import { AkTheme } from '../stylesheets';

interface Props {
  backgroundImage?: string;
  children: ReactNode;
  classes?: object;
  className?: string;
  staticContext?: StaticContext;
  theme: object;
}

const inputMarkets = `${INPUTMarkets}`;

const useStyles = makeStyles(
  () => ({
    main: {
      display: 'flex',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      background: 'linear-gradient(rgb(248, 250, 252), rgb(208, 222, 217))',
    },
    card: {
      padding: '25px 16px 40px',
      minWidth: 300,
      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      height: '34.85px',
      paddingTop: '0.32rem',
      paddingBottom: '0.32rem',
      marginRight: '1rem',
      fontSize: '1.125rem',
    },
    logo1: {
      marginRight: '1.65rem',
    },
    icon: {
      marginLeft: '40%',
      marginBottom: '12px',
      width: '12.5%',
    }
  }),
  { name: 'AkLogin' }
);

/**
* A standalone login page, to serve as authentication gate to the admin
*
* Expects the user to enter a login and a password, which will be checked
* by the `authProvider.login()` method. Redirects to the root page (/)
* upon success, otherwise displays an authentication error message.
*
* Copy and adapt this component to implement your own login logic
* (e.g. to authenticate via email or facebook or anything else).
*
* @example
*     import MyLoginPage from './MyLoginPage';
*     const App = () => (
*         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
*             ...
*        </Admin>
*     );
*/
const AkLoginPage: React.FunctionComponent<
  Props & HtmlHTMLAttributes<HTMLDivElement>
> = props => {

  const {
    theme,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
  } = props;

  const classes = useStyles();
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  const checkAuth = useCheckAuth();
  const history = useHistory();

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  return (
    <ThemeProvider theme={muiTheme}>
      <AkAppBar className={clsx('entry-login')} />
      <Box
        className={clsx(classes.main, className)}
        {...rest}
        // ref={containerRef}
      >
        <Box width={{ md: '42.5%' }} marginTop={{ md: '-50px' }}>
          <h3 className={clsx('title')}>Welcome to input markets.</h3>
          <div>            
            <ArrowForwardIcon className={clsx('placement')} />
            <h5 className={clsx('sub-title', 'placement')}>As an&nbsp;<span className={clsx('highlight')}>Admin</span>, organize farmers &amp; providers</h5>
          </div>
          <div>            
            <ArrowForwardIcon className={clsx('placement')} />
            <h5 className={clsx('sub-title', 'placement')}>As a&nbsp;<span className={clsx('highlight')}>Provider</span>, manage your services &amp; orders</h5>
          </div>
          <div>            
            <ArrowForwardIcon className={clsx('placement')} />
            <h5 className={clsx('sub-title', 'placement')}>As a&nbsp;<span className={clsx('highlight')}>farmer</span>, dial &#42;284&#42;60&#35; to make orders</h5>
          </div>
        </Box>
        <Box className={clsx('login-section')}>
          <img src={inputMarkets} alt="input-markets" className={classes.icon} />
          <span className={clsx('login-guide-text')}>Login to Input Markets</span>
          <Card className={classes.card}>
            {/* <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div> */}
            {children}
          </Card>
        </Box>
      </Box>
      <Notification />
    </ThemeProvider>
  );
};

AkLoginPage.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  // theme: PropTypes.object,
  staticContext: PropTypes.object,
};

AkLoginPage.defaultProps = {
  theme: AkTheme,
  children: <LoginForm />,
};

export default AkLoginPage;
