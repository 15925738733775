import React from 'react';
import { Admin, fetchUtils, Resource } from 'react-admin';

import drfProvider from '../dataProvider';
import authProvider from '../authProvider';
import i18nProvider from '../i18n';
import AkLoginPage from '../login';
import { AbLayout, AkLogout } from '../layout';
import { Dashboard } from '../dashboard';
import customRoutes from '../customRoutes';

import {
  BASE_URL,
  requests,
  readCookie,
  services,
  subcategories,
  categories,
  cooperatives,
  cooperativebranches,
  coopusers,
  coopservices,
  providers,
  users,
  providerrequests,
  serviceproviderslist,
  farmerscardlist,
  farmerslist
} from '../modules';

let outputsCookie = readCookie('token');

const App = () => {
  const [dataProvider, setDataProvider] = React.useState<null | any>(null);

  let isLoaded = React.useRef(true);

  const fetchDataProvider = async () => {
    const httpClient = (url: string, options: { headers: any }): any => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }
      const token = sessionStorage.getItem('token');
      options.headers.set('Authorization', `Bearer ${token}`);
      return fetchUtils.fetchJson(url, options);
    };

    let dataProviderInstance = await drfProvider( BASE_URL, httpClient );

    setDataProvider(
      // NOTE: dataProviderInstance can be a function
      () => dataProviderInstance
    );
  };

  const setCookie = () => {
    const url: string = `${process.env.REACT_APP_VERIFY}`;
  
    const request = new Request(url, {
      method: 'POST',
      body: `token=${outputsCookie}`,
      headers: new Headers({ 
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    });

    return fetch(request)
      .then(response => {
        return response.json();        
      })
      .then(response => {
        if (!!response['id'] && !!outputsCookie) {

          sessionStorage.setItem('token', outputsCookie);
          
          const { user_other_roles } = response;
          
          let permissions: Array<string> = user_other_roles;
          
          // Add permissions to session
          sessionStorage.setItem('permissions', JSON.stringify(permissions));
          console.log('got outputs token');

          let verified = sessionStorage.getItem('verified');
          console.log(verified);

          if (!verified){ // undefined or null
            window.location.reload(); // reload page
            sessionStorage.setItem('verified', '1'); // set key/value for "verified"
          }
        };
      });
  };

  React.useEffect(() => {
    fetchDataProvider();
  }, []);

  React.useEffect(() => {

    if (isLoaded && !!outputsCookie) { 
      // verify the cookie     
      setCookie();
    };

    // clean up API call, on unmount
    return function cleanup() { 
      isLoaded.current = false; 
    };

  }, [isLoaded, outputsCookie]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  };

  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={AkLoginPage}
      logoutButton={AkLogout}
      layout={AbLayout}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
    >
      {/* access users */}
      <Resource name="farmers" {...users} />
      <Resource name="provider" {...providers} />
      
      {/* service categorizaton */}
      <Resource name="services" {...services} />
      <Resource name="categories" {...categories} />
      <Resource name="subcategories" {...subcategories} />
      {/* group related */}
      <Resource name="cooperatives" {...cooperatives} />
      <Resource name="cooperativeservices" {...coopservices} />
      <Resource name="cooperativebranches" {...cooperativebranches} />
      <Resource name="groupusers" {...coopusers} />
      {/* system requests */}
      <Resource name="saccorequests" {...requests} />
      <Resource name="providerrequests" {...providerrequests} />
      <Resource name="systemfarmerrequest" />
      {/* Other */}
      <Resource name="locationviewsearch" />
      <Resource name="programs" />
      <Resource name="usersaddress" />
      <Resource name="usersview" />
      <Resource name="user-program" />
      <Resource name="providerservice" />
      <Resource name="importproviderservices" />
      <Resource name="completerequest" />

      {/* reports */} 
      <Resource name="serviceproviders" {...serviceproviderslist}/>
      <Resource name="farmersreport" {...farmerscardlist}/>
      <Resource name="farmersclassificationdetails" {...farmerslist}/>
    </Admin>
  );
};

export default App;
