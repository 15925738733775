import React, { FC } from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, Provider } from '../../types';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

const ProviderLinkField: FC<FieldProps<Provider>> = props => {
  const { record } = props;
  const classes = useStyles();

	return record ? (
    <Link to={`/provider/${record.id}`} className={classes.root}>
			{record.business_name}
    </Link>
  ) : null;
};

ProviderLinkField.defaultProps = {
  source: 'business_name',
  label: 'Name',
};

export default ProviderLinkField;
