import React from 'react';
import { useForm } from 'react-final-form';
import {
  Create,
  FormDataConsumer,
  TextInput,
  SimpleForm,
  SaveButton,
  SelectInput,
  ReferenceInput,
  Toolbar,
  useRefresh,
} from 'react-admin';
import { CancelButton } from '../../layout';
import { SectionTitle } from '../utils';
import { Precise } from '../validation';
import { serviceCreateStyles } from '../stylesheets';

const ProviderServiceCreateToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

const ServiceSelection = props => {
  const classes = serviceCreateStyles();
  const form = useForm();

  return (
    <React.Fragment>
      <ReferenceInput
        label="Subcategory"
        source="subcategoryid"
        reference="subcategories"
        className={classes.input}
      >
        <SelectInput 
          optionText="sub_category_name"
          onChange={value => form.change('services', null)}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.subcategoryid &&
          <ReferenceInput 
            label="Service" 
            source="serviceid" 
            reference="services"
            className={classes.input}
            filter={{ subcategoryid: formData.subcategoryid }}
          >
            <SelectInput 
              optionText="service_name"
              {...rest} 
            />
          </ReferenceInput>          
        }
      </FormDataConsumer>
    </React.Fragment>
  );
};

const ProviderServiceCreate = ({ onCancel, ...props }) => {
  const refresh = useRefresh();
  const classes = serviceCreateStyles();
  return (
    <Create {...props} title=" " successMessage="You've registered a service.">
      <SimpleForm
        className="services"
        toolbar={
          <ProviderServiceCreateToolbar
            onCancel={onCancel}
            refresh={refresh()}
          />
        }
      >
        <ServiceSelection />
        <TextInput
          label="Cost"
          source="service_cost"
          className={classes.input}
          validate={Precise}
        />
        <SectionTitle label="resources.providerservice.fieldGroups.terms" />
        <TextInput
          label="Delivery time"
          source="turn_around_time"
          className={classes.input}
        />
        <TextInput
          label="Deposit (%)"
          source="down_payment_percent"
          className={classes.input}
          validate={Precise}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProviderServiceCreate;
