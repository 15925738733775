import React, { useCallback, FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles';

import LockIcon from '@material-ui/icons/Lock';
import { useTranslate, useLogout } from 'react-admin';

interface Props {
  className?: string;
  redirectTo?: string;
  icon?: ReactElement;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    menuItem: {
      color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(2.25) },
  }),
  { name: 'RaLogout' }
);

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
const LogoutWithRef: FunctionComponent<
  Props & MenuItemProps<'li', { button: true }> // HACK: https://github.com/mui-org/material-ui/issues/16245
> = React.forwardRef(function Logout(props, ref) {
  const {
    className,
    classes: classesOverride,
    redirectTo,
    icon,
    ...rest
  } = props;
  
  const classes = useStyles(props);
  const translate = useTranslate();
  const logout = useLogout();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback(() => logout(redirectTo), [
    redirectTo,
    logout,
  ]);

  return (
    <MenuItem
      className={clsx('logout', classes.menuItem, className)}
      onClick={handleClick}
      ref={ref}
      {...rest}
    >
      <div>
        <Avatar>
          <ListItemIcon className={classes.icon}>
          {icon ? icon : <LockIcon />}
        </ListItemIcon>
        </Avatar>        
        {translate('ra.auth.logout')}
      </div>
      <div className={clsx('txt')}>Thanks for using</div>
      <div className={clsx('txt')}>Input Markets</div>
    </MenuItem>
  );
});

LogoutWithRef.propTypes = {
  className: PropTypes.string,
  redirectTo: PropTypes.string,
  icon: PropTypes.element,
};

export default LogoutWithRef;
