import React, { useState, useEffect } from 'react';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import AddFunder from './AddFunder';
import FunderActionButton from './FunderActionButton';
import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

export function Funders() {
  const [funders, setFunders] = useState([]);
  const classes = requestStyles();

  const fetchFunders = () => {
    apiCall('', sessionStorage.getItem('token'), 'get', 'funder/')
      .then(fundersData => {
        setFunders(fundersData.results);
      })
      .catch(error => 
        console.error('Error during fetch:', error)
      );
  };
  useEffect(fetchFunders, []);

  return (
    <div className={classes.saccoServicesWrapper}>
      <AddFunder />
      <TableContainer component={Paper}>
        <Table
          className={classes.saccoServiceTable}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.saccoServiceTableCell}>#</TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Funder Names
              </TableCell>
              <TableCell
                className={classes.saccoServiceTableCell}
                align="center"
              >
                Total Programs
              </TableCell>
              <TableCell
                className={classes.saccoServiceTableCell}
                align="center"
              >
                Active Programs
              </TableCell>
              <TableCell
                className={classes.saccoServiceTableCell}
                align="center"
              >
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funders.length > 0 ? (
              funders.map((funder, index) => (
                <TableRow className={classes.saccoServiceTableRow}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <strong>{funder['funder_name']}</strong>
                    {funder['short_name'].length > 0 && (
                      <>
                        <br />({funder['short_name']})
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      variant="outlined"
                      className={classes.chipInfo}
                      label={funder['active_programs'].length + funder['inactive_programs']}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      variant="outlined"
                      className={classes.chipSuccess}
                      label={funder['active_programs'].length}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FunderActionButton funder={funder}/>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.saccoServiceTableRow}>
                <TableCell align="center" colSpan="6">
                  No Funders Added
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
