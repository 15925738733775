import React, { FC } from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps, Group } from '../../types';

const useStyles = makeStyles({
  root: {
    color: '#3f51b5',
  },
});

const CooperativesLinkField: FC<FieldProps<Group>> = props => {
  const { record } = props;
  const classes = useStyles();

	return record ? (
    <Link to={`/cooperativebranches/${record.id}`} className={classes.root}>
			{record.cooperative_branch_name}
    </Link>
  ) : null;
};

CooperativesLinkField.defaultProps = {
  source: 'cooperative_branch_name',
  label: 'Group Name',
};

export default CooperativesLinkField;
