import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiTab from '@material-ui/core/Tab';
import { useTranslate } from 'react-admin';

const sanitizeRestProps = ({
  contentClassName,
  label,
  icon,
  value,
  translate,
  ...rest
}) => rest;

const hiddenStyle = { display: 'none' };

export const AbFormTab = props => {
  const {
    basePath,
    className,
    contentClassName,
    children,
    hidden,
    icon,
    intent,
    label,
    margin,
    record,
    resource,
    variant,
    value,
    ...rest
  } = props;

  const translate = useTranslate();

  const renderHeader = () => (
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      className={clsx(
        'form-tab',
        {
          'not-farmer':
            // TODO: refactor based on user_system_role
            label === 'Farmer Details' && record.is_farmer === false,
          'not-provider':
            // TODO: refactor based on user_system_role
            label === 'Provider Details' && record.is_provider === false,
        },
        className
      )}
      component={Link}
      to={{ pathname: value }}
      {...sanitizeRestProps(rest)}
    />
  );

  const renderContent = () => (
    <span style={hidden ? hiddenStyle : null} className={contentClassName}>
      {React.cloneElement(children, {
        basePath,
        children,
        intent,
        record,
        resource,
      })}
    </span>
  );

  return record
    ? intent === 'header'
      ? renderHeader()
      : renderContent()
    : null;
};

AbFormTab.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	children: PropTypes.node,
	intent: PropTypes.oneOf(['header', 'content']),
	hidden: PropTypes.bool,
	icon: PropTypes.element,
	label: PropTypes.string.isRequired,
	path: PropTypes.string,
	value: PropTypes.string,
};

AbFormTab.displayName = 'AbFormTab';
