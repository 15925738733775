import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import ProgramStepper from './ProgramStepper';
import { requestStyles } from '../stylesheets';

const AddProgram = () => {
  const [open, setOpen] = useState(false);
  const classes = requestStyles();

  const closeAddProgramDialog = () => {
    setOpen(false);
  };

  const addProgramDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        className={classes.addFunderButton}
        variant="outlined"
        color="primary"
        onClick={addProgramDialog}
      >
        <AddIcon /> Add Program
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={closeAddProgramDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeAddProgramDialog}
          className={classes.programDialog}
        >
          Add Program
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeAddProgramDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProgramStepper />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddProgram;
