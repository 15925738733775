import { AuthProvider } from 'ra-core';
import { BASE_URL, createCookie, eraseCookie } from './modules';

console.log(document.cookie);

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const url = BASE_URL + '/token-auth/';

    const request = new Request(url, {
      method: 'POST',
      body: `username=${username}&password=${password}`,
      headers: new Headers({ 
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error('Wrong username or password. Try again!');
        }
        return response.json();
      })
      .then(({ token, user, cooperative_branch, provider }) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('id', user.id);
        sessionStorage.setItem('firstName', user.first_name);
        sessionStorage.setItem('lastName', user.last_name);
        sessionStorage.setItem('providerlocationid', provider ? provider.providerlocationid : null);
        sessionStorage.setItem('providerId', provider ? provider.id : null);
        sessionStorage.setItem('hasServices', 'false');
        sessionStorage.setItem(
          'saccoName',
          cooperative_branch 
            && cooperative_branch.results 
              && cooperative_branch.results[0]!== undefined
                ? cooperative_branch.results[0].sacco_name : null
        );

        let permissions: Array<string> = [];

        const { user_system_role } = user;

        // populate the permissions array
        user_system_role && user_system_role.map(
          value => permissions.push(value.key)
        );

        sessionStorage.setItem('permissions', JSON.stringify(permissions));
      })
      .then(() =>
        // create a cookie
        createCookie('token', '55a28a40d13b2e5d04be9c53bc0e9546ff892f5c', 7)
      );
  },

  logout: () => {
    sessionStorage.clear(); // clear sessionstorage
    eraseCookie('token');   // delete cookie

    return Promise.resolve();
  },

  checkError: error => {
    const status = error.status;
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    if (status === 401 || status === 403) {
      if (permissions !== undefined) {
        return Promise.resolve();
      }
      sessionStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    // "token" is either set at login or when we receive a valid cookie
    // "verified" is set when we cookie is valid (support page reload)
    sessionStorage.getItem('token') || sessionStorage.getItem('verified') ? Promise.resolve() : Promise.reject(),
    
  getPermissions: () => {
    // We assert that permissions isn't 'null' (@typescript)
    let permissions = JSON.parse(sessionStorage.getItem('permissions')!);

    return permissions
      ? Promise.resolve(permissions)
      : Promise.reject('Unknown method');
  },
};

export default authProvider;
