import React, { useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  Datagrid, 
  DateField, 
  List,
  useRefresh 
} from 'react-admin';
import { Divider, Tabs, Tab, useMediaQuery } from '@material-ui/core';

import ProviderReferenceField from '../providers/ProviderReferenceField';
import { NbServicesField, TypeOfServices } from './NbItemsField';
import SystemProviderServicesShow from './SystemProviderServicesShow';
import { ActionButton, ListActions } from '../../layout';

import { userDatagridStyles } from '../stylesheets';
import { dialogStyles } from '../stylesheets';

const StyledTabbedDatagrid = props => {
  const classes = userDatagridStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return <TabbedDatagrid classes={classes} isXSmall={isXSmall} {...props} />;
};

class TabbedDatagrid extends React.Component {
  tabs = [
    { id: 'unapproved', name: 'UNAPPROVED' },
    { id: 'approved', name: 'APPROVED' },
  ];

  state = { unapproved: [], approved: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props; // eslint-disable-line
    value === 'approved'
      ? setFilters({ approved: true, status: value })
      : setFilters({ approved: false, status: value });
  };

  render() {
    const { unapproved, approved } = this.state;
    const { isXSmall, classes, filterValues, ...props } = this.props;

    return (
      <>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.tabs.map(choice => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
          <div>
            {filterValues.status === 'unapproved' && (
              <Datagrid
                {...props}
                ids={unapproved}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <ProviderReferenceField />
                <DateField label="Requested On" source="added_added" showTime />
                <TypeOfServices />
                <NbServicesField />
                <ActionButton
                  MenuOptions={ActionServiceMenu}
                  {...props}
                />
              </Datagrid>
            )}
            {filterValues.status === 'approved' && (
              <Datagrid
                {...props}
                ids={approved}
                optimized
                classes={{ headerCell: classes.heading }}
              >
                <ProviderReferenceField />
                <DateField label="Requested On" source="added_added" showTime />
                <TypeOfServices />
                <NbServicesField />
                <ActionButton
                  MenuOptions={ActionServiceMenu}
                  {...props}
                />
              </Datagrid>
            )}
          </div>
      </>
    );
  }
}

const SystemProviderServicesList = props => {
  const classes = dialogStyles();
  const history = useHistory();
  const refresh = useRefresh();

  const handleClose = useCallback(() => {
    history.push('/providerservice');
    refresh();
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <List
        {...props}
        // passing "hasCreate=false" hides "register" option
        actions={<ListActions hasCreate={false} />}
        filterDefaultValues={{ status: 'unapproved', approved: false }}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={25}
      >
        <StyledTabbedDatagrid />
      </List>
      <Route path="/providerservice/:id">
        {({ match }) => {
          let isMatch = match && match.params && match.params.id !== 'create';

          return (
            <>
              {/* To avoid any errors if the route does not match,
                * we don't render at all the component in this case
                */}
              {isMatch ? (
                <SystemProviderServicesShow
                  className={classes.detailedServiceContent}
                  id={isMatch && match !== null ? match.params.id : null}
                  onCancel={handleClose}
                  isMatch={isMatch}
                  {...props}
                />
              ) : (
                <div className={classes.detailedServiceContent} />
              )}
            </>
          );
        }}
      </Route>
    </>
  );
};

const ActionServiceMenu = (basePath = '', record = { id: '' }) => [
  {
    label: 'View details',
    path: `${basePath}/${record['id']}/`,
  },
];

export default SystemProviderServicesList;
