import React from 'react';
import clsx from 'clsx';
import {
  BooleanInput,
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  required,
} from 'react-admin';

import { CancelButton } from '../../layout';
import { categoryStyles } from '../stylesheets';

const SubCategoryCreateToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

interface category  {
  input?: string;
  check?: string;
}
const SubCategoryCreate = ({ onCancel, ...props }) => {
  const classes: category = categoryStyles();
  const bool = clsx(classes.input, classes.check);

  return (
    <Create {...props} title=" " successMessage="Subcategory has been created">
      <SimpleForm toolbar={<SubCategoryCreateToolbar onCancel={onCancel} />}>
        <TextInput
          label="Subcategory Name"
          source="sub_category_name"
          validate={required()}
        />
        <ReferenceInput
          label="Category"
          source="categoryid"
          reference="categories"
        >
          <SelectInput optionText="category_name" />
        </ReferenceInput>
        <BooleanInput label="Activate" source="is_active" className={bool} />
      </SimpleForm>
    </Create>
  );
};

export default SubCategoryCreate;
