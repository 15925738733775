import React from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Create } from 'react-admin';

import { ProviderMultiForm } from './form';
import { ProviderTitle } from './ProviderTitle';

const ProviderCreate = (props: any) => {
  const { 
    basePath, 
    hasCreate, 
    hasEdit, 
    hasList, 
    hasShow,
    match,
    permissions,
    ...rest 
  } = props;

  const history = useHistory();

  let superuser = permissions && permissions.includes('superuser'),
       provider = permissions && permissions.includes('provider'),
          agent = permissions && permissions.includes('agent');

  const handleClose = React.useCallback(() => {
    history.push(basePath);
  }, [history, basePath]);

  return props.permissions !== undefined ? (
    (superuser || provider || agent) ? (
      <Create 
        {...props}
        title={<ProviderTitle />}
      >
        <ProviderMultiForm onCancel={handleClose} {...rest} />
      </Create>
    ) : (
      <Redirect to="/" />
    )
  ) : null;
};

export default ProviderCreate;
