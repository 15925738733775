import React from 'react';
import clsx from 'clsx';
import {
  BooleanInput,
  Create,
  TextInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  required,
} from 'react-admin';

import { CancelButton } from '../../layout';
import { categoryStyles } from '../stylesheets';

const CategoryCreateToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" />
    <CancelButton onCancel={onCancel} />
  </Toolbar>
);

interface category  {
  input?: string;
  check?: string;
}

const CategoryCreate = ({ onCancel, ...props }) => {
  const classes: category = categoryStyles();
  const bool = clsx(classes.input, classes.check);

  return (
    <Create {...props} title=" " successMessage="Category has been created">
      <SimpleForm toolbar={<CategoryCreateToolbar onCancel={onCancel} />}>
        <TextInput
          label="Category Name"
          source="category_name"
          validate={required()}
        />
        <BooleanInput label="Activate" source="is_active" className={bool} />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
