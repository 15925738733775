import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import allSaccoMembersStyle from '../../stylesheets/jss/saccoDashboard';
import CardIcon from './CardIcon';

interface Props {
  value?: number;
}

const AllSaccoRequestsCount: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = allSaccoMembersStyle();

  return (
    <div className={classes.mainallrequests}>
      <CardIcon Icon={ShoppingCartIcon} bgColor="#ff9800" />
      <Card className={classes.card}>
        <Typography color="textSecondary">
          {translate('pos.dashboard.all_requests')}
        </Typography>
        <Typography variant="h5" component="h2">
          {value}
        </Typography>
      </Card>
    </div>
  );
};

export default AllSaccoRequestsCount;
