import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const UserProgramsField = ({ record }) => {
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {/* display only "one" program within <List> */}
      {record.user_program && 
        record.user_program.length >= 1 && 
          record.user_program.map(
            ({ id, short_name }) => 
              <Chip key={id} label={short_name} className={classes.chip} />
      ).slice(0,1)}
      {/* display "and more" if there's more than "one" user program within <List> */}
      {record.user_program && 
        record.user_program.length >= 2 && <i>and more</i>}
    </span>
   ) : null;
};

UserProgramsField.defaultProps = {
  label: 'Program(s)',
  textAlign: 'left',
};

export default UserProgramsField;
