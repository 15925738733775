import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';



import ImageIcon from '@material-ui/icons/Image';
import { requestStyles } from '../../stylesheets';
import apiCall from '../../apiHelper';

export default function AddNewUserBasic() {
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  let userBasicDetails = JSON.parse(sessionStorage.getItem("user_details"));
  if (userBasicDetails === null) {
    userBasicDetails = {
      gender: '',
      user_type: '',
      income_status: '',
      marital_status: ''
    };
  }
  const [basicDetails, setBasicDetails] = useState(userBasicDetails);
  const classes = requestStyles();

  const trim = (x) => {
    return x.replace(/^\s+|\s+$/gm, '');
  }

  const searchUsers = event => {
    event.preventDefault();
    let search = event.target.value
    let user = trim(search);
    setBasicDetails({
      ...basicDetails,
      first_name: user,
      id: ''
    });
    if (user.length > 0) {
      apiCall('', sessionStorage.getItem("token"), 'get', 'usersview/?search=' + user).then((response) => {
        setSearchResults(response.results);
        setShowSearchResults(true);
      }).catch(error => error)
    } else {
      setSearchResults([]);
    }
  }

  const displayResults = () => {
    setShowSearchResults(true);
  };

  const hideResults = () => {
    setShowSearchResults(false);
  };

  const selectUser = (user) => {
    user['user_type'] = user.is_farmer ? 'farmer' : user.is_provider ? 'provider' : 'other';
    setBasicDetails(user);
    hideResults();
    sessionStorage.setItem("user_address", JSON.stringify(user));
  };

  const selectGender = (event) => {
    setBasicDetails({
      ...basicDetails,
      gender: event.target.value
    });
  };

  const selectUserType = (event) => {
    setBasicDetails({
      ...basicDetails,
      user_type: event.target.value
    });
  };

  const selectIncomeStatus = (event) => {
    setBasicDetails({
      ...basicDetails,
      income_status: event.target.value
    });
  };

  const selectMaritalStatus = (event) => {
    setBasicDetails({
      ...basicDetails,
      marital_status: event.target.value
    });
  };

  const handleTextInput = (event) => {
    event.preventDefault();
    const id = event.target.id;
    const value = event.target.value;
    setBasicDetails({
      ...basicDetails,
      [id]: value
    });
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <input type="hidden" value={basicDetails.id} id="user_id" />
          <TextField
            className={classes.formInput}
            id="first_name"
            label="First Name"
            color="primary"
            variant="outlined"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.first_name}
            onFocus={displayResults}
            onFocusOut={hideResults}
            onChange={searchUsers} />
          {searchResults.length > 0 && showSearchResults &&
            <div className={classes.parent}>
              <div className={classes.searchResults}>
                {
                  <List >
                    {searchResults.map((user, index) =>
                      <>
                        <Divider />
                        <ListItem button onClick={() => selectUser(user)}>
                          <ListItemAvatar>
                            <Avatar>
                              <ImageIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={user.full_name + " (" + user.username + ")"}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {user.villagename + " (" + user.districtname + "-" + user.regionname + ")"}
                                </Typography>
                              </React.Fragment>
                            } />
                        </ListItem>
                        <Divider />
                      </>
                    )}
                    <Divider />
                    <ListItem
                      button
                      onClick={hideResults}>
                      <ListItemText
                        className={classes.clearSuggestion}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="primary"
                            >
                              clear suggestion
                            </Typography>
                          </React.Fragment>
                        } />
                    </ListItem>
                    <Divider />
                  </List>
                }
              </div>
            </div>
          }
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="middle_name"
            label="Middle Name"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.middle_name} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="last_name"
            label="Last Name"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.last_name} />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="username"
            label="Primary Contact"
            color="primary"
            variant="outlined"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.username} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="secondary_phone_number"
            label="Sec Contact"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.secondary_phone_number} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            id="nin"
            className={classes.formInput}
            label="NIN"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.nin} />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="email"
            label="Primary Email"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.email} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="secondary_email"
            label="Sec Email"
            variant="outlined"
            color="primary"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.secondary_email} />
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            id="dob"
            className={classes.formInput}
            variant="outlined"
            label="Birthday"
            type="date"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.dob} />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <FormControl
            className={classes.formInput}
            variant="outlined">
            <input type="hidden" id="gender" value={basicDetails.gender} />
            <InputLabel required id="gender_field-label">Gender</InputLabel>
            <Select
              labelId="gender_field-label"
              id="gender_field"
              onChange={selectGender}
              value={basicDetails.gender}
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
              <MenuItem value="O">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formThreeColumn}>
          <FormControl
            className={classes.formInput}
            variant="outlined">
            <input type="hidden" id="user_type" value={basicDetails.user_type} />
            <InputLabel required id="user_type_field-label">User Type</InputLabel>
            <Select
              labelId="user_type_field-label"
              id="user_type_field"
              onChange={selectUserType}
              value={basicDetails.user_type}
            >
              <MenuItem value="provider">Provider</MenuItem>
              <MenuItem value="farmer">Farmer</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formThreeColumn}>
          <FormControl
            className={classes.formInput}
            variant="outlined">
            <input type="hidden" id="income_status" value={basicDetails.income_status} />
            <InputLabel id="income_status_field-label">Income Status</InputLabel>
            <Select
              labelId="income_status_field-label"
              id="income_status_field"
              onChange={selectIncomeStatus}
              value={basicDetails.income_status}
            >
              <MenuItem value="Below 1M">Below 1M</MenuItem>
              <MenuItem value="Btn 1M & 5M">Btn 1M & 5M</MenuItem>
              <MenuItem value="Above 5M">Above 5M</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <FormControl
            className={classes.formInput}
            variant="outlined">
            <input type="hidden" id="marital_status" value={basicDetails.marital_status} />
            <InputLabel id="marital_status_field-label">Marital Status</InputLabel>
            <Select
              labelId="marital_status_field-label"
              id="marital_status_field"
              onChange={selectMaritalStatus}
              value={basicDetails.marital_status}
            >
              <MenuItem value="Single">Single</MenuItem>
              <MenuItem value="Married">Married</MenuItem>
              <MenuItem value="Divorced">Divorced</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formThreeColumn}>
          <TextField
            className={classes.formInput}
            id="occupation"
            label="Occupation"
            color="primary"
            variant="outlined"
            onChange={handleTextInput}
            InputLabelProps={{
              shrink: true,
            }}
            value={basicDetails.occupation} />
        </div>
        <div className={classes.formThreeColumn}>
          <input
            accept="image/*"
            className={classes.profilePhoto}
            id="profile_photo"
            type="file"
          />
          <label
            className={classes.formInput}
            htmlFor="profile_photo">
            <Button variant="contained" color="primary" component="span">
              Upload Profile Photo
            </Button>
          </label>
        </div>
      </div>
    </form>
  );
}
