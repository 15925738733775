import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  withMobileDialog,
} from '@material-ui/core';
import {
  ReferenceField,
  useShowController,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

import { FieldProps, CategoryOfProvider } from '../../types';
import ProviderLinkField from '../providers/ProviderLinkField';
import apiCall from '../apiHelper';

interface DataSet {
  categories?: CategoryOfProvider[];
  count?: number;
  dateadded?: string;
  is_active?: boolean;
  userid?: number;
  [key: string]: any;
}

const useStyles = makeStyles({
  header: { marginBottom: 22 },
  container: { 
    minWidth: '35em', 
    marginLeft: '1em', 
    marginRight: '1em',
    overflowX: 'hidden',
  },
  leftAlignedCell: { textAlign: 'left' },
  rightAlignedCell: { textAlign: 'right' },
  boldCell: { fontWeight: 'bold' },
  detailedServiceContent: {},
});

const SystemProviderServicesShow: FC<FieldProps<CategoryOfProvider>> = (props: any) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const refresh = useRefresh();
  const history = useHistory();

  const { record } = useShowController(props);
  const [state, setState] = useState<DataSet | null>({});
  const token = sessionStorage.getItem('token');

  const getUnapprovedServices = useCallback(async () => {
    await apiCall(
      '',
      token,
      'get',
      `${props.resource}/${props.id}/?${stringify({ approved: false })}`
    ).then(res => {
      if (res) {
        setState(state => ({ ...state, ...res }));
      }
    }).catch(error => {
      notify('ra.notification.item_doesnt_exist', 'warning');
      redirectTo('list', props.basePath);
    });
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  const getApprovedServices = useCallback(async () => {
    await apiCall(
      '',
      token,
      'get',
      `${props.resource}/${props.id}/`
    ).then(res => {
      if (res) {
        setState(state => ({ ...state, ...res }));
      }
    }).catch(error => {
      notify('ra.notification.item_doesnt_exist', 'warning');
      redirectTo('list', props.basePath);
    });
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.location && !props.location.search) {
      getApprovedServices();
    } else {
      getUnapprovedServices();
    };

    if (state && state.count === 0) {
      history.push('/systemproviderservices');
      refresh();
    };
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  // const handleApprove = url => {      
  //   if (!url.includes('undefined'))  {
  //     apiCall({ "approved": true }, token, 'put', url ).then(res => {
  //       if (res) {
  //         getUnapprovedServices();
  //         notify('Service has been approved');
  //       }
  //     }).catch(error => {
  //       notify('Error: Not approved', 'warning');
  //     });
  //   };
  // };

  if (!record || !state) return null;

  return (   
    <Dialog
      fullScreen={false}
      open={!!props.isMatch}
      onClose={props.onCancel}
      aria-labelledby="services-dialog-title"
      className={classes.detailedServiceContent}
    >
      {/* {state.count && state.count !== 0 
        ? (
          <> */}
            <DialogTitle id="services-dialog-title">
              <ReferenceField
                resource="provider"
                reference="provider"
                source="providerid"
                basePath="/provider"
                record={record}
                link={false}
              >
                <ProviderLinkField />
              </ReferenceField>
            </DialogTitle>
            <DialogContent>        
              "Moved this..."
            </DialogContent>
          {/* </>
      ) : (
        null
      )} */}
    </Dialog>
  );
};

export default withMobileDialog()(SystemProviderServicesShow);
