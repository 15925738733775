import React from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const SubCategoryEdit = (props: any) => (
  <Edit {...props} title=" " successMessage="Subcategory has been updated">
    <SimpleForm>
      <TextInput label="Subcategory name" source="sub_category_name" />
      <ReferenceInput
        label="Category"
        source="categoryid"
        reference="categories"
      >
        <SelectInput optionText="category_name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default SubCategoryEdit;
