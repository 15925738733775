import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin';

import { SectionTitle } from '../utils';
import {
  serviceType,
  serviceTypeChoices,
  Precise,
  weightUnits,
} from '../validation';
import { serviceEditStyles } from '../stylesheets';

const ServiceEdit = props => {
  const classes = serviceEditStyles();

  return (
    <Edit {...props} title=" " successMessage="Service has been updated">
      <SimpleForm>
        <TextInput
          label="Service Name"
          source="service_name"
          validate={required()}
        />
        <SelectInput
          label="Type of Service"
          source="service_type"
          choices={serviceTypeChoices}
          className={classes.input}
          validate={serviceType}
        />
        <ReferenceInput
          label="Subcategory"
          source="subcategoryid"
          reference="subcategories"
        >
          <SelectInput optionText="sub_category_name" optionValue="id" />
        </ReferenceInput>
        <SectionTitle label="resources.services.fieldGroups.measure" />
        <TextInput
          label="Quantity"
          source="unit"
          className={classes.input}
          validate={Precise}
        />
        <SelectInput
          label="Unit"
          source="unit_of_measure"
          choices={weightUnits}
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ServiceEdit;
