import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { AddServices } from './AddServices';
import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

export function ManageSaccoServices() {
  const [categories, setCategories] = useState({
    current: '',
    list: [],
  });
  const [subCategories, setSubCategories] = useState({
    current: '',
    list: [],
  });
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const classes = requestStyles();

  const selectServiceCateogry = event => {
    event.preventDefault();
    setCategories({
      ...categories,
      current: event.target.value,
    });
    apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      'subcategories/?categoryid=' + event.target.value
    )
      .then(response => {
        const newSubcategory =
          response['results'].length > 0 ? response['results'][0].id : '';
        setSubCategories({
          current: newSubcategory,
          list: response.results,
        });
        apiCall(
          '',
          sessionStorage.getItem('token'),
          'get',
          'services/?subcategoryid=' + newSubcategory
        )
          .then(response => {
            setServices(response.results);
            apiCall(
              '',
              sessionStorage.getItem('token'),
              'get',
              'cooperativebranchservices?subcategoryid=' + newSubcategory
            )
              .then(response => {
                setSelectedServices(response.results);
              })
              .catch(error => error);
          })
          .catch(error => error);
      })
      .catch(error => error);
  };

  const selectServiceSubCateogry = event => {
    event.preventDefault();
    setSubCategories({
      ...subCategories,
      current: event.target.value,
    });
    apiCall(
      '',
      sessionStorage.getItem('token'),
      'get',
      'services/?subcategoryid=' + event.target.value
    )
      .then(response => {
        setServices(response.results);
        apiCall(
          '',
          sessionStorage.getItem('token'),
          'get',
          'cooperativebranchservices?subcategoryid=' + event.target.value
        )
          .then(response => {
            setSelectedServices(response.results);
          })
          .catch(error => error);
      })
      .catch(error => error);
  };

  const initialiseCategories = () => {
    apiCall('', sessionStorage.getItem('token'), 'get', 'categories/')
      .then(response => {
        if (response.results.length > 0) {
          setCategories({
            current:
              response['results'].length > 0 ? response['results'][0].id : '',
            list: response.results,
          });
          apiCall(
            '',
            sessionStorage.getItem('token'),
            'get',
            'subcategories/?categoryid=' + response.results[0].id
          )
            .then(response => {
              if (response['results'].length > 0) {
                setSubCategories({
                  current:
                    response['results'].length > 0
                      ? response['results'][0].id
                      : '',
                  list: response.results,
                });
                apiCall(
                  '',
                  sessionStorage.getItem('token'),
                  'get',
                  'services/?subcategoryid=' + response.results[0].id
                )
                  .then(response => {
                    setServices(response.results);
                    apiCall(
                      '',
                      sessionStorage.getItem('token'),
                      'get',
                      'cooperativebranchservices?subcategoryid=' +
                        subCategories['current']
                    )
                      .then(response => {
                        setSelectedServices(response.results);
                      })
                      .catch(error => error);
                  })
                  .catch(error => error);
              }
            })
            .catch(error => error);
        }
      })
      .catch(error => error);
  };
  useEffect(initialiseCategories, []);

  const getServiceDetails = serviceid => {
    let service = {};
    for (let c = 0; c < services.length; c++) {
      if (services[c].id === serviceid) {
        service = services[c];
        break;
      }
    }

    return service;
  };

  return (
    <div className={classes.saccoServicesWrapper}>
      <div className={classes.formRow}>
        <div className={classes.formThreeColumn}>
          <FormControl
            size="small"
            className={classes.formInput}
            variant="outlined"
          >
            <input type="hidden" id="gender" value={categories.current} />
            <InputLabel required id="gender_field-label">
              Service Category
            </InputLabel>
            <Select
              labelId="gender_field-label"
              id="gender_field"
              value={categories.current}
              label="Service Category"
              onChange={selectServiceCateogry}
            >
              {categories['list'].map((value, index) => (
                <MenuItem value={value.id}>{value.category_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formThreeColumn}>
          <FormControl
            size="small"
            className={classes.formInput}
            variant="outlined"
          >
            <input type="hidden" id="user_type" value={subCategories.current} />
            <InputLabel required id="user_type_field-label">
              Subcategories
            </InputLabel>
            <Select
              labelId="user_type_field-label"
              id="user_type_field"
              label="Subcategories"
              value={subCategories.current}
              onChange={selectServiceSubCateogry}
            >
              {subCategories['list'].map((value, index) => (
                <MenuItem value={value.id}>{value.sub_category_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.formThreeColumn}>
          <AddServices />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table
          className={classes.saccoServiceTable}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.saccoServiceTableCell}>#</TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Service
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Type
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Measures
              </TableCell>
              <TableCell className={classes.saccoServiceTableCell}>
                Days Payable
              </TableCell>
              <TableCell
                className={classes.saccoServiceTableCell}
                align="center"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedServices.length > 0 ? (
              selectedServices.map((value, index) => (
                <TableRow className={classes.saccoServiceTableRow}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {getServiceDetails(value.id)['service_name']}
                  </TableCell>
                  <TableCell>
                    {getServiceDetails(value.id)['service_type']}
                  </TableCell>
                  <TableCell>
                    {getServiceDetails(value.id)['unit_of_measure']}
                  </TableCell>
                  <TableCell>
                    {getServiceDetails(value.id)['days_payable']}
                  </TableCell>
                  <TableCell>
                    <Switch
                      color="primary"
                      checked={value['is_active']}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.saccoServiceTableRow}>
                <TableCell align="center" colSpan="6">
                  No services found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
