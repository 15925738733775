import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery, Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RequestIcon from '@material-ui/icons/AttachMoney';
import ServiceIcon from '@material-ui/icons/Collections';
import AdminImage from '../../assets/akb_pro.jpg';
import MonthlyRevenue from './MonthlyRevenue';
import AllSaccoMembers from './AllSaccoMembers';
import ServicesOffered from './ServicesOffered';
import AllSaccoRequestsCount from './AllSaccoRequestsCount';
import PendingApproval from './PendingApproval';
import AllRequestsLineGraph from './AllRequestsLineGraph';
import allSaccoMembersStyle from '../../stylesheets/jss/saccoDashboard';
import apiCall from '../../modules/apiHelper';

import { useTranslate, useDataProvider, usePermissions, useVersion, useNotify } from 'react-admin';

interface Props {
	value?: number;
}

interface State {
	allRequestsCount: number;
	pendingRequestsCount: number;
	pendingRequests: any;
	allRequests: any;
}

const mediaUrl: string = `${AdminImage}`;

export default (props: any) => {
	const translate = useTranslate();
	const classes = allSaccoMembersStyle();
	const notify = useNotify();

	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const version = useVersion();

	// Rely on the DOM API to clean side effects
	const abortController = new AbortController();

	const [ state, setState ] = useState<State>({
		allRequests: [],
		allRequestsCount: 0,
		pendingRequestsCount: 0,
		pendingRequests: []
	});

	const getRequestStats = useCallback(
		async () => {
			await apiCall('', sessionStorage.getItem('token'), 'get', 'saccorequests/')
				.then((response) => {
					console.log(response, 'responseresponseresponseresponseresponseresponse');

					const pendingRequests = response.results.filter(
						(requests) => requests.request_status === 'pending'
					);
					setState((state) => ({
						...state,
						allRequests: response.results,
						allRequestsCount: response.results.length,
						pendingRequests: pendingRequests,
						pendingRequestsCount: pendingRequests.length
					}));

					// }
				})
				.catch((error) => console.log(error, 'ooooooooooo'));

			// const { data: requests } = await dataProvider.getList(
			//   `saccorequests/`,
			//   {
			//     // Required params but no need to populate them,
			//     // since we'll just manupilate the user count from this call
			//     filter: {},
			//     sort: {},
			//     pagination: { page: 1, perPage: 50 },
			//   },
			//   { signal: signal }
			// );
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ dataProvider ]
	);
	const handleApprove = (value) => {
		apiCall(
			{ status: 'approved', request_id: value, reason: '' },
			sessionStorage.getItem('token'),
			'post',
			'adminresponceonrequest/'
		)
			.then((response) => {
				if (response) {
					getRequestStats();
					notify('Service has been approved');
				}
			})
			.catch((error) => console.log(error, 'ooooooooooo'));
	};

	useEffect(
		() => {
			function checkMyRights() {
				if (permissions !== undefined) {
					if (permissions.includes('admin')) {
						return getRequestStats();
					}
				}
			}

			checkMyRights();

			return function cleanup() {
				abortController.abort();
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ version, permissions ]
	);

	const { allRequests, allRequestsCount, pendingRequests, pendingRequestsCount } = state;

	console.log(allRequests, allRequestsCount, pendingRequests, pendingRequestsCount, 'pppppppppppppppppppppppppp');

	return isXSmall ? (
		<Card>
			<CardMedia image={mediaUrl} className={classes.media} />
			<CardContent>
				<Typography variant="h5" component="h2">
					{translate('pos.dashboard.welcome.title')}
				</Typography>
				<Typography component="p">{translate('pos.dashboard.welcome.subtitle')}</Typography>
			</CardContent>
			<CardActions style={{ justifyContent: 'flex-end' }}>
				<Button component={Link} to="/provider">
					<RequestIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.aor_button')}
				</Button>
				<Button component={Link} to="/providerservice">
					<ServiceIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.demo_button')}
				</Button>
			</CardActions>
		</Card>
	) : isSmall ? (
		<Card>
			<CardMedia image={mediaUrl} className={classes.media} />
			<CardContent>
				<Typography variant="h5" component="h2">
					{translate('pos.dashboard.welcome.title')}
				</Typography>
				<Typography component="p">{translate('pos.dashboard.welcome.subtitle')}</Typography>
			</CardContent>
			<CardActions style={{ justifyContent: 'flex-end' }}>
				<Button component={Link} to="/provider">
					<RequestIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.aor_button')}
				</Button>
				<Button component={Link} to="/providerservice">
					<ServiceIcon style={{ paddingRight: '0.5em' }} />
					{translate('pos.dashboard.welcome.demo_button')}
				</Button>
			</CardActions>
		</Card>
	) : (
		<div className={classes.flex}>
			<div className={classes.leftCol}>
				<div className={classes.flex}>
					<MonthlyRevenue />
					<AllSaccoRequestsCount value={allRequestsCount} />
				</div>
				<div className={classes.singleCol}>
					<PendingApproval
						handleApproval={handleApprove}
						pendingRequestsCount={pendingRequestsCount}
						pendingRequests={pendingRequests}
					/>
				</div>
				<div className={classes.singleCol}>
					<ServicesOffered />
				</div>
				<div className={classes.singleCol}>
					{allRequests.length !== 0 ? <AllRequestsLineGraph allRequests={allRequests} /> : null}
				</div>
			</div>
			<div className={classes.rightCol}>
				<div className={classes.flex}>
					<AllSaccoMembers value={100} />
				</div>
			</div>
		</div>
	);
};
