import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import apiCall from '../apiHelper';
import { requestStyles } from '../stylesheets';

const AddFunder = () => {
  const [open, setOpen] = useState(false);
  const classes = requestStyles();
  const refresh = useRefresh();

  const closeAddFunderDialog = () => {
    setOpen(false);
  };

  const addFunderDialog = () => {
    setOpen(true);
  };

  const saveFunder = event => {
    event.preventDefault();

    let funder_name = document.getElementById('funder_name').value;
    let short_name = document.getElementById('short_name').value;
    if (funder_name.length > 0) {
      let data = {
        funder_name: funder_name,
        short_name: short_name,
      };
      apiCall(data, sessionStorage.getItem('token'), 'post', 'funder/')
        .then(response => {
          setOpen(false);
          refresh();
        })
        .catch(error => console.error('Error while saving:', error));
    } else {
      document.getElementById('funder_name').focus();
    }
  };

  return (
    <>
      <Button
        className={classes.addFunderButton}
        variant="outlined"
        color="primary"
        onClick={addFunderDialog}
      >
        <AddIcon /> Add Funder
      </Button>
      <Dialog
        open={open}
        onClose={closeAddFunderDialog}
        className={classes.addFunderDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeAddFunderDialog}
          className={classes.requestDialog}
        >
          Add Funder
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeAddFunderDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.formColumFullWidth}>
            <TextField
              id="funder_name"
              label="Funder Names"
              variant="outlined"
              className={classes.formInput}
            />
          </div>
          <br />
          <div className={classes.formColumFullWidth}>
            <TextField
              id="short_name"
              label="Short Name"
              variant="outlined"
              className={classes.formInput}
            />
          </div>
          <br />
          <Button
            className={classes.buttonSaveFunder}
            variant="outlined"
            color="primary"
            onClick={saveFunder}
          >
            <AddIcon /> Save Details
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddFunder;
