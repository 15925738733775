import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Moment from 'react-moment';
import {
  Button,
  Drawer,
  IconButton,
  FormControlLabel,
  lighten,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import CooperativeDropDown from './CooperativeDropDown';
import CoopServicesCreate from '../coopservices/CoopServicesCreate';
import CooperativesCreate from './CooperativesCreate';

import apiCall from '../apiHelper';
import { drawerStyles } from '../stylesheets';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'shortName',
    numeric: true,
    disablePadding: false,
    label: 'Short Name',
  },
  {
    id: 'dateCreated',
    numeric: true,
    disablePadding: false,
    label: 'Date created',
  },
  {
    id: 'active',
    numeric: true,
    disablePadding: false,
    label: 'Active',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{
              paddingLeft: '30px',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.sharedServiceValue}{' '}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export function SharedServices() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('shortName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [sharedServiceValue, setSharedServiceValue] = useState(
    localStorage.getItem('sharedServiceName') ||
      'Savings And Credit Cooperatives'
  );
  const [createCooperative, setCreateCooperative] = React.useState(false);
  const [createSharedService, setCreateSharedService] = React.useState(false);
  const [sharedServices, setSharedServices] = useState();

  const [input, setInput] = useState(
    localStorage.getItem('input') ? localStorage.getItem('input') : 4
  );

  const [cooperatives, setCooperatives] = useState();

  const classesDrawer = drawerStyles();

  const handleCreateCooperativeClose = () => setCreateCooperative(false);
  const handleCreateSharedServiceClose = () => setCreateSharedService(false);
  const handleCreateCooperative = () => setCreateCooperative(true);
  const handleCreateSharedService = () => setCreateSharedService(true);

  const location = useLocation();
  useEffect(() => {
    const handleFetchCoopservices = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperativeservices/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCoopservices(pageNumber, results);
          } else {
            setSharedServices(results);
          }
        })
        .catch(error => error);

    const handleFetchCooperative = (pageNumber, results) =>
      apiCall(
        '',
        sessionStorage.getItem('token'),
        'get',
        'cooperatives/?page=' + pageNumber
      )
        .then(response => {
          response.results.map((coop, index) => results.push(coop));

          if (response.next !== null) {
            pageNumber = parseInt(pageNumber) + 1;
            handleFetchCooperative(pageNumber, results);
          } else {
            setCooperatives(results.reverse());
          }
        })
        .catch(error => error);

    apiCall('', sessionStorage.getItem('token'), 'get', 'cooperatives/')
      .then(response => {
        if (response.next !== null) {
          handleFetchCooperative(2, response.results);
        }
      })
      .catch(error => error);

    apiCall('', sessionStorage.getItem('token'), 'get', 'cooperativeservices/')
      .then(response => {
        if (response.next !== null) {
          handleFetchCoopservices(2, response.results);
        }
      })
      .catch(error => error);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds =
        cooperatives && cooperatives.map(n => n.cooperative_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      cooperatives && cooperatives.length - page * rowsPerPage
    );

  const handleChange = (event, value) => {
    let indexes = sharedServices
      ? sharedServices
          .map(function(e) {
            return e.coop_service_name;
          })
          .indexOf(value ? value.coop_service_name : null)
      : null;

    setInput(indexes);
    setSharedServiceValue(value ? value.coop_service_name : null);
    localStorage.setItem('input', indexes);
    localStorage.setItem(
      'sharedServiceName',
      value ? value.coop_service_name : null
    );
    return value ? value.id : null;
  };

  const permissions = sessionStorage.getItem('permissions');

  return permissions !== null ? (
    permissions.includes('superuser') && (
      <div className={classes.root}>
        <Button
          className={classes.menuItem}
          style={{ float: 'right', color: '#DF2026' }}
          onClick={handleCreateSharedService}
        >
          Create Shared Service
        </Button>

        <Autocomplete
          id="combo-box-demo"
          options={sharedServices}
          getOptionLabel={option => option.coop_service_name}
          style={{ width: 300, marginTop: '20px', marginBottom: '30px' }}
          onChange={handleChange}
          value={sharedServices ? sharedServices[input] : null}
          renderInput={params => (
            <TextField {...params} label="Shared Services" variant="outlined" />
          )}
        />
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            sharedServiceValue={sharedServiceValue}
            numSelected={selected.length}
          />
          <Button
            className={classes.menuItem}
            style={{ float: 'right', color: '#DF2026' }}
            onClick={handleCreateCooperative}
          >
            Create Service
          </Button>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={cooperatives && cooperatives.length}
              />
              <TableBody>
                {stableSort(
                  cooperatives
                    ? cooperatives.filter(cooperative => {
                        let coopServiceId = cooperative.coop_service_id;
                        let sharedServiceId =
                          sharedServices && parseInt(sharedServices[input].id);

                        return coopServiceId === sharedServiceId;
                      })
                    : [],
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{
                            paddingLeft: '30px',
                          }}
                        >
                          {row.cooperative_name}
                        </TableCell>
                        <TableCell align="right">{row.short_name}</TableCell>
                        <TableCell align="right">
                          <Moment
                            className={classes.deliveryDate}
                            format="DD-MM-YYYY"
                          >
                            {row.date_added}
                          </Moment>
                        </TableCell>
                        <TableCell align="right">
                          {row.is_active ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell align="right">
                          <CooperativeDropDown
                            id={row.id}
                            serviceName={row.cooperative_name}
                            dateCreated={row.date_added}
                            shortName={row.short_name}
                            isActive={row.is_active}
                            sharedService={sharedServiceValue}
                            sharedServiceId={row.coop_service_id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={
              cooperatives &&
              cooperatives.filter(cooperative => {
                let coopServiceId = cooperative.coop_service_id;
                let sharedServiceId =
                  sharedServices && parseInt(sharedServices[input].id);

                return coopServiceId === sharedServiceId;
              }).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
        <Drawer
          anchor="right"
          open={createCooperative}
          onClose={handleCreateCooperativeClose}
          className={classesDrawer.drawerContent}
        >
          <CooperativesCreate
            sharedServiceId={sharedServices ? sharedServices[input].id : null}
          />
        </Drawer>
        <Drawer
          anchor="right"
          open={createSharedService}
          onClose={handleCreateSharedServiceClose}
          className={classesDrawer.drawerContent}
        >
          <CoopServicesCreate
            handleCreateSharedServiceClose={handleCreateSharedServiceClose}
          />
        </Drawer>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </div>
    )
  ) : (
    <Redirect to={{ pathname: '/', state: { from: location } }} />
  );
}
