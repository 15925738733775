import React from 'react';
import { Link } from 'react-router-dom';
import {
	Button,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
	MenuItem,
	MenuList,
	makeStyles,
} from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	paper: {
		marginRight: theme.spacing(2),
	},
	menuItem: {
		fontWeight: 398,
		minHeight: '34px',
		paddingTop: '0px',
		paddingBottom: '0px',
	},
	indexedz: {
		zIndex: 99999999,
		paddingRight: '30px',
	},
}));

const ProviderAcionList = (props) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<div className={classes.root}>
			<>
				<div>
					<Button
						size="small"
						ref={anchorRef}
						color="primary"
						variant="outlined"
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
					>
						Action <ArrowDropDownOutlinedIcon />
					</Button>
					<Popper
						className={classes.indexedz}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin:
										placement === 'bottom' ? 'center top' : 'center bottom',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											autoFocusItem={open}
											id="menu-list-grow"
											onKeyDown={handleListKeyDown}
										>
											<MenuItem
												component={Link}
												to={{}}
												className={classes.menuItem}
											>
												view Detail
                                            </MenuItem>
											{!props.is_approved &&
												<MenuItem
													component={Link}
													to={{}}
													className={classes.menuItem}
												>
													Approve
                                                </MenuItem>
											}
											{(props.is_approved && !props.is_active) &&
												<MenuItem
													component={Link}
													to={{}}
													className={classes.menuItem}
												>
													Activate
                                                </MenuItem>
											}
											{(props.is_approved && props.is_active) &&
												<MenuItem
													component={Link}
													to={{}}
													className={classes.menuItem}
												>
													Deactivate
                                                </MenuItem>
											}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</>
		</div>
	);
}
export default ProviderAcionList;
