import React from 'react';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography,
} from '@material-ui/core';

import { useNotify } from 'react-admin';

import { DialogTitle as EditServiceDialogTitle } from './DialogTitle';
import { apiFullCall } from '../../apiHelper';

export const ProviderEditServiceModal = ({
	openDialog, 
	handleClose, 
	specification, 
	service_name 
}) => {
	
	const notify = useNotify();
	const [service_cost, setCost] = React.useState('');

	const handleEditServiceCost = e => {
		const re = /^[0-9\b]+$/;

		if (e.target.value === "" || re.test(e.target.value)) {
			setCost(e.target.value);
		};
	};

	const handleSaveNewCost = () => {

		if (service_cost !== '') {
			apiFullCall(
				{ service_cost: service_cost },
				sessionStorage.getItem('token'),
				'put',
				`providerservice/${specification.id}/`
			).then(res => {

				if (res) {
					const { status } = res;

					if (status === 200) {
						handleClose();
					}	else {
						notify(`Something went wrong, try again.`, 'warning');
					};

				};
			}).catch(
				error => console.error('Error while fetching "categories":', error)
			);
		}
	};

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={openDialog}
			maxWidth={'md'}
		>
			<EditServiceDialogTitle
				id="customized-dialog-title" 
				onClose={handleClose}
			>
				Edit Service
			</EditServiceDialogTitle>
			<DialogContent dividers>
				<Typography gutterBottom>
					<span><b>Service:</b></span>&nbsp;<Chip variant="outlined" label={`${service_name} ( ${specification.unit} ${specification.unit_of_measure} - ${specification.service_cost ? specification.service_cost.toLocaleString() : ``} UGX`} /><br />
					<TextField
						inputProps={{
							maxLength: 7,
						}}
						onChange={handleEditServiceCost} value={service_cost} label="Enter new price/cost" variant="outlined" style={{ minWidth: 460, marginTop: '20px' }} />
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					autoFocus 
					color="default"
					variant="outlined"
					onClick={handleClose}
				>
					Close
				</Button>
				<Button
					color="primary"
					variant="outlined"
					autoFocus onClick={handleSaveNewCost}
				>
					Update
			   </Button>
			</DialogActions>
		</Dialog>
	);
};
