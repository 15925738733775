import React, { useState } from 'react';
import Moment from 'react-moment';
import {
  Button,
  Chip,
  Dialog,
  Divider,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';

import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';

import { requestStyles } from '../stylesheets';

const DialogDetails = ({ record }, source) => {
  const [open, setOpen] = useState(false);
  let requestDate = new Date(record['request_date']);
  let day =
    requestDate.getDate() < 10
      ? '0' + requestDate.getDate()
      : requestDate.getDate();
  let month =
    requestDate.getMonth() < 9
      ? '-0' + (requestDate.getMonth() + 1)
      : requestDate.getMonth() + 1;
  let formatedDate = day + month + '-' + requestDate.getFullYear();

  const classes = requestStyles();

  const viewRequestDetials = () => {
    console.log(record);
    setOpen(true);
  };

  const closeRequestDetails = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={viewRequestDetials}>
        <CalendarViewDayIcon />
      </Button>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={closeRequestDetails}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeRequestDetails}
          className={classes.requestDialog}
        >
          Process Request
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeRequestDetails}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <h3>Request Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Request No."
                  className={classes.formEntry}
                  defaultValue={record['request_number']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Request Date"
                  className={classes.formEntry}
                  defaultValue={formatedDate}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <h3>Farmer Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Farmer"
                  className={classes.formEntry}
                  defaultValue={record['farmer']['farmer_full_name']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Email Address"
                  className={classes.formEntry}
                  defaultValue={record['farmer']['farmer_user_email']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <Divider />
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <RoomIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={record['location']['vallagename']}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {record['location']['parishname'] + "(" + record['location']['districtname'] + ')'}
                    </Typography>
                  </React.Fragment>
                } />
            </ListItem>
            <Divider />
            <h3>Provider Details</h3>
            <div className={classes.formRow}>
              <div className={classes.formColumn}>
                <TextField
                  label="Provider"
                  className={classes.formEntry}
                  defaultValue={record['provider']['provider_full_name']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.formColumn}>
                <TextField
                  label="Email Address"
                  className={classes.formEntry}
                  defaultValue={record['provider']['provider_user_email']}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <h3>Requested Items</h3>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="right">Unit Cost</TableCell>
                    <TableCell align="right">Total Cost</TableCell>
                    <TableCell align="right">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record['farmer_requested_services'].map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">
                          {value['service_name']}
                        </TableCell>
                        <TableCell align="left">
                          {value['number_of_items']}
                          {value['unit_of_measure']}
                        </TableCell>
                        <TableCell align="right">
                          {value['service_cost']}
                        </TableCell>
                        <TableCell align="right">
                          {value['total_cost']}
                        </TableCell>
                        <TableCell align="right">
                          <Chip
                            label={value['service_type']}
                            className={classes.requestItemType}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow key="total">
                    <TableCell className={classes.requestTotal} colSpan="2">
                      Total Cost
                    </TableCell>
                    <TableCell
                      className={classes.requestTotal}
                      colSpan="4"
                      align="right"
                    >
                      {record['total']}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider className={classes.dividerSuccess} />
            {record['approval']['reason'].length > 0 && (
              <>
                <h3>Additional Comments</h3>
                <span>{record['approval']['reason']}</span>
                <br />
                <br />
              </>
            )}
            <span className={classes.approverLabel}>Approved by:</span>
            <br />
            <span className={classes.approver}>
              {record['approval']['approved_by_full_name']}
            </span>
            <br />
            <Moment className={classes.approvalDate} format="DD-MM-YYYY">
              {record['approval']['approval_date']}
            </Moment>
            (
            <Moment className={classes.approvalDate} fromNow>
              {record['approval']['approval_date']}
            </Moment>
            )
            <Divider className={classes.dividerSuccess} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogDetails;
