import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LinearProgress, lighten } from '@material-ui/core';

const dashboardStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
  childDiv: {
    flex: 1,
    margin: '10px 20px 10px 10px',
    padding: '0px',
  },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  membersStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '97%',
    borderRadius: '3px',
    margin: '30px auto',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': { boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' },
    justifyContent: 'space-around',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '84.5%',
    },
  },
  dashboardHeader: {
    border: '1px solid blue',
  },
  title: {
    color: '#497c55',
    padding: '10px',
    margin: '32px auto',
    fontSize: '40.5px',
    fontWeight: '500',
  },
}));

const cardIconStyles = makeStyles({
  card: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
  },
  icon: {
    float: 'right',
    width: 54,
    height: 54,
    padding: 14,
    color: '#fff',
  },
});

const abRequestStyles = makeStyles(theme => ({
  root: {
    flex: '0 0 100%',
    marginTop: 30,
  },
  margin: {
    margin: theme.spacing(1),
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 100%',
    marginLeft: '1em',
    marginTop: 20,
    color: '#000000',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'left',
    padding: 16,
    minHeight: 52,
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    color: '#000000',
  },
}));

const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    backgroundColor: lighten('#cccccc', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#000000',
  },
})(LinearProgress);

/* Dashboard cards */

const headerCardStyles = makeStyles(theme => ({
  main: {
    flex: '0 0 100%',
    marginLeft: '1em',
    marginTop: 10,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      '& > h6': {
        fontSize: '1.5rem',
      },
      '& > span': {
        fontSize: '0.95rem',
      },
    },
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    backgroundColor: '#80C867',
  },
  icon: {
    fontSize: 13.5,
    marginRight: 4.5,
    verticalAlign: 'middle',
  },
  title: {
    textDecoration: 'underline',
    color: '#000000',
  },
}));

const saccoUsersCardSytles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      marginRight: '0',     
    },    
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    background: '#0274C6',
    height: 150,
    [theme.breakpoints.down('sm')]: {
      height: 120, 
      '& > h2': {
        fontSize: '2.5rem'
      },
      '& > p': {
        fontSize: '1.5rem',
      },
    },  
  },
  title: {},
}));

const farmersCardStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginLeft: '1em',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      marginLeft: '0',     
    },    
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    backgroundColor: '#80C867',
    color: '#ffffff',
    height: 150,
    [theme.breakpoints.down('sm')]: {
      height: 120,
      '& > h2': {
        fontSize: '2.5rem'
      },
      '& > p': {
        fontSize: '1.5rem',
      }, 
    },    
  },
  title: {},
}));

const providerCardStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginLeft: '1em',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      marginLeft: '0',     
    },    
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
    height: 150,    
    backgroundColor: '#ECAD59',
    [theme.breakpoints.down('sm')]: {
      height: 120,
      '& > h2': {
        fontSize: '2.5rem'
      },
      '& > p': {
        fontSize: '1.5rem',
      },
    },    
  },
  title: {},
}));

export {
  dashboardStyles,
  cardIconStyles,
  farmersCardStyles,
  headerCardStyles,
  abRequestStyles,
  BorderLinearProgress,
  saccoUsersCardSytles,
  providerCardStyles,
};
