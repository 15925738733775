import React from 'react';
import Chip from '@material-ui/core/Chip';
import { FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const renderService = record => {
  return record && 
    record.categories && 
      record.categories.reduce((acc, item) => acc + item.count, 0);
}

export const NbServicesField = props => (
  <FunctionField {...props} render={renderService} />
);

NbServicesField.defaultProps = {
  label: 'Total',
  textAlign: 'left',
};

export const TypeOfServices = ({ record }) => {
  const classes = useStyles();
  const { servicename } = record;

  return record ? (
    <span className={classes.main}> 
        <Chip label={servicename} className={classes.chip} />
    </span>
  ) : null;
};

TypeOfServices.defaultProps = {
  label: 'Services',
  textAlign: 'left',
  source: 'providerservice',
};

export const SubcategoriesField = ({ record }) => {
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {record.categories &&
        record.categories.map(({ id, categoryname }) =>
          <Chip
            key={id}
            className={classes.chip}
            label={categoryname}
          />
      )}
    </span>
  ) : null;
};

SubcategoriesField.defaultProps = {
  addLabel: true,
  source: 'systemproviderservices',
};
