import React from 'react';
import clsx from 'clsx';
import Downshift from 'downshift';
import {
  BooleanInput,
  TextInput,
} from 'react-admin';
import {
  Box,
  MenuItem, 
  Paper,
} from '@material-ui/core';
import {
  boxNumber,
  Text,
} from '../../validation';

import { AkbInput, AkbTextField, SectionTitle } from '../../utils';
import { userEditStyles } from '../../stylesheets';

interface Style {
  input?: string;
  container?: string;
  details?: string;
  photo?: string;
}

export const CooperativesFormAddress = (props: any) => {
  const classes: Style = userEditStyles();

  const {
    isRegistered,
    // related to village search
    searchable,
    filterValue,
    inputOnChangeVillage,
    downshiftOnChangeVillage,
    // related to district search
    getDistrict,
    filterDistrict,
    inputOnChangeDistrict,
    downshiftOnChangeDistrict
  } = props;

  return (
    searchable.address && (
      <Box>
        <Box display={{ sm: 'block' }} className={clsx('form--subsection')}>
          <SectionTitle label="Location" />
        </Box>
        {isRegistered 
          ? props.record['village_id'] && (
            // edit-mode:
            // render just intial record
            <>
              <Box
                display={{ md: 'flex' }}
                mb={{ xs: '1em' }}
                width={{ md: '100% !important' }}
                className={clsx('address', 'details', 'edit', 'AkRegisterForm-body')}
              >
                <Box flex={3}>
                  <TextInput
                    source="box_number"
                    className={clsx(classes.input, 'branch')}
                    validate={boxNumber}
                  />
                  <TextInput
                    source="physical_address"
                    className={clsx(classes.input, 'branch')}
                    validate={Text}
                  />           
                  <Downshift
                    onChange={downshiftOnChangeVillage}
                    itemToString={item => (item ? item.villagename : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div 
                        className={clsx(
                          classes.container,
                          { 'stretch': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-village',
                          label: 'Search by Village',
                          InputProps: getInputProps({
                            onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-location')}>
                            {getVillageSuggestions(searchable.address, inputValue).map(
                              (item, index) => {
                                const {
                                  villagename,
                                  parishname,
                                  subcountyname,
                                  districtname,
                                } = item;
                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(villagename) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {villagename} ({parishname})<br />
                                    {subcountyname}, {districtname}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>
                </Box>
              </Box>
              <Box display={{ md: 'flex' }} width={{ md: '100% !important' }}>
                <SectionTitle
                  label='*These fields are only filled after you "Search by Village"'
                  className={classes.details}
                />
              </Box>
              <Box
                display={{ md: 'flex' }}
                width={{ md: '100% !important' }}
                className={clsx('address', 'details')}
              >
                <Box flex={2}>
                  <TextInput
                    id="villageId"
                    value={filterValue.id}
                    name="village_id"
                    className={clsx(classes.input, 'hidden')}
                  />
                  <TextInput
                    id="districtId"
                    value={filterValue.districtid}
                    name="district_id"
                    className={clsx(classes.input, 'hidden')}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.villagename}
                    name="villagename"
                    label="Village"
                    className={classes.input}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.parishname}
                    name="parishname"
                    label="Parish"
                    className={classes.input}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.subcountyname}
                    name="subcountyname"
                    label="Subcounty"
                    className={classes.input}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.countyname}
                    name="countyname"
                    label="County"
                    className={classes.input}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.districtname}
                    name="districtname"
                    label="District"
                    className={classes.input}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.regionname}
                    name="regionname"
                    label="Region"
                    className={classes.input}
                  />
                </Box>
                <Box></Box>
              </Box>
            </>
          ) : (
            // create-mode: focus on creating one record
            <>
              <Box
                mb={{ xs: '1em' }}
                display={{ md: 'flex' }}
                flexDirection={{ xs: 'column' }}
                width={{ md: '100% !important' }}
                className={clsx('address', 'details', 'AkRegisterForm-body')}
              >
                <Box flex={2}>
                  <TextInput
                    source="box_number"
                    className={clsx(classes.input, 'branch')}
                    validate={boxNumber}
                  />
                  <TextInput
                    source="physical_address"
                    className={clsx(classes.input, 'branch')}
                    validate={Text}
                  />
                  <BooleanInput
                    label="Active"
                    source="is_active"
                    defaultValue={true}
                    className={clsx('hidden')}
                  />
                </Box>
                <Box>
                  <Downshift
                    onChange={downshiftOnChangeDistrict}
                    itemToString={item => (item ? item.districtname : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div 
                        className={clsx(
                          classes.container,
                          { 'stretch--district': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-district',
                          label: 'District',
                          InputProps: getInputProps({
                            onChange: inputOnChangeDistrict,
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-location')}>
                            {getDistrictSuggestions(getDistrict.districtList, inputValue).map(
                              (item, index) => {
                                const { districtname } = item;

                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(districtname) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {districtname}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>           
                  <Downshift
                    onChange={downshiftOnChangeVillage}
                    itemToString={item => (item ? item.villagename : '')}
                  >
                    {({ selectedItem, getInputProps, getItemProps, highlightedIndex, isOpen, inputValue, getLabelProps }) => (
                      <div 
                        className={clsx(
                          classes.container,
                          { 'stretch': isOpen }
                        )}
                      >
                        {AkbInput({
                          fullWidth: true,
                          classes,
                          name: 'search-village',
                          label: 'Search by Village',
                          InputProps: getInputProps({
                            onChange: e => inputOnChangeVillage(e, filterDistrict['districtid']),
                          }),
                        })}
                        {isOpen && (
                          <Paper square className={clsx('select-location')}>
                            {getVillageSuggestions(searchable.address, inputValue).map(
                              (item, index) => {
                                const {
                                  villagename,
                                  parishname,
                                  subcountyname,
                                  districtname,
                                } = item;
                                const isHighlighted = highlightedIndex === index;
                                const isSelected =
                                  String(selectedItem || '').indexOf(villagename) >
                                  -1;

                                return (
                                  <MenuItem
                                    {...getItemProps({ key: index, index, item })}
                                    selected={isHighlighted}
                                    component="div"
                                    style={{
                                      fontWeight: isSelected ? 500 : 400,
                                    }}
                                  >
                                    {villagename} ({parishname})<br />
                                    {subcountyname}, {districtname}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift> 
                </Box>
              </Box>
              <Box display={{ md: 'flex' }} width={{ md: '100% !important' }}>
                <SectionTitle
                  label='*These fields are only filled after you "Search by Village"'
                  className={clsx(
                    { 'hidden': !filterValue.id },
                    classes.details
                  )}
                />
              </Box>
              <Box
                display={{ md: 'flex' }}
                width={{ md: '100% !important' }}
                className={clsx('address', 'details')}
              >
                <Box flex={2}>
                  <BooleanInput
                    label="Main Branch"
                    source="is_main_branch"
                    defaultValue={true}
                    className={clsx('hidden')}
                  />
                  <AkbTextField
                    id="villageId"
                    value={filterValue.id}
                    name="village_id"
                    label="Village"
                    className={clsx(classes.input, 'hidden')}
                  />
                  <AkbTextField
                    id="districtId"
                    value={filterValue.districtid}
                    name="district_id"
                    label="District"
                    className={clsx(classes.input, 'hidden')}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.villagename}
                    name="villagename"
                    label="Village"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.parishname}
                    name="parishname"
                    label="Parish"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.subcountyname}
                    name="subcountyname"
                    label="Subcounty"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.countyname}
                    name="countyname"
                    label="County"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.districtname}
                    name="districtname"
                    label="District (selected)"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                  <AkbTextField
                    disabled={true}
                    value={filterValue.regionname}
                    name="regionname"
                    label="Region"
                    className={clsx(
                      { 'hidden': !filterValue.id },
                      classes.input
                    )}
                  />
                </Box>
                <Box></Box>
              </Box>
            </> 
        )}
      </Box>
    )
  );
};


const getDistrictSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ districtname }) => {
    const keep =
      (!inputValue ||
        String(districtname)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};

const getVillageSuggestions = (address = [], inputValue) => {
  let count = 0;

  return address.filter(({ villagename }) => {
    const keep =
      (!inputValue ||
        String(villagename)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 10; // max. number of suggestion within list

    if (keep) {
      count += 1;
    }

    // return just the first ten,
    // helps improve performance
    return keep;
  });
};
