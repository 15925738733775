import { makeStyles } from '@material-ui/core/styles';

const gridListStyles = makeStyles(theme => ({
  root: {
    margin: '-2px',
  },
  gridList: {
    width: '100%',
    margin: 0,
  },
  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
}));

const posterStyles = makeStyles({
  root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
  content: { padding: 0, '&:last-child': { padding: 0 } },
  img: {
    width: 'initial',
    minWidth: 'initial',
    maxWidth: '42em',
    maxHeight: '15em',
  },
});

// style to extend
const newStyle = {
  input: { width: 256 },
  price: { width: '7em' },
  width: { width: '7em' },
  height: { width: '7em' },
  stock: { width: '7em' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const serviceCreateStyles = makeStyles(newStyle);

const extendedStyle = {
  ...newStyle,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const serviceEditStyles = makeStyles(extendedStyle);

const thumbNailFieldStyles = makeStyles({
  root: { width: 25, maxWidth: 25, maxHeight: 25 },
});

const useQuickFilterStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const LinkToRelatedServiceStyles = makeStyles({
  icon: { paddingRight: '0.5em' },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

const LinkToRelatedSubcategoryStyles = LinkToRelatedServiceStyles;

const drawerStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const dialogStyles = makeStyles({
  detailedServiceContent: {
    maxWidth: 650,
  },
});

export {
  gridListStyles,
  posterStyles,
  serviceCreateStyles,
  serviceEditStyles,
  useQuickFilterStyles,
  thumbNailFieldStyles,
  LinkToRelatedServiceStyles,
  LinkToRelatedSubcategoryStyles,
  drawerStyles,
  dialogStyles,
};
