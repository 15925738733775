import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const CategoryEdit = (props: any) => (
  <Edit {...props} title=" " successMessage="Category has been updated">
    <SimpleForm>
      <TextInput source="category_name" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
