import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRefresh,
  useNotify,
} from 'react-admin';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { ApproveButton } from '../../layout';
import { Transition, SectionTitle } from '../utils';

import { userEditStyles } from '../stylesheets';
import { apiFullCall } from '../apiHelper';

export const RequestRejectForm = props => {
  const classes = userEditStyles();

  const { isXSmall, rejectionDialog, handleCloseRejectDialog, ...rest } = props; // eslint-disable-line
  
  return (
    <Dialog
      maxWidth="xs"
      open={rejectionDialog}
      TransitionComponent={Transition}
      className={clsx('request--modal')}
      aria-labelledby="reject-dialog-title"
    >
      <DialogTitle
        disableTypography={true} // makes this a <div> not a default <h2>
        id="reject-dialog-title"
      >
        <SectionTitle label="Reject this request" />
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          {...rest}
          className={clsx(
            'request',
            props.className
          )}
          submitOnEnter={false} 
          toolbar={
            <RejectRequestToolbar
              handleCloseRejectDialog={handleCloseRejectDialog}
              isXSmall={isXSmall}
              {...rest}
            />
          }
        >
          <TextInput
            label="Enter a reason"
            source="reason"
            className={classes.input}
            validate={[required('Please provide a reason')]}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

// Handling "create" mode
const RejectRequestToolbar = props => {

  // destructure to pass directly to <ApproveButton>
  const { isXSmall, ...rest } = props;
  const { handleCloseRejectDialog, ...other } = rest;

  return (
    <Toolbar {...other}>
      <ApproveButton
        label="No"
        icon={null}
        isXSmall={isXSmall}
        className={clsx('small--btn')}
        onClick={handleCloseRejectDialog}
      />
      <RejectButton {...rest} /> 
    </Toolbar>
  )  
};

const RejectButton = props => {
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();

  // destructure to pass "rest" to <SaveButton>
  const { handleCloseRejectDialog, onClose, record, ...rest } = props;

  // destructure "reason" to pass to API call
  const { reason } = formState && formState.values;

  const token = sessionStorage.getItem('token');

  const [ loading, setLoading ] = React.useState(false); // eslint-disable-line

  const rejectionData = {
    request_id: record && record['id'],
    reason: reason,
    status: 'cancelled',
  };

  const handleRejection = React.useCallback(() => {

    // Double-check to ensure that "reason" and "id" are defined or not empty
    if (!!reason && !!record['id']) {

      apiFullCall(
        rejectionData, 
        token, 
        'post', 
        `providerrequests/` 
      ).then(res => {
        if (res) {
          setLoading(!loading);
          const { status } = res;

          if (status === 200 || status === 201) {

            // when successfully submitted
            notify('The request has been rejected', 'info');

            // go back to requests list
            handleCloseRejectDialog();
            onClose();
            refresh();
            
          } else if (status === 400) {
            notify(`Please check again and resend.`, 'warning');
          } else if (status >= 500) {
            notify(`Server error, please try again later.`, 'warning');          
          };

          setLoading(!loading);
        };

      }).catch(
        error => console.error('Error while sending:', error)
      );

    } else {

      // inform user to enter a "reason"
      notify(`Please provide a reason`, 'warning');
    }

  }, [rejectionData]); // eslint-disable-line react-hooks/exhaustive-deps

  // define a component that returns "null"
  // And pass it to <SaveButton> to override the "save" icon
  const NoIcon = () => null;

  return (
    <SaveButton
      {...rest}
      icon={<NoIcon />}
      label={loading ? "" : "Yes"}
      submitOnEnter={false}
      saving={loading}
      onSave={handleRejection} 
    />
  );
};
